.mainButton {
    display: inline-flex;
    background-color: var(--white);
    font-size: 14px;
    text-transform: none;
    color: #84929e;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #f2f2f2;
    outline: 0;
    min-width: 70px;
    padding: 8px 10px;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    transition: all ease 0.3s;
    position: relative;
}

.mainButton .buttonLoading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff90;
}

.mainButton.fullWidth {
    width: 100%;
}

.mainButton.withOutIconBtn {
    justify-content: center;
}

.mainButton.disabled,
.mainButton.disabled[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
}

.mainButton:hover {
    background-color: #f2f2f2;
}

.mainButton span.buttonIcon {
    display: flex;
}

.mainButton span.buttonTitle {
    flex: 1;
    text-align: left;
}

.mainButton.withOutIconBtn span.buttonTitle {
    text-align: center;
}

.mainButton.left {
    align-items: center;
    flex-direction: row;
}

.mainButton.left .buttonIcon {
    margin-right: 4px;
    margin-left: -4px;
}

.mainButton.right {
    align-items: center;
    flex-direction: row;
}

.mainButton.right .buttonIcon {
    margin-left: 4px;
    margin-right: -4px;
    order: 1;
}

.mainButton.headerBtn {
    font-size: 14px;
    color: #84929e;
    font-weight: 400;
    padding: 8px 10px;
}

.mainButton.RedFillBtn {
    background-color: #f44336;
    border-color: #f44336;
    color: var(--white);
    padding: 8px 10px;
}

.mainButton.RedFillBtn:hover {
    background-color: var(--white);
    border-color: #f44336;
    color: #f44336;
}

.mainButton.GrayFillBtn {
    background-color: #f6f8fc;
    border-color: #f6f8fc;
    color: var(--black);
    padding: 8px 10px;
}

.mainButton.GrayFillBtn:hover {
    background-color: var(--white);
    border-color: rgb(32, 31, 31);
    color: rgb(32, 31, 31);
}

.mainButton.RedOutlineBtn {
    background-color: var(--white);
    border-color: #f44336;
    color: #f44336;
    padding: 8px 10px;
}

.mainButton.RedOutlineBtn:hover {
    background-color: #f44336;
    border-color: #f44336;
    color: var(--white);
}

.mainButton.BlueFillBtn {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--blue-invert);
    padding: 8px 10px;
}

.mainButton.BlueFillBtn:hover {
    background-color: var(--white);
    border-color: var(--dark-blue);
    color: var(--dark-blue);
}

.mainButton.BlueOutlineBtn {
    background-color: var(--white);
    border-color: var(--dark-blue);
    color: var(--dark-blue);
    padding: 8px 10px;
}

.mainButton.BlueOutlineBtn:hover {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--blue-invert);
}

.mainButton.WhiteFillBtn {
    background-color: var(--white);
    border-color: var(--white);
    color: #26262b;
}

.mainButton.WhiteFillBtn:hover {
    background-color: #26262b;
    border-color: #26262b;
    color: var(--white);
}

.mainButton.WhiteOutlineBtn {
    background-color: var(--white);
    border-color: #26262b;
    color: #26262b;
}

.mainButton.WhiteOutlineBtn:hover {
    background-color: #26262b;
    border-color: #26262b;
    color: var(--white);
}

.mainButton.BlackFillBtn {
    background-color: #26262b;
    border-color: #26262b;
    color: var(--white);
}

.mainButton.BlackFillBtn:hover {
    background-color: var(--white);
    border-color: #26262b;
    color: #26262b;
}

.mainButton.BlackOutlineBtn {
    background-color: var(--white);
    border-color: #26262b;
    color: #26262b;
}

.mainButton.BlackOutlineBtn:hover {
    background-color: #26262b;
    border-color: #26262b;
    color: var(--white);
}

.mainButton.SimpleLink {
    padding: 0;
    border: 0;
    background-color: transparent;
}

.mainButton.SimpleLink:hover {
    background-color: transparent;
    text-decoration: underline;
    color: var(--dark-blue);
}

.mainButton.SimpleLink span.buttonIcon {
    margin-left: 0;
}

.mainButton.roundBtn {
    border-radius: 50px;
    background-color: #ebecec;
    color: #2e3033;
    font-weight: 600;
    padding: 4px 13px;
    min-width: fit-content;
    border-color: #ebecec;
}

.mainButton.roundBtn.RedFillBtn {
    border-radius: 50px;
    font-weight: 600;
    padding: 4px 13px;
    min-width: auto;
    background-color: #f44336;
    border-color: #f44336;
    color: var(--white);
}

.mainButton.roundBtn.RedFillBtn:hover {
    background-color: var(--white);
    border-color: #f44336;
    color: #f44336;
}

// Responsive

@media (min-width: 1559px) and (max-width: 1800px) {
}

@media (min-width: 1367px) and (max-width: 1600px) {
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .mainButton.headerBtn {
        font-size: 12px;
        padding: 6px 8px;
        min-width: 50px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .mainButton.headerBtn {
        font-size: 13px;
        padding: 7px 14px;
        min-width: 50px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .mainButton.headerBtn {
        font-size: 13px;
        padding: 7px 14px;
        min-width: 50px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .mainButton.headerBtn {
        font-size: 13px;
        padding: 7px 14px;
        min-width: 50px;
    }
}
