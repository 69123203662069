.RatingMain {
    display: inline-flex;
    align-items: center;
}

.RatingMain .ReviewsIcon {
    font-size: 20px;
    display: flex;
    margin: 0 2px;
    color: #e6e6e6;
}

.RatingMain .ReviewsIcon:first-child {
    margin-left: 0;
}

.RatingMain .ReviewsIcon:last-child {
    margin-right: 0;
}

.RatingMain .ReviewsIcon.selected {
    color: #ffb400;
}
