.pageContainer {
    flex: 1;
    position: relative;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.pageContainer .pageContainerRow {
    height: 100%;
    display: flex;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: hidden !important;
}
