.AddNewCardDialog .ANCDZCS {
    display: flex;
    margin-bottom: 15px;
    column-gap: 15px;
}

.AddNewCardDialog .ANCDZCS .fieldControl {
    margin: 0;
    flex: 1;
}

.AddNewCardDialog .ANCBtn {
    display: flex;
}

.AddNewCardDialog .ANCBtn button {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
}
.TACBody {
    padding: 10px;
}

.AddNewCardDialog .BodyBox .BodyBoxInner .ANCGTC {
    text-transform: lowercase;
    font-weight: 600;
    color: var(--dark-blue);
}
