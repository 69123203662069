.CardInput {
    display: flex;
    align-items: center;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
}

.CardInput.CardInputError {
    border-color: #f44336;
    box-shadow: 0 0 3px #f44336;
}

.CardInput.CardInputFocused {
    border-color: var(--dark-blue);
    box-shadow: 0 0 3px var(--dark-blue);
}

.CardInputWarper .CardError {
    color: #f44336;
    font-size: 14px;
    font-weight: 500;
    padding-top: 2px;
}

.CardInput .CardInputIcon {
    font-size: 34px;
    padding: 3px 8px;
    display: flex;
}

.CardInput .CardInputWrapper {
    flex: 1;
    display: flex;
}

.CardInput .CardInputWrapper input {
    height: auto;
    font-size: 14px;
    font-weight: 500;
    color: #7b89a4;
    padding: 10px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
    border: 0;
}

/* Chrome, Safari, Edge, Opera */

.CardInput .CardInputWrapper input[type='number']::-webkit-outer-spin-button,
.CardInput .CardInputWrapper input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.CardInput .CardInputWrapper input[type='number'] {
    -moz-appearance: textfield;
}

.CardInput .CardInputWrapper input.CIWNumber {
    width: 50%;
}

.CardInput .CardInputWrapper input.CIWDate {
    width: 25%;
    text-align: center;
}

.CardInput .CardInputWrapper input.CIWCVC {
    width: 25%;
    text-align: center;
}

// Responsive
@media (min-width: 1560px) and (max-width: 1800px) {
}
@media (min-width: 1367px) and (max-width: 1559.98px) {
    .CardInput .CardInputIcon {
        font-size: 24px;
    }
}
@media (min-width: 1280px) and (max-width: 1366.98px) {
    .CardInput .CardInputIcon {
        font-size: 24px;
    }
}
@media (min-width: 1024px) and (max-width: 1279.98px) {
    .CardInput .CardInputIcon {
        font-size: 24px;
    }
}
@media (min-width: 992px) and (max-width: 1023.98px) {
    .CardInput .CardInputIcon {
        font-size: 24px;
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .CardInput .CardInputIcon {
        font-size: 24px;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .CardInput .CardInputIcon {
        font-size: 24px;
    }
}
@media (max-width: 575.98px) {
    .CardInput .CardInputIcon {
        font-size: 24px;
    }

    .DCDSOptionList .COPICPMLCardOption .CardInput .CardInputWrapper input {
        color: #000;
        font-weight: 600;
        padding: 10px 5px;
    }
}
