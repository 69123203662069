.ColorPickerMain {
    display: flex;
    align-items: center;
}

.ColorPickerMain .CPMLabel {
    flex: 1;
    padding-right: 10px;
    font-weight: 600;
    color: #666;
    font-size: 14px;
}

.ColorPickerMain .CPMLColor {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    box-shadow: 0 0 10px #a7a7a7;
    border: 2px solid #FFF;
}

