.SessionsPage {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    background: rgba(0, 0, 0, 0.01);
}

.SessionsPage .container {
    transform: translateY(-3rem);
}

.SessionsPage .SPHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SessionsPage .SPHeader .SPHLogo {
    display: flex;
}

.SessionsPage .SPHeader .SPHLogo {
    width: 200px;
    height: 45px;
    object-fit: contain;
    object-position: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.SessionsPage .SPHeader .SPHTitle {
    font-size: 1.5rem;
    font-weight: thin;
    margin: 0.4rem 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        font-size: 1.1rem;
    }
}

.SPListContainer {
    margin: 0px 20px;
    box-shadow: rgba(17, 17, 26, 0.04) 0px 4px 16px, rgba(17, 17, 26, 0.02) 0px 8px 32px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.SPLItem {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    background-color: #fff;
    border-radius: 5px;

    @media (max-width: 600px) {
        padding: 1.6rem 1.5rem;
    }

    &:hover {
        .SPLIACTABtn svg {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

.SessionsPage .SPListContainer .SPLItem .SPLITitle {
    font-size: 20px;
    font-weight: 600;
    flex: 1;

    @media (max-width: 600px) {
        font-size: 18px;
    }

    span {
        font-weight: bold;
    }
}

.SessionsPage .SPLLastItem {
    padding: 10px;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    padding: 1.6rem 3rem;
    background: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

    @media (max-width: 600px) {
        font-size: 0.9rem;
        padding: 1.6rem 1.5rem;
    }

    a {
        text-decoration: none;
        color: blue;
        margin-left: 0.2rem;
    }
}

.SPLIActions {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
}

.SPLIACTALabel {
    font-size: 1.2rem;
    font-weight: thin;
    transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    @media (max-width: 600px) {
        font-size: 1rem;
    }
}

.SPLIACTABtn svg {
    transition: all 0.3s;
    transform: translateX(-0.3rem);
    backface-visibility: hidden;
    opacity: 0;
}
