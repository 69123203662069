.IconTitleSubtitle {
    background-color: #f6f8fc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.IconTitleSubtitle:last-child {
    margin-bottom: 0px;
}

.IconTitleSubtitle .IconTitleSubtitleInner {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.IconTitleSubtitle .IconTitleSubtitleInner .ITSIIcon {
    color: var(--dark-blue);
    font-size: 24px;
    background-color: var(--white);
    padding: 5px;
    border: 1px solid var(--dark-blue);
    border-radius: 4px;
}

.IconTitleSubtitle .IconTitleSubtitleInner .ITSIInfo {
    flex: 1;
    padding-left: 10px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    padding-right: 15px;
}

.IconTitleSubtitle .IconTitleSubtitleInner .ITSIInfo a.ITSIITitle {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-blue);
    // text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.IconTitleSubtitle .IconTitleSubtitleInner .ITSIInfo .ITSIIDate {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    // text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.IconTitleSubtitle .IconTitleSubtitleInner .ITSIBtn {
    display: flex;
}
.IconTitleSubtitle .IconTitleSubtitleInner .ITSIBtn button {
    margin-left: 10px;
}
.IconTitleSubtitle .IconTitleSubtitleInner .ITSIBtn button:first-child {
    margin-left: 0px;
}
.PTBudge {
     display: inline-block;
    padding: 3px 5px;
    border: 1px solid;
    font-size: 12px;
    line-height: normal;
    border-radius: 4px;
    min-width: 50px;
    text-align: center;
}
