.TimePicker {
    display: flex;
    flex-direction: column;
}

.TimePicker .TimePickerInput input {
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #7b89a4;
    padding: 10px;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
}

.TimePickerOptionsLists {
    display: flex;
    max-height: 230px;
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    z-index: 9999999;
}

.TimePickerOptionsLists ul {
    padding: 0;
    margin: 0;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.TimePickerOptionsLists ul li {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 10px 15px;
    cursor: pointer;
}

.TimePickerOptionsLists ul li.active {
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}
