.SearchBox {
    position: relative;
    width: 200px;
    background-color: var(--white);
}

.SearchBox.fullWidth {
    width: 100%;
}

.SearchBox input {
    padding: 8px;
    background-color: transparent;
    font-size: 14px;
    color: #000;
    height: 100%;
    z-index: 2;
    border-radius: 4px;
    padding-left: 30px;
    padding-right: 25px;
    outline: 0;
    border: 1px solid #f2f2f2;
    width: 100%;
    position: relative;
}

.SearchBox .SearchIcon {
    position: absolute;
    top: calc(50% - 7.5px);
    color: #929292;
    font-size: 15px;
    left: 8px;
}

.SearchBox .SearchCross {
    position: absolute;
    top: calc(50% - 4px);
    color: var(--dark-blue);
    font-size: 10px;
    right: 10px;
    z-index: 5;
    cursor: pointer;
}

.SearchBox .SearchLoader {
    position: absolute;
    top: calc(50% - 10px);
    color: #929292;
    font-size: 18px;
    right: 5px;
}

.SearchBox .SearchLoader .CircularLoader,
.SearchBox .SearchLoader .CircularLoader .circular-loader {
    width: 20px;
    height: 20px;
}

.PopoverSearchBox.ArrowPopover .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    right: 7px;
}

.PopoverSearchBox.ArrowPopover.bottom-start .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    right: 7px;
}

.PopoverSearchBox.ArrowPopover.bottom-center .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    left: calc(50% - 7px);
}

button.iconButton.searchIconBtn.headerIconBtn {
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}

.PopoverSearchBox.psb1.ArrowPopover .PopoverMainInner::before {
    right: 10px;
}
.PopoverSearchBox.psb2.ArrowPopover .PopoverMainInner::before {
    right: 50px;
}
.PopoverSearchBox.psb3.ArrowPopover .PopoverMainInner::before {
    right: 95px;
}
.PopoverSearchBox.psb5.ArrowPopover .PopoverMainInner::before {
    right: 200px;
}
.PopoverSearchBox.psb6.ArrowPopover .PopoverMainInner::before {
    right: 250px;
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
}

@media (min-width: 1367px) and (max-width: 1560px) {
}

@media (min-width: 1281px) and (max-width: 1366px) {
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .SearchBox {
        width: 160px;
    }

    .SearchBox input {
        padding: 6px;
        font-size: 12px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .SearchBox .SearchIcon {
        top: calc(50% - 6px);
        font-size: 12px;
        left: 6px;
    }
}
