.AppComparePlans {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.AppComparePlans .ACPBOuter {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.AppComparePlans .ACPBody {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    overflow: auto;
    padding: 15px;
}

.AppComparePlans .ACPBody .ACPBHead {
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid #e8eef9;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol {
    flex: 1;
    padding: 15px;
    border-right: 1px solid #e8eef9;
    min-width: 250px;
}
.AppComparePlans .ACPBody .ACPBHead .ACPBHCol:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol:last-child {
    border-right: 0;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCText {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #666;
    padding-bottom: 15px;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCSwitch {
    display: flex;
    padding: 5px 5px;
    background-color: #dbdbdb;
    border-radius: 100px;
    font-size: 14px;
    column-gap: 5px;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCSwitch span {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #000000;
    padding: 5px 7px;
    border-radius: 100px;
    cursor: pointer;
    user-select: none;
    transition: all ease 0.3s;
    flex: 1;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCSwitch span.ACPBHCSActive {
    color: #0083ff;
    background-color: #fff;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCTitle {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    padding-bottom: 7px;
    color: #fff;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCPrice {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCBtn {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCBtn .ACPBHCBtnTooltip {
    width: 100%;
    justify-content: center;
    border-radius: 50px;
    border: 0;

    font-size: 16px;
    font-weight: 600;
    background-color: #ffffff;
}

.AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCBtn button.mainButton {
    width: 100%;
    border-radius: 50px;
    border: 0;
    padding: 13px;
    font-size: 16px;
    font-weight: 600;
    background-color: #ffffff;
}

.AppComparePlans .ACPBody .ACPBCompare {
    background-color: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid #e8eef9;
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.AppComparePlans .ACPBody .ACPBCompare .ACPBCGroup {
    border-bottom: 1px solid #e8eef9;
}

.ACPBCompare .ACPBCGroup .ACPBCGHead {
    padding: 10px;
}

.ACPBCompare .ACPBCGroup .ACPBCGHead .ACPBCGHInner {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.ACPBCompare .ACPBCGroup .ACPBCGHead .ACPBCGIcon {
    display: flex;
    font-size: 24px;
}

.ACPBCompare .ACPBCGroup .ACPBCGHead .ACPBCGTitle {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
}

.ACPBCompare .ACPBCGroup .ACPBCRow {
    display: flex;
    padding: 10px;
}

.ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol {
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    color: #666;
    min-width: 245px;
}

.ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol:first-child {
    justify-content: flex-start;
    color: #000;
    font-size: 15px;
    font-weight: 600;
}

.ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol.ACPBCRCIcon {
    font-size: 18px;
    align-self: center;
}

.ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol.ACPBCRCIcon1 {
    color: #fb3c6d;
}
.ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol.ACPBCRCIcon2 {
    color: #0083ff;
}
.ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol.ACPBCRCIcon3 {
    color: #15e6cd;
}
.ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol.ACPBCRCIcon4 {
    color: #f6b72b;
}

// Responsive
@media (min-width: 1559px) and (max-width: 1800px) {
}

@media (min-width: 1367px) and (max-width: 1600px) {
}

@media (min-width: 1281px) and (max-width: 1366px) {
}

@media (min-width: 1024px) and (max-width: 1280px) {
}

@media (min-width: 992px) and (max-width: 1023.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .AppComparePlans .ACPBody {
        width: 850px;
        display: table;
        padding: 0px;
    }

    .AppComparePlans .ACPBody .ACPBHead {
        margin-bottom: 15px;
    }

    .AppComparePlans .ACPBody .ACPBCompare {
        overflow: visible;
    }

    .AppComparePlansRoot.MainDialog.centerZoom .MDContainer .MDCRow {
        margin: 0;
        max-height: unset;
        height: 100%;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol {
        max-width: 170px;
        min-width: 170px;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol:first-child {
        max-width: 170px;
        min-width: 170px;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
        padding: 10px;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCText {
        font-size: 12px;
        text-align: center;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow {
        padding: 0;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol {
        padding: 10px;
        max-width: 170px;
        min-width: 170px;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol:first-child {
        max-width: 170px;
        min-width: 170px;
        display: table;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
        font-size: 12px;
    }

    .ACPBCompare .ACPBCGroup .ACPBCGHead {
        display: table;
        position: sticky;
        left: 0;
        z-index: 1;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .AppComparePlans .ACPBody {
        width: 850px;
        display: table;
        padding: 0px;
    }

    .AppComparePlans .ACPBody .ACPBHead {
        margin-bottom: 15px;
    }

    .AppComparePlans .ACPBody .ACPBCompare {
        overflow: visible;
    }

    .AppComparePlansRoot.MainDialog.centerZoom .MDContainer .MDCRow {
        margin: 0;
        max-height: unset;
        height: 100%;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol {
        max-width: 170px;
        min-width: 170px;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol:first-child {
        max-width: 170px;
        min-width: 170px;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
        padding: 10px;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCText {
        font-size: 12px;
        text-align: center;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow {
        padding: 0;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol {
        padding: 10px;
        max-width: 170px;
        min-width: 170px;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol:first-child {
        max-width: 170px;
        min-width: 170px;
        display: table;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
        font-size: 12px;
    }

    .ACPBCompare .ACPBCGroup .ACPBCGHead {
        display: table;
        position: sticky;
        left: 0;
        z-index: 1;
    }
}

@media (max-width: 575.98px) {
    .AppComparePlans .ACPBody {
        width: 850px;
        display: table;
        padding: 0px;
    }

    .AppComparePlans .ACPBody .ACPBHead {
        margin-bottom: 15px;
    }

    .AppComparePlans .ACPBody .ACPBCompare {
        overflow: visible;
    }

    .AppComparePlansRoot.MainDialog.centerZoom .MDContainer .MDCRow {
        margin: 0;
        max-height: unset;
        height: 100%;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol {
        max-width: 170px;
        min-width: 170px;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol:first-child {
        max-width: 170px;
        min-width: 170px;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
        padding: 10px;
    }

    .AppComparePlans .ACPBody .ACPBHead .ACPBHCol .ACPBHCText {
        font-size: 12px;
        text-align: center;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow {
        padding: 0;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol {
        padding: 10px;
        max-width: 170px;
        min-width: 170px;
    }

    .ACPBCompare .ACPBCGroup .ACPBCRow .ACPBCRCol:first-child {
        max-width: 170px;
        min-width: 170px;
        display: table;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
        font-size: 12px;
    }

    .ACPBCompare .ACPBCGroup .ACPBCGHead {
        display: table;
        position: sticky;
        left: 0;
        z-index: 1;
    }
}

