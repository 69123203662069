.SwitchMain.disabled {
    opacity: 0.9;

    & * {
        opacity: 0.8;
        cursor: not-allowed !important;
    }
}
.SwitchMain .SwitchMainInner {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    background-color: #f6f8fc;
    border-radius: 4px;
}

.SwitchMain .SwitchMainInner .SMIBtn {
    font-size: 12px;
    font-weight: 600;
    padding: 5px;
    line-height: normal;
    min-width: 40px;
    text-align: center;
    border-radius: 4px;
    transition: all ease 0.2s;
    cursor: pointer;
    user-select: none;
    color: #26262b;
}

.SwitchMain .SwitchMainInner .SMIBtn.SMIBtnOn.active {
    background-color: #4caf50;
    color: var(--white);
}

.SwitchMain .SwitchMainInner .SMIBtn.SMIBtnOff.active {
    background-color: #f44336;
    color: var(--white);
}

.SwitchWithLabel .SWLInner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.SwitchWithLabel .SWLInner .SWLILabel {
    font-size: 15px;
    font-weight: 500;
    color: #7b7b7b;
    padding-right: 10px;
}

.SwitchGroup.SwitchMain .SwitchMainInner .SMIBtn.selected {
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}

// Desktop Responsive

@media (min-width: 1920px) {
}
@media (min-width: 1559px) and (max-width: 1800px) {
    .SwitchMain .SwitchMainInner .SMIBtn {
        font-size: 10px;
        min-width: 35px;
    }
}
@media (min-width: 1367px) and (max-width: 1600px) {
    .SwitchMain .SwitchMainInner .SMIBtn {
        font-size: 10px;
        min-width: 35px;
    }
}
@media (min-width: 1280px) and (max-width: 1367px) {
    .SwitchMain .SwitchMainInner .SMIBtn {
        font-size: 10px;
        min-width: 35px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .SwitchMain .SwitchMainInner .SMIBtn {
        font-size: 9px;
        padding: 4px;
        min-width: 32px;
    }
}
