.PCRViewMain {
    width: calc(100% - 270px);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.PCRViewMain.noSidebar {
    width: 100%;
}

.PCRViewMain .PCRViewContainer {
    padding: 15px;
    background-color: #f6f8fc;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.PCRViewMain .PCRViewContainer .PCRVBInner {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    position: relative;
}

// Responsive
@media (min-width: 1559px) and (max-width: 1800px) {
    .PCRViewMain {
        width: calc(100% - 250px);
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    .PCRViewMain {
        width: calc(100% - 250px);
    }
}

@media (min-width: 1280px) and (max-width: 1367px) {
    .PCRViewMain {
        width: calc(100% - 250px);
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .PCRViewMain {
        width: calc(100% - 200px);
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .PCRViewMain {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .PCRViewMain {
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .PCRViewMain {
        width: 100%;
    }
}
@media (max-width: 575.98px) {
    .PCRViewMain {
        width: 100%;
    }
}
@media only screen and (min-width: 321px) and (max-device-width: 960px) and (orientation: landscape) {
    /* mobile landscape */

    .PCRViewMain .PCRViewContainer .PCRVBInner {
        height: 500px !important;
    }
}
