@keyframes remove-toast {
    0% {
        left: 0;
    }
    50% {
        opacity: 0;
        transform: translateX(-110%);
    }
    100% {
        transform: translateX(-110%);
        opacity: 0;
        height: 0;
        margin-bottom: 0;
    }
}

.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 100005;
}

.toast-container-top-right {
    top: 12px;
    right: 12px;
}

.toast-container-bottom-right {
    bottom: 12px;
    right: 12px;
}

.toast-container-top-left {
    top: 12px;
    left: 12px;
}

.toast-container-bottom-left {
    bottom: 12px;
    left: 12px;
}

.toast {
    transition: transform 0.6s ease-in-out;
    &.top-right {
        animation: toast-in-right 0.7s;
    }
    &.bottom-right {
        animation: toast-in-right 0.7s;
    }
    &.top-left {
        animation: toast-in-left 0.7s;
    }
    &.bottom-left {
        animation: toast-in-left 0.7s;
    }

    &.removed {
        animation: remove-toast 600ms;
    }
}

.notification {
    display: flex;
    align-items: center;
}

.notification-title {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 300px;
    height: 18px;
}

.toast .notification-message {
    flex: 1;
    padding: 0 10px;
    line-height: normal;
    font-size: 16px;
    font-weight: 500;
}

.toast .notification-icon {
    font-size: 25px;
    display: flex;
}

.toast {
    min-width: 325px;
    max-width: 100%;
    color: var(--white);
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.toast:last-child {
    margin-bottom: 0px;
}

.success {
    background-color: #5cb85c;
}
.error {
    background-color: #d9534f;
}
.info {
    background-color: #5bc0de;
}

.warning {
    background-color: #f0ad4e;
}
.default {
    background-color: #2c3e50;
}

.toast button.notification-remove {
    padding: 0;
    background-color: transparent;
    border: 0;
    outline: 0;
    color: var(--white);
    font-size: 12px;
    cursor: pointer;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}
