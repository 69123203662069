.SingleTextChip {
    display: inline-block;
    padding: 5px;
    margin: 2.5px;
    background-color: #e8eef9;
    border: 1px solid #cad3e3;
    user-select: none;
}

.SingleTextChip .STCInner {
    display: flex;
    align-items: center;
}
.SingleTextChip .STCInner .STCIIcon {
    display: flex;
    font-size: 12px;
    color: var(--dark-blue);
    padding-right: 7px;
}
.SingleTextChip .STCInner .STCITitle {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    font-weight: 500;
    padding-right: 5px;
    user-select: none;
    line-height: normal;
}

.SingleTextChip .STCInner .STCITEdit {
    display: flex;
    font-size: 10px;
    cursor: pointer;
    color: #000;
    margin-right: 5px;
}
.SingleTextChip .STCInner .STCITRemove {
    display: flex;
    font-size: 8px;
    cursor: pointer;
    color: #000;
}
