.AvatarGroups .AvatarGroupsInner {
    display: flex;
    align-items: center;
}

.SingleAvatar {
    position: relative;
    margin-left: -15px;
}

.SingleAvatar .SingleAvatarInner {
    display: flex;
    width: 30px;
    height: 30px;
    font-size: 30px;
    border-radius: 100%;
    background-color: #000;
    border: 1px solid #b4b4b4;
    transition: all 0.3s ease;
}

.SingleAvatar .SingleAvatarInner svg,
.SingleAvatar .SingleAvatarInner img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.AvatarGroups .AvatarGroupsInner .ToolTipWrapper {
    margin-left: -15px;
}

.ToolTipWrapper .SingleAvatar {
    margin-left: 0;
}

.AvatarGroups .AvatarGroupsInner .ToolTipWrapper .SingleAvatar .SingleAvatarInner:hover {
    border-color: #6a6a6a;
    box-shadow: 0 0 7px #6a6a6a;
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .SingleAvatar .SingleAvatarInner {
        width: 25px;
        height: 25px;
        font-size: 25px;
    }
}
