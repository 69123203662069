.DropZoneContainer {
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #cfe1f8;
    border-radius: 4px;
    background-color: #f6f8fc;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.DropZoneContainer .DZCInner {
    position: relative;
}

.DropZoneContainer input.file-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    top: 0;
    left: 0;
}
.DropZoneContainer .DZCUFiles {
    height: 100%;
    overflow-y: scroll;
}

.DropZoneContainer .DZCInner .DZCIMessage .DZCIMIcon {
    display: flex;
    font-size: 100px;
    justify-content: center;
}

.DropZoneContainer .DZCInner .DZCIMessage .DZCIMTitle {
    font-size: 26px;
    font-weight: 500;
    color: #26262b;
}

.DropZoneMain {
    position: relative;
    margin-bottom: 15px;
}

.DropZoneMain.small .DZCIUploading .DZCIUImgs {
    width: 60px;
    height: 60px;
    margin: 5px;
}

.DropZoneMain.small .DZCIUploading .DZCIUImgs .DZCIUIPro .DZCIUIPInner .iconButton {
    padding: 2px;
    font-size: 8px;
}

.DropZoneContainer.DZCUpload {
    align-items: unset;
    justify-content: unset;
    display: block;
    flex-direction: unset;
    padding: 0;
}

.DZCIUploading {
    padding-top: 15px;
}

.DZCIUAttach {
    display: inline-block;
    position: relative;
    margin: 15px;
    width: 80px;
}

.DZCIUAttach .DZCIUAIIIcon {
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    font-size: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #26262b33;
    border-radius: 4px;
}

.DZCIUAttach .DZCIUAIIInfo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #26262b33;
    border-radius: 4px;
}
.DZCIUAttach .DZCIUAIIInfo .DZCIUAIIIBtns button {
    font-size: 9px;
    margin: 0 4px;
}

.DZCIUAttach .DZCIUAIIInfo .DZCIUAIIIRemover.circleBtn {
    color: var(--white);
    background-color: #f44336;
}

.DZCIUAttach .DZCIUAIIInfo .DZCIUAIIIRemover.circleBtn:hover {
    color: #f44336;
    background-color: var(--white);
}

.DZCIUAttach .DZCIUAIIInfo .DZCIUAIIIReset.circleBtn {
    color: #4caf50;
    background-color: var(--white);
}

.DZCIUAttach .DZCIUAIIInfo .DZCIUAIIIReset.circleBtn:hover {
    color: var(--white);
    background-color: #4caf50;
}

.DZCIUAttach .DZCIUAIIInfo .DZCIUAIIILoader {
    width: 80%;
    height: 10px;
    background-color: #f6f8fc;
    border-radius: 10px;
    overflow: hidden;
}

.DZCIUAttach .DZCIUAIIInfo .DZCIUAIIILoader .DZCIUAIIILInner {
    height: 100%;
    background-color: var(--dark-blue);
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}
@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}

.DZCIUAttach .DZCIUATitleSize {
    text-align: center;
    padding-top: 3px;
}

.DZCIUAttach .DZCIUATitleSize .DZCIUATSSize {
    font-size: 12px;
    color: #000;
    font-weight: 500;
}

.DZCIUAttach .DZCIUATitleSize .DZCIUATSTitle {
    font-size: 12px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

@media (max-width: 575.98px) {
    .DropZoneContainer .DZCInner .DZCIMessage .DZCIMTitle {
        text-align: center;
        font-size: 20px;
    }
}
.CEMIPBImageBtn {
    position: absolute !important;
    display: block !important;
    bottom: 5% !important;
    right: 3% !important;
    z-index: 999 !important;
    border-radius: 100% !important;
    transform: scale(1.3) !important;
    transform-origin: center !important;
}
