.PCRSidebar {
    width: 270px;
    border-right: 1px solid #eaeaea;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
}

.PCRSidebar .PCRSInner {
    height: 100%;
}

button.iconButton.SideBarToggleBtn {
    display: none;
}

button.iconButton.BlueFillBtn.SideBarToggleBtn:focus,
button.iconButton.BlueFillBtn.SideBarToggleBtn:active,
button.iconButton.BlueFillBtn.SideBarToggleBtn {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--blue-invert);
}

button.iconButton.RedFillBtn.SideBarToggleBtn:focus,
button.iconButton.RedFillBtn.SideBarToggleBtn:active,
button.iconButton.RedFillBtn.SideBarToggleBtn {
    background-color: #f44336;
    border-color: #f44336;
    color: var(--white);
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .PCRSidebar {
        width: 250px;
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    .PCRSidebar {
        width: 250px;
    }
}

@media (min-width: 1280px) and (max-width: 1366px) {
    .PCRSidebar {
        width: 250px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .PCRSidebar {
        width: 200px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .PCRSidebar {
        width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
        background-color: var(--white);
        border-top: 1px solid #eaeaea;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .PCRSidebar.sidebarView {
        transform: translateX(0);
    }

    button.iconButton.SideBarToggleBtn {
        display: flex;
        margin-right: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .PCRSidebar {
        width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
        background-color: var(--white);
        border-top: 1px solid #eaeaea;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .PCRSidebar.sidebarView {
        transform: translateX(0);
    }

    button.iconButton.SideBarToggleBtn {
        display: flex;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .PCRSidebar {
        width: 200px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
        background-color: var(--white);
        border-top: 1px solid #eaeaea;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .PCRSidebar.sidebarView {
        transform: translateX(0);
    }

    button.iconButton.SideBarToggleBtn {
        display: flex;
    }
}
@media (max-width: 575.98px) {
    .PCRSidebar {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
        background-color: var(--white);
        border-top: 1px solid #eaeaea;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .PCRSidebar.sidebarView {
        transform: translateX(0);
    }

    button.iconButton.SideBarToggleBtn {
        display: flex;
    }
}
