.SDStepHead {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.SCStep2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.SCStep2 .SDStepHead2 {
    text-align: center;
    padding: 20px;
    background-color: #ffebeb;
    border-radius: 4px;
    border: 1px solid #f8c4c4;
}

.SCStep2 .SDStepHead2 .SDSH2Title {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 5px;
}

.SCStep2 .SDStepHead2 .SDSH2Title strong {
    text-transform: capitalize;
    font-weight: 900;
    text-decoration: underline;
}

.SCStep2 .SDStepHead2 .SDSH2STitle {
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.SCStep2 .SDSHBody {
    padding: 50px 0;
    flex: 1;
}

.SCStep2 .SDSHBody .SDSHBInner {
    background-color: #fff;
    padding: 25px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    height: 100%;
    overflow: auto;
}

.SCStep2 .SDSHBody .SDSHBInner .SDSHBIItem {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
}

.SCStep2 .SDSHBody .SDSHBInner .SDSHBIItem:last-child {
    padding-bottom: 0px;
}

.SCStep2 .SDSHBody .SDSHBInner .SDSHBIItem .SDSHBIIIcon {
    font-size: 24px;
    display: flex;
    color: #d9534f;
}

.SCStep2 .SDSHBody .SDSHBInner .SDSHBIItem .SDSHBIIText {
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    flex: 1;
}
.SCStep2 .SDSHFooter {
    padding: 25px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eaeaea;
}

.SCStep2 .SDSHFooter label.Checkbox span.CheckboxLabel {
    text-transform: none;
}

.SCStep2 .SDS2Plan {
    margin-top: 25px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.SCStep2 .SDS2Plan .SDS2PCur {
    flex: 1;
    font-size: 24px;
    font-weight: 500;
    color: #666;
    text-align: center;
}

.SCStep2 .SDS2Plan .SDS2PNew {
    flex: 1;
    font-size: 24px;
    font-weight: 500;
    color: #666;
    text-align: center;
}

.SCStep2 .SDS2Plan .SDS2PArr {
    display: flex;
    font-size: 34px;
    color: #000;
}

.SCStep2 .SDS2Team {
    margin-top: 25px;
    border: 1px solid #eaeaea;
}

.SCStep2 .SDS2Team .SDS2THead {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 15px;
    padding-right: 50px;
    position: relative;
}

.SCStep2 .SDS2Team .SDS2THead .SDS2THTitle {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.SCStep2 .SDS2Team .SDS2THead .SDS2THTitle .SDS2THTIcon {
    font-size: 22px;
    display: flex;
    color: #83e814;
}

.SCStep2 .SDS2Team .SDS2THead .SDS2THTitle .SDS2THTText {
    flex: 1;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.SCStep2 .SDS2Team .SDS2THead .SDS2THDes {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}
.SCStep2 .SDS2Team .SDS2TBody {
    background-color: #fff;
    display: flex;
    max-height: 190px;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBLeft {
    width: 50%;
    border-right: 1px solid #eaeaea;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBRight {
    width: 50%;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBLeft .SDS2TBLInner {
    display: block;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    height: 100%;
    min-height: 100px;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBLeft .SDS2TBLInner .SDS2TBLIItemSkeleton {
    margin: auto;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBLeft .SDS2TBLInner .SDS2TBLIItem {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #f6f8fc;
}
.SCStep2 .SDS2Team .SDS2TBody .SDS2TBLeft .SDS2TBLInner .SDS2TBLIItem.active {
    background-color: #c4c4c4ba;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBLeft .SDS2TBLInner .SDS2TBLIItem:last-child {
    border-bottom: 0;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBLeft .SDS2TBLInner .SDS2TBLIItem .SDS2TBLIImg {
    font-size: 25px;
    display: flex;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBLeft .SDS2TBLInner .SDS2TBLIItem .SDS2TBLITitle {
    padding: 0 15px;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    flex: 1;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBRight .SDS2TBRInner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBRight .SDS2TBRInner .SDS2TBRIProcess {
    width: 100%;
    max-width: 350px;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBRight .SDS2TBRInner .SDS2TBRIProcess .SDS2TBRIPBG {
    padding: 7px;
    background-color: #f6f8fc;
    border-radius: 50px;
}

.SCStep2
    .SDS2Team
    .SDS2TBody
    .SDS2TBRight
    .SDS2TBRInner
    .SDS2TBRIProcess
    .SDS2TBRIPBG
    .SDS2TBRIPBGInner {
    height: 10px;
    background-color: #1157f2;
    border-radius: 50px;
    transition: all ease 0.3s;
}

.SCStep2 .SDS2Team .SDS2TBody .SDS2TBRight .SDS2TBRInner .SDS2TBRIProcess .SDS2TBRIPText {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding-top: 5px;
    color: #000;
}

.SCStep2 .SDS2Team .SDS2THead .SDS2THIcon {
    position: absolute;
    display: flex;
    top: 15px;
    right: 15px;
    font-size: 24px;
    color: #666;
}

.SCStep2 .SDS2File {
    margin-top: 25px;
    border: 1px solid #eaeaea;
    background-color: #fff;
}

.SCStep2 .SDS2File .SDS2FHead {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 15px;
    padding-right: 50px;
    position: relative;
}

.SCStep2 .SDS2File .SDS2FHead .SDS2FHTitle {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.SCStep2 .SDS2File .SDS2FHead .SDS2FHTitle .SDS2FHTIcon {
    font-size: 24px;
    display: flex;
}

.SCStep2 .SDS2File .SDS2FHead .SDS2FHTitle .SDS2FHText {
    flex: 1 1;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.SCStep2 .SDS2File .SDS2FHead .SDS2FHDes {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.SCStep2 .SDS2File .SDS2FHead .SDS2FHIcon {
    position: absolute;
    display: flex;
    top: 15px;
    right: 15px;
    font-size: 24px;
    color: #666;
}

.SCStep2 .SDS2File .SDS2FBody {
    padding: 15px;
}

.SCStep2 .SDS2File .SDS2FBody .SDS2FBIHelp {
    border: 1px solid #ff8b14;
    background-color: #fed8b1;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
}

.SCStep2 .SDS2File .SDS2FBody .SDS2FBIHelp .SDS2FBIHIcon {
    display: flex;
    font-size: 24px;
    color: #000;
}

.SCStep2 .SDS2File .SDS2FBody .SDS2FBIHelp .SDS2FBIHTitle {
    flex: 1;
    padding-left: 15px;
    font-size: 14px;
    color: #666;
}

.SCStep2 .SDS2File .SDS2FBody .SDS2FBFooter {
    padding-top: 15px;
}

.SCStep2 .SDS2FBFooter label.Checkbox span.CheckboxLabel {
    text-transform: none;
}

