.CropImage {
    height: 250px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
}

.UserImageEdit {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    transition: all ease 0.3s;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000d4;
    color: var(--white);
    border-radius: 4px;
}
.Circular {
    border-radius: 100%;
}

.UserImageEdit.active,
.UserImageEdit:hover {
    opacity: 1;
}

.CropImage .ReactCrop {
    width: 100%;
    height: 100%;
}

.CropImage .ReactCrop > div:first-child {
    width: 100%;
    height: 100%;
}

.CropImage .ReactCrop > div:first-child img.ReactCrop__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
