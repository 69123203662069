.passwordDialogMain .MDCRow {
    border-radius: 4px;
}

.passwordDialogMain .MDBackdrop {
    background-color: #000000e3;
}

.passwordDialogMain .pDMInner {
    background-color: var(--white);
    border-radius: 4px;
    padding: 40px 40px;
    position: relative;
    z-index: 1;
}

.passwordDialogMain .pDMInner .pDMIIcon {
    display: flex;
    justify-content: center;
}

.passwordDialogMain .pDMInner .pDMIIcon .pDMIIInner {
    font-size: 80px;
    display: flex;
    padding: 10px;
    border-radius: 100%;
    box-shadow: 0 0 10px #00000016;
    color: #2c3b4e;
}

.passwordDialogMain .pDMInner .pDMITitle {
    text-align: center;
    font-size: 28px;
    width: 100%;
    padding-top: 20px;
}

.passwordDialogMain .pDMInner .pDMIInput {
    margin-top: 20px;
    position: relative;
}

.passwordDialogMain .pDMInner .pDMIError {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #f44336;
}

.passwordDialogMain .pDMInner .pDMIInput.pDMIIErr .DCInputWrapper input.DCInput {
    border-color: #f44336;
    box-shadow: 0 0 3px #f44336;
}

.passwordDialogMain .pDMInner .pDMIInput.pDMIIErr .pDMIIIcon {
    color: #f44336;
}

.passwordDialogMain .pDMInner .pDMIInput .pDMIIIcon {
    position: absolute;
    font-size: 20px;
    top: 15px;
    left: 10px;
    z-index: 9;
}

.passwordDialogMain .pDMInner .pDMIInput .pDMIIRightIcon {
    position: absolute;
    font-size: 20px;
    top: 15px;
    right: 10px;
}

.passwordDialogMain .pDMInner .pDMIInput .DCInputWrapper input.DCInput {
    color: #000;
    padding: 15px;
    padding-left: 40px;
    font-size: 16px;
}

.passwordDialogMain .pDMInner .pDMIBtn {
    padding-top: 20px;
}

.passwordDialogMain .pDMInner .pDMIBtn button.mainButton {
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 575.98px) {
    .passwordDialogMain .pDMInner {
        padding: 25px 25px;
    }
    .passwordDialogMain .pDMInner .pDMITitle {
        font-size: 20px;
    }

    .passwordDialogMain .pDMInner .pDMIIcon .pDMIIInner {
        font-size: 50px;
    }
}
