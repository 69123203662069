.BillingPage {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.SingleChipWrapper {
    width: fit-content;
    float: left;
}

.SingleChip {
    border-radius: 10px;
}

.BillingPage .BPSPBtn {
    display: flex;
}

.PageTableBox table.DCTable .PageTableBody tr td .TableFieldNote {
    margin-left: 10px;
    background: #dcdcdc;
    padding: 2px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.BillingPage .BPSPBtn button {
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    min-width: 230px;
    border: 0;
    border-radius: 0;
    margin-right: 15px;
    position: relative;
    background-color: var(--white);
    border-bottom: 3px solid #d5dfef;
}

.BillingPage .BPSPBtn button span.buttonIcon {
    margin-left: 0;
    margin-right: 10px;
    font-size: 30px;
}

.BillingPage .BPSPBtn button.active {
    border-bottom-color: var(--dark-blue);
}

.BillingPage .BPSubPage {
    flex: 1 1;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.OrdersApproved {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.OrdersApproved .BodyBox {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.OrdersApproved .BodyBox .OAHead {
    display: flex;
    align-items: center;
    padding: 15px 15px;
    background-color: #f6f8fc;
}

.OrdersApproved .BodyBox .OAHead .OAHTitle {
    flex: 1;
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.OrdersApproved .BodyBox .OAHead .OAHBtn {
    border-radius: 50px;
    padding: 8px 15px;
    color: #26262b;
    box-shadow: 0 0 15px #f2f2f2;
}

.OrdersApproved .BodyBox .OABody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    padding: 0px 10px;
}

.ViewCredential {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.ViewCredential .BodyBox {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ViewCredential .BodyBox .VCHead {
    background-color: #f6f8fc;
    padding: 15px;
}

.ViewCredential .BodyBox .VCBody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
}

.ViewCredential .VCBody .VCBGroup {
    border-bottom: 1px solid #f6f8fc;
}

.ViewCredential .VCBGroup .VCBGTitle {
    padding: 15px 15px;
    border-bottom: 1px solid #f6f8fc;
    font-size: 16px;
    font-weight: 600;
}

.ViewCredential .VCBGroup .VCBGInner {
    padding: 15px;
}

.UpdateCommission .BodyBoxInner .UCBtns {
    display: flex;
    justify-content: center;
}

.UpdateCommission .BodyBoxInner .UCBtns button {
    margin: 0 7.5px;
}

.AddAgent {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.AddAgent .BodyBox {
    flex: 1;
}

.BillingPage .BPSubPage .BPSPAD {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BillingPage .BPSubPage .BPSPAD .BPSPADHead {
    display: flex;
    max-height: 250px;
    min-height: 250px;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHUP {
    width: 20%;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHUP .BPSPADHUPTitle {
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #eaeaea;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHUP .BPSPADHUPBody {
    display: flex;
    flex: 1;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHUP .BPSPADHUPBody .BPSPADHUPBBox {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHUP .BPSPADHUPBody .BPSPADHUPBBox span {
    font-size: 24px;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHUP .BPSPADHUPBody .BPSPADHUPBBox:nth-child(1) {
    border-right: 1px solid #eaeaea;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHUP .BPSPADHUPFoot {
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-top: 1px solid #eaeaea;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN {
    width: calc(60% - 30px);
    margin: 0 15px;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN .BPSPADHSGRNBox {
    width: 25%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-right: 1px solid #eaeaea;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN .BPSPADHSGRNBox:last-child {
    border-right: 0;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN .BPSPADHSGRNBox .BPSPADHSGRNBTitle {
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #eaeaea;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN .BPSPADHSGRNBox .BPSPADHSGRNBBody {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHPP {
    width: 20%;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHPP .BPSPADHPPHead {
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #eaeaea;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHPP .BPSPADHPPTable {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHPP .BPSPADHPPTable .BPSPADHPPTHead {
    display: flex;
    border-bottom: 1px solid #eaeaea;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHPP .BPSPADHPPTable .BPSPADHPPTHead .BPSPADHPPTCol1 {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
}

.BillingPage
    .BPSubPage
    .BPSPADHead
    .BPSPADHPP
    .BPSPADHPPTable
    .BPSPADHPPTHead
    .BPSPADHPPTCol1:nth-child(1) {
    width: 60%;
    border-right: 1px solid #eaeaea;
}
.BillingPage
    .BPSubPage
    .BPSPADHead
    .BPSPADHPP
    .BPSPADHPPTable
    .BPSPADHPPTHead
    .BPSPADHPPTCol1:nth-child(2) {
    width: 40%;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHPP .BPSPADHPPTable .BPSPADHPPTBody {
    flex: 1 1;
    overflow: auto;
    overflow-y: overlay;
}

.BillingPage .BPSubPage .BPSPADHead .BPSPADHPP .BPSPADHPPTable .BPSPADHPPTBody .BPSPADHPPTBRow {
    display: flex;
    border-bottom: 1px solid #eaeaea;
}

.BillingPage
    .BPSubPage
    .BPSPADHead
    .BPSPADHPP
    .BPSPADHPPTable
    .BPSPADHPPTBody
    .BPSPADHPPTBRow:last-child {
    border-bottom: 0;
}

.BillingPage
    .BPSubPage
    .BPSPADHead
    .BPSPADHPP
    .BPSPADHPPTable
    .BPSPADHPPTBody
    .BPSPADHPPTBRow
    .BPSPADHPPTRCol1 {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
}

.BillingPage
    .BPSubPage
    .BPSPADHead
    .BPSPADHPP
    .BPSPADHPPTable
    .BPSPADHPPTBody
    .BPSPADHPPTBRow
    .BPSPADHPPTRCol1:nth-child(1) {
    width: 60%;
    border-right: 1px solid #eaeaea;
}
.BillingPage
    .BPSubPage
    .BPSPADHead
    .BPSPADHPP
    .BPSPADHPPTable
    .BPSPADHPPTBody
    .BPSPADHPPTBRow
    .BPSPADHPPTRCol1:nth-child(2) {
    width: 40%;
}

.BillingPage .BPSubPage .BPSPAD .BPSPADBody {
    flex: 1;
    padding-top: 15px;
}

.BillingPage .BPSubPage .BPSPAD .BPSPADBody .BPSPADBInner {
    height: 100%;
    background-color: #fff;
}

.APTransactions {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.APTransactions .BodyBox {
    flex: 1;
}

.APTransactions .BodyBox .BodyBoxInner {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.PayoutTransactions .SPTable {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.PayoutTransactions .SPTable .SPTHead {
    display: flex;
    background-color: #e8eef9;
    border-top: 1px solid #cad3e3;
    border-bottom: 1px solid #cad3e3;
}

.PayoutTransactions .SPTable .SPTHead .SPTHCol {
    font-size: 16px;
    color: #434750;
    border-right: 1px solid #cad3e3;
    font-weight: 400;
    padding: 9px 10px;
    -webkit-user-select: none;
    user-select: none;
    min-width: 200px;
    position: relative;
    width: 25%;
}

.PayoutTransactions .SPTable .SPTHead .SPTHCol:first-child {
    border-left: 1px solid #cad3e3;
}

.PayoutTransactions .SPTable .SPTBody {
    flex: 1;
    overflow: auto;
    overflow-x: overlay;
    overflow-y: overlay;
}

.PayoutTransactions .SPTable .SPTBody .SPTRow {
    display: flex;
    border-bottom: 1px solid #e8eef9;
}

.PayoutTransactions .SPTable .SPTBody .SPTRow .SPTRCol {
    display: block;
    background-color: var(--white);
    font-size: 14px;
    color: #000;
    font-weight: 400;
    border-right: 1px solid #e8eef9;
    padding: 9px 10px;
    -webkit-user-select: none;
    user-select: none;
    min-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 25%;
}

.PayoutTransactions .SPTable .SPTBody .SPTRow .SPTRCol:first-child {
    border-left: 1px solid #e8eef9;
}

.PayoutTransactions .SPTable .SPTFooter {
    display: flex;
    border: 1px solid #e8eef9;
    border-top: 0;
}

.PayoutTransactions .SPTable .SPTFooter .SPTFTitle {
    font-size: 16px;
    color: #434750;
    font-weight: 600;
    padding: 9px 10px;
    -webkit-user-select: none;
    user-select: none;
    min-width: 200px;
    position: relative;
    width: 50%;
}

.PayoutTransactions .SPTable .SPTFooter .SPTFCol {
    display: block;
    background-color: var(--white);
    font-size: 14px;
    color: #000;
    font-weight: 400;
    border-left: 1px solid #e8eef9;
    padding: 9px 10px;
    -webkit-user-select: none;
    user-select: none;
    min-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 25%;
}

.PayConfirmation .PCBtns {
    display: flex;
    justify-content: flex-end;
}

.PayConfirmation .PCBtns button {
    margin-left: 15px;
}

.PayoutTransactions .UserInfo .UIDetail .UIDetailName {
    color: inherit;
}

.NotePopOver.ArrowPopover.top-center .PopoverMainInner::before {
    visibility: visible;
    bottom: -5px;
    left: calc(50% - 7px);
}

.NotePopOver.ArrowPopover.top-start .PopoverMainInner::before {
    visibility: visible;
    bottom: -5px;
    left: calc(0% + 2px);
}

.NotePopOver.ArrowPopover.top-end .PopoverMainInner::before {
    visibility: visible;
    bottom: -5px;
    left: calc(100% - 16px);
}

.NotePopOver.ArrowPopover.bottom-center .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    left: calc(50% - 7px);
}

.NotePopOver.ArrowPopover.bottom-start .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    left: calc(0% + 2px);
}

.NotePopOver.ArrowPopover.bottom-end .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    left: calc(100% - 7px);
}

.NotePopOver.ArrowPopover.left-start .PopoverMainInner::before {
    visibility: visible;
    top: calc(0% + 2px);
    right: -5px;
}

.NotePopOver.ArrowPopover.left-end .PopoverMainInner::before {
    visibility: visible;
    top: calc(100% - 16px);
    right: -5px;
}

.NotePopOver.ArrowPopover.left-center .PopoverMainInner::before {
    visibility: visible;
    top: calc(50% - 7px);
    right: -5px;
}

.NotePopOver.ArrowPopover.right-start .PopoverMainInner::before {
    visibility: visible;
    top: calc(0% + 2px);
    left: -5px;
}

.NotePopOver.ArrowPopover.right-end .PopoverMainInner::before {
    visibility: visible;
    top: calc(100% - 16px);
    left: -5px;
}

.NotePopOver.ArrowPopover.right-center .PopoverMainInner::before {
    visibility: visible;
    top: calc(50% - 7px);
    left: -5px;
}

.NotePopOver .PopoverMainInner .PMIContent .ButtonWrapper {
    margin-top: 10px;
}

.SSOUserItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
}

.SSOUserItem:hover {
    background-color: #e5e5e5;
}

.SSOUserItem .SSOUIBadge {
    display: inline-block;
    padding: 3px 5px;
    border: 1px solid;
    font-size: 12px;
    line-height: normal;
    border-radius: 4px;
    min-width: 50px;
    text-align: center;
}

.BodyBox .SSOLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #000;
    opacity: 0.15;
}

.BodyBox .SSOLoader .CircularLoader {
    margin: auto;
}

@media (min-width: 576px) and (max-width: 991px) {
    .BillingPage .BPSPBtn {
        display: flex;
        overflow: auto;
    }
    .BillingPage .BPSPBtn button {
        padding: 10px 15px;
        font-size: 16px;
        min-width: 200px;
    }
    .BillingPage .BPSPBtn button span.buttonIcon {
        margin-right: 10px;
        font-size: 24px;
    }
    .BillingPage .BPSubPage .BPSPAD .BPSPADHead {
        display: flex;
        max-height: unset;
        min-height: auto;
        flex-direction: column;
    }
    .BillingPage .BPSubPage .BPSPADHead .BPSPADHUP {
        width: 100%;
        margin-bottom: 15px;
    }
    .BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }

    .BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN .BPSPADHSGRNBox .BPSPADHSGRNBTitle {
        padding: 10px 5px;
        font-size: 14px;
    }

    .BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN .BPSPADHSGRNBox .BPSPADHSGRNBBody {
        font-size: 16px;
        padding: 10px 0;
    }

    .BillingPage .BPSubPage .BPSPAD .BPSPADHead .BPSPADHPP {
        width: 100%;
    }

    .PayoutTransactions .SPTable {
        overflow: unset;
        width: 900px;
    }

    .PayoutTransactions .SPTable .SPTBody {
        overflow: unset;
    }
}

@media (max-width: 575.98px) {
    .BillingPage .BPSPBtn {
        display: flex;
        overflow: auto;
    }
    .BillingPage .BPSPBtn button {
        padding: 10px 15px;
        font-size: 16px;
        min-width: 200px;
    }
    .BillingPage .BPSPBtn button span.buttonIcon {
        margin-right: 10px;
        font-size: 24px;
    }
    .BillingPage .BPSubPage .BPSPAD .BPSPADHead {
        display: flex;
        max-height: unset;
        min-height: auto;
        flex-direction: column;
    }
    .BillingPage .BPSubPage .BPSPADHead .BPSPADHUP {
        width: 100%;
        margin-bottom: 15px;
    }
    .BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }

    .BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN .BPSPADHSGRNBox .BPSPADHSGRNBTitle {
        padding: 10px 5px;
        font-size: 14px;
    }

    .BillingPage .BPSubPage .BPSPADHead .BPSPADHSGRN .BPSPADHSGRNBox .BPSPADHSGRNBBody {
        font-size: 16px;
        padding: 10px 0;
    }

    .BillingPage .BPSubPage .BPSPAD .BPSPADHead .BPSPADHPP {
        width: 100%;
    }

    .PayoutTransactions .SPTable {
        overflow: unset;
        width: 900px;
    }

    .PayoutTransactions .SPTable .SPTBody {
        overflow: unset;
    }
}
