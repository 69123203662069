.UpdateViewText .UVTLoading {
    display: flex;
    align-items: center;
}

.UpdateViewText .UVTLoading .UVTLText {
    color: var(--dark-blue);
    font-weight: 600;
    padding-left: 5px;
    user-select: none;
}

.UpdateViewText .UVTLoading .CircularLoader {
    width: 24px;
    height: 24px;
}

.UpdateViewText .UVTAfterLoading {
    display: flex;
    align-items: center;
    color: var(--dark-blue);
    font-weight: 600;
}

.UpdateViewText .UVTAfterLoading .UVTALIcon {
    font-size: 18px;
    display: flex;
}

.UpdateViewText .UVTAfterLoading .UVTALText {
    padding-left: 5px;
    font-style: oblique;
}
