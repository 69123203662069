.Reminder.PageUpperBox {
    width: 100%;
}
.Reminder.PageUpperBox .PageUpperHeader .PUHTab {
    cursor: pointer;
}
.Reminder.PageUpperBox .PageUpperHeader .PUHTab.upcoming {
    // background-color: rgb(222, 222, 252);
    color: rgba(0, 0, 255, 0.65);
    margin: 0;
    padding: 4px;
    opacity: 0.8;
    font-weight: 600;
}

.PageUpperBox .PageUpperHeader .PUHTab.active.upcoming {
    border-bottom: 2px solid blue;
    // background-color: rgb(222, 222, 252);
    font-weight: 600;
    opacity: 1;
    color: rgb(0, 0, 255);
}
.Reminder.PageUpperBox .PageUpperHeader .PUHTab.post {
    // background-color: rgb(252, 222, 222);
    color: rgba(255, 0, 0, 0.65);
    margin: 0;
    padding: 4px;
    opacity: 0.8;
    font-weight: 600;
}

.PageUpperBox .PageUpperHeader .PUHTab.active.post {
    border-bottom: 2px solid rgb(255, 0, 0);
    // background-color: rgb(252, 222, 222);
    font-weight: 600;
    opacity: 1;
    color: rgb(255, 0, 0);
}
.Reminder.PageUpperBox .PageUpperHeader .PUHTab.completed {
    // background-color: rgb(252, 222, 222);
    color: rgba(0, 112, 47, 0.65);
    margin: 0;
    padding: 4px;
    opacity: 0.99;
    font-weight: 600;
}

.PageUpperBox .PageUpperHeader .PUHTab.active.completed {
    border-bottom: 2px solid rgb(0, 112, 47);
    // background-color: rgb(252, 222, 222);
    font-weight: 600;
    opacity: 1;
    color: rgb(0, 112, 47);
}
.Reminder.PageUpperBox .PageUpperHeader {
    padding: 0px;
    display: flex;
    flex-direction: row;
    gap: 3px;
    justify-content: space-around;
    padding-top: 4px;
    height: auto;
}
.UpcomingSection {
    width: 100%;
}
.UpcomingSection .UBTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 5px;
    color: #000;
}

.UpcomingSection .UpcomingBody .CardContainerBox {
    background-color: var(--white);
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 0 6px #9c9c9c16;
    margin: 8px auto;
    overflow: hidden;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar {
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-bottom: 1px solid #f6f8fc;
    align-items: center;
    padding: 8px;
    padding-left: 8px;
    font-size: 12px;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar.upcoming {
    background: #eaeaff;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar.pastdue {
    background: #ffeaea;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar.completed {
    background: #dcffeb;
}

.UpcomingSection .UpcomingBody .CardContainerBox .CCMiddleBox {
    display: flex;
    flex-direction: row;
    gap: 2px;
    border-bottom: 1px solid #f6f8fc;
    padding: 8px;
    justify-content: space-between;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCBottomBox {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 8px;
}

.UpcomingSection .UpcomingBody .CardContainerBox .CCBottomBox .CCBBTitle {
    display: flex;
    column-gap: 10px;
    font-weight: 600;

    &.upcoming {
        color: #0000ff;
    }
    &.pastdue {
        color: #ff0000;
    }
    &.completed {
        color: #00702f;
    }
}

.UpcomingSection .UpcomingBody .CardContainerBox .CCBottomBox .CCBBTitle .radioButtonLabel {
    font-weight: 600;
    font-size: 16px;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCBottomBox .CCBBTitle svg {
    font-size: 19px;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCBottomBox .CCBBTitle .radioButton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.UpcomingSection
    .UpcomingBody
    .CardContainerBox
    .CCBottomBox
    .CCBBTitle
    .radioButton
    .radioButtonIcon {
    margin-top: 2.5px;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar .CCTBDate {
    width: 70%;
    text-align: left;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar .CCTBRight.Icon {
    width: 10%;
    display: flex;
    justify-content: flex-end;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar .CCTBRight.Icon svg {
    height: 22px;
    width: 22px;
    text-align: right;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar .CCTBRight.type {
    color: #626262;
    text-align: center;
    font-weight: 400;
    border-radius: 3px;
    width: 15%;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCTopBar .CCTBRight.type {
    border: 1px solid #222;
    color: #222;
    background: #fff;
}
.ReminderCard {
    cursor: default;
    &--completed {
        animation-delay: 0s !important;
        animation: slideRightReminderCard 0.5s ease forwards;
    }
}

.UpcomingSection .UpcomingBody .CardContainerBox .CCMiddleBox .CCMBRight .SingleAvatar:hover {
    z-index: 99 !important;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCMiddleBox .CCMBRight .CCMBRightSkeleton {
    border-radius: 50%;
    margin-left: -15px;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCBottomBox .CCBBBody .CCBBBViewMoreContainer {
    max-height: 14rem;
    overflow-y: auto;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCBottomBox .CCBBBody .CCBBBQuickActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.UpcomingSection .UpcomingBody .CardContainerBox .CCBottomBox .CCBBBody .showMorebtn {
    margin-left: 3px;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
}
.UpcomingSection
    .UpcomingBody
    .CardContainerBox
    .CCBottomBox
    .CCBBBody
    .CCBBBQuickActions
    button.CCBBBQAStatus {
    padding: 5px 12px !important;
    border: 1px solid #bebcbf;
    border-radius: 4px;
    color: #bebcbf;
    font-size: 12px;

    &:hover {
        background-color: #fff;
        color: #00702f;
        border: 1px solid #00702f;
    }
}
.UpcomingSection
    .UpcomingBody
    .CardContainerBox
    .CCBottomBox
    .CCBBBody
    .CCBBBQuickActions
    .CCBBBQAStatus.completed {
    background: #4caf50;
    padding: 2px 8px !important;
    color: #fff;
    border-radius: 4px;
    font-size: 10px;
}

@keyframes slideRightReminderCard {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(5rem);
        opacity: 0;
        height: 0px !important;
    }
}
