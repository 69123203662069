.DCSupportDialogContainer {
    transition: all 0.6s cubic-bezier(0.76, 0, 0.24, 1);
    transform-origin: right;

    & * {
        cursor: default;
    }

    &.isClosed {
        transform: scaleX(0);
        .DCSupportDialog p,
        .DCSupportDialog h1,
        .DCSupportDialog button,
        .DCSupportDialog svg {
            opacity: 1;
            animation: hideChildren 0.5s ease forwards;
        }
    }
    &.isOpen {
        transform: scaleX(1);
        .DCSupportDialog p,
        .DCSupportDialog h1,
        .DCSupportDialog button {
            opacity: 0;
            animation: showChildren 0.5s ease forwards;
            animation-delay: 0.2s;
        }
    }
}

.DCSupportDialog {
    background: #f6f8fc;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.DCSupportDialog .DCSDBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 15px;

    & > * {
        padding: 0 15px;
    }
}

.DCSupportDialog .DCSDBody .DCSDBHeading {
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.DCSupportDialog .DCSDBody .DCSDBHelplinks {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 0.2rem;
}

.DCSupportDialog .DCSDBody .DCSDBHelplinks .DCSDBHItem {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    background: #fff;
    border-radius: 4px;
    transition: all 0.3s ease;
    box-shadow: 0px 0.2px 0.9px rgb(0 0 0 / 25%);
    opacity: 0;

    & * {
        cursor: pointer !important;
    }

    &:hover {
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }

    &:not(:last-of-type) {
        margin-bottom: 10px;
    }

    &:last-of-type {
        margin-bottom: 4px;
    }
    &:nth-child(1) {
        animation: slideLeft 0.3s ease forwards 0.2s;
    }
    &:nth-child(2) {
        animation: slideLeft 0.3s ease forwards 0.3s;
    }
    &:nth-child(3) {
        animation: slideLeft 0.3s ease forwards 0.4s;
    }
    &:nth-child(4) {
        animation: slideLeft 0.3s ease forwards 0.5s;
    }
    &:nth-child(5) {
        animation: slideLeft 0.3s ease forwards 0.6s;
    }
    &:nth-child(6) {
        animation: slideLeft 0.3s ease forwards 0.7s;
    }
    &:nth-child(7) {
        animation: slideLeft 0.3s ease forwards 0.8s;
    }
    &:nth-child(8) {
        animation: slideLeft 0.3s ease forwards 0.9s;
    }
    &:nth-child(9) {
        animation: slideLeft 0.3s ease forwards 1s;
    }
}
.DCSupportDialog .DCSDBody .DCSDBHelplinks .DCSDBHItem .DCSDBHIIcon svg {
    width: 2.2rem;
    height: 2.2rem;
}

.DCSupportDialog .DCSDBody .DCSDBHelplinks .DCSDBHItem .DCSDBHIInfo {
    margin-left: 15px;
}

.DCSupportDialog .DCSDBody .DCSDBHelplinks .DCSDBHItem .DCSDBHIInfo .DCSDBHIILabel {
    font-size: 1rem;
    font-weight: 600;
}

.DCSupportDialog .DCSDBody .DCSDBHelplinks .DCSDBHItem .DCSDBHIInfo .DCSDBHIIDescription {
    color: #a09cad;
    font-size: 14px;
}

@keyframes hideChildren {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes showChildren {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(2rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
