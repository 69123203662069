.PopoverMain.TextOverflowTooltipPopover .PopoverMainInner {
    margin-bottom: 10px;
    max-width: 250px;
}

.PopoverMain.TextOverflowTooltipPopover .PopoverMainInner::before {
    content: '';
}

.TOFTPWrapper {
    position: relative;
}

.TOFTPWrapper .TOFTPWInner {
    padding: 7px 7px;
    word-break: break-all;
}

.PopoverMain.TextOverflowTooltipPopover .PopoverMainInner .PMIContent {
    z-index: 2;
}

.PopoverMain.TextOverflowTooltipPopover .PopoverMainInner::before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: var(--white);
    box-shadow: 0 4px 14px 0 #00000033, 0 0 0 1px #0000000d;
    transform: rotate(45deg);
    border-radius: 4px;
}

.PopoverMain.TextOverflowTooltipPopover.top-center .PopoverMainInner::before {
    bottom: -5px;
    left: calc(50% - 5px);
}

.TOFTPWText {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
