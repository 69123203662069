.under-maintenance {
    height: 100%;
}

.under-maintenance .um-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.under-maintenance .um-inner .umi-logo {
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.under-maintenance .um-inner .umi-logo img {
    width: 250px;
}

.under-maintenance .um-inner .umi-body {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.under-maintenance .um-inner .umi-body .umib-content {
    max-width: 800px;
    text-align: center;
}

.under-maintenance .um-inner .umi-body .umib-content h1 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 48px;
    font-weight: bold;
    color: #000;
}

.under-maintenance .um-inner .umi-body .umib-content p {
    font-size: 18px;
    font-weight: 400;
    color: #000000ba;
    line-height: 32px;
    margin: 0;
}

.under-maintenance .um-inner .umi-body .umib-plug {
    width: 100%;
    padding-top: 50px;
    display: flex;
}

.under-maintenance .um-inner .umi-body .umib-plug img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    max-width: 100%;
    height: auto;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.under-maintenance .um-inner .umi-footer {
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #d7d7d7;
}

.under-maintenance .um-inner .umi-footer .umif-mail a {
    color: #0083ff;
}

.under-maintenance .um-inner .umi-footer .umif-text {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.under-maintenance .um-inner .umi-footer .umif-mail {
    margin: 0 15px;
    color: #666;
    font-size: 14px;
    font-weight: 500;
}

.under-maintenance .um-inner .umif-social {
    display: flex;
    align-items: center;
}

.under-maintenance .um-inner .umif-social a {
    display: flex;
    margin: 0 15px;
    color: #000;
}

.under-maintenance .um-inner .umif-social a svg {
    width: 24px;
    height: 24px;
}

@media (max-width: 850px) {
    .under-maintenance .um-inner .umi-footer {
        flex-direction: column;
        align-items: center;
    }

    .under-maintenance .um-inner .umi-footer .umif-mail {
        margin: 20px 0;
    }
}
