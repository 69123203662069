.CardView {
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    background-color: #f6f8fc;
    border: 1px solid #eaeaea;
    padding: 50px;
    border-radius: 4px;
    height: 100%;
}
.CardView.RRSPCTable {
    overflow: hidden;
}

.StepperStepWarn {
    background-color: #fed8b1;
    border: 1px solid #ff8b14;
    padding: 25px;
    border-radius: 4px;
    margin-bottom: 25px;
    display: flex;
    align-items: flex-start;
    grid-column-gap: 15px;
    column-gap: 15px;
    box-shadow: 0 0 15px #fed8b1;
}

.StepperStepWarn .SSMIcon {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #ff8b14;
    font-size: 24px;
    border-radius: 100%;
    box-shadow: 0 0 4px #fff;
}
.StepperStepWarn .StepperStepWarnText,
.StepperStepWarn .SSMText {
    flex: 1 1;
    font-size: 14px;
    font-weight: 500;
    color: #111111;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    line-height: 24px;
}

.StepperStepWarn .StepperStepWarnText {
    font-size: 20px;
}

.CardView .CVInner {
    display: grid;
    grid-gap: 45px;
    gap: 45px;
    grid-template-columns: repeat(auto-fill, minMax(250px, 1fr));
    grid-auto-rows: 250px;
    margin-bottom: 1.5rem;
}

.CardView .CVInner > div,
.CardView .CVInner .ToolTipWrapper > div {
    background-color: var(--white);
    width: 100%;
    height: 100%;
}

//

.SimpleInputViewMain {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
}
.SimpleInputViewMainMessage {
    font-size: 14px;
    color: rgb(102, 102, 102);
    border: 1px solid #ff8b14;
    background-color: #fed8b1;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    box-shadow: 0 0 15px #fed8b1;
    margin-bottom: 10px;
}

.SimpleInputView {
    background-color: #f6f8fc;
    border: 1px solid #eaeaea;
    padding: 50px;
    border-radius: 4px;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.SimpleInputView.fullWidth {
    align-items: unset;
    justify-content: unset;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SimpleInputView.fullWidth .SIVRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--white);
}

.SimpleInputView.fullWidth .SIVRow .SIVRCol1 {
    width: 50%;
    height: 100%;
}
.SimpleInputView.fullWidth .SIVRow .SIVRCol2 {
    width: 50%;
    height: 100%;
}

.SimpleInputView .SIVInner {
    width: 50%;
    padding: 40px;
    background-color: var(--white);
    border: 1px solid #eaeaea;
    border-radius: 4px;
}

.SimpleInputView .SIVInner .SIVATC {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.SimpleInputView .SIVInner .SIVATC .SwitchWithLabel {
    margin-right: 15px;
}

.SIVATCdisable {
    pointer-events: none;
    opacity: 0.6;
}

.SimpleInputViewMain .SIVHelp {
    position: absolute;
    right: 50px;
    bottom: 50px;
    padding: 20px;

    @media screen and (min-width: 767px) and (max-width: 991px) {
        padding: 0.6rem 0.8rem;
    }

    @media screen and (max-width: 767px) {
        padding: 6px 0.8rem;
        transform: translate(-1.45rem, -2rem);
    }

    @media screen and (max-width: 575px) {
        padding: 0;
        transform: translateY(0.45rem);
    }
}

.SimpleInputViewMain .SIVHelp .SIVHInner {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
}

.SimpleInputViewMain .SIVHelp .SIVHInner a {
    text-decoration: none;
    color: var(--dark-blue);
}

.SimpleInputViewMain .SIVHelp .SIVHInner a:hover {
    text-decoration: underline;
}

.SimpleInputView .SIVInner .SIVBtns {
    display: flex;
    justify-content: flex-end;
}

//

.SelectList {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SelectList .SLIMFHead {
    display: flex;
    column-gap: 15px;
    margin-bottom: 15px;
}

.SelectList .SLITitle {
    padding: 15px;
    background-color: #fff4e5;
    border-radius: 4px;
    color: #663c00;
    display: flex;
    align-items: center;
    flex: 1;
}

.SelectList .SLITitle .SLITIcon {
    display: flex;
    font-size: 22px;
}

.SelectList .SLITitle h5 {
    flex: 1;
    padding-left: 10px;
}

.SelectList .SLITitle h5 span {
    font-weight: 600;
}

.SelectList .SLIMFHead button.mainButton {
    padding: 10px 25px;
}

.SelectList .SLIHeader {
    display: flex;
    align-items: center;
    background-color: #e8eef9;
    font-size: 16px;
    color: #434750;
    font-weight: 400;
    border: 1px solid #cad3e3;
}

.SelectList .SLIHeader .SLIHCol {
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.SelectList .SLIHeader .SLIHCol:nth-child(1),
.SelectList .SLIBRow .SLIBRCol:nth-child(1) {
    width: 15%;
}

.SelectList .SLIHeader .SLIHCol:nth-child(2),
.SelectList .SLIBRow .SLIBRCol:nth-child(2) {
    width: 30%;
}

.SelectList .SLIHeader .SLIHCol:nth-child(3),
.SelectList .SLIBRow .SLIBRCol:nth-child(3) {
    width: 30%;
}

.SelectList .SLIHeader .SLIHCol:nth-child(4),
.SelectList .SLIBRow .SLIBRCol:nth-child(4) {
    width: 25%;
}

.SelectList .SLIBody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SelectList .SLIBody .SLIBRow {
    display: flex;
    border: 1px solid #cad3e3;
    border-top: 0;
}

.SelectList .SLIBody .SLIBRow .SLIBRCol {
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    display: flex;
}

.SelectList .SLIBody .SLIBRow .SLIBRCol > .SLIBRCCheck,
.SelectList .SLIBody .SLIBRow .SLIBRCol > .SLIBRCText,
.SelectList .SLIBody .SLIBRow .SLIBRCol > .SLIBRCBtn {
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: center;
}

.SelectList .SLIBody .SLIBRow .SLIBRCol > div.SLIBRCBtn button {
    min-width: 200px;
    max-width: 200px;
}

.SelectList .SLIBody .SLIBRow .SLIBRCol > div.SLIBRCBtn button .buttonTitle {
    flex: 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

//

.CongratulationsBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CongratulationsBox .CBInner {
    width: 50%;
    padding: 50px;
    position: relative;
}

.CongratulationsBox .CBInner::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 50%;
    background-color: #3b3bff;
    z-index: -1;
    border-radius: 15px 15px 0 0;
}

.CongratulationsBox .CBInner::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background-color: #eff2ff;
    z-index: -1;
    border-radius: 0 0 15px 15px;
}

.CongratulationsBox .CBInner .CBIImg {
    display: flex;
    padding-top: 15px;
    background-color: var(--white);
    border-radius: 4px 4px 0px 0px;
}

.CongratulationsBox .CBInner .CBIImg img {
    width: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.CongratulationsBox .CBInner .CBIDetail {
    padding: 50px;
    text-align: center;
    background-color: var(--white);
    position: relative;
    border-radius: 0px 0px 4px 4px;
}

.CongratulationsBox .CBInner .CBIDetail .CBIDThumb {
    position: absolute;
    width: 150px;
    height: 150px;
    right: -50px;
    bottom: -10px;
}

.CongratulationsBox .CBInner .CBIDetail h3 {
    font-size: 28px;
    font-weight: 600;
    color: #434750;
    margin-bottom: 10px;
}

.CongratulationsBox .CBInner .CBIDetail h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.CongratulationsBox .CBInner .CBIDetail .CBIDBtn button.mainButton {
    padding: 10px 20px;
}

.CongratulationsBox .CBInner .CBIDetail .CBIDBtn button.mainButton .buttonIcon {
    font-size: 10px;
    margin-left: 10px;
}

//

.UploadCSVBox .UCBInner .DropZoneContainer {
    padding: 100px;
    min-height: 340px;
    text-align: center;
}

.UploadCSVBox .UCBInner .DropZoneContainer .DZCIUAttach {
    width: 150px;
}

.UploadCSVBox .UCBInner .DropZoneContainer .DZCIUAttach .DZCIUAIIIcon {
    height: 150px;
    font-size: 150px;
}

.UploadCSVBox .UCBInner .DropZoneContainer .DZCIUAttach .DZCIUAIIInfo {
    height: 150px;
}

.UploadCSVBox .UCVCBLink {
    text-align: center;
}

.UploadCSVBox .UCVCBLink a {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark-blue);
    text-decoration: underline;
}

//

.AssignRepsStep {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    border-right: 1px solid #eaeaea;
}

.AssignRepsStep .ARSHead {
    padding: 15px;
    box-shadow: 0 4px 34px 0 #00000010;
}

.AssignRepsStep .ARSHead .SearchBox input {
    padding: 12px;
    padding-left: 30px;
    padding-right: 25px;
}

.AssignRepsStep .ARSBody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    display: flex;
    flex-direction: column;
}

.AssignRepsStep .ARSBody .ARSBInner {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    padding-top: 15px;
}

.AssignRepsStep .ARSBody .ARSBInner .ARSBINU {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.AssignRepsStep .ARSBody .ARSBInner .ARSBINU .ARSBINUIcon {
    font-size: 200px;
    display: flex;
}

.AssignRepsStep .ARSBody .ARSBInner .ARSBINU .ARSBINUTitle {
    font-size: 22px;
    font-weight: 600;
    color: #000;
}

.AssignRepsStep .ARSBody .ARSBILoader {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.19) 0%, rgb(255 255 255 / 0%) 100%);
}

.AssignRepsStep .ARSBody .ARSBInner .ARSBIUserInfo {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #f6f8fc;
}

.ARSBIUSkeleton {
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.ARSBIUSkeleton .SkeletonMain.ARSBIUSIcon {
    border-radius: 100%;
}

.ARSBIUSkeleton .ARSBIUSTitle {
    flex: 1;
    padding-left: 15px;
}

.ARSSelectedUser {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
}

.ARSSelectedUser .ARSSUHead {
    padding: 15px;
    box-shadow: 0 4px 34px 0 #00000010;
}

.ARSSelectedUser .ARSSUHead .ARSSUHTitle {
    font-size: 22px;
    line-height: 44px;
    font-weight: 500;
}

.ARSSelectedUser .ARSSUBody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.ARSSelectedUser .ARSSUBody .ARSSUBInner {
    padding: 10px 15px;
    padding-top: 20px;
}

.ARSSelectedUser .ARSSUBody .ARSSUBInner .ARSSUBUser {
    padding: 15px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin-bottom: 15px;
}

.ARSSelectedUser .ARSSUBody .ARSSUBInner .ARSSUBUser .ARSSUBUDetail {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
}

.ARSSelectedUser .ARSSUBody .ARSSUBInner .ARSSUBUser .ARSSUBUDetail .UserInfo {
    flex: 1;
    padding-right: 15px;
}

.ARSSelectedUser .ARSSUBody .ARSSUBInner .ARSSUBUser .ARSSUBUDetail .ARSSUBUDRemove {
    font-size: 12px;
}

.ARSSelectedUser .ARSSUBody .ARSSUBINoUser {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
    display: flex;
    font-size: 200px;
    padding-top: 15px;
}

.ARSSelectedUser .ARSSUBody .ARSSUBINoUser .ARSSUBINUTitle {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-top: 10px;
}

.ARSSUBUser .ARSSUBUDropdown .ARSSUBUDAddNum {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
}

.ARSSUBUser .ARSSUBUDropdown .ARSSUBUDAddNum .DCPhoneNumberWrapper {
    flex: 1;
    margin-right: 10px;
}

//

.CreateEmailStep {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.CreateEmailStep .CESForm {
    width: 65%;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.CreateEmailStep .CESForm .CESFSelectField {
    display: flex;
    background-color: var(--white);
    border: 1px solid #f2f2f2;
}
.CreateEmailStep .CESForm .CESFSelectField .DCInputWrapper {
    width: 100%;
    height: 100%;
}

.CreateEmailStep .CESForm .CESFSelectField .DCDSMainBtn span.buttonTitle {
    text-align: left;
}

.CreateEmailStep .CESForm .CESFSelectField .DCDropdownSelect {
    flex: 1;
}

.CreateEmailStep .CESForm .CESFSelectField .DCDropdownSelect button {
    border: 0;
}

.CreateEmailStep .CESForm .CESFSelectField.CESFSFActive,
.CreateEmailStep .CESForm .CESFSelectField.CESFSFActive .DCDropdownSelect button {
    border-color: var(--dark-blue);
}

.CreateEmailStep .CESForm .CESFSelectField .CESFSFLabel {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border-right: 1px solid #f2f2f2;
    font-size: 14px;
    text-transform: none;
    color: #84929e;
    font-weight: 400;
    line-height: normal;
}

.CreateEmailStep .CESForm .CESFSelectField.CESFSFActive .CESFSFLabel {
    border-color: var(--dark-blue);
}

.CreateEmailStep .CESForm .fieldControl .CESFInputContainer {
    display: flex;
    align-items: flex-start;
}

.CreateEmailStep .CESForm .fieldControl .CESFInputContainer .DCInputWrapper {
    flex: 1;
    padding-right: 15px;
}

.CreateEmailStep .CESForm .fieldControl .CESFInputContainer .PersonalizeBtn {
    display: flex;
}

.CreateEmailStep .CESForm .fieldControl .CESFInputContainer .PersonalizeBtn button.mainButton {
    padding: 10px;
}

.CreateEmailStep .CESForm .fieldControl .CESFInputContainer .AutoCompleteRoot .ACRLabelReadonly {
    background-color: #fafafa;
}

.CreateEmailStep .CESForm .CESFTestForm {
    display: flex;
    align-items: flex-start;
}

.CreateEmailStep .CESForm .CESFTestForm .DCInputWrapper {
    flex: 1;
    margin-right: 10px;
}

.CreateEmailStep .CESMobView {
    width: 35%;
    padding-left: 50px;
    display: flex;
    justify-content: flex-end;
}

.CESFEditor {
    position: relative;
    border: 1px solid #e8f0f9;
    background-color: var(--white);
}

.CESFEditor.CESFEFocus {
    border-color: var(--dark-blue);
    box-shadow: 0 0 3px var(--dark-blue);
}

.CESFEditor .WysiwygEditor {
    border: 0;
}

.CESFEditor .WysiwygEditor .UseTemplate {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 125px;
    z-index: 1;
}

.CESFEditor .WysiwygEditor .UseTemplate.UseTemplateStepperDropdown button {
    flex-direction: row-reverse !important;
}
.CESFEditor .WysiwygEditor .UseTemplate.UseTemplateStepperDropdown button svg {
    margin-left: 0.2rem !important;

    @media screen and (max-width: 1480px) {
        padding-left: 0.48rem !important;
        transform: translateY(1px) !important;
    }
}

.CESFEditor .WysiwygEditor .UseTemplate button.mainButton {
    width: 100%;
}

.CESFEditor .WysiwygEditor .UseTemplate button.mainButton span.buttonTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CESFEditor .WysiwygEditor .PersonalizeBtn {
    position: absolute;
    bottom: 10px;
    left: 145px;
    z-index: 1;
}

.CESFEditor .WysiwygEditor .PersonalizeBtn .AutoCompleteRoot {
    width: auto;
    height: auto;
    margin: 0;
    margin-left: 0;
    opacity: 1;
}

.CESFEditor .WysiwygEditor .PersonalizeBtn .AutoCompleteRoot .ACRLabel {
    padding: 8px;
    padding-right: 35px;
    color: #84929e;
}

.CESFEditor .WysiwygEditor .PersonalizeBtn .AutoCompleteRoot .ACRLabel::after {
    top: 11px;
    width: 13px;
    height: 13px;
}

.CESFEditor .CESFEBtns {
    height: 57px;
    display: flex;
    align-items: center;
    padding-left: 265px;
    column-gap: 10px;
    position: relative;
}

.CESFEditor .CESFEBtns .CESFEAttachmentBtn {
    position: relative;
}

.CESFEditor .CESFEBtns .CESFEAttachmentBtn input[type='file'] {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 10px;
    z-index: -1;
}

.CESFEditor .CESFEAttachList {
    padding: 5px 7px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;
}

.CESFEditor .CESFEAttachList .CESFEAttachListLoading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.CESFEditor .CESFEAttachList .CESFEALItem {
    display: flex;
    align-items: center;
    border: 1px solid #e8f0f9;
    padding: 3px 5px;
    border-radius: 2px;
    margin: 3px;
    max-width: 150px;
    position: relative;
    overflow: hidden;
}

.CESFEditor .CESFEAttachList .CESFEALItem .CESFEALILoad {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    transition: all ease 0.3s;
}

.CESFEditor .CESFEAttachList .CESFEALItem .CESFEALILoad .CESFEALILInner.inError {
    height: 100%;
    background-color: #f44336;
}

.CESFEditor .CESFEAttachList .CESFEALItem .CESFEALILoad .CESFEALILInner.inProgress {
    height: 100%;
    background-color: var(--dark-blue);
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}
@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}

.CESFEditor .CESFEAttachList .CESFEALItem a.CESFEALITitle {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.CESFEditor .CESFEAttachList .CESFEALItem .CESFEALIRemove {
    font-size: 8px;
    display: flex;
    cursor: pointer;
    color: var(--dark-blue);
    position: relative;
    z-index: 1;
    margin-left: 5px;
}

.CESFEditor .CESFEAttachList .CESFEALItem .CESFEALIRestart {
    font-size: 10px;
    display: flex;
    cursor: pointer;
    color: #f44336;
    position: relative;
    z-index: 1;
}

.CESFEditor .CESFEAttachList .CESFEALItem .CESFEALIPreView {
    font-size: 12px;
    display: flex;
    cursor: pointer;
    color: var(--dark-blue);
    margin-right: 5px;
    position: relative;
    z-index: 1;
}

.CESFEditor .CESFEAttachList .CESFEALItem .CESFEALIDownload {
    font-size: 12px;
    display: flex;
    cursor: pointer;
    color: var(--dark-blue);
    margin-right: 5px;
    position: relative;
    z-index: 1;
}

.CESFEditor.CESFESub .WysiwygEditor .WEMEditor {
    min-height: 40px;
    padding: 7.5px 10px;
    padding-right: 125px;
}

.CESFEditor.CESFESub .PersonalizeBtn {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
}

.CESFEditor.CESFESub .PersonalizeBtn .PSBLabel {
    border: 0;
    padding: 9px 10px;
    font-size: 14px;
    line-height: 22px;
    border-left: 1px solid #f2f2f2;
}

.useTemplateDropdown .DCDSOptionList .DropdownOptionsBtn.mainButton span.buttonTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personalizeDropdown .DCDSOptionList .DropdownOptionsBtn.mainButton span.buttonTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//

.SelectBusinesses {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SelectBusinesses .SelectBusinessesHeader {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.SelectBusinesses .SelectBusinessesHeader .SearchBox {
    flex: 1;
}

.SelectBusinesses .SelectBusinessesHeader .SearchBox input {
    font-size: 16px;
    padding: 14px;
    padding-left: 30px;
    padding-right: 25px;
}

.SelectBusinesses .SelectBusinessesHeader .SBHBtn {
    font-size: 16px;
    margin-left: 25px;
    padding: 14px 15px;
}

.SelectBusinesses .SelectBusinessesBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    position: relative;
}

.SelectBusinesses .SelectBusinessesBody .SBBPreview {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    border-radius: 0;
    z-index: 1;
    width: 100px;
    align-self: flex-start;
}

.SelectBusinesses .SelectBusinessesBody .SBBPreview:hover {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    border-radius: 0;
    z-index: 1;
    width: 100px;
    align-self: flex-start;
    border-color: var(--dark-blue);
    color: var(--dark-blue);
    text-decoration: underline;
    cursor: pointer;
}

.summaryStep {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.summaryStep .summaryStepHead {
    display: flex;
    align-items: center;
    background-color: #cad3e3;
    border: 1px solid #cad3e3;
}

.summaryStep .summaryStepHead .SSHCol {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #434750;
}

.summaryStep .summaryStepHead .SSHCol1 {
    width: 40%;
}

.summaryStep .summaryStepHead .SSHCol2 {
    width: 60%;
}

.summaryStep .summaryStepRow {
    display: flex;
    align-items: center;
    background-color: var(--white);
    border: 1px solid #cad3e3;
    border-top: 0;
}

.summaryStep .summaryStepRow .SSRCol1 {
    width: 40%;
    border-right: 1px solid #cad3e3;
}

.summaryStep .summaryStepRow .SSRCol2 {
    width: 60%;
}

.summaryStep .summaryStepRow .SSRCol {
    padding: 10px;
}

.summaryStep .summaryStepRow .SSRAction {
    display: flex;
    align-items: center;
}

.summaryStep .summaryStepRow .SSRAction .SSRAIcon {
    display: flex;
    font-size: 20px;
    padding: 5px;
    background-color: #f6f8fc;
    border-radius: 4px;
}

.summaryStep .summaryStepRow .SSRAction .SSRAITitle {
    flex: 1;
    padding-left: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #666666;
}

.summaryStep .summaryStepRow .SSRDes {
    font-size: 15px;
    font-weight: 500;
    color: #666666;
}

.summaryStep .summaryStepFooter {
    background-color: var(--white);
    border: 1px solid #cad3e3;
    border-top: 0;
    padding: 10px;
}

.summaryStep .summaryStepFooter .SSFContent {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-blue);
}

//

.SelectContactsMain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.SelectContacts {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.SelectContacts .SelectContactsHeader {
    display: flex;
    background-color: var(--white);
    border: 1px solid #cad3e3;
}

.SelectContacts .SelectContactsHeader .SCHCol {
    padding: 7px;
}

.SelectContacts .SelectContactsHeader .SCHCol.SCHCol1 {
    width: 13%;
}

.SelectContacts .SelectContactsHeader .SCHCol.SCHCol2 {
    width: 29%;
    border-left: 1px solid #cad3e3;
}

.SelectContacts .SelectContactsHeader .SCHCol.SCHCol2 .SCHCTitle {
    font-size: 16px;
    font-weight: 600;
    color: #666;
}

.SelectContacts .SelectContactsBody {
    border: 1px solid #cad3e3;
    border-top: 0;
    background-color: var(--white);
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SelectContacts .SelectContactsBody .SCHBRow {
    display: flex;
    background-color: var(--white);
    border-bottom: 1px solid #cad3e3;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol {
    padding: 7px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol > div {
    width: 100%;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol.SCHBRCol1 {
    width: 13%;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol.SCHBRCol2 {
    width: 29%;
    border-left: 1px solid #cad3e3;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCCheck {
    display: flex;
    align-items: center;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCValid {
    font-size: 24px;
    display: flex;
}

.SelectContacts .SelectContactsBody .SCHBWrapper .SCHBRow .SCHBRCol .SCHCValid {
    margin-left: 15px;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCValid.valid {
    color: #4de98a;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCValid.unValid {
    color: #f44336;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCCheck .iconButton.circleBtn {
    margin-right: 10px;
}

.SelectContacts
    .SelectContactsBody
    .SCHBRow
    .SCHBRCol
    .SCHCCheck
    .iconButton.circleBtn.SCHCCCollapsed {
    transform: rotate(90deg);
    color: var(--blue-invert);
    background-color: var(--dark-blue);
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCTitle {
    position: relative;
    padding-right: 30px;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCTitle .UserInfo .UIImg {
    width: 25px;
    height: 25px;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCTitle button.iconButton {
    position: absolute;
    top: 7px;
    right: 0;
    visibility: hidden;
    opacity: 0;
}

.SelectContacts .SelectContactsBody .SCHBRow:hover .SCHBRCol .SCHCTitle button.iconButton {
    visibility: visible;
    opacity: 1;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol.SCHCTAddress {
    overflow: hidden;
}

.SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol.SCHCTAddress .SCHCTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.TitleFieldGroup .TFGTitle {
    font-size: 24px;
    font-weight: 600;
    color: #26262b;
    padding-bottom: 15px;
    text-align: center;
}

//

.SelectTemplateView {
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    background-color: #f6f8fc;
    border: 1px solid #eaeaea;
    padding: 50px;
    border-radius: 4px;
    height: 100%;
    margin-top: 10px;
}

.SelectTemplateView .STVInner {
    display: grid;
    grid-gap: 45px;
    gap: 45px;
    grid-template-columns: repeat(auto-fill, minMax(250px, 1fr));
    grid-auto-rows: 250px;
}

.SelectTemplateView .STVInner > div {
    background-color: var(--white);
    width: 100%;
    height: 100%;
}

.TemplateViewSkeleton {
    height: 100%;
    position: relative;
}

.TemplateViewSkeleton .TVSInner {
    height: 100%;
}

.TemplateViewSkeleton .TVSHeader {
    position: absolute;
    top: 10px;
    width: 100%;
}

.TemplateViewSkeleton .TVSHeader .TVSHInner {
    margin: auto;
}

.TemplateViewSkeleton .TVSTitle {
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.TemplateViewSkeleton .TVSTitle .TVSTInner {
    margin: auto;
}

.CheckboxStepOption label.Checkbox {
    padding: 10px 0;
}

.CheckboxStepOption label.Checkbox span.CheckboxLabel {
    font-size: 16px;
    color: #666;
    font-weight: 600;
}

.CheckboxStepOption label.Checkbox span.CheckboxLabel strong {
    font-weight: 600;
    color: #000;
}
.CheckboxStepOptionAdd button.mainButton {
    color: var(--dark-blue);
    font-weight: 600;
    font-size: 15px;
    padding: 10px 4px;
}

.CreateEmailStep .CESForm .CESFTestForm .DCPhoneNumberWrapper {
    flex: 1;
    margin-right: 10px;
}

.CESFEditor.CESFSMSEditor .WysiwygEditor {
    padding-bottom: 15px;
}

.CESFEditor.CESFSMSEditor .WysiwygEditor .WEMWrapper .WEMToolbar {
    padding: 0;
    border: 0;
}

.CESFEditor.CESFSMSEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div {
    display: none;
}

.CESFEditor.CESFSMSEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.PersonalizeBtn,
.CESFEditor.CESFSMSEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.UseTemplate {
    display: block;
}

.CESFEditor.CESFSMSEditor .CESFESMSELimit {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: right;
}

.CESFEditor.CESFSMSEditor .CESFESMSELimit .CESFESMSELNum {
    font-size: 15px;
    font-weight: 500;
    color: #7b7b7b;
    line-height: normal;
}

.CESFEditor.CESFSMSEditor .CESFESMSELimit .CESFESMSELCre {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
}

.CESFEditor.CESFSMSEditor .CESFESMSEPer {
    position: absolute;
    color: #f44336;
    font-weight: 500;
    bottom: -20px;
    left: unset;
    font-size: 12px;
}

.CESFEditor.CESFSMSEditor .CESFSMSESaveTemp {
    display: flex;
    padding-left: 260px;
    padding-bottom: 10px;
}

//

.SSTableView {
    height: 100%;
    background-color: var(--white);
}

.SSTableView .SSTVInner {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

//

.SelectedBusinessDialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SelectedBusinessDialog .BodyBox {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    padding: 0;
}

.SelectedBusinessDialog .SBDUser {
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.SelectedBusinessDialog .SBDUser .UserInfo {
    flex: 1;
}

.SelectedBusinessDialog .SBDUser .SBDUBtn {
    display: flex;
}

.SelectedBusinessDialog .SBDUser .SBDUBtn button {
    margin-left: 10px;
}

.MapFieldsSL {
    flex: 1;
    overflow: auto;
}

.MapFieldsSL .MFSLInner {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-width: 1245px;
}

.MapFieldsSL .MFSLInner .SLIHeader .SLIHCol {
    border-right: 1px solid #cad3e3;
    text-align: left;
}

.MapFieldsSL .MFSLInner .SLIHeader .SLIHCol:nth-child(1) {
    width: 10%;
}

.MapFieldsSL .MFSLInner .SLIHeader .SLIHCol:nth-child(2) {
    width: 25%;
}

.MapFieldsSL .MFSLInner .SLIHeader .SLIHCol:nth-child(3) {
    width: 40%;
}

.MapFieldsSL .MFSLInner .SLIHeader .SLIHCol:nth-child(4) {
    width: 25%;
    border-right: 0;
}

.MapFieldsSL .MFSLInner .SLIBody .SLIBRow .SLIBRCol {
    border-right: 1px solid #cad3e3;
    text-align: left;
}

.MapFieldsSL .MFSLInner .SLIBody .SLIBRow .SLIBRCol .SLIBRCText {
    justify-content: flex-start;
}

.MapFieldsSL .MFSLInner .SLIBody .SLIBRow .SLIBRCol:nth-child(1) {
    width: 10%;
}

.MapFieldsSL .MFSLInner .SLIBody .SLIBRow .SLIBRCol:nth-child(2) {
    width: 25%;
}

.MapFieldsSL .MFSLInner .SLIBody .SLIBRow .SLIBRCol:nth-child(3) {
    width: 40%;
    padding: 0;
}

.MapFieldsSL .MFSLInner .SLIBody .SLIBRow .SLIBRCol:nth-child(3) .SLIBRCText:nth-child(1) {
    border-right: 1px solid #cad3e3;
}

.MapFieldsSL .MFSLInner .SLIBody .SLIBRow .SLIBRCol:nth-child(3) .SLIBRCText {
    padding: 10px;
    width: 50%;
    height: 100%;
    align-items: center;
}

.MapFieldsSL .MFSLInner .SLIBody .SLIBRow .SLIBRCol:nth-child(4) {
    width: 25%;
    border-right: 0;
}

// Desktop Responsive

@media (min-width: 1920px) {
}
@media (min-width: 1559px) and (max-width: 1800px) {
    .ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
        font-size: 150px;
    }

    .ARSSelectedUser .ARSSUBody .ARSSUBINoUser .ARSSUBINUTitle {
        font-size: 20px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox input {
        font-size: 16px;
        padding: 10px;
        padding-left: 30px;
        padding-right: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SBHBtn {
        font-size: 14px;
        margin-left: 15px;
        padding: 10px 15px;
    }

    .SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCTitle button.iconButton {
        top: 2px;
    }

    .CESFEditor .CESFEBtns {
        padding-left: 260px;
    }
}
@media (min-width: 1367px) and (max-width: 1600px) {
    .ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
        font-size: 150px;
    }

    .ARSSelectedUser .ARSSUBody .ARSSUBINoUser .ARSSUBINUTitle {
        font-size: 20px;
    }

    .CreateEmailStep .CESMobView {
        padding-left: 45px;
        width: 40%;
    }

    .CreateEmailStep .CESForm {
        width: 60%;
        overflow: auto;
    }

    .CongratulationsBox .CBInner {
        width: 60%;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox input {
        font-size: 16px;
        padding: 10px;
        padding-left: 30px;
        padding-right: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SBHBtn {
        font-size: 14px;
        margin-left: 15px;
        padding: 10px 15px;
    }

    .SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCTitle button.iconButton {
        top: 2px;
    }

    .CESFEditor .CESFEBtns {
        padding-left: 260px;
    }

    .CardView .CVInner {
        grid-template-columns: repeat(auto-fill, minMax(240px, 1fr));
        grid-auto-rows: 240px;
    }

    .SelectContactsMain .SelectContacts {
        width: 1000px;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSEPer {
        bottom: -20px;
        left: unset;
        font-size: 12px;
    }

    .CESFEditor .WysiwygEditor .UseTemplate button.mainButton {
        font-size: 12px;
    }

    .CESFEditor .CESFEBtns .CESFEAttachmentBtn button.mainButton {
        font-size: 12px;
    }

    .CESFEditor .CESFEBtns .CESFSaveTemp button.mainButton {
        font-size: 12px;
    }
}
@media (min-width: 1280px) and (max-width: 1366.98px) {
    .ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
        font-size: 150px;
    }

    .ARSSelectedUser .ARSSUBody .ARSSUBINoUser .ARSSUBINUTitle {
        font-size: 20px;
    }

    .CreateEmailStep .CESMobView {
        padding-left: 45px;
        width: 40%;
    }

    .CreateEmailStep .CESForm {
        width: 60%;
        overflow: auto;
    }

    .CongratulationsBox .CBInner {
        width: 60%;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox input {
        font-size: 16px;
        padding: 10px;
        padding-left: 30px;
        padding-right: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SBHBtn {
        font-size: 14px;
        margin-left: 15px;
        padding: 10px 15px;
    }

    .SelectContacts .SelectContactsBody .SCHBRow .SCHBRCol .SCHCTitle button.iconButton {
        top: 2px;
    }

    .CESFEditor .CESFEBtns {
        padding-left: 10px;
        height: auto;
        padding-bottom: 55px;
    }

    .CardView .CVInner {
        grid-template-columns: repeat(auto-fill, minMax(220px, 1fr));
        grid-auto-rows: 220px;
    }

    .SelectContactsMain .SelectContacts {
        width: 1000px;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSEPer {
        bottom: -20px;

        left: unset;
        font-size: 12px;
    }

    .CESFEditor.CESFSMSEditor .CESFSMSESaveTemp {
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 55px;
    }
}

@media (min-width: 1024px) and (max-width: 1279.98px) {
    .CESFEditor .CESFEBtns {
        padding-left: 10px;
        height: auto;
        padding-bottom: 55px;
    }
    .CardView .CVInner {
        grid-template-columns: repeat(auto-fill, minMax(210px, 1fr));
        grid-auto-rows: 220px;
    }
    .SimpleInputView .SIVInner {
        width: 100%;
    }
    .CreateEmailStep .CESMobView {
        display: none;
    }

    .CreateEmailStep .CESForm {
        width: 100%;
        overflow: auto;
    }

    .ARSSelectedUser .ARSSUBody .ARSSUBINoUser {
        padding: 10px 40px;
        text-align: center;
    }
    .ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
        font-size: 100px;
    }
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div {
        margin-bottom: 15px;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar {
        padding-bottom: 0;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.UseTemplate,
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.PersonalizeBtn {
        margin-bottom: 0px;
    }

    .CongratulationsBox .CBInner {
        width: 90%;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox input {
        padding: 10px;
        padding-left: 30px;
        padding-right: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox span.SearchIcon {
        left: 10px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SBHBtn {
        font-size: 14px;
        margin-left: 15px;
        padding: 11px 15px;
        border-radius: 0;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner {
        flex-wrap: wrap;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner .PMPages {
        width: 100%;
        order: 1;
        justify-content: center;
    }

    .SelectContactsMain .SelectContacts {
        width: 1000px;
    }

    .UploadCSVBox .UCBInner .DropZoneContainer {
        padding: 50px;
        text-align: center;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSEPer {
        bottom: -20px;

        left: unset;
        font-size: 12px;
    }

    .CESFEditor.CESFSMSEditor .CESFSMSESaveTemp {
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 55px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .CardView .CVInner {
        grid-template-columns: repeat(auto-fill, minMax(200px, 1fr));
        grid-auto-rows: 200px;
    }
    .SimpleInputView .SIVInner {
        width: 100%;
    }

    .ARSSelectedUser .ARSSUBody .ARSSUBINoUser {
        padding: 10px 40px;
        text-align: center;
    }
    .ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
        font-size: 100px;
    }

    .CreateEmailStep .CESMobView {
        display: none;
    }
    .CreateEmailStep .CESForm {
        width: 100%;
        overflow: auto;
    }
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div {
        margin-bottom: 15px;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar {
        padding-bottom: 0;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.UseTemplate,
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.PersonalizeBtn {
        margin-bottom: 0px;
    }

    .CongratulationsBox .CBInner {
        width: 90%;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox input {
        padding: 10px;
        padding-left: 30px;
        padding-right: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox span.SearchIcon {
        left: 10px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SBHBtn {
        font-size: 14px;
        margin-left: 15px;
        padding: 11px 15px;
        border-radius: 0;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner {
        flex-wrap: wrap;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner .PMPages {
        width: 100%;
        order: 1;
        justify-content: center;
    }

    .SelectContactsMain .SelectContacts {
        width: 1000px;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSEPer {
        bottom: -20px;

        left: unset;
        font-size: 12px;
    }

    .CESFEditor.CESFSMSEditor .CESFSMSESaveTemp {
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 55px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .CardView .CVInner {
        grid-template-columns: repeat(auto-fill, minMax(200px, 1fr));
        grid-auto-rows: 250px;
    }
    .SimpleInputView .SIVInner {
        width: 100%;
    }
    .ARSSelectedUser .ARSSUBody .ARSSUBINoUser {
        padding: 10px 40px;
        text-align: center;
    }
    .ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
        font-size: 100px;
    }

    .CreateEmailStep .CESMobView {
        display: none;
    }
    .CreateEmailStep .CESForm {
        width: 100%;
        overflow: auto;
    }
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div {
        margin-bottom: 15px;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar {
        padding-bottom: 0;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.UseTemplate,
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.PersonalizeBtn {
        margin-bottom: 0px;
    }

    .CongratulationsBox .CBInner {
        width: 70%;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox input {
        padding: 10px;
        padding-left: 30px;
        padding-right: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox span.SearchIcon {
        left: 10px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SBHBtn {
        font-size: 14px;
        margin-left: 15px;
        padding: 11px 15px;
        border-radius: 0;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner {
        flex-wrap: wrap;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner .PMPages {
        width: 100%;
        order: 1;
        justify-content: center;
    }

    .SelectContactsMain .SelectContacts {
        width: 1000px;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSEPer {
        bottom: -20px;

        left: unset;
        font-size: 12px;
    }

    .CESFEditor.CESFSMSEditor .CESFSMSESaveTemp {
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 55px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .CESFEditor .CESFEBtns {
        padding-left: 10px;
        height: auto;
        padding-bottom: 55px;
    }

    .CardView .CVInner {
        grid-template-columns: repeat(auto-fill, minMax(190px, 1fr));
        gap: 20px;
        grid-auto-rows: 220px;
        margin-bottom: 0;
    }

    .CardView {
        padding: 20px;
    }

    .SimpleInputView {
        padding: 20px;
        align-items: flex-start;
    }

    .SimpleInputView .SIVInner {
        width: 100%;
    }

    .SimpleInputView.fullWidth .SIVRow {
        flex-direction: column;
        flex-wrap: nowrap;
        overflow: auto;
        background-color: unset;
    }

    .SimpleInputView.fullWidth .SIVRow .SIVRCol1 {
        width: 100%;
        background-color: var(--white);
        margin-bottom: 15px;
    }

    .SimpleInputView.fullWidth .SIVRow .SIVRCol2 {
        width: 100%;
    }

    .AssignRepsStep .ARSBody {
        height: 300px;
        flex: unset;
    }

    .ARSSelectedUser {
        flex: unset;
        background-color: var(--white);
    }

    .ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
        font-size: 100px;
    }

    .CreateEmailStep .CESMobView {
        display: none;
    }
    .CreateEmailStep .CESForm {
        width: 100%;
    }
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div {
        margin-bottom: 15px;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar {
        padding-bottom: 0;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.UseTemplate,
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.PersonalizeBtn {
        margin-bottom: 0px;
    }

    .CongratulationsBox .CBInner {
        width: 100%;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox input {
        padding: 10px;
        padding-left: 30px;
        padding-right: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox span.SearchIcon {
        left: 10px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SBHBtn {
        font-size: 14px;
        margin-left: 15px;
        padding: 11px 15px;
        border-radius: 0;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner {
        flex-wrap: wrap;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner .PMPages {
        width: 100%;
        order: 1;
        justify-content: center;
    }

    .SelectContactsMain .SelectContacts {
        width: 1000px;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSEPer {
        bottom: -20px;

        left: unset;
        font-size: 12px;
    }

    .CESFEditor.CESFSMSEditor .CESFSMSESaveTemp {
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 55px;
    }
}

@media (max-width: 575.98px) {
    .CardView {
        padding: 20px;
    }

    .CardView .CVInner {
        grid-template-columns: 250px;
        gap: 20px;
        grid-auto-rows: 220px;
        margin-bottom: 0;
        justify-content: center;
    }

    .SimpleInputView {
        padding: 20px;
        align-items: flex-start;
    }

    .SimpleInputView .SIVInner {
        width: 100%;
        padding: 20px;
    }

    .SimpleInputView.fullWidth .SIVRow {
        flex-direction: column;
        flex-wrap: nowrap;
        overflow: auto;
        background-color: unset;
    }

    .SimpleInputView.fullWidth .SIVRow .SIVRCol1 {
        width: 100%;
        background-color: var(--white);
        margin-bottom: 15px;
    }

    .SimpleInputView.fullWidth .SIVRow .SIVRCol2 {
        width: 100%;
    }

    .AssignRepsStep .ARSBody {
        height: 300px;
        flex: unset;
    }

    .ARSSelectedUser {
        flex: unset;
        background-color: var(--white);
    }

    .ARSSelectedUser .ARSSUBody .ARSSUBINoUser {
        padding: 50px;
        text-align: center;
    }

    .ARSSelectedUser .ARSSUBody .ARSSUBINUIcon {
        font-size: 100px;
    }

    .CreateEmailStep .CESMobView {
        display: none;
    }
    .CreateEmailStep .CESForm {
        width: 100%;
        overflow: auto;
    }
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div {
        margin-bottom: 15px;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar {
        padding-bottom: 0;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.UseTemplate,
    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.PersonalizeBtn {
        margin-bottom: 0px;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.UseTemplate button {
        font-size: 12px;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.UseTemplate button span.buttonIcon {
        font-size: 10px;
    }

    .CESFEditor .WysiwygEditor .WEMWrapper .WEMToolbar > div.PersonalizeBtn {
        left: 145px;
    }

    .CESFEditor .WysiwygEditor .PersonalizeBtn .AutoCompleteRoot .ACRLabel {
        font-size: 12px;
        padding-right: 27px;
    }

    .CESFEditor .WysiwygEditor .PersonalizeBtn .AutoCompleteRoot .ACRLabel::after {
        top: 10px;
        width: 10px;
        height: 10px;
    }

    .CESFEditor .CESFEBtns {
        padding-left: 10px;
        height: auto;
        padding-bottom: 55px;
    }

    .CESFEditor .CESFEBtns .CESFSaveTemp {
        display: flex;
    }

    .CESFEditor .CESFEBtns .CESFSaveTemp button.mainButton {
        font-size: 12px;
    }

    .CESFEditor .CESFEBtns .CESFEAttachmentBtn button {
        font-size: 12px;
    }

    .CESFEditor .CESFEBtns .CESFEAttachmentBtn button span.buttonIcon {
        font-size: 10px;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSELimit {
        bottom: 55px;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSELimit .CESFESMSELCre {
        font-size: 10px;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSELimit .CESFESMSELNum {
        font-size: 12px;
    }

    .CreateEmailStep
        .CESForm
        .CESFTestForm
        .DCPhoneNumberWrapper
        .react-tel-input
        .flag-dropdown
        ul.country-list {
        width: 300px;
    }

    .CongratulationsBox .CBInner {
        width: 100%;
        padding: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox input {
        padding: 10px;
        padding-left: 30px;
        padding-right: 25px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SearchBox span.SearchIcon {
        left: 10px;
    }

    .SelectBusinesses .SelectBusinessesHeader .SBHBtn {
        font-size: 14px;
        margin-left: 15px;
        padding: 11px 15px;
        border-radius: 0;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner {
        flex-wrap: wrap;
    }

    .SelectBusinesses .SelectBusinessesBody .PaginationMain .PMInner .PMPages {
        width: 100%;
        order: 1;
        justify-content: center;
    }

    .SelectContactsMain .SelectContacts {
        width: 1000px;
    }

    .summaryStep .summaryStepRow {
        flex-direction: column;
        padding: 10px;
    }

    .summaryStep .summaryStepRow .SSRCol {
        padding: 0;
    }

    .summaryStep .summaryStepRow .SSRCol1 {
        width: 100%;
        border-right: 0;
        padding-bottom: 5px;
        border-bottom: 1px solid #cad3e3;
    }

    .summaryStep .summaryStepRow .SSRCol2 {
        width: 100%;
        padding-top: 5px;
    }

    .summaryStep .summaryStepRow .SSRDes {
        font-size: 13px;
    }

    .UploadCSVBox .UCBInner .DropZoneContainer {
        padding: 50px;
        height: 340px;
    }

    .DropZoneContainer .DZCInner .DZCIMessage .DZCIMTitle {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    .CESFEditor.CESFSMSEditor .CESFESMSEPer {
        bottom: -20px;

        left: unset;
        font-size: 12px;
    }

    .CESFEditor.CESFSMSEditor .CESFSMSESaveTemp {
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 55px;
    }
    .SelectList .SLIMFHead {
        flex-direction: column;
        row-gap: 15px;
    }

    .SelectList .SLIMFHead button.mainButton {
        align-self: center;
    }
}

// Create Email
.CESFECreateEmailEditorActions {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    justify-content: flex-start !important;
    padding-bottom: 1rem;
    z-index: 2;

    @media screen and (max-width: 1480px) {
        bottom: 16px !important;
        width: 96% !important;
        height: 32%;
        background: #fff;
    }

    @media screen and (max-width: 1280px) {
        height: 24%;
    }
    @media screen and (max-width: 1019px) {
        height: 20%;
    }
    @media screen and (max-width: 991px) {
        height: 22%;
    }
    @media screen and (max-width: 500px) {
        height: 18%;
    }
    @media screen and (max-width: 400p4) {
        height: 14%;
    }

    .CESFECEEAChild {
        display: flex !important;
        width: 100% !important;
        flex-wrap: wrap !important;

        @media screen and (max-width: 1480px) {
            display: grid !important;
            grid-template-columns: 1fr 1fr !important;
            width: 100% !important;

            & > *:first-child {
                padding-left: 0 !important;
                width: 98.5% !important;
            }

            & > * {
                width: 100% !important;
            }

            & button,
            & .PersonalizeBtn {
                width: 100% !important;
                margin-top: 0 !important;
            }

            & .PersonalizeBtn .PSBLabel {
                display: flex !important;
                justify-content: center;
                align-items: center !important;
                height: 33px !important;
            }

            .CESFEBtns.OtherBtns {
                display: flex !important;
                gap: 0 !important;
                height: auto !important;
                grid-column: 1 / -1;

                & > * {
                    flex-basis: 50% !important;
                }
            }
        }

        @media screen and (max-width: 1366px) {
            & .PersonalizeBtn .PSBLabel {
                height: 36px !important;
            }
        }

        @media screen and (max-width: 575px) {
            .CESFEBtns.OtherBtns > * * {
                font-size: 14px !important;
            }
        }

        @media screen and (max-width: 440px) {
            & * {
                font-size: 10px !important;
            }

            .CESFEBtns.OtherBtns > * * {
                font-size: 10px !important;
            }

            & .PersonalizeBtn .PSBLabel {
                height: 31px !important;
            }
        }
    }

    * {
        width: max-content !important;
        position: relative !important;
        margin: 0 !important;
        padding-left: 4px !important;
        left: 0 !important;
        bottom: 0 !important;
        display: inline !important;
        transform: translate(0) !important;
        opacity: 1 !important;
    }

    .OtherBtns {
        display: flex;
        padding-left: 0 !important;

        .CESFSaveTemp {
            @media screen and (max-width: 1480px) {
                padding-left: 0 !important;
            }
        }
    }

    input {
        display: none !important;
    }

    .PersonalizeBtn {
        margin-top: 4px !important;

        @media screen and (max-width: 1480px) {
            padding-left: 0 !important;
        }

        .PSBLTitle {
            font-weight: 400 !important;
            color: #84929e;

            @media screen and (max-width: 1367px) {
                font-size: 14px !important;
            }

            @media screen and (max-width: 440px) {
                font-size: 10px !important;
            }
        }

        .PSBLabel {
            padding: 8px 10px !important;
        }
    }
}

.CESFEditor
    .WysiwygEditor
    .CESFECreateEmailEditorActions
    .CESFECEEAChild
    .PersonalizeBtn
    .PSBLabel {
    @media screen and (max-width: 1480px) {
        width: 100% !important;
    }
}

.CreateEmailStep .CESFEditor.CESFEMessage .WysiwygEditor .DraftEditor-editorContainer {
    padding-bottom: 3rem;

    @media screen and (min-width: 500px) and (max-width: 1480px) {
        padding: 1rem;
        padding-bottom: 5.5rem;
    }
}

// Create SMS
.CESFECreateSMSEditorActions {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0.2rem 0.4rem;
    z-index: 2;
    background: #fff;

    @media (min-width: 1280px) and (max-width: 1480px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 992px) and (max-width: 1100px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 790px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    & > * {
        width: max-content;

        button {
            width: 100%;
        }

        &:not(:last-child) {
            margin-right: 0.2rem;
        }

        @media (min-width: 1280px) and (max-width: 1480px) {
            width: 100% !important;
        }
        @media (max-width: 1100px) {
            width: 100% !important;
        }
    }

    .PersonalizeBtn .PSBLabel,
    .UseTemplate button {
        width: 100% !important;
    }

    .PersonalizeBtn .PSBLabel {
        padding: 6px 10px;
    }

    &:first-child .UseTemplate,
    &:first-child .UseTemplate button {
        height: 100%;
    }

    & > *:last-child {
        flex: 1;
    }

    .CESFECSEACreditsInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;

        & * {
            text-align: center;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: max-content;
        }
    }

    .UseTemplateStepperDropdown.sms button .buttonTitle {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;

        svg {
            transform: translateY(2px) !important;
            margin-left: 0.45rem !important;
        }
    }

    .PersonalizeBtn .PSBLabel {
        justify-content: center;
    }
}

//
.CreateEmailStep .CESFEditor.CESFSMSEditor .WysiwygEditor .DraftEditor-editorContainer {
    padding: 0 1rem;
    padding-bottom: 4rem;
}

.DashboardAutomations__PersonalizeLabel .PSBLTitle {
    @media screen and (max-width: 1367px) {
        font-size: 15px !important;
    }
}

//
.DashboardAutomations__PersonalizeLabel .PSBLabel .PSBLTitle {
    font-size: 12px !important;
    font-family: sans-serif;
    line-height: 2;
}
.DashboardAutomations__PersonalizeLabel .PSBLabel .PSBLIcon {
    padding-left: 0 !important;
}
.DashboardAutomations__PersonalizeLabel .PSBLIcon svg {
    padding-left: 4px !important;
    transform: translateY(0.9px) !important;
}
