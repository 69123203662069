.NotificationsDialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.NotificationsDialog .BodyBox {
    padding: 0;
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    display: flex;
    flex-direction: column;
}

.NotificationsDialog .BodyBox .NDHTabs {
    display: flex;
    align-items: center;
    background-color: #f6f8fc;
    border-bottom: 1px solid #cad3e3;
}

.NotificationsDialog .BodyBox .NDHTabs button.mainButton {
    flex: 1;
    border: 0;
    padding: 13px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border-radius: 0;
    position: relative;
    background-color: var(--white);
}

.NotificationsDialog .BodyBox .NDHTabs button.mainButton:hover,
.NotificationsDialog .BodyBox .NDHTabs button.mainButton.active {
    color: var(--dark-blue);
}

.NotificationsDialog .BodyBox .NDHTabs button.mainButton.active::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--dark-blue);
}

.NotificationsDialog .BodyBox .NDHTabPanel {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.NDHTabPanel .NDHTPType1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.NotificationsDialog .BodyBox .NDHTabPanel .NDHTPTHead {
    display: flex;
}

.NotificationsDialog .BodyBox .NDHTabPanel .NDHTPTHead button {
    padding: 10px;
    border-radius: 0;
    font-size: 16px;
    border: 0;
    background-color: #fff;
    color: var(--dark-blue);
}

.NDHTabPanel .NDHTPType1 .NDHTPT1Body {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    background-color: #f6f8fc;
}

.NDNotificationList {
    padding: 15px;
    padding-bottom: 0;
    height: 100%;
    min-height: 0;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;

    &--noData {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px !important;

        & > * {
            width: 100%;
            height: 100%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.NDNotificationList .NDNLTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 5px;
    color: #000;
}

.NDNotificationList .NDNLBox {
    padding: 20px;
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 0 6px #9c9c9c16;
    margin-bottom: 15px;
}

.NDNotificationList.type1 .NDNLBox {
    padding: 10px;
}

.NDNotificationList .NDNLBox:last-child {
    margin-bottom: 0px;
}

.NDNotificationList .NDNLBox .NDNLBTime {
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-blue);
    line-height: normal;
    padding-bottom: 10px;
    text-transform: capitalize;
}

.NDNotificationList .NDNLBox .NDNLBInfo {
    display: flex;
    align-items: flex-start;
}

.NDNotificationList .NDNLBox .NDNLBInfo .NDNLBIIcon {
    font-size: 24px;
    display: flex;
    border-radius: 100%;
    color: var(--blue-invert);
}

.NDNotificationList .NDNLBox .NDNLBInfo .NDNLBIDetail {
    flex: 1;
    padding-left: 10px;
}

.NDNotificationList .NDNLBox .NDNLBInfo .NDNLBIDetail .NDNLBIDTitle {
    font-size: 18px;
    font-weight: 600;
    color: #26262b;
    line-height: normal;
    padding-bottom: 3px;
}

.NDNotificationList .NDNLBox .NDNLBInfo .NDNLBIDetail .NDNLBIDSTitle {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.NDNotificationList.type2 .NDNLBox .NDNLBInfo .NDNLBIIcon {
    color: var(--dark-blue);
    padding-top: 3px;
    display: none;
}

.NDNotificationList.type2 .NDNLBox .NDNLBInfo .NDNLBIDetail {
    padding-left: 0;
}

.NDNotificationList.type2 .NDNLBox .NDNLBInfo .NDNLBIDetail .NDNLBIDSTitle p {
    line-height: 20px;
    margin-bottom: 10px;
}

.NDNotificationList.type2 .NDNLBox .NDNLBInfo .NDNLBIDetail .NDNLBIDSTitle h2 {
    font-size: 28px;
    color: #000;
    margin-bottom: 15px;
}

.NDNotificationList .NDNLBox .NDNLBInfo .NDNLBIDetail .NDNLBIDSTitle figure {
    margin: 0;
    margin-bottom: 15px;
    display: flex;
}

.NDNotificationList
    .NDNLBox
    .NDNLBInfo
    .NDNLBIDetail
    .NDNLBIDSTitle
    .medium-insert-embeds
    .medium-insert-embed {
    width: 100%;
}

.NDNotificationList .NDNLBox .NDNLBInfo .NDNLBIDetail .NDNLBIDSTitle .medium-insert-embeds iframe {
    width: 100%;
}

.NDNotificationList .NDNLNoData {
    height: calc(100% - 15px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffff;
}
