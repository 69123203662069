.DCDuplicateUser {
    padding: 15px;
    padding-top: 0;
    border-radius: 4px;
}

.DCDuplicateUser .DCDUInner .DCDUIContent {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
}

.DCDuplicateUser .DCDUInner a.DCDUIUser {
    display: block;
    padding: 15px;
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 0 4px #ddd;
}
