.TNTitleMain {
    position: relative;
}

.TitleNumber {
    display: flex;
    align-items: center;
    transition: all ease 0.3s;
    border-radius: 4px;
    cursor: pointer;
}

.TitleNumber.disabled {
    cursor: not-allowed;
}

.TNTitleMain:hover,
.TitleNumber:hover,
.TitleNumber.active {
    background-color: #f4f4f6;
}

.TitleNumber .TNTitle {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    padding-right: 50px;
}

.TitleNumber .TNTitle input {
    width: 100%;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.TitleNumber .TNTitle input:disabled {
    cursor: pointer;
    pointer-events: none;
}

.TNNumber {
    color: #bac0c7;
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
    width: 38px;
    text-align: right;
}

.TitleNumber .TNTitle .TNTBtns {
    display: flex;
    position: absolute;
    top: 6px;
    right: 18px;
    opacity: 0;
    transition: all ease 0.3s;
}

.TitleNumber .TNTitle .TNTBtns button {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.TNTitleMain button.iconButton.danger {
    padding: 0;
    background-color: transparent;
    border: 0;
    display: flex;
    position: absolute;
    top: 6px;
    right: 35px;
    opacity: 0;
    transition: all ease 0.3s;
    z-index: 1;
}

.TNTitleMain:hover .TitleNumber .TNTitle .TNTBtns {
    opacity: 1;
}

.TNTitleMain:hover button.iconButton.danger {
    opacity: 1;
}
// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .TitleNumber .TNTitle input {
        font-size: 12px;
    }
    .TNNumber {
        font-size: 12px;
        line-height: normal;
    }
}

@media (min-width: 1367px) and (max-width: 1560px) {
    .TitleNumber .TNTitle input {
        font-size: 12px;
    }
    .TNNumber {
        font-size: 12px;
        line-height: normal;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .TitleNumber .TNTitle input {
        font-size: 12px;
    }
    .TNNumber {
        font-size: 12px;
        line-height: normal;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .TitleNumber .TNTitle input {
        font-size: 12px;
    }
    .TNNumber {
        font-size: 12px;
        line-height: normal;
    }
}
