.MainDialogContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.MainDialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

.MainDialog .MDBackdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 2;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: #00000080;
    -webkit-tap-highlight-color: transparent;
}

.MainDialog .MDContainer {
    height: 100%;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainDialog .MDContainer .MDCRow {
    z-index: 4;
}
.MainDialog.fullWidth .MDContainer .MDCRow {
    height: 100%;
    width: 100%;
    background-color: var(--white);
}

.MainDialog.leftSlide .MDContainer {
    justify-content: flex-start;
}

.MainDialog.leftSlide .MDContainer .MDCRow {
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
    width: 450px;
    background-color: var(--white);
    box-shadow: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024,
        0px 9px 46px 8px #0000001f;
}

.MainDialog.rightSlide .MDContainer {
    justify-content: flex-end;
}

.MainDialog.rightSlide .MDContainer .MDCRow {
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
    width: 450px;
    background-color: var(--white);
    box-shadow: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024,
        0px 9px 46px 8px #0000001f;
}

.MainDialog.centerZoom .MDContainer {
    justify-content: center;
}

.MainDialog.centerZoom .MDContainer .MDCRow {
    max-height: calc(100% - 64px);
    background-color: var(--white);
    box-shadow: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024,
        0px 9px 46px 8px #0000001f;
    margin: 32px;
    position: relative;
    overflow-y: auto;
    max-width: 444px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.MainDialog.centerZoom.sizeXs .MDContainer .MDCRow {
    max-width: 444px;
}

.MainDialog.centerZoom.sizeSm .MDContainer .MDCRow {
    max-width: 600px;
}

.MainDialog.centerZoom.sizeMd .MDContainer .MDCRow {
    max-width: 960px;
}

.MainDialog.centerZoom.sizeLg .MDContainer .MDCRow {
    max-width: 1280px;
}

.MainDialog.centerZoom.sizeXl .MDContainer .MDCRow {
    max-width: 1920px;
}

.MainDialog.centerZoom.sizeFull .MDContainer .MDCRow {
    max-width: calc(100% - 64px);
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .MainDialog.rightSlide .MDContainer .MDCRow {
        width: 400px;
    }
}

@media (min-width: 1367px) and (max-width: 1560px) {
    .MainDialog.rightSlide .MDContainer .MDCRow {
        width: 350px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .MainDialog.rightSlide .MDContainer .MDCRow {
        width: 350px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .MainDialog.rightSlide .MDContainer .MDCRow {
        width: 350px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .MainDialog.rightSlide .MDContainer .MDCRow {
        width: 350px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .MainDialog.rightSlide .MDContainer .MDCRow {
        width: 350px;
    }
}

@media (min-width: 650px) and (max-width: 767.98px) {
    .MainDialog.rightSlide .MDContainer .MDCRow {
        width: 100%;
    }
    .MainDialog.centerZoom.sizeMd .MDContainer .MDCRow {
        max-width: unset;
        max-height: unset;
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

@media (max-width: 649.98px) {
    .MainDialog.rightSlide .MDContainer .MDCRow {
        width: 100%;
    }
    .MainDialog.centerZoom.sizeMd .MDContainer .MDCRow {
        max-width: unset;
        max-height: unset;
        width: 100%;
        height: 100%;
        margin: 0;
    }
}
