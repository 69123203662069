.SteperDialog {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.topBar.SDTopBar .STBLeft {
    flex: 1;
    padding: 0 45px 0 30px;
    display: flex;
    align-items: center;
}

.topBar.SDTopBar .STBLeft .STBBtn {
    display: flex;
    align-items: center;
}

.topBar.SDTopBar .STBLeft .STBBtn button {
    margin-left: 15px;
}

.topBar.SDTopBar .STBRight {
    display: flex;
    justify-content: flex-end;
}

.SteperDialog .SteperDialogBody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SteperDialog .SteperDialogBody .SDBInner {
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    display: flex;
}

.SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
    width: 70%;
    padding: 50px 50px 50px 45px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    border-right: 1px solid #f2f2f2;
    position: relative;
    display: flex;
    flex-direction: column;
}

.SteperDialog .SteperDialogBody .SDBInner .SDBILeft .AutomationNoteContent .WEMWrapper .WEMEditor {
    min-height: 400px;
    max-height: 450px;
}

.SteperDialog .SteperDialogBody .SDBInner .SDBIRight {
    width: 30%;
    box-shadow: 0 4px 34px 0 #00000010;
    padding: 50px 50px 50px 45px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SteperDialog .SteperDialogBody .SDBInner .SDBIRight .SDBIRInner {
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.SDBIRIStepList .SDBIRISLItem {
    display: flex;
    padding-bottom: 25px;
    position: relative;
    min-height: 96px;
}

.SDBIRIStepList .SDBIRISLItem::before {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #eaeaea;
    height: 100%;
    left: 25px;
    top: 25px;
}

.SDBIRIStepList .SDBIRISLItem:last-child {
    padding-bottom: 0px;
}

.SDBIRIStepList .SDBIRISLItem:last-child::before {
    display: none;
}

.SDBIRIStepList .SDBIRISLItem .SDBIRISLINum {
    width: 50px;
    height: 50px;
    border: 1px solid #eaeaea;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: #84929e;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 2px #eaeaea;
    background-color: var(--white);
    user-select: none;
}

.SDBIRIStepList .SDBIRISLItem.activeStep .SDBIRISLINum {
    border-color: var(--dark-blue);
    color: var(--dark-blue);
}

.SDBIRISLItem.completeStep::before {
    background-color: var(--dark-blue);
}

.SDBIRISLItem.completeStep .SDBIRISLINum {
    background-color: var(--dark-blue);
    color: var(--dark-blue);
    position: relative;
}

.SDBIRISLItem.completeStep .SDBIRISLINum::before {
    content: '';
    background-image: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 512 512' ><path d='M15.32,276.83c0,0-10.7-50.04,42.4-38.2l140.57,134.08h3.15L447.73,72.85c0,0,17.38-17.19,34.57-6.49  c0,0,27.31,14.32,14.13,41.45L222.99,440.83c0,0-14.26,14.52-34.89,4.07L16.97,280.65C16.97,280.65,15.51,277.9,15.32,276.83z' fill='rgb(255 255 255)'></path></svg>");
    position: absolute;
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo {
    padding-left: 25px;
    flex: 1;
}

.SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIIDes {
    font-size: 14px;
    font-weight: 400;
    color: #48484d;
}

// Desktop Responsive

@media (min-width: 1920px) {
}
@media (min-width: 1559px) and (max-width: 1800px) {
    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
        font-size: 18px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIIDes {
        font-size: 13px;
    }
    .topBar.SDTopBar .STBLeft .STBBtn button.iconButton {
        padding: 10px;
        font-size: 14px;
    }
}
@media (min-width: 1367px) and (max-width: 1600px) {
    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
        font-size: 18px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIIDes {
        font-size: 13px;
    }
    .topBar.SDTopBar .STBLeft .STBBtn button.iconButton {
        padding: 10px;
        font-size: 14px;
    }
}
@media (min-width: 1280px) and (max-width: 1367px) {
    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
        font-size: 18px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIIDes {
        font-size: 13px;
    }
    .topBar.SDTopBar .STBLeft .STBBtn button.iconButton {
        padding: 10px;
        font-size: 14px;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight {
        width: 27%;
        padding: 50px 25px 50px 30px;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
        width: 73%;
        padding: 50px 30px 50px 30px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
        width: 65%;
    }
    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight {
        width: 35%;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLINum {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo {
        padding-left: 15px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
        font-size: 18px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
        width: 65%;
    }
    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight {
        width: 35%;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLINum {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo {
        padding-left: 15px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .SDBIRIStepList .SDBIRISLItem .SDBIRISLINum {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo {
        padding-left: 15px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
        font-size: 18px;
    }

    .SteperDialog .SteperDialogBody .SDBInner {
        flex-direction: column;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
        width: 100%;
        order: 1;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight {
        width: 100%;
        padding: 25px 20px 25px 45px;
        overflow: unset;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight .SDBIRInner {
        height: auto;
    }

    .SDBIRIStepList .SDBIRISLItem {
        display: none;
        padding-bottom: 0px;
        min-height: auto;
    }

    .SDBIRIStepList .SDBIRISLItem.activeStep {
        display: flex;
    }

    .SDBIRIStepList .SDBIRISLItem::before {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .SDBIRIStepList .SDBIRISLItem .SDBIRISLINum {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo {
        padding-left: 15px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
        font-size: 18px;
    }

    .SteperDialog .SteperDialogBody .SDBInner {
        flex-direction: column;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
        width: 100%;
        order: 1;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight {
        width: 100%;
        padding: 25px 20px 25px 45px;
        overflow: unset;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight .SDBIRInner {
        height: auto;
    }

    .SDBIRIStepList .SDBIRISLItem {
        display: none;
        padding-bottom: 0px;
        min-height: auto;
    }

    .SDBIRIStepList .SDBIRISLItem.activeStep {
        display: flex;
    }

    .SDBIRIStepList .SDBIRISLItem::before {
        display: none;
    }
}

@media (max-width: 575.98px) {
    .topBar.SDTopBar .STBLeft {
        padding: 0 15px 0 0px;
    }
    .topBar.SDTopBar .STBLeft h1.HeaderBarTitle {
        display: none;
    }
    .topBar.SDTopBar .STBLeft .STBBtn button {
        margin-left: 0px;
        margin-right: 15px;
    }
    .SteperDialog .SteperDialogBody .SDBInner {
        flex-direction: column;
    }
    .SDBIRIStepList .SDBIRISLItem .SDBIRISLINum {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo {
        padding-left: 15px;
    }

    .SDBIRIStepList .SDBIRISLItem .SDBIRISLIInfo .SDBIRISLIITitle {
        font-size: 18px;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
        width: 100%;
        order: 1;
        padding: 15px 15px 15px 15px;
        flex: 1;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight {
        width: 100%;
        padding: 15px 15px 15px 15px;
        overflow: unset;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBIRight .SDBIRInner {
        height: auto;
    }

    .SDBIRIStepList .SDBIRISLItem {
        display: none;
        padding-bottom: 0px;
        min-height: auto;
    }

    .SDBIRIStepList .SDBIRISLItem.completeStep:last-child {
        display: flex;
    }

    .SDBIRIStepList .SDBIRISLItem.activeStep {
        display: flex;
    }

    .SDBIRIStepList .SDBIRISLItem::before {
        display: none;
    }
}

@media screen and (max-height: 730px) {
    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft .SimpleInputViewMain .SimpleInputView {
        padding: 20px;
    }
}

@media screen and (max-height: 650px) {
    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
        padding: 15px;
    }
    .SteperDialog
        .SteperDialogBody
        .SDBInner
        .SDBILeft
        .SimpleInputViewMain
        .SimpleInputView
        .SelectBusinesses
        .SelectBusinessesBody
        .PaginationMain {
        padding: 0;
    }
}
@media screen and (max-height: 550px) {
    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft .SimpleInputViewMain .SimpleInputView {
        padding: 5px;
    }

    .SteperDialog .SteperDialogBody .SDBInner .SDBILeft {
        padding: 5px;
    }
}
