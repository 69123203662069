h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.2;
}

h1 {
    font-size: calc(1.375rem + 1.5vw);
}

h2 {
    font-size: calc(1.325rem + 0.9vw);
}

h3 {
    font-size: calc(1.3rem + 0.6vw);
}

h4 {
    font-size: calc(1.275rem + 0.3vw);
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}
