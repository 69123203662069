.iconButton {
    position: relative;
    display: inline-flex;
    align-items: center;
    background-color: var(--white);
    font-size: 14px;
    text-transform: none;
    color: #84929e;
    font-weight: 400;
    border: 1px solid #f2f2f2;
    outline: 0;
    padding: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 3px;
    transition: all ease 0.3s;
}

.iconButton .buttonLoading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff90;
}

.iconButton:hover {
    background-color: #f2f2f2;
}

.iconButton[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
    color: #666666 !important;
    background-color: transparent !important;
}

.iconButton.headerIconBtn {
    padding: 8px 8px;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    color: #84929e;
    font-size: 18px;
}

.iconButton.circleBtn {
    color: var(--dark-blue);
    background-color: #f6f9ff;
    border: 0;
    border-radius: 100%;
    padding: 5px;
    font-size: 14px;
}

.iconButton.circleBtn:hover {
    color: var(--blue-invert);
    background-color: var(--dark-blue);
}

.iconButton.RedFillBtn {
    background-color: #f44336;
    border-color: #f44336;
    color: var(--white);
}

.iconButton.RedFillBtn:hover {
    background-color: var(--white);
    border-color: #f44336;
    color: #f44336;
}

.iconButton.RedOutlineBtn {
    background-color: var(--white);
    border-color: #f44336;
    color: #f44336;
}

.iconButton.RedOutlineBtn:hover {
    background-color: #f44336;
    border-color: #f44336;
    color: var(--white);
}

.iconButton.BlueFillBtn {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--blue-invert);
}

.iconButton.BlueFillBtn.disabled:disabled {
    background-color: #ccc !important;
}

.iconButton.BlueFillBtn:hover {
    background-color: var(--white);
    border-color: var(--dark-blue);
    color: var(--dark-blue);
}

.iconButton.BlueOutlineBtn {
    background-color: var(--white);
    border-color: var(--dark-blue);
    color: var(--dark-blue);
}

.iconButton.BlueOutlineBtn:hover {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--blue-invert);
}

.iconButton.WhiteFillBtn {
    background-color: var(--white);
    border-color: var(--white);
    color: #26262b;
}

.iconButton.WhiteFillBtn:hover {
    background-color: #26262b;
    border-color: #26262b;
    color: var(--white);
}

.iconButton.WhiteOutlineBtn {
    background-color: var(--white);
    border-color: #26262b;
    color: #26262b;
}

.iconButton.WhiteOutlineBtn:hover {
    background-color: #26262b;
    border-color: #26262b;
    color: var(--white);
}

.iconButton.BlackFillBtn {
    background-color: #26262b;
    border-color: #26262b;
    color: var(--white);
}

.iconButton.BlackFillBtn:hover {
    background-color: var(--white);
    border-color: var(--white);
    color: #26262b;
}

.iconButton.BlackOutlineBtn {
    background-color: #26262b;
    border-color: var(--white);
    color: var(--white);
}

.iconButton.BlackOutlineBtn:hover {
    background-color: var(--white);
    border-color: #26262b;
    color: #26262b;
}

.iconButton.OrangeFillBtn {
    background-color: #ff4c00;
    border-color: #ff4c00;
    color: var(--white);
}

.iconButton.OrangeFillBtn:disabled {
    background-color: #ccc !important;
}

.iconButton.OrangeFillBtn:hover {
    background-color: var(--white);
    border-color: var(--white);
    color: #ff4c00;
}

.iconButton.OrangeOutlineBtn {
    background-color: #ff4c00;
    border-color: var(--white);
    color: var(--white);
}

.iconButton.OrangeOutlineBtn:hover {
    background-color: var(--white);
    border-color: #ff4c00;
    color: #ff4c00;
}

// Desktop Responsive

@media (min-width: 1920px) {
}
@media (min-width: 1559px) and (max-width: 1800px) {
    .iconButton.circleBtn {
        font-size: 12px;
    }

    .iconButton {
        font-size: 15px;
        padding: 8px;
    }
}
@media (min-width: 1367px) and (max-width: 1600px) {
    .iconButton.circleBtn {
        font-size: 12px;
    }

    .iconButton {
        font-size: 15px;
        padding: 8px;
    }
}
@media (min-width: 1281px) and (max-width: 1366px) {
    .iconButton.circleBtn {
        font-size: 12px;
    }

    .iconButton {
        font-size: 15px;
        padding: 8px;
    }

    .iconButton.headerIconBtn {
        padding: 6px 6px;
        font-size: 15px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .iconButton.headerIconBtn {
        padding: 7px 7px;
        font-size: 17px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .iconButton.headerIconBtn {
        padding: 7px 7px;
        font-size: 17px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .iconButton.headerIconBtn {
        border-color: var(--dark-blue);
        color: var(--dark-blue);
        padding: 7px 7px;
        font-size: 17px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .iconButton.headerIconBtn {
        border-color: var(--dark-blue);
        color: var(--dark-blue);
        padding: 7px 7px;
        font-size: 17px;
    }
}

@media (max-width: 575.98px) {
    .iconButton.headerIconBtn {
        border-color: var(--dark-blue);
        color: var(--dark-blue);
        padding: 7px 7px;
        font-size: 17px;
    }

    a.linkIconButton.iconButton.headerIconBtn {
        color: var(--dark-blue);
        padding: 7px 7px;
        font-size: 17px;
        background-color: #fff;
        border: 1px solid var(--dark-blue);
        border-radius: 3px;
    }
}
