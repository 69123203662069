.PageTableBox {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    background-color: var(--white);
    position: relative;
    transition: all ease 0.3s;
    z-index: 0;
}

.PageTableBox.DCTiktokTable {
    height: calc(100% - 60px);
}

.PageTableBox table.DCTable {
    height: 100%;
    min-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: initial;
    overflow-y: overlay;
}

.PageTableBox table.DCTable thead.PageTableHead {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #e8eef9;
    border-bottom: 1px solid #cad3e3;
}

.PageTableBox table.DCTable thead.PageTableHead tr.PTHRow {
    display: flex;
}

.PageTableBox table.DCTable thead.PageTableHead tr.PTHRow th.PTHRHead {
    display: table-cell;
    vertical-align: inherit;
    font-size: 16px;
    color: #434750;
    border-right: 1px solid #cad3e3;
    font-weight: 400;
    padding: 9px 10px;
    min-width: 200px;
    position: relative;
}

.PageTableBox table.DCTable thead.PageTableHead tr.PTHRow th.PTHRHead .resizable-div-handler {
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s;
}

.PageTableBox table.DCTable thead.PageTableHead tr.PTHRow th.PTHRHead:hover .resizable-div-handler {
    visibility: visible;
    opacity: 1;
}

.PageTableBox table.DCTable thead.PageTableHead tr.PTHRow th.PTHRHead .PTHSelectAll {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PageTableBox table.DCTable .PTHRHTitle {
    display: flex;
    align-items: center;
}

.PageTableBox table.DCTable .PTHRHTitle .PTHRHText {
    flex: 1 1;
    text-align: left;
    padding-right: 15px;
    font-weight: 600;
    padding: 0;
    font-size: 16px;
    color: #434750;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PageTableBox table.DCTable .PTHRHTitle .PTHRHSort {
    display: flex;
    flex-direction: column;
}

.PageTableBox table.DCTable .PTHRHTitle .PTHRHSort > div {
    display: flex;
    font-size: 10px;
    cursor: pointer;
    color: #abb7d4;
    transition: all ease 0.3s;
}

.PageTableBox table.DCTable .PTHRHTitle:hover .PTHRHSort > div {
    color: #6079a4;
}

.PageTableBox table.DCTable .PTHRHTitle .PTHRHSort > div.active {
    color: var(--dark-blue);
}

.PageTableBox table.DCTable .PageTableBody {
    display: block;
    flex: 1;
}

.PageTableBox table.DCTable .PageTableBody tr {
    display: flex;
    border-bottom: 1px solid #e8eef9;
}

.PageTableBox table.DCTable .PageTableBody tr td {
    display: block;
    background-color: var(--white);
    font-size: 14px;
    color: #000;
    font-weight: 400;
    border-right: 1px solid #e8eef9;
    padding: 9px 10px;
    min-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
}

.PageTableBox.RowClick table.DCTable .PageTableBody tr:hover td,
.PageTableBox.RowClick table.DCTable .PageTableBody tr.selected td {
    cursor: pointer;
    background-color: #f6f8fc;
}

.PageTableBox table.DCTable .PageTableBody tr.PTBRow {
    display: block;
}

.PageTableBox table.DCTable .PageTableBody tr.PTBRow td.PTBRTd {
    display: block;
    width: 100%;
}

.PageTableBox table.DCTable .PageTableBody tr.PTBRow td.PTBRTd table.PTBRTdTable {
    display: block;
}

.PageTableBox table.DCTable .PTSAllSelect {
    background-color: #f6f8fc;
    border-bottom: 1px solid #cad3e3;
    padding: 7px;
    padding-left: 58px;
    position: sticky;
    top: 35px;
    z-index: 91;
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.PageTableBox table.DCTable .PTSAllSelect span.PTSASText {
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.PageTableBox table.DCTable .PTSAllSelect button.mainButton.PTSASBtn {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 600;
}

.PageTableBox table.DCTable .PTSAllSelect button.mainButton.PTSASBtn:hover {
    text-decoration: underline;
}

.PageTableBox table.DCTable .PTSAllSelect button.mainButton.PTSBtn {
    padding: 0;
    background-color: transparent;
    font-size: 15px;
    border: 0;
    border-radius: 0;
    color: #f44336;
}

//

// .PageTableBox table.DCTable.skeletonStart {
//     opacity: 0;
//     visibility: hidden;
//     max-height: 0;
//     overflow: hidden;
// }

.PageTableBox table.DCTable.TableSkeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 4;
}

.PageTableBox table.DCTable.TableSkeleton tr.PTHRow th.PTHRHead {
    height: 35px;
    flex: 1;
}

.PageTableBox table.DCTable tr {
    width: 100%;
    position: relative;
}

.PageTableBox table.DCTable.TableSkeleton tr {
    display: flex;
}
.PageTableBox table.DCTable.TableSkeleton tr th,
.PageTableBox table.DCTable.TableSkeleton tr td {
    height: 35px;
    flex: 1;
}

.PageTableBox .PTBEmpty {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.PageTableBox table.DCTable .PageTableBody .textWithBtn {
    position: relative;
    padding-right: 30px;
}

.PageTableBox table.DCTable .PageTableBody .textWithBtn .twbText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PageTableBox table.DCTable .PageTableBody .textWithBtn .twbText.twbTClick {
    color: var(--dark-blue);
    cursor: pointer;
}

.PageTableBox table.DCTable .PageTableBody .textWithBtn button {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
}

.PageTableBox table.DCTable .PageTableBody tr:hover td .textWithBtn button,
.PageTableBox table.DCTable .PageTableBody .textWithBtn button.open {
    visibility: visible;
    opacity: 1;
    color: var(--blue-invert);
    background-color: var(--dark-blue);
}

.PageTableBox table.DCTable .PageTableBody tr:hover .iconButton.InboundMyleadsBuildFailedBtn,
.PageTableBox table.DCTable .PageTableBody tr .iconButton.InboundMyleadsBuildFailedBtn {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(-0.8px) !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;

    svg {
        width: 1.43rem;
        height: 1.43rem;
        fill: var(--blue-invert) !important;

        circle {
            fill: var(--dark-blue) !important;
        }
    }

    &:hover {
        border-radius: 0 !important;

        svg circle {
            fill: var(--dark-blue) !important;
        }
    }
}

.PageTableBox table.DCTable .PageTableBody .textWithBtn.active button {
    visibility: visible;
    opacity: 1;
}

.PageTableBox
    table.DCTable
    .PageTableBody
    tr.PTBRow
    td.PTBRTd
    table.PTBRTdTable
    tbody
    tr:hover
    .textWithBtn
    button {
    visibility: visible;
    opacity: 1;
}

.PageTableBox table.DCTable .PageTableBody tr .UserInfo .UIImg {
    width: 25px;
    height: 25px;
}

// PTBTitleIcon

.PageTableBox .PTBTitleIcon {
    display: flex;
    align-items: center;
}

.PageTableBox .PTBTitleIcon .PTBTIIcon {
    font-size: 18px;
    display: flex;
}

.PageTableBox .PTBTitleIcon .PTBTITitle {
    flex: 1;
    padding-left: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PageTableBox table.DCTable .PageTableBody tr td .PTBudge {
    display: inline-block;
    padding: 3px 5px;
    border: 1px solid;
    font-size: 12px;
    line-height: normal;
    border-radius: 4px;
    min-width: 50px;
    text-align: center;
}

.PageTableBox table.DCTable .PageTableBody tr td .OwnerUserInfo.UserInfo .UIDetail .UIDetailName {
    color: inherit;
}

.PageTableBox
    table.DCTable
    .PageTableBody
    tr
    td
    .OwnerUserInfoBold.UserInfo
    .UIDetail
    .UIDetailName {
    color: inherit;
    font-weight: bold;
}

.PageTableBox table.DCTable .PageTableBody tr td .PTHSingleSelect {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PopoverMain.TableActionDropdown {
    z-index: 11111;
}

.infinityScrollTable {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto !important;
    overflow: auto;
}

.PageTableBox .iSTOuter {
    height: 100%;
    overflow: auto;
}

.PageTableBox.DCTableStriped table.DCTable {
    display: table;
}

.PageTableBox.DCTableStriped table.DCTable .PageTableBody tr:nth-child(2n + 1) td {
    background-color: #f9f9f9;
}

.PageTableBox.DCTableStriped table.DCTable .PageTableBody tr:hover td {
    background-color: #f5f7fb;
}

.PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow .PTHRSigSel,
.PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow th:nth-child(1):not(.PTHRSigSel),
.PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow .PTHRSigSel + th {
    background-color: #e8eef9;
}

.PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow .PTHRSigSel,
.PageTableBox.DCFixCol table.DCTable .PageTableBody tr .PTDRSigSel,
.PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow th:nth-child(1):not(.PTHRSigSel),
.PageTableBox.DCFixCol table.DCTable .PageTableBody tr td:nth-child(1):not(.PTDRSigSel) {
    position: sticky !important;
    left: 0;
    z-index: 1;
}

.PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow .PTHRSigSel + th,
.PageTableBox.DCFixCol table.DCTable .PageTableBody tr .PTDRSigSel + td {
    position: sticky !important;
    left: 48px;
    z-index: 1;
}

.TableAvatarGroups {
    padding-left: 15px;
}

.TableField .TagsContainer {
    flex-wrap: wrap;
}

.TFVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.PageTableBox table.DCTable .PageTableBody tr td .TableLink {
    color: #0000ed;
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .PageTableBox table.DCTable thead.PageTableHead tr.PTHRow th.PTHRHead {
        font-size: 14px;
        font-weight: 500;
    }
    .PageTableBox table.DCTable .PTHRHTitle .PTHRHText {
        font-size: 14px;
        font-weight: 500;
    }
    .PageTableBox table.DCTable .PageTableBody tr td label.Checkbox span.CheckboxIcon {
        font-size: 20px;
    }
    .PageTableBox table.DCTable .PageTableBody tr td {
        font-size: 13px;
    }
    .PageTableBox table.DCTable .PageTableBody tr .UserInfo .UIImg {
        width: 22px;
        height: 22px;
    }
}

@media (min-width: 1367px) and (max-width: 1560px) {
    .PageTableBox table.DCTable thead.PageTableHead tr.PTHRow th.PTHRHead {
        font-size: 14px;
        font-weight: 500;
    }
    .PageTableBox table.DCTable .PTHRHTitle .PTHRHText {
        font-size: 14px;
        font-weight: 500;
    }
    .PageTableBox table.DCTable .PageTableBody tr td label.Checkbox span.CheckboxIcon {
        font-size: 20px;
    }
    .PageTableBox table.DCTable .PageTableBody tr td {
        font-size: 13px;
    }
    .PageTableBox table.DCTable .PageTableBody tr .UserInfo .UIImg {
        width: 22px;
        height: 22px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .PageTableBox table.DCTable thead.PageTableHead tr.PTHRow th.PTHRHead {
        font-size: 14px;
        font-weight: 500;
    }
    .PageTableBox table.DCTable .PTHRHTitle .PTHRHText {
        font-size: 14px;
        font-weight: 500;
    }
    .PageTableBox table.DCTable .PageTableBody tr td label.Checkbox span.CheckboxIcon {
        font-size: 20px;
    }
    .PageTableBox table.DCTable .PageTableBody tr td {
        font-size: 13px;
    }
    .PageTableBox table.DCTable .PageTableBody tr .UserInfo .UIImg {
        width: 22px;
        height: 22px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .PageTableBox table.DCTable thead.PageTableHead tr.PTHRow th.PTHRHead {
        font-size: 14px;
        font-weight: 500;
    }
    .PageTableBox table.DCTable .PTHRHTitle .PTHRHText {
        font-size: 14px;
        font-weight: 500;
    }
    .PageTableBox table.DCTable .PageTableBody tr td label.Checkbox span.CheckboxIcon {
        font-size: 18px;
    }
    .PageTableBox table.DCTable .PageTableBody tr td {
        font-size: 13px;
    }
    .PageTableBox table.DCTable .PageTableBody tr .UserInfo .UIImg {
        width: 22px;
        height: 22px;
    }
    table.DCTable .PTSOnPageSelect span.PTSNumber {
        font-size: 13px;
    }
    table.DCTable .PTSOnPageSelect button.mainButton.PTSBtn {
        font-size: 13px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow .PTHRSigSel,
    .PageTableBox.DCFixCol table.DCTable .PageTableBody tr .PTDRSigSel,
    .PageTableBox.DCFixCol
        table.DCTable
        thead.PageTableHead
        tr.PTHRow
        th:nth-child(1):not(.PTHRSigSel),
    .PageTableBox.DCFixCol table.DCTable .PageTableBody tr td:nth-child(1):not(.PTDRSigSel),
    .PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow .PTHRSigSel + th,
    .PageTableBox.DCFixCol table.DCTable .PageTableBody tr .PTDRSigSel + td {
        position: unset !important;
        left: 0;
    }
}

@media (max-width: 575.98px) {
    .PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow .PTHRSigSel,
    .PageTableBox.DCFixCol table.DCTable .PageTableBody tr .PTDRSigSel,
    .PageTableBox.DCFixCol
        table.DCTable
        thead.PageTableHead
        tr.PTHRow
        th:nth-child(1):not(.PTHRSigSel),
    .PageTableBox.DCFixCol table.DCTable .PageTableBody tr td:nth-child(1):not(.PTDRSigSel),
    .PageTableBox.DCFixCol table.DCTable thead.PageTableHead tr.PTHRow .PTHRSigSel + th,
    .PageTableBox.DCFixCol table.DCTable .PageTableBody tr .PTDRSigSel + td {
        position: unset !important;
        left: 0;
    }
}
