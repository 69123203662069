.ComparisonDialog {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.ComparisonDialog .CDBody {
    flex: 1 1;
    overflow: auto;
}

.ComparisonDialog .CDBody .CDBInner {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 15px;
    row-gap: 15px;
}

.ComparisonDialog .CDBPlans {
    display: flex;
    column-gap: 15px;
}

.ComparisonDialog .CDBPlans .CDBPCol {
    width: 20%;
    border: 1px solid;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.ComparisonDialog .CDBPlans .CDBPCol:first-child {
    background-color: #f6f8fc;
    border: 1px solid #e8eef9;
    justify-content: center;
}

.ComparisonDialog .CDBPlans .CDBPCol .CDBPCPlanInfo {
    display: flex;
    align-items: center;
}

.ComparisonDialog .CDBPlans .CDBPCol .CDBPCPlanInfo .CDBPCPlanName {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
}

.ComparisonDialog .CDBPlans .CDBPCol .CDBPCPlanInfo .CDBPCPlanPrice {
    font-size: 14px;
    font-weight: 600;
    color: #666;
}

.ComparisonDialog .CDBPlans .CDBPCol .CDBPCPlanInfo.CDBPCSetup .CDBPCPlanName {
    font-size: 14px;
}

.ComparisonDialog .CDBPInfo {
    border: 1px solid #ddd;
    overflow: auto;
    flex: 1;
}

.ComparisonDialog .CDBPInfo .CDBPIGroup {
    background-color: var(--white);
    border-bottom: 1px solid #ddd;
}

.ComparisonDialog .CDBPInfo .CDBPIGroup:last-child {
    border-bottom: 0;
}

.ComparisonDialog .CDBPInfo .CDBPIGroup .CDBPIRow {
    display: flex;
}

.ComparisonDialog .CDBPInfo .CDBPIGroup .CDBPIRow .CDBPIRCol {
    width: 20%;
    padding: 7px 10px;
    font-size: 14px;
    text-align: center;
}

.ComparisonDialog .CDBPInfo .CDBPIGroup .CDBPIRow .CDBPIRCol:first-child {
    font-weight: 600;
    text-align: left;
    display: flex;
}

.ComparisonDialog .CDBPInfo .CDBPIGroup .CDBPIRow .CDBPIRCol.CDBPIRTitle {
    font-size: 18px;
    font-weight: 600;
}

.ComparisonDialog .CDBPInfo .CDBPIGroup .CDBPIRow .CDBPIRCol.CDBPIRCIcon {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.ComparisonDialog .CDBPApp {
    display: flex;
    align-items: center;
}

.ComparisonDialog .CDBPApp .CDBPAIcon {
    display: flex;
    font-size: 25px;
    padding: 10px;
    // background-color: var(--white);
    box-shadow: 0 0 4px #5b5b5b16;
    border-radius: 10px;
}

.ComparisonDialog .CDBPApp .CDBPAIcon img {
    width: 50px;
    height: 50px;
}

.ComparisonDialog .CDBPApp .CDBPAInfo {
    padding-left: 10px;
}

.ComparisonDialog .CDBPApp .CDBPAInfo .CDBPAITitle {
    font-size: 16px;
    font-weight: 600;
}

.ComparisonDialog .CDBPApp .CDBPAInfo .CDBPAISTitle {
    font-size: 12px;
    font-weight: 500;
    padding-top: 1px;
}

.ComparisonDialog .HeaderBar .HeaderBarClose button {
    margin-left: 15px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .ComparisonDialog .CDBody .CDBInner {
        width: 1280px;
    }
}

@media (max-width: 575.98px) {
    .ComparisonDialog .CDBody .CDBInner {
        width: 1280px;
    }
}
