.TabWithTitleNumber {
    display: flex;
    align-items: center;
    transition: all ease 0.3s;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 0 5px;
}

.TabWithTitleNumber:hover,
.TabWithTitleNumber.active {
    background-color: #f4f4f6;
}

.TabWithTitleNumber .TWTNTitle {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    line-height: 28px;
}

.TabWithTitleNumber .TWTNTNumber {
    color: #bac0c7;
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
}
