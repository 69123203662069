.sMBIRMenuBox {
    flex: 1;
    display: flex;
    padding: 0 15px;
}

.sMBIRMenuBox .sMBIRMBMenuItems {
    position: relative;
}

.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu {
    display: flex;
    height: 100%;
}

.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu span,
.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 20px;
    text-transform: inherit;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    width: 100%;
    position: relative;
    border-radius: 0;
    background-color: transparent;
    color: #00000099;
    transition: all ease 0.3s;
}

.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu span {
    cursor: not-allowed;
    user-select: none;
}

.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu.active a::after {
    content: '';
    position: absolute;
    bottom: -1.5px;
    left: 0;
    right: 0;
    border-bottom: 3px solid var(--dark-blue);
    border-radius: 5px;
    z-index: 3;
}

.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a:hover,
.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu.active a {
    color: #000000;
}

.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu.sMBIRMBMIMore {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 20px;
    text-transform: inherit;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    position: relative;
    border-radius: 0;
    background-color: transparent;
    color: #00000099;
    transition: all ease 0.3s;
    cursor: pointer;
}

.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMore .sMBIRMBMIMTitle {
    padding-right: 10px;
    line-height: normal;
}

.sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMore .sMBIRMBMIMIcon {
    font-size: 14px;
    display: flex;
}

.MoreDropdownMenu.ArrowPopover .PopoverMainInner::before {
    visibility: visible;
    top: -6px;
    left: calc(50% - 7px);
}

.MoreDropdownMenu .MDMList {
    display: flex;
    flex-direction: column;
}

.MoreDropdownMenu .MDMList .MDMLItem {
    display: block;
    border-bottom: 1px solid #f6f8fc;
}

.MoreDropdownMenu .MDMList .MDMLItem span,
.MoreDropdownMenu .MDMList .MDMLItem a {
    display: block;
    align-items: center;
    height: 100%;
    padding: 10px 15px;
    text-transform: inherit;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    width: 100%;
    position: relative;
    border-radius: 0;
    background-color: transparent;
    color: #00000099;
}

.MoreDropdownMenu .MDMList .MDMLItem:last-child {
    border-bottom: 0;
}

.MoreDropdownMenu .MDMList .MDMLItem.active a {
    color: var(--dark-blue);
    background-color: #f6f8fc;
}

.sMBIRMBtn {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-end;
    grid-column-gap: 10px;
    column-gap: 10px;
    padding: 7px 0px;
    user-select: none;
    cursor: pointer;
}
.subMenuBar .sMBInner .sMBIRight .sMBIRMenuBox .sMBIRMBtn {
    flex-direction: row;
}
.sMBIRMBtn .sMBIRMBText {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}

.sMBIRMenuBox .MobFilter {
    display: none;
    align-self: center;
    flex: 1;
    justify-content: flex-end;
}
.sMBIRMenuBox .MobFilter button.iconButton {
    border-color: #fff;
    background-color: #fff;
    color: var(--primary-main);
}

.sMBIRMBtn .sMBIRMBIcon {
    display: flex;
    font-size: 15px;
    color: #666;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .sMBIRMBtn {
        align-items: center;
    }

    .sMBIRMBtn .sMBIRMBIcon {
        margin-left: 10px;
    }
}

.HeaderDropdownMenu.ArrowPopover .PopoverMainInner {
    margin-top: 10px;
}

.HeaderDropdownMenu.ArrowPopover .PopoverMainInner::before {
    visibility: visible;
    top: -6px;
    right: 10px;
}

.HeaderDropdownMenu .HDMList .HDMLItem {
    display: flex;
    border-bottom: 0.5px solid #e8eef9;
}

.HeaderDropdownMenu .HDMList .HDMLItem span,
.HeaderDropdownMenu .HDMList .HDMLItem a {
    width: 100%;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-blue);
}

.HeaderDropdownMenu .HDMList .HDMLItem {
    border-bottom: 0;
}

.HeaderDropdownMenu .HDMList .HDMLItem.active a {
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}

.sMBIRMSettings {
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 0;
    width: calc(100% - 149px);
    z-index: 92;
    overflow: auto;
    background-color: #fff;
    border-left: 1px solid #eaeaea;
    transform: translateX(100%);
    transition: all ease 0.3s;
}

.sMBIRMSettings.active {
    transform: translateX(0);
}

.sMBIRMSettings .sMBIRMSInner {
    display: flex;
    flex-direction: column;
}

.sMBIRMSettings .sMBIRMSInner .SwitchMain .SwitchMainInner {
    display: flex;
}

.sMBIRMSettings .sMBIRMSInner .SwitchMain .SwitchMainInner .SMIBtn {
    flex: 1;
    padding: 10px;
}

.sMBIRMSettings .sMBIRMSInner .mainButton {
    border: 0;
    padding: 10px 15px;
    border-bottom: 1px solid #f6f8fc;
    border-radius: 0;
}

.sMBIRMSettings .sMBIRMSInner .mainButton span.buttonIcon {
    margin-left: 0;
    margin-right: 10px;
    color: var(--dark-blue);
}

.sMBIRMSettings .sMBIRMSInner .UpdateViewText {
    padding: 10px;
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
        font-size: 16px;
    }
}

@media (min-width: 1367px) and (max-width: 1560px) {
    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
        font-size: 16px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
        font-size: 16px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
        font-size: 16px;
    }

    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu.sMBIRMBMIMore {
        font-size: 16px;
    }

    .sMBIRMSettings {
        width: calc(100% - 199px);
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .sMBIRMenuBox {
        width: 100%;
        flex: unset;
        min-height: 50px;
        padding-left: 245px;
        border-bottom: 1px solid #eaeaea;
        background-color: var(--white);
    }
    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
        font-size: 16px;
    }

    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu.sMBIRMBMIMore {
        font-size: 16px;
    }

    .sMBIRMSettings {
        width: calc(100% - 229px);
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .sMBIRMenuBox {
        width: 100%;
        flex: unset;
        min-height: 50px;
        padding-left: 245px;
        border-bottom: 1px solid #eaeaea;
        background-color: var(--white);
    }
    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
        font-size: 16px;
    }

    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu.sMBIRMBMIMore {
        font-size: 16px;
    }

    .sMBIRMSettings {
        width: calc(100% - 229px);
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .sMBIRMenuBox {
        width: 100%;
        justify-content: space-between;
        flex: unset;
        min-height: 50px;
        padding-left: 215px;
        border-bottom: 1px solid #eaeaea;
        background-color: var(--white);
    }
    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
        font-size: 16px;
    }

    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu.sMBIRMBMIMore {
        font-size: 16px;
    }

    .sMBIRMSettings {
        width: calc(100% - 199px);
    }
}
@media (max-width: 575.98px) {
    .sMBIRMenuBox {
        width: 100%;
        flex: unset;
        justify-content: space-between;
        min-height: 50px;
        padding-left: 155px;
        border-bottom: 1px solid #eaeaea;
        background-color: var(--white);
    }
    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu a {
        font-size: 16px;
    }

    .sMBIRMenuBox .sMBIRMBMenuItems .sMBIRMBMIMenu.sMBIRMBMIMore {
        font-size: 16px;
    }
}

@media (max-width: 1200px) {
    .sMBIRMenuBox .MobFilter {
        display: flex;
    }
    .sMBIRMDaterange {
        display: grid;
    }
}
