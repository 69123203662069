.RARSTBContainer {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 0px;
}

.RARSTBCIRMBTBImage {
    width: 30px;
}
.RARSTBCIRMBTBImage.RARSTBCISAvatar {
    padding-left: 15px;
}
.RARSTBCIRMBTBIRBubblr {
    position: absolute;
    right: -4%;
    top: -4%;
    padding: 1px;
    background: #fff;
    border-radius: 50%;
    z-index: 9;
}
.RARSBFSABubble {
    right: -11%;
    top: -18%;
}
.RARSTBCIRMBTBIRBubblr svg {
    font-size: 10px;
}
.RARSTBContainer .RARSTBCLInner {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    margin-right: 10px;
    color: #000000;
}
.RARSTBContainer .RARSTBCRInner {
    background: #eaeaea;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 5px 10px;
}
.RARSTBContainer .RARSTBCRInner .RARSTBCRIRCount {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
}
.RARSTBContainer .RARSTBCRInner .RARSTBCRISMBox {
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.RARSTBContainer .RARSTBCRInner .RARSTBCRISMBox svg {
    font-size: 11px;
    color: #f8b805;
}

.RARSTBCIRMBox {
    background: #fff;
    border-left: 6px solid #f44336;
    border-radius: 8px;
    margin: 15px auto;
    overflow: overlay;
}
.RARSTBCIRMBox.red {
    border-left: 6px solid #f44336;
}
.RARSTBCIRMBox.green {
    border-left: 6px solid #4caf50;
}
.RARSTBCIRMBox .RARSTBCIRMBTBox {
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}
.RARSTBCIRMBox .RARSTBCIRMBTBox .RARSTBCIRMBTBoxRight {
    display: flex;
    flex-direction: row;
    gap: 15px;
    // margin-right: 30px;
}
.RARSTBCIRMBox .RARSTBCIRMBTBox .RARSTBCIRMBTBoxRight .RARSTBCIRMBTBRIbutton {
    border: 1px solid #8a8a8a;
    border-radius: 4px;
    height: 31px;
    width: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}
.RARSTBCIRMBox .RARSTBCIRMBTBox .RARSTBCIRMBTBRFBox {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0a88ff !important;
    border: none !important;
}

.RARSTBCIRMBTBRFBox {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 31px;
    color: #0a88ff !important;
    max-width: 31px;
}

.RARSTBCIRMBTBRFBox {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 31px;
    color: #0a88ff !important;
    max-width: 31px;
}

.RARSTBCIRMBTBRFBox {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 31px;
    color: #0a88ff !important;
    max-width: 31px;
}

.RARSTBCIRMBTBRFBox {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 31px;
    color: #0a88ff !important;
    max-width: 31px;
}

.RARSTBCIRMBox .RARSTBCIRMBTBox .RARSTBCIRMBTBoxRight .RARSTBCIRMBTBRPRButton {
    border-radius: 20px;
    max-height: 31px;
    background-color: #fff;
    border-color: #f44336;
    border: 2px solid #f44336;
    color: #f44336;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
}
.RARSTBCIRMBox .RARSTBCIRMBTBox .RARSTBCIRMBTBoxLeft {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.RARSTBCIRMBox .RARSTBCIRMBTBoxLeft .RARSTBCIRMBTBLeft {
    font-size: 30px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
}
.RARSTBCIRMBox .RARSTBCIRMBTBoxLeft .RARSTBCIRMBTBLeft svg {
    border-radius: 50% !important;
}
.RARSTBCIRMBox .RARSTBCIRMBTBoxLeft .RARSTBCIRMBTBRight {
    display: flex;
    flex-direction: column;
}
.RARSTBCIRMBTBRightFaceBookRec {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.RARSTBCIRMBox .RARSTBCIRMBTBoxLeft .RARSTBCIRMBTBRight .RARSTBCIRMBTBRSbox {
    display: flex;
    flex-direction: row;
    gap: 1px;
    font-size: 15px;
}

.RARSTBCIRMBTBRSbox {
    display: flex;
    flex-direction: row;
    gap: 1px;
    font-size: 15px;
}
.RARSTTRSLoderContainer {
    position: fixed;
    top: 130px;
    width: calc(100% - 250px) !important;
    display: flex;
    justify-content: center;
    background-color: transparent;
    z-index: 9999;
}
.RARSTTRSLoder {
    z-index: 9999;
    transition: all 1s;
    background: #fff;
    padding: 6px 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    opacity: 0;
    margin-bottom: 30px;
}
.RARSTTRSLoder.active {
    animation: 0.5s ease-out 0s 1 pushdown forwards;
    opacity: 1;
}
.RARSTTRSLoder.inactive {
    animation: 0.5s ease-out 0s 1 pushup forwards;
    opacity: 0;
    height: 0px;
}
@keyframes pushdown {
    from {
        opacity: 0;
        height: 0;
    }
    to {
        opacity: 1;
        height: 29px;
    }
}
@keyframes pushup {
    0% {
        opacity: 1;
        height: 29px;
    }
    60% {
        opacity: 0;
    }
    100% {
        height: 0;
    }
}
.RARSTTRSLoder .CircularLoader,
.RARSTTRSLoder .CircularLoader .circular-loader {
    width: 18px !important;
    height: 18px !important;
}
.RARSTTRSLoder .RARSTTRSLIText {
    display: inline;
    margin-left: 11px;
}

.RARSTBCIRMBox .RARSTBCIRMBTBoxLeft .RARSTBCIRMBTBRight .RARSTBCIRMBTBRSbox .true svg {
    color: #f8b805;
}
.RARSTBCIRMBox .RARSTBCIRMBTBoxLeft .RARSTBCIRMBTBRight .RARSTBCIRMBTBRSbox .false svg {
    color: #d1d1d1;
}
.RARSTBCIRMBox .RARSTBCIRMBTBoxLeft .RARSTBCIRMBTBRight .RARSTBCIRMBTBRBBox {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
}
.RARSTBCIRMBox .RARSTBCIRMBMBox {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding: 5px 15px 15px;
    white-space: pre-line;
}
.RARSTBCIRMBox .RARSTBCIRMBMBoxReply {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    padding: 15px;
    margin-left: 15px;
    margin-right: 15px;
    background: #f4f4f4;
    border-radius: 2px;
    margin-bottom: 15px;
}
.RARSTBCIRMBox .RARSTBCIRMBMBoxReply .RARSTBCIRMBMBoxRTBar {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    align-items: center;
    gap: 5px;
}
.RARSTBCIRMBMBoxRBodyOuter {
    margin-top: 8px;
    margin-left: 17px;
}
.RARSTBCIRMBox .RARSTBCIRMBMBoxReply .RARSTBCIRMBMBoxRBody {
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    display: inline !important;
}
.RARSTBCIRMBox .RARSTBCIRMBBBox {
    border-top: 1px solid #e4e4e4;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 15px;
}

.RARSTBCIRMBox .RARSTBCIRMBBBox .RARSTBCIRMBBBPPic {
    width: 30px;
}
.RARSTBCIRMBox .RARSTBCIRMBBBox .RARSTBCIRMBBBPPic img {
    width: 30px;
    max-width: 30px !important;
    height: 30px;
    border-radius: 4px;
}
.RARSTBCIRMBox .RARSTBCIRMBBBox .RARSTBCIRMBBBIResponse {
    display: flex;
    flex: 1;
    width: 100%;
}
.RARSTBCIRMBBBIResponse .DCTextareaWrapper {
    width: 100%;
    min-height: 31px;
}
.RARSTBCIRMBBBIResponse .DCTextareaWrapper .DCTextarea {
    min-height: 31px !important;
    max-height: 61px !important;
    padding: 5px 10px;
}
.RARSTBCIRMBox .RARSTBCIRMBBBox .RARSTBCIRMBBBIResponse input {
    width: 100%;
    height: 31px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
}
.RARSTBCIRMBox .RARSTBCIRMBBBox .RARSTBCIRMBBBRButton .RARSTBCIRMBBBCClass {
    background: #0a88ff;
    max-height: 31px !important;
}
.RASBTIcon.TitleIconMain .TIMInner .TIMIIcon {
    font-size: 11px;
}
.RASBTIcon.TitleIconMain .TIMInner .TIMITitle {
    padding-right: 6px !important;
}
.RASBTIconBtn {
    margin-right: -5px !important;
}
.RAPRDialog {
    position: absolute;
    top: 20px;
    right: 40px;
}
.RAPRDialog .RAPRDCButton {
    background: none;
    border: none;
}
.RAPRDialog .RAPRDCButton svg {
    color: #656565;
    font-size: 25px;
}
.RAPRDialogContainer .MDBackdrop {
    background: #efefef;
}
.RAPRDIMContainer {
    background: #fff;
    padding: 0px 0px 50px;
    width: 480px;
    border-radius: 15px;
}
.RAPRDIMContainer .RAPRDIMCBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.RAPRDIMContainer .RAPRDIMCBox .RAPRDIMCBLogoBox {
    display: flex;
    justify-content: center;
    width: 100% !important;
    padding: 15px 0px;
    border-radius: 15px 15px 0px 0px;
}
.RAPRDIMContainer .RAPRDIMCBox .RAPRDIMCBTitle1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 35px;
    color: #000000;
}
.RAPRDIMContainer .RAPRDIMCBox .RAPRDIMCBText1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 0px 60px;
    padding-bottom: 30px;
    text-align: center;
    color: #353535;
}
.RAPRDIMContainer .RAPRDIMCBox .RAPRDIMCBSButton {
    border: 1px solid #000000;
    border-radius: 4px;
    width: 200px;
    height: 45px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 5px;
    color: #000000;
}
.RARSTBCIRMBTBImage.RARSTBCISAvatar .SingleAvatar .SingleAvatarInner {
    border: none;
    background: transparent;
}
// Responsive
@media only screen and (min-width: 300px) and (max-width: 640px) and (orientation: portrait) {
    /* mobile portrait */
    .RARSTBContainer {
        display: flex;
        flex-direction: row;
        padding-left: 0px;
        padding-top: 8px;
        padding-bottom: 0px;
    }
    .RARSTBCIRMBox .RARSTBCIRMBTBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px;
    }

    .RARSTBCIRMBox .RARSTBCIRMBTBox .RARSTBCIRMBTBoxLeft {
        width: 100% !important;
    }

    .RARSTBCIRMBox .RARSTBCIRMBMBox {
        padding: 5px 20px 15px 15px;
    }
    .RARSTBCIRMBox .RARSTBCIRMBMBoxReply {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
        padding: 15px 10px;
        margin-left: 15px;
        margin-right: 15px;
        background: #f4f4f4;
        border-radius: 2px;
        margin-bottom: 15px;
    }
    .RARSTBCIRMBox .RARSTBCIRMBTBox .RARSTBCIRMBTBoxRight .RARSTBCIRMBTBRPRButton {
        background-color: #fff;
        border: 2px solid #f44336;
        color: #f44336;
        max-height: 25px !important;
        font-size: 12px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
    }
    .RARSTBCIRMBox .RARSTBCIRMBTBox .RARSTBCIRMBTBoxRight .RARSTBCIRMBTBRIbutton {
            width: 25px;
            height: 25px;
            font-size: 12px;
            padding: 7px !important;
        }

    .RAPRDialog {
        top: 20px;
        right: 20px;
    }
}

@media only screen and (max-width: 1023px) {
    .RARSTTRSLoderContainer {
        position: fixed;
        left: 0px;
        top: 160px;
        width: 100% !important;
        display: flex;
        justify-content: center;
        background-color: transparent;
    }
}

