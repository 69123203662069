.DCPhoneNumberWrapper form .PhoneInput {
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #7b89a4;
    // padding: 10px 0px;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
    line-height: normal;
    & > * {
        border-top: none !important;
        border-right: none !important;
        border-bottom: none !important;
    }
}

.DCPhoneNumberWrapper form .PhoneInput .PhoneInputInput {
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #7b89a4;
    padding: 10px;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
}

.DCPhoneNumberWrapper form .PhoneInput .PhoneInputCountry {
    padding-left: 5px;
}

.DCPhoneNumberWrapper.phoneNumberError form .PhoneInput {
    border-color: #f44336;
}

.DCPhoneNumberWrapper.phoneNumberError form .PhoneInput .PhoneInputInput {
    border-left: 1px solid #f44336;
    border-radius: 0px;
}

.DCPhoneNumberWrapper .DCPhoneNumberError {
    color: #f44336;
    font-size: 14px;
    font-weight: 500;
    padding-top: 2px;
}

.DCPhoneNumberWrapper form .PhoneInput .AutoCompleteRoot .ACRLabel img {
    width: 20px;
    height: 14px;
}

//PhoneNumberView

.PhoneNumberView {
    position: relative;
}

label .PhoneNumberView::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
}

.PhoneNumberView .PhoneInput {
    width: 100%;
    border: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    height: auto;
    cursor: inherit;
    line-height: inherit;
    background: inherit;
}

.PhoneNumberView .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow {
    border: 0;
    margin: 0;
    background-color: unset;
}

.PhoneNumberView .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
    padding: 0;
    width: 20px;
}
.PhoneNumberView .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon--border {
    box-shadow: none;
}

.PhoneNumberView .PhoneInput .PhoneInputCountry .PhoneInputCountryIconUnicode {
    font-size: 16px;
}

.PhoneNumberView .PhoneInput .PhoneInputInput {
    border: unset;
    background: inherit;
}
