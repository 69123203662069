.IconTitleCard {
    width: 230px;
    height: 230px;
    padding: 15px;
    border: 1px solid #e4edff;
    border-radius: 4px;
    cursor: pointer;
    transition: all ease 0.3s;
    position: relative;
}

.IconTitleCard.disabled {
    opacity: 0.5;
    cursor: default;
}

.IconTitleCard .ITCCheckBox {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
}

.IconTitleCard.activeCard {
    border-color: var(--dark-blue);
}

.IconTitleCard .ITCInner {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.IconTitleCard .ITCInner .ITCIIcon {
    font-size: 70px;
    display: flex;
}

.IconTitleCard.ITCWidth .ITCInner .ITCIIcon svg {
    width: 100%;
}

.IconTitleCard .ITCInner .ITCITitle {
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

.IconTitleCard .ITCInner .ITCIDes {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    padding-top: 10px;
    color: #84929e;
}

// Desktop Responsive

@media (min-width: 1920px) {
}
@media (min-width: 1559px) and (max-width: 1800px) {
    .IconTitleCard .ITCInner .ITCIIcon {
        font-size: 60px;
    }

    .IconTitleCard .ITCInner .ITCITitle h5 {
        font-size: 18px;
    }

    .IconTitleCard .ITCInner .ITCIDes {
        font-size: 15px;
    }
}
@media (min-width: 1367px) and (max-width: 1600px) {
    .IconTitleCard .ITCInner .ITCIIcon {
        font-size: 60px;
    }

    .IconTitleCard .ITCInner .ITCITitle h5 {
        font-size: 18px;
    }

    .IconTitleCard .ITCInner .ITCIDes {
        font-size: 12px;
    }
}
@media (min-width: 1280px) and (max-width: 1367px) {
    .IconTitleCard .ITCInner .ITCIIcon {
        font-size: 60px;
    }

    .IconTitleCard .ITCInner .ITCITitle h5 {
        font-size: 18px;
    }

    .IconTitleCard .ITCInner .ITCIDes {
        font-size: 12px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .IconTitleCard .ITCInner .ITCIIcon {
        font-size: 50px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .IconTitleCard .ITCInner .ITCIIcon {
        font-size: 50px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .IconTitleCard .ITCInner .ITCIIcon {
        font-size: 50px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .IconTitleCard .ITCInner .ITCIIcon {
        font-size: 50px;
    }
}
