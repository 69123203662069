.NoData {
    display: flex;
    justify-content: center;
    margin: auto;
}

.NoData.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.NoData .NoDataInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 10px;
}

.NoData .NoDataInner .NDIIcon {
    font-size: 200px;
    display: flex;
}

.NoData .NoDataInner .NDITitle {
    font-size: 24px;
    line-height: normal;
    font-weight: 600;
    text-transform: capitalize;
}

.NoData .NoDataInner .NDIDes {
    font-size: 16px;
    font-weight: 500;
    color: #00000099;
}

.NoData .NoDataInner .NDIButton {
    padding-top: 15px;
}

.NoData .NoDataInner .NDIIcon.NDIISizesmall {
    font-size: 130px;
}

.NoData .NoDataInner .NDIIcon.NDIISizeextrasmall {
    font-size: 80px;
}

.NoData .NoDataInner .NDITitle.NDIISizeextrasmall {
    font-size: 18px;
    line-height: 12px;
}

.NoData .NoDataInner .NDIDes.NDIISizeextrasmall {
    font-size: 12px;
    line-height: 12px;
}
