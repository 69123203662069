.WebLogo {
    display: flex;
    align-items: center;
}
.WebLogo a {
    display: flex;
    justify-content: flex-start;
}

.WebLogo a img {
    width: 200px;
    height: 45px;
    object-fit: contain;
    object-position: left;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

// Responsive

@media (min-width: 1559px) and (max-width: 1800px) {
    .WebLogo a img {
        width: 160px;
        height: 40px;
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    .WebLogo a img {
        width: 160px;
        height: 40px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .WebLogo a img {
        width: 120px;
        height: 25px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .WebLogo a img {
        width: 140px;
        height: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .WebLogo a img {
        width: 140px;
        height: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .WebLogo a img {
        width: 140px;
        height: 30px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .WebLogo a img {
        width: 140px;
        height: 30px;
    }
}

@media (max-width: 575.98px) {
    .WebLogo a img {
        width: 140px;
        height: 30px;
    }
}
