.TabWithIconTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
    cursor: pointer;
    user-select: none;
    transition: all ease 0.3s;
    color: #666666;
}

.TabWithIconTitle.active,
.TabWithIconTitle:hover {
    background-color: #ebf8ff;
    color: #13b4ff;
}

.TabWithIconTitle .TWITIcon {
    display: flex;
    font-size: 25px;
}

.TabWithIconTitle .TWITTitle {
    flex: 1;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.TabWithIconTitle .TWITArw {
    display: flex;
    font-size: 16px;
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .TabWithIconTitle .TWITIcon {
        font-size: 20px;
    }

    .TabWithIconTitle .TWITTitle {
        padding: 0 7px;
        font-size: 15px;
    }

    .TabWithIconTitle .TWITArw {
        font-size: 14px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
}
