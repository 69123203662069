.PCRViewMain .PCRViewContainer.CheckoutPageContainer {
    padding: 0;
}

.DCCoupon {
    font-size: 14px;
    font-style: italic;
    color: var(--dark-blue);
}

.DCCouponRemove {
    color: red !important;
    padding: 2px 1rem 0 !important;
}

.CheckoutPage {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: center;
}

.CheckoutPagePadding {
    padding: 50px;
}

.CheckoutPage .CheckoutPageInner {
    max-width: 1200px;
    background-color: var(--white);
    width: 100%;
    flex: 1;
    box-shadow: 0 10px 20px #c8c8c816;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
}

.CheckoutPageLoader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999;
    background-color: #f5f5f5e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CheckoutPageLoader .CircularLoader {
    width: 80px;
    height: 80px;
}

.CheckoutPageLoader svg.circular-loader {
    width: 80px;
    height: 80px;
}

.CheckoutPageLoader .CPLTitle {
    font-size: 28px;
    font-weight: 600;
    margin-top: 30px;
}

.CheckoutPage .CheckoutPageInner .COPIRow {
    height: 100%;
    display: flex;
}

.CheckoutPage .CheckoutPageInner .COPICol {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.CheckoutPage .CheckoutPageInner .COPICol:nth-child(1) {
    width: 60%;
    border-right: 1px solid #f6f8fc;
}

.CheckoutPage .CheckoutPageInner .COPICol:nth-child(2) {
    width: 40%;
}

.CheckoutPage .CheckoutPageInner .COPICol .COPICHead {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
    display: flex;
    align-items: center;
}

.CheckoutPage .CheckoutPageInner .COPICol .COPICHead span {
    flex: 1;
}

.CheckoutPage .CheckoutPageInner .COPICol .COPICHead strong {
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    color: var(--dark-blue);
    display: none;
}
.CheckoutPage .CheckoutPageInner .COPICol .COPICHead button {
    display: none;
}

.CartPage .CartPageInner .COPIHead {
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
}

.CartPage .CartPageInner .COPIHead .COPIHTitle {
    font-size: 32px;
    font-weight: 600;
    color: #000;
    padding-bottom: 15px;
}

.CartPage .CartPageInner .COPIHead .COPIHBtn {
    display: flex;
    justify-content: center;
    column-gap: 15px;
}

.CartPage .CartPageInner .COPICartItems {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.COPICartItems .COPICIHead {
    display: flex;
    padding: 15px;

    & > *:last-child {
        flex: 1;
    }
}

.COPICartItems .COPICIHead .COPICITitle {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.COPICartItems .COPICIHead > div:nth-child(1) {
    width: 49%;
}
.COPICartItems .COPICIHead > div:nth-child(2) {
    width: 17%;
    text-align: right;
}
.COPICartItems .COPICIHead > div:nth-child(3) {
    width: 17%;
    text-align: right;
}
.COPICartItems .COPICIHead > div:nth-child(4) {
    width: 17%;
    text-align: right;
}

.COPICartItems .COPICIList {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.COPICartItems .COPICIList .COPICIGroup {
    margin-bottom: 15px;
}

.COPICartItems .COPICIList .COPICIGroup:last-child {
    margin-bottom: 0px;
}

.COPICIGroup .COPICIGTitle {
    font-size: 14px;
    color: var(--dark-blue);
    font-weight: 500;
    padding: 0 15px;
    padding-bottom: 5px;
}

.COPICIGroup .COPICIGProduct {
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: 0 3px 6px #b2b2b216;
    margin-bottom: 15px;
    position: relative;

    & > *:last-child {
        flex: 1;
    }
}

.OrderConfirmation .COPICIGroup .COPICIGProduct.PFailure {
    box-shadow: 0 0px 0px 2px #f44336 inset;
    background-color: #fffcfc;
}

.OrderConfirmation .COPICIGroup .COPICIGProduct.COPICIGPSuccess {
    background-color: #f9fcfa;
}

.COPICIGroup .COPICIGProduct:last-child {
    margin-bottom: 0px;
}

.COPICIGProduct .COPICIGPInfo {
    width: 49%;
    display: flex;
    align-items: center;
}

.COPICIGProduct .COPICIGPInfo .COPICIGPIIcon {
    display: flex;
    font-size: 30px;
    padding: 10px;
    box-shadow: 0 0 4px #5b5b5b16;
    border-radius: 10px;
}

.COPICIGProduct .COPICIGPInfo .COPICIGPIIcon img {
    width: 35px;
    height: 35px;
}

.COPICIGProduct .COPICIGPInfo .COPICIGPIDetail {
    flex: 1;
    padding-left: 15px;
}

.COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDTitle {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    line-height: normal;
    padding-bottom: 5px;
}

.COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType {
    display: flex;
    align-items: center;
}

.COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType span {
    font-size: 14px;
    color: #666;
    margin-right: 10px;
}

.COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDUD {
    display: inline-block;
    font-size: 12px;
    padding: 5px;
    border-radius: 4px;
    color: #fff;
    min-width: 70px;
    text-align: center;
}

.COPICIGroup .COPICIGProduct .COPICIGPQun {
    width: 17%;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.COPICIGroup .COPICIGProduct .COPICIGPRec .discounted,
.COPICIGroup .COPICIGProduct .COPICIGPSetup .discounted {
    display: flex;
    flex-direction: column;
}

.COPICIGroup .COPICIGProduct .COPICIGPRec .discounted .strike,
.COPICIGroup .COPICIGProduct .COPICIGPSetup .discounted .strike {
    text-decoration: line-through;
    font-style: italic;
}

.COPICIGroup .COPICIGProduct .COPICIGPRec {
    width: 17%;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.COPICIGroup .COPICIGProduct .COPICIGPSetup {
    width: 17%;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.COPICIGroup .COPICIGProduct .COPICIGPInfoIcon {
    font-size: 24px;
    display: flex;
    padding-left: 10px;
}

.COPICIGroup .COPICIGProduct .COPICIGPInfoIcon .TooltipWithIcon {
    color: #f44336;
}

.COPIFooter {
    border-top: 1px solid #eaeaea;
}

.COPIFooter .COPISubTotal {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f6f8fc;
}

.COPIFooter .COPISubTotal .COPIFTitle {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    width: 66%;
}

.COPIFooter .COPISubTotal .COPIFTRec {
    width: 17%;
    text-align: right;
}

.COPIFooter .COPISubTotal .COPIFTRec .COPIFTRPrice {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.COPIFooter .COPISubTotal .COPIFTRec .COPIFTRTime {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}
.COPIFooter .COPISubTotal .COPIFTSet {
    width: 17%;
    text-align: right;
}

.COPIFooter .COPISubTotal .COPIFTSet .COPIFTSPrice {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.COPIFooter .COPISubTotal .COPIFTSet .COPIFTSTime {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.COPIFooter .COPIAmDu {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 15px;
}

.COPIFooter .COPIAmDu .COPIADCol {
    width: 60%;
}

.COPIFooter .COPIAmDu .COPIADCol .COPIADCTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.COPIFooter .COPIAmDu .COPIADCol .SimpleLink {
    color: var(--dark-blue);
    font-weight: 600;
}

.COPIFooter .COPIAmDu .COPIADPrice {
    width: 20%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.COPIFooter .COPIAmDu .COPIADBtn {
    width: 20%;
    text-align: center;
}

.COPIFooter .COPIAppCoup {
    display: flex;
    padding: 15px;
    border-top: 1px solid #f6f8fc;
}

.COPIFooter .COPIAppCoup .DCInputWrapper {
    width: 50%;
    margin-right: 15px;
}

.COPIFooter .COPIAMDue {
    display: flex;
    padding: 15px;
    background-color: #fff;
}

.COPIFooter .COPIAMDue .COPIAMDTitle {
    font-size: 18px;
    font-weight: 600;
    flex: 1;
}

.COPIFooter .COPIAMDue .COPIAMDTitleSpace {
    font-size: 18px;
    font-weight: 600;
    width: 330px;
    text-align: end;
}

.COPIAMACDue {
    display: flex;
    padding: 15px;
    background-color: #fff;
    justify-content: flex-end;
    column-gap: 100px;
}

.COPIAMDPriceSpace {
    width: 100px;
    text-align: end;
}

.COPIFooter .COPIAMDue .COPIAMDPrice {
    font-size: 18px;
    font-weight: 600;
    color: #666;
}

.CheckoutPage .CheckoutPageInner .COPICol .COPICartItems {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.COPICPromoCode {
    display: flex;
    padding: 8px 15px 0;
}
.COPICPromoCode .mainButton {
    margin-left: 0.4rem;
}

.CheckoutPage .CheckoutPageInner .COPICol .COPICPayMethList {
    flex: 1;
    padding: 15px;
}

.COPICPayMethList .COPICPMLItem {
    margin-bottom: 15px;
}

.COPICPayMethList .COPICPMLItem .COPICPMLCards {
    margin-top: 15px;
}
.COPICPayMethList .COPICPMLItem .COPICPMLCards .DCDSMainBtn.DCDSMSelect {
    padding: 0;
}
.COPICPayMethList .COPICPMLItem .COPICPMLCards .CardInput {
    border: 0;
}

.DCDSOptionList .COPICPMLCardOption .DropdownOptionsBtn.mainButton {
    padding: 0;
    border: 0;
}

.DCDSOptionList .COPICPMLCardOption .CardInput .CardInputWrapper input {
    color: #000;
    font-weight: 600;
}

.DCDSOptionList .COPICPMLCardOption .CardInput {
    border: 0;
}

.DCDSOptionList .COPICPMLCardOption {
    display: flex;
    align-items: center;
    column-gap: 10px;
    background-color: #f6f8fc;
    padding-right: 10px;
}

.COPICPayMethList .StripeElement {
    padding: 10px;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
}

.COPICPayMethList .ANCDZCS {
    display: flex;
    column-gap: 10px;
}

.COPICPayMethList .ANCBtn {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
}

.COPICPayMethList .COPICPayBtn button.mainButton {
    padding: 13px;
    border-radius: 100px;
    justify-content: center;
}

.COPICPayMethList .COPICPayBtn button.mainButton span.buttonTitle {
    flex: unset;
    padding-right: 10px;
}

.COPICPayMethList .COPICPMLItem .AddNewCard {
    margin-top: 15px;
}

.COPICPayMethList .COPICPMLItem .AddNewCard .ANCGroup {
    margin-bottom: 15px;
}

.AddNewCard .ANCGroup .ANCGroupTitle {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    padding-bottom: 10px;
}

.AddNewCard .ANCGroup .ANCGZCS {
    display: flex;
}

.AddNewCard .ANCGroup .ANCGZCS .DCInputWrapper input {
    border-radius: 0;
}

.AddNewCard .ANCGroup .ANCGTC {
    text-transform: lowercase;
    font-weight: 600;
    color: var(--dark-blue);
}

.OCPIAMPaid {
    display: flex;
    padding: 15px;
    margin: 15px;
    background-color: #f6f8fc;
    border: 1px solid #e8eef9;
    border-radius: 4px;
}

.OCPIAMPaid .OCPIAMPTitle {
    display: flex;
    align-items: center;
    flex: 1;
    column-gap: 15px;
}

.OCPIAMPaid .OCPIAMPTitle .OCPIAMPIcon {
    font-size: 24px;
    color: #4caf50;
}

.OCPIAMPaid .OCPIAMPTitle .OCPIAMPText {
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.OCPIAMPaid .OCPIAMPTitle .OCPIAMPText strong {
    color: #000;
}

.OCPIAMPaid .COPIAMDPrice {
    font-size: 18px;
    font-weight: 600;
    color: #666;
}

.OrderConfirmationPage {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.OrderConfirmationPage .OCPHead {
    padding: 15px 15px;
    text-align: center;
}

.OrderConfirmationPage .OCPHead .OCPHTitle {
    font-size: 28px;
    font-weight: 600;
    color: #000;
}

.OrderConfirmationPage .OCPHead .OCPHSTitle {
    font-size: 18px;
    font-weight: 500;
    color: #666;
}

.OrderConfirmationPage .OCPHead .OCPHSTitle span {
    font-weight: 600;
    text-transform: capitalize;
}

.OrderConfirmationPage .OCPHead .OCPHSRetry {
    padding: 15px;
    background-color: #fffcfc;
    border: 2px solid #f44336;
    font-size: 16px;
    font-weight: 600;
    color: #f44336;
    cursor: pointer;
    margin: 20px 50px 0px 50px;
}

.OrderConfirmationPage .OCPIcon {
    display: flex;
    justify-content: center;
    font-size: 350px;
    padding: 15px 0;
}

.OrderConfirmationPage .OCPVMAB {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.OrderConfirmationPage .OCPVMAB a {
    color: var(--dark-blue);
    text-decoration: underline;
}

.OrderConfirmationPage .OCPCD {
    padding: 15px;
}

.OrderConfirmationPage .OCPCD button.mainButton {
    padding: 13px;
    border-radius: 100px;
    justify-content: center;
}

.OrderConfirmationPage .OCPCD button.mainButton span.buttonTitle {
    flex: unset;
    padding-right: 10px;
}

.OrderConfirmationPage .OCPDRec {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.CheckoutPage .CheckoutPageInner .COPICol .COPICont {
    display: none;

    @media screen and (max-width: 991px) {
        display: flex;
        justify-content: flex-end;
        padding: 13px 15px;
    }
}

.CheckoutPage .CheckoutPageInner .COPICol .COPICont button {
    @media screen and (max-width: 575.98px) {
        padding: 13px 15px;
        width: 100%;
    }
}

.COPICIGroup .COPICIGProduct .COPICIGPQun strong,
.COPICIGroup .COPICIGProduct .COPICIGPSetup strong,
.COPICIGroup .COPICIGProduct .COPICIGPRec strong {
    display: none;
}

.CheckoutPage.OrderConfirmation .COPICIGroup .COPICIGProduct .COPICIGPSetup,
.CheckoutPage.OrderConfirmation .COPICIGroup .COPICIGProduct .COPICIGPRec {
    width: 25%;
}

.CheckoutPage.OrderConfirmation .COPICartItems .COPICIHead > div:nth-child(2),
.CheckoutPage.OrderConfirmation .COPICartItems .COPICIHead > div:nth-child(3) {
    width: 25%;
}

.OrderConfirmationPage .OCPContent {
    flex: 1;
    border-top: 1px solid #e8eef9;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.OrderConfirmationPage .OCPContent .OCPCTitle {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 15px;
}

.OrderConfirmationPage .OCPContent .OCPCFeature {
    flex: 1;
    overflow-x: overlay;
}

.OrderConfirmationPage .OCPContent .OCPCFeature .OCPCFItem {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #e8eef9;
    column-gap: 15px;
}

.OrderConfirmationPage .OCPContent .OCPCFeature .OCPCFItem .OCPCFIIcon {
    display: flex;
    font-size: 24px;
    color: #4caf50;
}

.OrderConfirmationPage .OCPContent .OCPCFeature .OCPCFItem .OCPCFIInfo {
    flex: 1;
}

.OrderConfirmationPage .OCPContent .OCPCFeature .OCPCFItem .OCPCFIInfo .OCPCFIITitle {
    font-size: 16px;
    font-weight: 600;
}

.OrderConfirmationPage .OCPContent .OCPCFeature .OCPCFItem .OCPCFIInfo .OCPCFIIDes {
    font-size: 14px;
    color: #666;
}

// Responsive
@media (min-width: 1560px) and (max-width: 1800px) {
}

@media (min-width: 1367px) and (max-width: 1559.98px) {
    .COPICartItems .COPICIHead .COPICITitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIIcon img {
        width: 30px;
        height: 30px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDTitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType span {
        font-size: 12px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPRec {
        font-size: 14px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPSetup {
        font-size: 14px;
    }

    .COPIFooter .COPISubTotal .COPIFTitle {
        font-size: 18px;
    }
}

@media (min-width: 1280px) and (max-width: 1366.98px) {
    .COPICartItems .COPICIHead .COPICITitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIIcon img {
        width: 30px;
        height: 30px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDTitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType span {
        font-size: 12px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPRec {
        font-size: 14px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPSetup {
        font-size: 14px;
    }

    .COPIFooter .COPISubTotal .COPIFTitle {
        font-size: 18px;
    }
}

@media (min-width: 1024px) and (max-width: 1279.98px) {
    .CheckoutPage {
        padding: 15px 0;
    }
    .COPICartItems .COPICIHead .COPICITitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIIcon img {
        width: 30px;
        height: 30px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDTitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType span {
        font-size: 12px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPRec {
        font-size: 14px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPSetup {
        font-size: 14px;
    }

    .COPIFooter .COPISubTotal .COPIFTitle {
        font-size: 18px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .CheckoutPage {
        padding: 15px 0;
    }
    .COPICartItems .COPICIHead .COPICITitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIIcon img {
        width: 30px;
        height: 30px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDTitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType span {
        font-size: 12px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPRec {
        font-size: 14px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPSetup {
        font-size: 14px;
    }

    .COPIFooter .COPISubTotal .COPIFTitle {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .CheckoutPage {
        padding: 15px 0;
    }
    .COPICartItems .COPICIHead .COPICITitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIIcon img {
        width: 30px;
        height: 30px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDTitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType span {
        font-size: 12px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPRec {
        font-size: 14px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPSetup {
        font-size: 14px;
    }

    .COPIFooter .COPISubTotal .COPIFTitle {
        font-size: 18px;
    }

    .CheckoutPage .CheckoutPageInner .COPIRow {
        position: relative;
        overflow: hidden;
    }

    .CheckoutPage .CheckoutPageInner .COPICol:nth-child(1) {
        width: 100%;
    }

    .CheckoutPage .CheckoutPageInner .COPICol.COPIMethod {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 60%;
        z-index: 1;
        background-color: #ffff;
        border: 1px solid #e8eef9;
        transition: all ease 0.3s;
        transform: translateX(100%);
    }

    .CheckoutPage .CheckoutPageInner .COPICol.COPIMethod.open {
        transform: translateX(0);
    }

    .CheckoutPage .CheckoutPageInner .COPICol .COPICHead strong {
        display: block;
    }
    .CheckoutPage .CheckoutPageInner .COPICol .COPICHead button {
        display: block;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .CheckoutPage {
        padding: 15px 0;
    }
    .COPICartItems .COPICIHead .COPICITitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIIcon img {
        width: 30px;
        height: 30px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDTitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType span {
        font-size: 12px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPRec {
        font-size: 14px;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPSetup {
        font-size: 14px;
    }

    .COPIFooter .COPISubTotal .COPIFTitle {
        font-size: 18px;
    }

    .CheckoutPage .CheckoutPageInner .COPIRow {
        position: relative;
        overflow: hidden;
    }

    .CheckoutPage .CheckoutPageInner .COPICol:nth-child(1) {
        width: 100%;
    }

    .CheckoutPage .CheckoutPageInner .COPICol.COPIMethod {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 80%;
        z-index: 1;
        background-color: #ffff;
        border: 1px solid #e8eef9;
        transition: all ease 0.3s;
        transform: translateX(100%);
    }

    .CheckoutPage .CheckoutPageInner .COPICol.COPIMethod.open {
        transform: translateX(0);
    }

    .CheckoutPage .CheckoutPageInner .COPICol .COPICHead strong {
        display: block;
    }
    .CheckoutPage .CheckoutPageInner .COPICol .COPICHead button {
        display: block;
    }
}

@media (max-width: 575.98px) {
    .CheckoutPage {
        padding: 0;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIIcon img {
        width: 30px;
        height: 30px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDTitle {
        font-size: 16px;
    }

    .COPICIGProduct .COPICIGPInfo .COPICIGPIDetail .COPICIGPIDType span {
        font-size: 12px;
    }

    .COPIFooter .COPISubTotal {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .COPIFooter .COPISubTotal .COPIFTitle {
        font-size: 18px;
        width: 100%;
    }

    .COPIFooter .COPISubTotal .COPIFTRec {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 15px;
        text-align: left;
    }

    .COPIFooter .COPISubTotal .COPIFTSet {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 15px;
        text-align: left;
    }

    .COPIFooter .COPISubTotal .COPIFTRec .COPIFTRTime {
        order: -1;
        font-weight: 600;
        font-size: 14px;
        color: #000;
        width: 100px;
    }

    .COPIFooter .COPISubTotal .COPIFTSet .COPIFTSTime {
        order: -1;
        font-weight: 600;
        font-size: 14px;
        color: #000;
        width: 100px;
    }

    .CheckoutPage .CheckoutPageInner .COPIRow {
        position: relative;
        overflow: hidden;
    }

    .CheckoutPage .CheckoutPageInner .COPICol:nth-child(1) {
        width: 100%;
    }

    .CheckoutPage .CheckoutPageInner .COPICol.COPIMethod {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        background-color: #ffff;
        border: 1px solid #e8eef9;
        transition: all ease 0.3s;
        transform: translateX(100%);
        overflow-y: overlay;
        overflow-x: overlay;
    }

    .CheckoutPage .CheckoutPageInner .COPICol.COPIMethod.open {
        transform: translateX(0);
    }

    .CheckoutPage .CheckoutPageInner .COPICol .COPICHead strong {
        display: block;
    }

    .CheckoutPage .CheckoutPageInner .COPICol .COPICHead button {
        display: block;
    }

    .COPICIGroup .COPICIGProduct {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .COPICIGProduct .COPICIGPInfo {
        width: 100%;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPQun,
    .COPICIGroup .COPICIGProduct .COPICIGPSetup,
    .COPICIGroup .COPICIGProduct .COPICIGPRec {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 15px;
        text-align: left;
    }

    .CheckoutPage.OrderConfirmation .COPICIGroup .COPICIGProduct .COPICIGPSetup,
    .CheckoutPage.OrderConfirmation .COPICIGroup .COPICIGProduct .COPICIGPRec {
        width: 100%;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPQun strong,
    .COPICIGroup .COPICIGProduct .COPICIGPSetup strong,
    .COPICIGroup .COPICIGProduct .COPICIGPRec strong {
        width: 100px;
        font-weight: 600;
        font-size: 14px;
        color: #000;
        display: block;
    }

    .COPICIGroup .COPICIGProduct .COPICIGPQun span,
    .COPICIGroup .COPICIGProduct .COPICIGPSetup span,
    .COPICIGroup .COPICIGProduct .COPICIGPRec span {
        flex: 1;
    }

    .OrderConfirmation .CheckoutPageInner .COPIRow {
        flex-direction: column;
        overflow: auto;
    }

    .OrderConfirmation .CheckoutPageInner .COPICol:nth-child(1) {
        width: 100%;
        overflow: unset;
    }

    .OrderConfirmation .CheckoutPageInner .COPICol:nth-child(2) {
        width: 100%;
        overflow: unset;
    }

    .OrderConfirmation .COPICartItems .COPICIHead > div:nth-child(2) {
        width: 26%;
        text-align: right;
    }

    .OrderConfirmation .COPICartItems .COPICIHead > div:nth-child(3) {
        width: 26%;
        text-align: right;
    }

    .OrderConfirmationPage {
        padding: 15px 15px;
    }

    .COPICartItems .COPICIHead {
        display: none;
    }

    .COPICIGProduct .COPICIGPInfo {
        width: calc(100% - 24px);
        order: -1;
    }

    .CheckoutPage.OrderConfirmation .COPICIGroup .COPICIGProduct .COPICIGPInfoIcon {
        order: -1;
        position: relative;
        right: 0;
        top: 0;
    }

    .OrderConfirmationPage .OCPIcon {
        font-size: 250px;
    }

    .CheckoutPage .CheckoutPageInner .COPICol .COPICartItems {
        overflow: visible;
    }
}
