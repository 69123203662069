.ExternalPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.ExternalPageHeaderBar .EPHBTitle {
    display: flex;
    align-items: center;
    width: 270px;
}

.ExternalPageHeaderBar .EPHBTitle button.iconButton {
    border-radius: 100%;
    background-color: #26262b;
    border-color: transparent;
    color: var(--white);
    padding: 4px;
    font-size: 16px;
    margin: 7px;
}

.ExternalPageHeaderBar .EPHBOption {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.ExternalPageHeaderBar .EPHBOption button.mainButton {
    margin-left: 15px;
}

.ExternalPage .ExternalPageBody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.ExternalPage .ExternalPageBody .EPBInner {
    display: flex;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    position: relative;
}

.ExternalPage .ExternalPageBody .EPBInner .EPSidebar {
    width: 285px;
    background-color: var(--white);
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    transition: all ease 0.3s;
}

.ExternalPage .ExternalPageBody .EPBInner.EPSMinimize .EPBTabSections {
    width: calc(100% - 62px);
    transition: all ease 0.3s;
}

.ExternalPage .ExternalPageBody .EPBInner .EPBTabSections {
    width: calc(100% - 285px);
    height: 100%;
    background-color: #f6f8fc;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    transition: all ease 0.3s;
}

.ExternalPage .ExternalPageBody .EPBInner .EPBTabSections .EPBTSInner {
    height: 100%;
    padding: 15px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.EPSidebar .EPSInner {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
}

.EPSidebar .EPSInner .EPSILogo {
    padding: 15px;
    display: flex;
    background-color: #f6f8fc;
}

.EPSidebar .EPSInner .EPSILogo img {
    height: 35px;
    width: 100%;
    object-fit: contain;
    object-position: left;
}

.EPSidebar .EPSInner .EPSIHead {
    padding: 15px;
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}

.EPSidebar .EPSInner .EPSIHead .EPSIHTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 5px;
}

.EPSidebar .EPSInner .EPSIHead .EPSIHAddress {
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
}

.EPSidebar .EPSInner .EPSIBody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.ExternalPage .ExternalPageBody .EPBInner.EPSMinimize .EPSidebar {
    width: 62px;
    transition: all ease 0.3s;
}

.EPSMinimize .EPSidebar .EPSInner .EPSILogo {
    display: none;
}

.EPSMinimize .EPSidebar .EPSInner .EPSIHead {
    display: none;
}

.EPSMinimize .EPSidebar .EPSInner .EPSIFooter {
    display: none;
}

.EPSMinimize .EPSidebar .EPSInner .EPSIBody .EPSIBTab .EPSIBTTitle,
.EPSMinimize .EPSidebar .EPSInner .EPSIBody .EPSIBTab .EPSIBTCir,
.EPSMinimize .EPSidebar .EPSInner .EPSIBody .EPSIBTab .EPSIBTTag {
    display: none;
}

.EPSIBody .EPSIBTab {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #f6f8fc;
    color: #8e93a5;
    position: relative;
}

.EPSIBody .EPSIBTab::after {
    content: '';
    position: absolute;
    right: 0;
    top: 10px;
    bottom: 10px;
    width: 3px;
    background-color: var(--dark-blue);
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
}

.EPSIBody .EPSIBTab.active {
    color: var(--dark-blue);
}

.EPSIBody .EPSIBTab.active::after {
    visibility: visible;
    opacity: 1;
}

.EPSIBody .EPSIBTab .EPSIBTIcon {
    font-size: 24px;
    display: flex;
}

.EPSIBody .EPSIBTab .EPSIBTTitle {
    flex: 1;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.EPSIBody .EPSIBTab .EPSIBTTag {
    line-height: normal;
    padding: 2px 7px;
    border-radius: 4px;
    font-weight: 600;
    color: var(--white);
}

.EPSIBody .EPSIBTab .EPSIBTCir {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 100%;
}

.EPSIFooter .EPSIFHead {
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}

.EPSIFooter .EPSIFBody {
    padding: 15px;
    background-color: #f6f8fc;
}

.EPSIFooter .EPSIFBody .UserInfo {
    padding-bottom: 15px;
}

.EPSIFooter .EPSIFBody .EPSIFBNum {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.EPSIFooter .EPSIFBody .EPSIFBNum .EPSIFBNIcon {
    font-size: 20px;
    display: flex;
    color: var(--dark-blue);
}

.EPSIFooter .EPSIFBody .EPSIFBNum a.EPSIFBNTitle {
    flex: 1;
    padding-left: 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

//

.EPBTabSections .EPComponentMain {
    margin-bottom: 15px;
}

.EPBTabSections .EPComponentMain:last-child {
    margin-bottom: 0;
}

.EPBTabSections .EPComponent {
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 3px 15px #b2b2b216;
    margin-bottom: 15px;
}

.EPBTabSections .EPComponent:last-child {
    margin-bottom: 0;
}

.EPBTabSections .EPComponent .EPCHead {
    display: flex;
    align-items: center;
    padding: 15px;
    line-height: normal;
    border-bottom: 1px solid #f6f8fc;
}

.EPComponent .EPCHead .EPCHTBox {
    display: flex;
    align-items: center;
    flex: 1;
}

.EPComponent .EPCHead .EPCHTBox .EPCHTBTitle {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-blue);
}

.EPComponent .EPCHead .EPCHTBox .EPCHTBIcon {
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 100%;
    font-size: 18px;
    font-weight: 600;
    margin-right: 15px;
}

.EPComponent .EPCHead .EPCHTBox .EPCHTBSTitle {
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.EPComponent .EPCHead .EPCHSTitle {
    font-size: 18px;
    font-weight: 500;
    color: var(--dark-blue);
    user-select: none;
    cursor: pointer;
}

.EPComponent .EPCRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 15px;
    grid-row-gap: 15px;
    padding-bottom: 15px;
}

.EPComponent .EPCRow .EPCRCol {
    width: calc(33.33% - 7.5px);
}

.EPComponent .EPCRow .EPCRCol2 {
    width: calc(20% - 10px);
}

.EPComponent .EPCRow .EPCRCol25 {
    width: calc(25% - 12.5px);
}

.EPComponent .EPCRow .EPCRCol3 {
    width: calc(33.33% - 10px);
    margin-bottom: 20px;
}

.EPComponent .EPCRow .EPCRCol4 {
    width: calc(40% - 10px);
}

.EPComponent .EPCRow .EPCRCol5 {
    width: calc(50% - 10px);
}

.EPComponent .EPCRow .EPCRCol6 {
    width: calc(60% - 10px);
}

.EPComponent .EPCRow .EPCRCol8 {
    width: calc(80% - 10px);
}

.EPBTabSections .EPComponent .EPCBody {
    padding: 15px;
}

.EPComponent .EPCVideoContent {
    display: flex;
    align-items: center;
}

.EPComponent .EPCVideoContent .EPCVCVideo {
    width: 450px;
    height: 250px;
    display: flex;
}

.EPComponent .EPCVideoContent .EPCVCVideo iframe {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.EPComponent .EPCVideoContent .EPCVCContent {
    width: calc(100% - 450px);
    padding-left: 15px;
}

.EPComponent .EPCVideoContent .EPCVCContent .EPCVCCTitle {
    font-size: 28px;
    font-weight: 600;
    color: #000;
    padding-bottom: 15px;
    line-height: normal;
}

.EPComponent .EPCVideoContent .EPCVCContent .EPCVCCDes {
    font-size: 18px;
    font-weight: 500;
    color: #666;
    line-height: 28px;
}

.EPComponent .EPCVideoContent .EPCVCContent .EPCVCCBtn {
    padding-top: 15px;
}

.EPComponent .EPCRCDetail {
    background-color: #f6f8fc;
    border: 1px solid #cad3e3;
    border-radius: 4px;
}

.EPComponent .EPCRCDetail .EPCRCDRow {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dae1ec;
}

.EPComponent .EPCRCDetail .EPCRCDRow:last-child {
    border-bottom: 0;
}

.EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRLabel {
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    line-height: normal;
    min-width: 175px;
    color: #666;
}

.EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRTitle {
    flex: 1;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
}

.EPComponent .EPCRCBPhotos {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.EPComponent .EPCRCBPhotos .EPCRCBPHead {
    padding-bottom: 15px;
    line-height: normal;
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-blue);
    border-bottom: 1px solid #cad3e3;
}

.EPComponent .EPCRCBPhotos .EPCRCBPBody {
    flex: 1;
    padding-top: 15px;
    padding-bottom: 20px;
}

.EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-slider ul.slick-dots {
    bottom: -25px;
}

.EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-slider ul.slick-dots li button::before {
    display: none;
}

.EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-slider ul.slick-dots li {
    width: 10px;
    height: 10px;
}

.EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-slider ul.slick-dots li button {
    background-color: #f4f6fa;
    border-radius: 100%;
    width: 10px;
    height: 10px;
}

.EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-slider ul.slick-dots li.slick-active button {
    background-color: var(--dark-blue);
}

.EPCRCBPhotos .EPCRCBPBody .slick-slider .slick-slide > div {
    display: flex;
    height: 100%;
    width: 100%;
}

.EPCRCBPhotos .EPCRCBPBody .slick-slider .slick-slide .EPCRCBPBSlide {
    margin: 0 7.5px;
    border: 1px solid #dae1ec;
    border-radius: 4px;
    height: 100%;
}

.EPCRCBPhotos .EPCRCBPBody .slick-slider .slick-slide .EPCRCBPBSlide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.EPComponent .EPCRow .EPCRCChart {
    display: flex;
    justify-content: center;
}

// .EPBTabSections .EPComponent .EPCRCChart .highcharts-label {
//     transform: translate(92px, 67px);
// }

// .EPBTabSections .EPComponent .EPCRCChart .highcharts-label rect.highcharts-label-box {
//     stroke: none;
// }

.EPBTabSections .EPComponent .EPCRCScore {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}

.EPComponent .EPCRCScore .EPCRCSBox {
    width: 172px;
    height: 172px;
    border: 1px solid #cad3e3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBTitle {
    font-size: 22px;
    font-weight: 600;
    color: #666;
    line-height: normal;
    padding-bottom: 15px;
}

.EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBType {
    width: 60px;
    height: 60px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 60px;
    border-radius: 100%;
}

.EPComponent .EPCInnerHeading {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding: 15px;
    background-color: #f5f7fb;
    border-radius: 4px;
}

.EPComponent .EPCInnerHeading .EPCIHLTitle {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}
.EPComponent .EPCInnerHeading .EPCIHRTitle {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-align: right;
}

.EPComponent .EPCASLA {
    border: 1px solid #e5f3ff;
    border-radius: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.EPComponent .EPCASLA .EPCASCHead {
    text-align: center;
}

.EPComponent .EPCASLA .EPCASCHead .EPCASCHTitle {
    font-size: 26px;
    font-weight: 600;
    color: #666;
}

.EPComponent .EPCASLA .EPCASCHead .EPCASCHNum {
    font-size: 48px;
    font-weight: 600;
    color: #008afb;
}

.EPComponent .EPCProgressMain {
    padding: 15px;
    height: 100%;
    border: 1px solid #f5f7fb;
    border-radius: 4px;
}

.EPComponent .EPCProgressMain .EPCPMTitle {
    font-size: 22px;
    font-weight: 600;
    color: #666;
    padding-bottom: 10px;
    line-height: normal;
}

.EPComponent .EPCProgressMain .EPCPMProgress {
    padding-bottom: 15px;
}

.EPComponent .EPCProgressMain .EPCPMProgress:last-child {
    padding-bottom: 0px;
}

.EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
}

.EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle .EPCPMPText {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    line-height: normal;
}

.EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPBar {
    height: 7px;
    border-radius: 10px;
    background-color: #f5f7fb;
    overflow: hidden;
}

.EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPBar .EPCPMPBInner {
    height: 100%;
}

.EPBTabSections .EPComponent .EPCListing {
    padding-top: 15px;
    padding-bottom: 15px;
    display: grid;
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minMax(calc(33.33% - 15px), 1fr));
    grid-auto-rows: auto;
}

.EPComponent .EPCListing .EPCLCol {
    padding: 15px;
    border: 1px solid #f5f7fb;
    border-radius: 4px;
}

.EPCListing .EPCLCol .EPCLCHead {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox {
    display: flex;
    align-items: center;
    flex: 1;
}

.EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon {
    font-size: 30px;
    display: flex;
    padding: 3px;
    border-radius: 100%;
    color: var(--white);
}

.EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon img {
    width: 40px;
    height: 40px;
}

.EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHTitle {
    flex: 1;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #666;
}

.EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox a {
    font-size: 18px;
    color: var(--dark-blue);
}

.EPCListing .EPCLCol .EPCLCHead a.EPCLCHTLink {
    display: flex;
    font-size: 18px;
    color: var(--dark-blue);
    align-self: flex-start;
}

.EPCListing .EPCLCol .EPCLCBody .EPCLCBRow {
    display: flex;
    padding-bottom: 15px;
}

.EPCListing .EPCLCol .EPCLCBody .EPCLCBRow:last-child {
    padding-bottom: 0px;
}

.EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRIcon {
    font-size: 20px;
    display: flex;
}

.EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRTitle {
    flex: 1;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    line-height: normal;
}

.EPBTabSections .EPComponent .EPCViewMore {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.EPBTabSections .EPComponent .EPCUserComt {
    padding: 15px;
    background-color: #eaeaea;
    border-radius: 4px;
}

.EPComponent .EPCUserComt .EPCUCInner {
    display: flex;
    align-items: center;
}

.EPCUserComt .EPCUCInner .EPCUCInfo {
    display: flex;
    align-items: center;
    max-width: 90%;
}
.EPCUserComt .EPCUCInner .EPCUCInfo .EPCUCIImg {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
}

.EPCUserComt .EPCUCInner .EPCUCInfo .EPCUCIImg img,
.EPCUserComt .EPCUCInner .EPCUCInfo .EPCUCIImg svg {
    width: 35px;
    height: 35px;
    border-radius: 100%;
}

.EPCUserComt .EPCUCInner .EPCUCInfo .EPCUCICmt {
    flex: 1;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.EPCUserComt .EPCUCInner .EPCUCBtn {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-self: baseline;
}

.EPComponent .EPCReviews {
    display: grid;
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minMax(calc(50% - 15px), 1fr));
    grid-auto-rows: auto;
}

.EPComponent .EPCReviews.EPCReviews3 {
    grid-template-columns: repeat(auto-fill, minMax(calc(33.33% - 15px), 1fr));
}

.EPComponent .EPCReviews .EPCReviewBox {
    border-radius: 4px;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBHead {
    padding: 15px;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBHead {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBHead .EPCRBHInfo {
    flex: 1;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBHead .EPCRBHInfo .EPCRBHITitle {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBHead .EPCRBHInfo .EPCRBHISTitle {
    line-height: normal;
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBHead .EPCRBHIcon {
    display: flex;
    font-size: 45px;
    color: #f4ad3b;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBBody {
    padding: 15px;
    display: flex;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol {
    flex: 1;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCTitle {
    font-size: 20px;
    font-weight: 600;
    color: #666;
    line-height: normal;
}

.EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCNum {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    line-height: normal;
}

.EPComponent .EPCRComment .EPCRCBox {
    border: 1px solid #f5f7fb;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.EPComponent .EPCRComment .EPCRCBox:last-child {
    margin-bottom: 0px;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead {
    display: flex;
    padding-bottom: 10px;
    column-gap: 10px;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUser {
    display: flex;
    align-items: center;
    flex: 1;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUser .EPCRCBUImg {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #f5f7fb;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUser .EPCRCBUImg img {
    border-radius: 100%;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUser .EPCRCBUIInfo {
    flex: 1;
    padding-left: 10px;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUser .EPCRCBUIInfo .EPCRCBUIIName {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    line-height: normal;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUser .EPCRCBUIInfo .EPCRCBUIIDate {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-blue);
    line-height: normal;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUReview {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f5f7fb;
    border-radius: 4px;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUReview .EPCRCBURIcon {
    font-size: 24px;
    display: flex;
    margin-left: 5px;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead button.iconButton {
    font-size: 16px;
    padding: 10px 13px;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBBody {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.EPComponent .EPCPageInfo {
    border: 1px solid #f5f7fb;
    border-radius: 4px;
}

.EPComponent .EPCPageInfo .EPCPIHead {
    padding: 15px;
    display: flex;
    align-items: center;
}

.EPComponent .EPCPageInfo .EPCPIHead .EPCPIHTitleBox {
    flex: 1;
    padding-right: 15px;
}

.EPComponent .EPCPageInfo .EPCPIHead .EPCPIHTitleBox .EPCPIHTBTitle {
    line-height: normal;
    font-size: 20px;
    font-weight: 600;
    color: #666;
}

.EPComponent .EPCPageInfo .EPCPIHead .EPCPIHTitleBox .EPCPIHTBSTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}

.EPComponent .EPCPageInfo .EPCPIHead .EPCPIHIcon {
    font-size: 25px;
    display: flex;
    color: var(--white);
    padding: 10px;
    border-radius: 100%;
}

.EPComponent .EPCPageInfo .EPCPIBody {
    padding: 15px;
    border-top: 1px solid #f5f7fb;
}

.EPComponent .EPCPageInfo .EPCPIBody .EPCPIBRow {
    display: flex;
}

.EPComponent .EPCPageInfo .EPCPIBody .EPCPIBRow .EPCPIBRCol {
    flex: 1;
}

.EPComponent .EPCPageInfo .EPCPIBody .EPCPIBRow .EPCPIBRCol .EPCPIBRCTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: #666;
}

.EPComponent .EPCPageInfo .EPCPIBody .EPCPIBRow .EPCPIBRCol .EPCPIBRCNum {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: normal;
}

.EPComponent .EPCWebType .EPCWTHead {
    padding: 10px 15px;
    background-color: #f8f8f8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.EPComponent .EPCWebType .EPCWTHead .EPCWTHGrade {
    width: 25px;
    height: 25px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 25px;
    border-radius: 100%;
}

.EPComponent .EPCWebType .EPCWTHead .EPCWTHTitle {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    color: #666;
}

.EPCWebType .EPCWTBView {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
}

.EPCWebType .EPCWTBView .EPCWTBVSummery {
    width: 50%;
    border: 1px solid #f5f7fb;
    border-radius: 4px;
}

.EPCWebType .EPCWTBView .EPCWTBVSummery .EPCWTBVSTitle {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    padding: 10px;
    border-bottom: 1px solid #f6f8fc;
}

.EPCWebType .EPCWTBView .EPCWTBVSummery .EPCWTBVSITN {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f6f8fc;
}

.EPCWebType .EPCWTBView .EPCWTBVSummery .EPCWTBVSITN:last-child {
    border-bottom: 0;
}

.EPCWebType .EPCWTBView .EPCWTBVSummery .EPCWTBVSITN .EPCWTBVSITNIcon {
    font-size: 20px;
    display: flex;
    color: #000;
}

.EPCWebType .EPCWTBView .EPCWTBVSummery .EPCWTBVSITN .EPCWTBVSITNTitle {
    flex: 1;
    padding: 0 10px;
    font-size: 16px;
    line-height: normal;
    color: #000;
}

.EPCWebType .EPCWTBView .EPCWTBVSummery .EPCWTBVSITN .EPCWTBVSITNNum {
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.EPCWebType .EPCWTBView .EPCWTBVMob {
    width: 50%;
}

.EPCWebType .EPCWTBView .EPCWTBVMob .EPCWTBVMobView {
    width: 225px;
    height: 400px;
    position: relative;
    display: flex;
    margin: 0 auto;
}

.EPCWebType .EPCWTBView .EPCWTBVMob .EPCWTBVMobView .EPCWTBVMVImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.EPCWebType .EPCWTBView .EPCWTBVMob .EPCWTBVMobView .EPCWTBVMVContent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000;
    overflow-y: auto;
    width: 88%;
    height: calc(94% + -2px);
    border-radius: 18px;
    overflow: hidden;
}

.EPCWebType .EPCWTBView .EPCWTBVMob .EPCWTBVMobView .EPCWTBVMVContent img {
    height: 100%;
    object-fit: cover;
}

.EPCWebType .EPCWTBView .EPCWTBVDesk .EPCWTBVDeskView .EPCWTBVDVContent img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.EPCWebType .EPCWTBView .EPCWTBVDesk {
    width: 50%;
}

.EPCWebType .EPCWTBView .EPCWTBVDesk .EPCWTBVDeskView {
    width: 350px;
    height: 200px;
    position: relative;
    display: flex;
    margin: 0 auto;
}

.EPCWebType .EPCWTBView .EPCWTBVDesk .EPCWTBVDeskView .EPCWTBVDVImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.EPCWebType .EPCWTBView .EPCWTBVDesk .EPCWTBVDeskView .EPCWTBVDVContent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -53%);
    background-color: #000000;
    overflow-y: auto;
    width: 75%;
    height: calc(84%);
    border-radius: 0;
    overflow: hidden;
}

.EPCWebType .EPCWTBView.EPCWTBDesktopView {
    height: 430px;
}

.EPCWTBFix {
    border: 1px solid #f6f8fc;
    margin-bottom: 15px;
}

.EPCWTBFix .EPCWTBFHead {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
}

.EPCWTBFix .EPCWTBFHead .EPCWTBFHIcon {
    display: flex;
    font-size: 24px;
    color: #f44336;
}

.EPCWTBFix .EPCWTBFHead .EPCWTBFHTitle {
    flex: 1;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
}

.EPCWTBFix .EPCIssueRow {
    border-bottom: 1px solid #f6f8fc;
}

.EPCWTBFix .EPCIssueRow:last-child {
    border-bottom: 0;
}

.EPCWTBFix .EPCIssueRow .EPCIRHead {
    padding: 15px;
    user-select: none;
    cursor: pointer;
}

.EPCIssueRow .EPCIRHead .EPCIRTitleDate {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    column-gap: 15px;
}

.EPCIssueRow .EPCIRHead .EPCIRTitleDate .EPCIRTDTitle {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    color: #666;
}

.EPCIssueRow .EPCIRHead .EPCIRTitleDate .EPCIRTDTime {
    font-size: 14px;
    font-weight: 600;
    color: var(--dark-blue);
}

.EPCIssueRow .EPCIRHead .EPCIRTitleDate .EPCIRTDArrow {
    display: flex;
    font-size: 18px;
    color: #8e93a5;
}

.EPCWTBFix .EPCIssueRow .EPCIRHead .EPCIRDes {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    word-wrap: break-word;
}

.EPCWTBFix .EPCIssueRow .EPCIRHead .EPCIRDes a {
    color: var(--dark-blue);
    text-decoration: underline;
}

.EPCWTBFix .EPCIssueRow .EPCIRHead .EPCIRLMore {
    display: flex;
}

.EPCWTBFix .EPCIssueRow .EPCIRHead .EPCIRLMore a.mainButton {
    color: var(--dark-blue);
}

.EPCIssueRow .EPCIRBRow {
    display: flex;
}

.EPCIssueRow .EPCIRBRow:nth-child(2n + 1) {
    background-color: #f4f6fa;
}

.EPCIssueRow .EPCIRBRow.EPCIRBHead .EPCIRBRCol {
    font-size: 16px;
    font-weight: 600;
}

.EPCIssueRow .EPCIRBRow .EPCIRBRCol {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
}

.EPCIssueRow .EPCIRBRow .EPCIRBRCol:nth-child(1) {
    width: 60%;
}
.EPCIssueRow .EPCIRBRow .EPCIRBRCol:nth-child(2) {
    width: 20%;
}
.EPCIssueRow .EPCIRBRow .EPCIRBRCol:nth-child(3) {
    width: 20%;
}

.EPCWTBFix .EPCWTBFBViewMore {
    display: flex;
    justify-content: center;
    padding: 15px;
}

.EPComponent .EPCRTSIH {
    padding: 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 100%;
}

.EPComponent .EPCRTSIH .EPCRTSIHInfo {
    flex: 1;
    padding-right: 15px;
}

.EPComponent .EPCRTSIH .EPCRTSIHInfo .EPCRTSIHITBox {
    display: flex;
    align-items: center;
}

.EPComponent .EPCRTSIH .EPCRTSIHInfo .EPCRTSIHITBox .EPCRTSIHITTitle {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    padding-right: 10px;
    line-height: normal;
}

.EPComponent .EPCRTSIH .EPCRTSIHInfo .EPCRTSIHISTBox {
    font-size: 24px;
    font-weight: 600;
    color: #666;
    line-height: normal;
}

.EPComponent .EPCRTSIH .EPCRTSIHIcon {
    font-size: 54px;
    display: flex;
}

.EPComponent .EPCRCBType {
    padding: 15px;
    border: 1px solid #f5f7fb;
    border-radius: 4px;
    height: 100%;
}

.EPComponent .EPCRCBType .EPCRCBTHead {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.EPComponent .EPCRCBType .EPCRCBTHead .EPCRCBTHTitle {
    font-size: 16px;
    font-weight: 600;
    padding-right: 10px;
    line-height: normal;
    color: #666;
}

.EPComponent .EPCRCBType .EPCRCBTBTList {
    display: flex;
    justify-content: space-around;
}

.EPComponent .EPCRCBType .EPCRCBTBTank {
    width: 125px;
}

.EPComponent .EPCRCBType .EPCRCBTBTank .EPCRCBTBTBox {
    height: 125px;
    border-radius: 0 0 7px 7px;
    background-color: #d6f2ff;
    display: flex;
    align-items: flex-end;
}

.EPComponent .EPCRCBType .EPCRCBTBTank .EPCRCBTBTBox .EPCRCBTBTBInner {
    background-color: #37c1ff;
    width: 100%;
    border-radius: 0 0 7px 7px;
}

.EPComponent .EPCRCBType .EPCRCBTBTank .EPCRCBTBTBTText {
    text-align: center;
    padding-top: 15px;
}

.EPComponent .EPCRCBType .EPCRCBTBTank .EPCRCBTBTBTText .EPCRCBTBTBTTitle {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    line-height: normal;
}

.EPComponent .EPCRCBType .EPCRCBTBTank .EPCRCBTBTBTText .EPCRCBTBTBTSTitle {
    font-size: 12px;
    color: #000;
    line-height: normal;
}

.EPComponent .EPCRCBType .EPCRCBTFNProgress {
    height: 50px;
    border-radius: 4px;
    background-color: #f6f6ff;
    overflow: hidden;
}

.EPComponent .EPCRCBType .EPCRCBTFNProgress .EPCRCBTFNPInner {
    height: 100%;
    background-color: #807ff0;
}

.EPComponent .EPCRCBType .EPCRCBTFNPTitle {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.EPComponent .EPCRCBType .EPCRCBTFNPTitle .EPCRCBTFNPTBox .EPCRCBTFNPTBText {
    font-size: 18px;
    font-weight: 600;
    color: #666;
}

.EPComponent .EPCRCBType .EPCRCBTFNPTitle .EPCRCBTFNPTBox .EPCRCBTFNPTBSText {
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.EPComponent .EPCRCKTTC {
    padding: 15px;
    border-radius: 4px;
}

.EPComponent .EPCRCKTTC {
    padding: 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.EPComponent .EPCRCKTTC .EPCRCKTTCInfo {
    flex: 1;
    padding-right: 15px;
}

.EPComponent .EPCRCKTTC .EPCRCKTTCInfo .EPCRCKTTCITitleBox {
    display: flex;
    align-items: center;
}

.EPComponent .EPCRCKTTC .EPCRCKTTCInfo .EPCRCKTTCITitleBox .EPCRCKTTCITBTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    padding-right: 15px;
}

.EPComponent .EPCRCKTTC .EPCRCKTTCInfo .EPCRCKTTCINumber {
    font-size: 26px;
    font-weight: 600;
    color: #666;
    line-height: normal;
}

.EPComponent .EPCRCKTTC .EPCRCKTTCIcon {
    font-size: 70px;
    display: flex;
}

.EPComponent .EPCPost {
    border: 1px solid #cad3e3;
}

.EPComponent .EPCPost .EPCPHead {
    padding: 15px;
    background-color: #f5f7fb;
    border-bottom: 1px solid #cad3e3;
}

.EPComponent .EPCPost .EPCPHead .EPCPHTitleStatus {
    display: flex;
    align-items: center;
}

.EPComponent .EPCPost .EPCPHead .EPCPHTitleStatus .EPCPHTSStatus {
    width: 7px;
    height: 7px;
    background-color: #3ae269;
    border-radius: 100%;
}

.EPComponent .EPCPost .EPCPHead .EPCPHTitleStatus .EPCPHTSTitle {
    flex: 1;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #666;
    line-height: normal;
}

.EPComponent .EPCPost .EPCPHead .EPCPHDate {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    line-height: normal;
}

.EPComponent .EPCPost .EPCPBUser {
    padding: 15px;
    display: flex;
    align-items: center;
}

.EPComponent .EPCPost .EPCPBUser .EPCPBUImg {
    font-size: 40px;
    display: flex;
    border-radius: 100%;
    border: 1px solid #f5f7fb;
}

.EPComponent .EPCPost .EPCPBUser .EPCPBUImg svg,
.EPComponent .EPCPost .EPCPBUser .EPCPBUImg img {
    border-radius: 100%;
    height: 40px;
    width: 40px;
}

.EPComponent .EPCPost .EPCPBUser .EPCPBUInfo {
    flex: 1;
    padding-left: 15px;
}

.EPComponent .EPCPost .EPCPBUser .EPCPBUInfo .EPCPBUIName {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: #000;
}

.EPComponent .EPCPost .EPCPBUser .EPCPBUInfo .EPCPBUIType {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.EPComponent .EPCPost .EPCPBDes {
    padding: 0 15px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.EPComponent .EPCPost .EPCPBDes .EPCPBDInner {
    line-height: 16px;
    max-height: 112px;
    -webkit-line-clamp: 7;
    overflow: hidden;
}

.EPComponent .EPCPost .EPCPBDes.EPCPBDesFull .EPCPBDInner {
    line-height: 16px;
    max-height: unset;
    -webkit-line-clamp: unset;
    overflow: visible;
}

.EPComponent .EPCPost .EPCPBDes span {
    color: var(--dark-blue);
}

.EPComponent .EPCPost .EPCPBImage {
    display: flex;
}

.EPComponent .EPCPost .EPCPBImage a {
    display: flex;
    width: 100%;
}

.EPComponent .EPCPost .EPCPBImage img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    object-position: center;
}

.EPComponent .EPCPost .EPCPBVideo {
    display: flex;
}

.EPComponent .EPCPost .EPCPBVideo video {
    width: 100%;
    height: 300px;
}

.EPComponent .EPCPost .EPCPFooter {
    padding: 15px;
    background-color: #f5f7fb;
    border-top: 1px solid #cad3e3;
    display: flex;
}

.EPComponent .EPCPost .EPCPFooter .EPCPFBInfo {
    flex: 1;
    padding-right: 15px;
    align-self: center;
}

.EPComponent .EPCPost .EPCPFooter .EPCPFBInfo .EPCPFBITitle {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    line-height: normal;
}

.EPComponent .EPCPost .EPCPFooter .EPCPFBInfo .EPCPFBILink {
    color: #666;
    font-size: 12px;
    font-weight: 500;
    display: block;
}

.EPComponent .EPCPost:hover .EPCPFooter .EPCPFBInfo .EPCPFBILink {
    text-decoration: underline;
}

.GradeA {
    background-color: #08cdce;
    color: #fff;
}
.GradeB {
    background-color: #ff3cf9;
    color: #fff;
}
.GradeC {
    background-color: #4151f5;
    color: #fff;
}
.GradeD {
    background-color: #ffaf3a;
    color: #fff;
}
.GradeF {
    background-color: #fe6786;
    color: #fff;
}
.seoGrade {
    background-color: #e4fbf8;
    color: #fff;
}

.EPCTable .EPCTInner {
    border: 1px solid #cad3e3;
}

.EPCTable .EPCTInner .EPCTIRow {
    display: flex;
}

.EPCTable .EPCTInner .EPCTIRow.EPCTIRCol5 > div {
    width: 20%;
}

.EPCTable .EPCTInner .EPCTIRow.EPCTIRCol7 > div {
    width: calc(100% / 7);
}

.EPCTable .EPCTInner .EPCTIRow .EPCTIRCol {
    border-right: 1px solid #cad3e3;
    padding: 5px 10px;
    -webkit-user-select: none;
    user-select: none;
}

.EPCTable .EPCTInner .EPCTIRow .EPCTIRCol:last-child {
    border-right: 0;
}

.EPCTable .EPCTInner .EPCTIRow.EPCTIRHeader {
    background-color: #e8eef9;
    border-bottom: 1px solid #cad3e3;
}

.EPCTable .EPCTInner .EPCTIRow.EPCTIRHeader .EPCTIRHTitle {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.EPCTable .EPCTInner .EPCTIRow.EPCTIRHeader .EPCTIRHTitle .EPCTIRHText {
    flex: 1;
    font-size: 18px;
    color: #434750;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.EPCTable .EPCTInner .EPCTIRBody.EPCTIRow {
    border-bottom: 1px solid #cad3e3;
}

.EPCTable .EPCTInner .EPCTIRBody.EPCTIRow:last-child {
    border-bottom: 0;
}

.EPCTable .EPCTInner .EPCTIRBody .EPCTIRCol .EPCTIRBText {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.EPCTable .EPCTInner .EPCTIRBody .EPCTIRCol .EPCTIRBText a {
    color: var(--dark-blue);
}

.EPCTable .EPCTInner .EPCTProgress {
    display: flex;
    align-items: center;
}

.EPCTable .EPCTInner .EPCTProgress .EPCTPField {
    flex: 1;
    height: 10px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #f5f7fb;
}

.EPCTable .EPCTInner .EPCTProgress .EPCTPField .EPCTPFInner {
    height: 100%;
    background-color: var(--dark-blue);
}

.EPCTable .EPCTInner .EPCTProgress .EPCTPPre {
    line-height: normal;
    min-width: 40px;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    color: #666;
}
.EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-track {
    display: flex;
    height: 200px;
}
.EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-track .slick-slide {
    height: 100%;
    display: flex;
}
.EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRTitle .PhoneNu mberView {
    display: flex;
    justify-content: flex-end;
}

.EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRTitle .PhoneNumberView .react-tel-input {
    width: auto;
}

.EPComponent .EPCRCDetail .EPCRCDRow.EPCRCDRPhone {
    justify-content: space-between;
}

.EPComponent .EPCRCDetail .EPCRCDRow.EPCRCDRPhone .EPCRCDRTitle {
    flex: unset;
}

.EPComponent
    .EPCRCDetail
    .EPCRCDRow
    .EPCRCDRTitle
    .PhoneNumberView
    .react-tel-input
    input.form-control {
    max-width: 150px;
    width: auto;
}

.EPComponent .EPCHead .EPCHTBox .EPCHTBIcon.EPCHTBIGradeC {
    background-color: #eef5ff;
    color: #4151f5;
}

.EPCListing .EPCLCol .EPCLCBody .EPCLCBNo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNIcon {
    font-size: 125px;
    color: #f44336;
    display: flex;
}

.EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNTitle {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    padding-top: 10px;
}
.HowToDialog .HTDDes {
    font-size: 16px;
    font-weight: 500;
    color: #666;
    line-height: 24px;
}

.HowToDialog .HTDGrade {
    display: flex;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 50px;
    background-color: #f6f8fc;
    border-radius: 4px;
}

.HowToDialog .HTDGrade .HTDGItem {
    font-size: 18px;
    font-weight: 600;
    padding: 7px;
    color: #fff;
    text-align: center;
    position: relative;
}

.HowToDialog .HTDGrade .HTDGItem .HTDGPer {
    position: absolute;
    color: var(--dark-blue);
    bottom: -40px;
    left: -47%;
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
}

.HowToDialog .HTDGrade .HTDGItem .HTDGPer span {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    border: 0;
}

.ReviewDialog .RDInfoBox .RDIHead {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.ReviewDialog .RDInfoBox .RDIHead .RDIHUser {
    display: flex;
    align-items: center;
    column-gap: 15px;
    flex: 1;
}

.ReviewDialog .RDInfoBox .RDIHead .RDIHUser .RDIHUImg {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #f5f7fb;
}

.ReviewDialog .RDInfoBox .RDIHead .RDIHUser .RDIHUImg img {
    border-radius: 100%;
}

.EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUser .EPCRCBUIInfo {
    flex: 1 1;
    padding-left: 10px;
}

.ReviewDialog .RDInfoBox .RDIHead .RDIHUser .RDIHUInfo {
    flex: 1;
}

.ReviewDialog .RDInfoBox .RDIHead .RDIHUser .RDIHUInfo .RDIHUIName {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    line-height: normal;
}

.ReviewDialog .RDInfoBox .RDIHead .RDIHUser .RDIHUInfo .RDIHUIDate {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-blue);
    line-height: normal;
}

.ReviewDialog .RDInfoBox .RDIHead .RDIHReview {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f5f7fb;
    border-radius: 4px;
}

.ReviewDialog .RDInfoBox .RDIBody {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.GetInTouch .GITBtn {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
}

.InstaReportNoData {
    padding: 50px;
    background-color: #f5f7fb;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin: 15px 0;
}

.InstaReportNoData .IRNDIcon {
    display: flex;
    justify-content: center;
    font-size: 150px;
}

.InstaReportNoData .IRNDTitle {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    padding: 20px;
}

.InstaReportNoData .IRNDDescription {
    text-align: center;
    font-size: 16px;
    color: #666;
}

.InstaReportNoData .IRNDDescription p {
    margin-bottom: 7px;
}

.InstaReportNoData .IRNDDescription p:last-child {
    margin-bottom: 0px;
}

.EPComponent .EPCRow .EPCRCChart .highcharts-label rect.highcharts-label-box {
    stroke-width: 0;
}

@media (min-width: 1559px) and (max-width: 1800px) {
    .EPComponent .EPCRow .EPCRCol2 {
        width: calc(30% - 10px);
    }
    .EPComponent .EPCRow .EPCRCol4 {
        width: calc(35% - 10px);
    }

    .EPComponent .EPCRow .EPCRCol6 {
        width: calc(65% - 5px);
    }

    .EPComponent .EPCRow .EPCRCol8 {
        width: calc(70% - 5px);
    }
}
@media (min-width: 1367px) and (max-width: 1600px) {
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCTitle {
        font-size: 22px;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCDes {
        font-size: 16px;
        line-height: 24px;
    }

    .EPComponent .EPCProgressMain .EPCPMTitle {
        font-size: 18px;
    }

    .EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle .EPCPMPText {
        font-size: 16px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon {
        font-size: 25px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon img {
        width: 30px;
        height: 30px;
    }

    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHTitle {
        font-size: 16px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRTitle {
        font-size: 14px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNIcon {
        font-size: 100px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNTitle {
        font-size: 16px;
    }

    .EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCTitle {
        font-size: 16px;
    }

    .EPSidebar .EPSInner .EPSIHead .EPSIHTitle {
        font-size: 16px;
    }

    .EPSidebar .EPSInner .EPSIHead .EPSIHAddress {
        font-size: 12px;
    }

    .EPSIBody .EPSIBTab .EPSIBTIcon {
        font-size: 18px;
    }

    .EPSIBody .EPSIBTab .EPSIBTTitle {
        font-size: 14px;
    }

    .EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBTitle {
        font-size: 18px;
    }

    .EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRLabel {
        font-size: 16px;
    }

    .EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRTitle {
        font-size: 14px;
        white-space: unset;
    }

    .EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBType {
        width: 50px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
    }

    .EPComponent .EPCASLA .EPCASCHead .EPCASCHTitle {
        font-size: 20px;
    }

    .EPComponent .EPCASLA .EPCASCHead .EPCASCHNum {
        font-size: 34px;
    }

    .EPCWebType .EPCWTBView.EPCWTBDesktopView {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
    }

    .EPCWebType .EPCWTBView.EPCWTBDesktopView .EPCWTBVSummery {
        width: 100%;
    }

    .EPCWebType .EPCWTBView.EPCWTBDesktopView .EPCWTBVDesk {
        width: 100%;
    }

    .EPComponent .EPCRTSIH .EPCRTSIHInfo .EPCRTSIHITBox .EPCRTSIHITTitle {
        font-size: 16px;
    }

    .EPComponent .EPCRTSIH .EPCRTSIHInfo .EPCRTSIHISTBox {
        font-size: 20px;
    }

    .EPComponent .EPCRTSIH .EPCRTSIHIcon {
        font-size: 44px;
    }

    .EPComponent .EPCRCBType .EPCRCBTBTank {
        width: 100px;
    }

    .EPCTable {
        width: 100%;
        overflow: auto;
    }

    .EPCTable .EPCTInner {
        width: 1540px;
    }

    .EPComponent .EPCRCKTTC .EPCRCKTTCInfo .EPCRCKTTCITitleBox .EPCRCKTTCITBTitle {
        font-size: 20px;
    }

    .EPComponent .EPCRCKTTC .EPCRCKTTCInfo .EPCRCKTTCINumber {
        font-size: 22px;
    }

    .EPBTabSections .EPComponent .EPCRCScore {
        column-gap: 10px;
    }
}
@media (min-width: 1280px) and (max-width: 1367px) {
    .EPComponent .EPCRow .EPCRCol2 {
        width: calc(30% - 10px);
    }
    .EPComponent .EPCRow .EPCRCol4 {
        width: calc(35% - 10px);
    }

    .EPComponent .EPCRow .EPCRCol6 {
        width: calc(65% - 5px);
    }

    .EPComponent .EPCRow .EPCRCol8 {
        width: calc(70% - 5px);
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCTitle {
        font-size: 22px;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCDes {
        font-size: 16px;
        line-height: 24px;
    }

    .EPComponent .EPCProgressMain .EPCPMTitle {
        font-size: 18px;
    }

    .EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle .EPCPMPText {
        font-size: 16px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon {
        font-size: 25px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon img {
        width: 30px;
        height: 30px;
    }

    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHTitle {
        font-size: 16px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRTitle {
        font-size: 14px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNIcon {
        font-size: 100px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNTitle {
        font-size: 16px;
    }

    .EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCTitle {
        font-size: 16px;
    }

    .EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBTitle {
        font-size: 18px;
    }
    .EPCWebType .EPCWTBView .EPCWTBVDesk .EPCWTBVDeskView {
        width: 260px;
        height: 180px;
        position: relative;
        display: flex;
        margin: 0 auto;
    }
    .EPCWebType .EPCWTBView .EPCWTBVMob .EPCWTBVMobView {
        width: 165px;
        height: 320px;
        position: relative;
        display: flex;
        margin: 0 auto;
    }
}
@media (min-width: 1024px) and (max-width: 1280px) {
    .EPComponent .EPCRow .EPCRCol2 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol4 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol6 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol8 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol5 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol25 {
        width: 100%;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCTitle {
        font-size: 22px;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCDes {
        font-size: 16px;
        line-height: 24px;
    }

    .EPComponent .EPCProgressMain .EPCPMTitle {
        font-size: 18px;
    }

    .EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle .EPCPMPText {
        font-size: 16px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon {
        font-size: 25px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon img {
        width: 30px;
        height: 30px;
    }

    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHTitle {
        font-size: 16px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRTitle {
        font-size: 14px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNIcon {
        font-size: 100px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNTitle {
        font-size: 16px;
    }

    .EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCTitle {
        font-size: 16px;
    }

    .EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBTitle {
        font-size: 18px;
    }

    .EPComponent .EPCVideoContent {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPComponent .EPCVideoContent .EPCVCVideo {
        width: 100%;
    }

    .EPComponent .EPCVideoContent .EPCVCContent {
        width: 100%;
        padding: 0;
    }
    .EPComponent .EPCRCScore .EPCRCSBox {
        width: 33.33%;
    }

    .EPBTabSections .EPComponent .EPCRCScore {
        flex-wrap: wrap;
    }
    .EPBTabSections .EPComponent .EPCListing {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        grid-template-columns: unset;
        grid-gap: unset;
        row-gap: 15px;
        column-gap: 15px;
    }

    .EPComponent .EPCListing .EPCLCol {
        width: calc(50% - 7.5px);
    }
}
@media (min-width: 992px) and (max-width: 1023.98px) {
    .EPComponent .EPCRow .EPCRCol2 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol4 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol6 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol8 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol5 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol25 {
        width: 100%;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCTitle {
        font-size: 22px;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCDes {
        font-size: 16px;
        line-height: 24px;
    }

    .EPComponent .EPCProgressMain .EPCPMTitle {
        font-size: 18px;
    }

    .EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle .EPCPMPText {
        font-size: 16px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon {
        font-size: 25px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon img {
        width: 30px;
        height: 30px;
    }

    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHTitle {
        font-size: 16px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRTitle {
        font-size: 14px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNIcon {
        font-size: 100px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNTitle {
        font-size: 16px;
    }

    .EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCTitle {
        font-size: 16px;
    }

    .EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBTitle {
        font-size: 18px;
    }

    .EPComponent .EPCVideoContent {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPComponent .EPCVideoContent .EPCVCVideo {
        width: 100%;
    }

    .EPComponent .EPCVideoContent .EPCVCContent {
        width: 100%;
        padding: 0;
    }
    .EPComponent .EPCRCScore .EPCRCSBox {
        width: 33.33%;
    }

    .EPBTabSections .EPComponent .EPCRCScore {
        flex-wrap: wrap;
    }
    .EPBTabSections .EPComponent .EPCListing {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        grid-template-columns: unset;
        grid-gap: unset;
        row-gap: 15px;
        column-gap: 15px;
    }

    .EPComponent .EPCListing .EPCLCol {
        width: calc(50% - 7.5px);
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .EPComponent .EPCRow .EPCRCol2 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol4 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol6 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol8 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol5 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol25 {
        width: 100%;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCTitle {
        font-size: 22px;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCDes {
        font-size: 16px;
        line-height: 24px;
    }

    .EPComponent .EPCProgressMain .EPCPMTitle {
        font-size: 18px;
    }

    .EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle .EPCPMPText {
        font-size: 16px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon {
        font-size: 25px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon img {
        width: 30px;
        height: 30px;
    }

    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHTitle {
        font-size: 16px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRTitle {
        font-size: 14px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNIcon {
        font-size: 100px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNTitle {
        font-size: 16px;
    }

    .EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCTitle {
        font-size: 16px;
    }

    .EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBTitle {
        font-size: 18px;
    }

    .EPComponent .EPCVideoContent {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPComponent .EPCVideoContent .EPCVCVideo {
        width: 100%;
    }

    .EPComponent .EPCVideoContent .EPCVCContent {
        width: 100%;
        padding: 0;
    }
    .EPComponent .EPCRCScore .EPCRCSBox {
        width: 33.33%;
    }

    .EPBTabSections .EPComponent .EPCRCScore {
        flex-wrap: wrap;
    }
    .EPBTabSections .EPComponent .EPCListing {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        grid-template-columns: unset;
        grid-gap: unset;
        row-gap: 15px;
        column-gap: 15px;
    }

    .EPComponent .EPCListing .EPCLCol {
        width: 100%;
    }

    .EPCTable {
        width: 100%;
        overflow: auto;
    }

    .EPCTable .EPCTInner {
        width: 1100px;
    }
    .EPSIBody .EPSIBTab .EPSIBTIcon {
        font-size: 18px;
    }

    .ExternalPage .ExternalPageBody .EPBInner.EPSMinimize .EPBTabSections {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCChart {
        display: flex;
        justify-content: center;
    }

    .EPComponent .EPCHead .EPCHSTitle {
        font-size: 14px;
    }

    .EPCWebType .EPCWTBView .EPCWTBVDesk .EPCWTBVDeskView {
        width: 290px;
        height: 160px;
    }

    .EPCWebType .EPCWTBView {
        padding: 0;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .EPComponent .EPCRow .EPCRCol2 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol4 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol6 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol8 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol5 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol25 {
        width: 100%;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCTitle {
        font-size: 22px;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCDes {
        font-size: 16px;
        line-height: 24px;
    }

    .EPComponent .EPCProgressMain .EPCPMTitle {
        font-size: 18px;
    }

    .EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle .EPCPMPText {
        font-size: 16px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon {
        font-size: 25px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon img {
        width: 30px;
        height: 30px;
    }

    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHTitle {
        font-size: 16px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRTitle {
        font-size: 14px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNIcon {
        font-size: 100px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNTitle {
        font-size: 16px;
    }

    .EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCTitle {
        font-size: 16px;
    }

    .EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBTitle {
        font-size: 18px;
    }

    .EPComponent .EPCVideoContent {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPComponent .EPCVideoContent .EPCVCVideo {
        width: 100%;
    }

    .EPComponent .EPCVideoContent .EPCVCContent {
        width: 100%;
        padding: 0;
    }
    .EPComponent .EPCRCScore .EPCRCSBox {
        width: 33.33%;
    }

    .EPBTabSections .EPComponent .EPCRCScore {
        flex-wrap: wrap;
    }
    .EPBTabSections .EPComponent .EPCListing {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        grid-template-columns: unset;
        grid-gap: unset;
        row-gap: 15px;
        column-gap: 15px;
    }

    .EPComponent .EPCListing .EPCLCol {
        width: 100%;
    }

    .EPComponent .EPCUserComt .EPCUCInner {
        flex-wrap: wrap;
    }

    .EPCUserComt .EPCUCInner .EPCUCInfo {
        max-width: 100%;
        width: 100%;
    }

    .EPCUserComt .EPCUCInner .EPCUCBtn {
        width: 100%;
    }

    .EPCTable {
        width: 100%;
        overflow: auto;
    }

    .EPCTable .EPCTInner {
        width: 1100px;
    }

    .EPComponent .EPCRCBType .EPCRCBTBTList {
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 15px;
    }

    .EPComponent .EPCRCBType .EPCRCBTBTank {
        width: calc(50% - 7.5px);
    }

    .EPCTable {
        width: 100%;
        overflow: auto;
    }

    .EPCTable .EPCTInner {
        width: 1100px;
    }

    .EPSIBody .EPSIBTab .EPSIBTIcon {
        font-size: 18px;
    }
    .ExternalPage .ExternalPageBody .EPBInner.EPSMinimize .EPBTabSections {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCChart {
        display: flex;
        justify-content: center;
    }

    .EPComponent .EPCHead .EPCHSTitle {
        font-size: 14px;
    }

    .EPCWebType .EPCWTBView .EPCWTBVDesk .EPCWTBVDeskView {
        width: 290px;
        height: 160px;
    }

    .EPCWebType .EPCWTBView {
        padding: 0;
    }
    .ExternalPage .ExternalPageBody .EPBInner .EPBTabSections {
        width: 100%;
    }

    .ExternalPage .ExternalPageBody .EPBInner .EPSidebar {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform: translateX(-100%);
        z-index: 9;
    }

    .ExternalPage .ExternalPageBody .EPBInner.EPSMSMM .EPSidebar {
        transform: translateX(0);
    }
}
@media (max-width: 575.98px) {
    .EPComponent .EPCRow .EPCRCol2 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol4 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol6 {
        width: 100%;
    }

    .EPComponent .EPCRow .EPCRCol8 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol5 {
        width: 100%;
    }
    .EPComponent .EPCRow .EPCRCol25 {
        width: 100%;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCTitle {
        font-size: 20px;
    }
    .EPComponent .EPCVideoContent .EPCVCContent .EPCVCCDes {
        font-size: 14px;
        line-height: 24px;
    }

    .EPComponent .EPCPageInfo .EPCPIBody .EPCPIBRow .EPCPIBRCol .EPCPIBRCTitle {
        font-size: 14px;
    }

    .EPComponent .EPCPageInfo .EPCPIHead .EPCPIHTitleBox .EPCPIHTBTitle {
        font-size: 18px;
    }

    .EPComponent .EPCPageInfo .EPCPIHead {
        align-items: center;
    }

    .EPCWebType .EPCWTBView .EPCWTBVSummery {
        width: 100%;
    }

    .EPCWebType .EPCWTBView .EPCWTBVMob {
        width: 100%;
    }

    .EPCWebType .EPCWTBView {
        flex-direction: column;
        row-gap: 15px;
        padding: 0;
    }

    .EPComponent .EPCRow .EPCRCChart {
        display: flex;
        justify-content: center;
    }

    .EPCIssueRow .EPCIRHead .EPCIRTitleDate {
        flex-wrap: wrap;
    }

    .EPCIssueRow .EPCIRHead .EPCIRTitleDate .EPCIRTDTitle {
        font-size: 16px;
        width: 100%;
    }

    .EPCIssueRow .EPCIRHead .EPCIRTitleDate .EPCIRTDTime {
        width: calc(100% - 34px);
    }

    .EPCWebType .EPCWTBView.EPCWTBDesktopView {
        height: 100%;
    }

    .EPCWebType .EPCWTBView .EPCWTBVDesk {
        width: 100%;
    }

    .EPCWebType .EPCWTBView .EPCWTBVDesk .EPCWTBVDeskView {
        width: 260px;
        height: 150px;
    }

    .EPComponent .EPCRow .EPCRCol3 {
        width: 100%;
        margin-bottom: 0;
    }

    .EPCIssueRow .EPCIRBRow {
        flex-direction: column;
    }

    .EPCIssueRow .EPCIRBHead.EPCIRBRow {
        display: none;
    }

    .EPCIssueRow .EPCIRBRow .EPCIRBRCol:nth-child(1),
    .EPCIssueRow .EPCIRBRow .EPCIRBRCol:nth-child(2),
    .EPCIssueRow .EPCIRBRow .EPCIRBRCol:nth-child(3) {
        width: 100%;
    }

    .EPCIssueRow .EPCIRBRow .EPCIRBRCol {
        word-break: break-word;
    }

    .EPCIssueRow .EPCIRBRow .EPCIRBRCol:nth-child(2n + 1) {
        background-color: #f4f6fa;
    }

    .EPComponent .EPCProgressMain .EPCPMTitle {
        font-size: 18px;
    }

    .EPComponent .EPCProgressMain .EPCPMProgress .EPCPMPTitle .EPCPMPText {
        font-size: 16px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon {
        font-size: 25px;
    }
    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHIcon img {
        width: 30px;
        height: 30px;
    }

    .EPCListing .EPCLCol .EPCLCHead .EPCLCHTitleBox .EPCLCHTitle {
        font-size: 16px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBRow .EPCLCBRTitle {
        font-size: 14px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNIcon {
        font-size: 100px;
    }

    .EPCListing .EPCLCol .EPCLCBody .EPCLCBNo .EPCLCBNTitle {
        font-size: 16px;
    }

    .EPComponent .EPCReviews .EPCReviewBox .EPCRBBody .EPCRBBCol .EPCRBBCTitle {
        font-size: 16px;
    }

    .EPComponent .EPCRCScore .EPCRCSBox .EPCRCSBTitle {
        font-size: 18px;
    }

    .EPComponent .EPCVideoContent {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPComponent .EPCVideoContent .EPCVCVideo {
        width: 100%;
        height: 180px;
    }

    .EPComponent .EPCVideoContent .EPCVCContent {
        width: 100%;
        padding: 0;
    }
    .EPBTabSections .EPComponent .EPCRCScore {
        flex-wrap: wrap;
        row-gap: 10px;
    }
    .EPComponent .EPCRCScore .EPCRCSBox {
        width: calc(50% - 10px);
    }

    .EPBTabSections .EPComponent .EPCListing {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        grid-template-columns: unset;
        grid-gap: unset;
        row-gap: 15px;
        column-gap: 15px;
    }

    .EPComponent .EPCListing .EPCLCol {
        width: 100%;
    }

    .EPComponent .EPCUserComt .EPCUCInner {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPCUserComt .EPCUCInner .EPCUCInfo {
        max-width: 100%;
        width: 100%;
        align-items: flex-start;
    }

    .EPCUserComt .EPCUCInner .EPCUCBtn {
        width: 100%;
    }

    .EPCUserComt .EPCUCInner .EPCUCInfo .EPCUCICmt {
        font-size: 14px;
    }

    .EPCUserComt .EPCUCInner .EPCUCBtn button.mainButton {
        width: 100%;
    }

    .EPCTable {
        width: 100%;
        overflow: auto;
    }

    .EPCTable .EPCTInner {
        width: 1100px;
    }

    .EPComponent .EPCRCBType .EPCRCBTBTList {
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 15px;
    }

    .EPComponent .EPCRCBType .EPCRCBTBTank {
        width: calc(50% - 7.5px);
    }

    .EPComponent .EPCRCDetail .EPCRCDRow {
        flex-wrap: wrap;
    }

    .EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRLabel {
        width: 100%;
        font-size: 16px;
    }

    .EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRTitle {
        width: 100%;
        text-align: left;
        font-size: 14px;
    }

    .EPBTabSections .EPComponent .EPCHead {
        flex-direction: column;
        row-gap: 15px;
    }

    .EPComponent .EPCHead .EPCHSTitle {
        width: 100%;
    }

    .EPComponent .EPCHead .EPCHTBox {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPComponent .EPCHead .EPCHTBox .EPCHTBSTitle {
        width: 100%;
        padding: 0;
    }

    .EPComponent .EPCHead .EPCHTBox .EPCHTBTitle {
        flex: 1;
    }

    .EPComponent .EPCInnerHeading {
        flex-direction: column;
        row-gap: 15px;
    }

    .EPComponent .EPCInnerHeading .EPCIHLTitle,
    .EPComponent .EPCInnerHeading .EPCIHRTitle {
        width: 100%;
        flex: unset;
        text-align: center;
    }

    .EPComponent .EPCReviews {
        grid-template-columns: repeat(auto-fill, minMax(100%, 1fr));
    }

    .EPComponent .EPCRComment .EPCRCBox .EPCRCBHead {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPComponent .EPCRComment .EPCRCBox .EPCRCBHead .EPCRCBUser {
        flex: unset;
        width: 100%;
    }

    .EPComponent .EPCReviews.EPCReviews3 {
        grid-template-columns: repeat(auto-fill, minMax(100%, 1fr));
    }

    .InstaReportNoData {
        padding: 10px;
    }

    .InstaReportNoData .IRNDIcon {
        font-size: 80px;
    }

    .InstaReportNoData .IRNDTitle {
        font-size: 18px;
        padding: 0;
    }

    .InstaReportNoData .IRNDDescription {
        font-size: 14px;
    }

    .EPComponent .EPCRow .EPCRCol25:last-child .EPCRTSIH {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .EPComponent .EPCRow .EPCRCol25:last-child .EPCRTSIH .EPCRTSIHInfo {
        padding: 0;
    }
    .ExternalPage .ExternalPageBody .EPBInner.EPSMinimize .EPBTabSections {
        width: 100%;
    }
    .EPSIBody .EPSIBTab .EPSIBTIcon {
        font-size: 18px;
    }

    .MainDialog.centerZoom.sizeSm .MDContainer .MDCRow.GetInTouchRow {
        margin: 0;
        max-height: 100%;
        height: 100%;
    }

    .MainDialog.centerZoom.sizeSm .MDContainer .MDCRow.HowToDialogRow {
        max-width: 100%;
        margin: 0;
    }

    .MainDialog.centerZoom.sizeSm .MDContainer .MDCRow.ReviewDialogRow {
        max-width: 100%;
        margin: 0;
    }

    .ReviewDialog .RDInfoBox .RDIHead {
        flex-wrap: wrap;
        flex-direction: column;
        row-gap: 15px;
    }

    .ReviewDialog .RDInfoBox .RDIHead .RDIHUser {
        width: 100%;
    }

    .ReviewDialog .RDInfoBox .RDIHead .RDIHReview {
        width: 100%;
    }
    .ExternalPage .ExternalPageBody .EPBInner .EPBTabSections {
        width: 100%;
    }

    .ExternalPage .ExternalPageBody .EPBInner .EPSidebar {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform: translateX(-100%);
        z-index: 9;
    }

    .ExternalPage .ExternalPageBody .EPBInner.EPSMSMM .EPSidebar {
        transform: translateX(0);
    }

    .ExternalPageHeaderBar .EPHBTitle {
        width: auto;
        column-gap: 15px;
    }

    .ExternalPageHeaderBar .EPHBTitle button.iconButton {
        order: -1;
        margin: 0px;
    }

    .EPComponent .EPCASLA .EPCASCHead {
        padding: 20px 0;
    }

    .EPComponent .EPCASLA .EPCASCHead .EPCASCHTitle {
        font-size: 18px;
    }

    .EPComponent .EPCASLA .EPCASCHead .EPCASCHNum {
        font-size: 38px;
    }
}

// Print
@media print {
    .EPComponentMain {
        break-inside: avoid;
    }
    .EPComponent .EPCRow .EPCRCol2 {
        width: 100% !important;
    }
    .EPComponent .EPCRCDetail .EPCRCDRow {
        flex-wrap: nowrap !important;
    }
    .EPComponent .EPCRCDetail .EPCRCDRow .EPCRCDRLabel {
        width: auto !important;
    }
    .EPCVCCBtn button,
    .EPCVideoContent .EPCVCVideo,
    .EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-slider ul.slick-dots,
    .EPComponent .EPCRComment .EPCRCBox .EPCRCBHead button.iconButton,
    .EPComponent .EPCBody .EPCViewMore button.mainButton,
    .EPComponent .EPCBody .EPCRow .EPCWTBody .EPCWTBFBViewMore button.mainButton,
    .EPComponent .EPCBody .EPCRow .EPCWTBFBody .EPCIRTDArrow,
    .EPComponent .EPCBody .EPCUserComt .EPCUCBtn button.mainButton,
    div[data-check='false'] {
        display: none !important;
    }
    .EPComponent .EPCRComment .EPCRCBox .EPCRCBHead {
        display: flex !important;
        flex-wrap: nowrap !important;
        align-items: flex-start !important;
    }
    .EPComponent .EPCRCScore,
    .EPComponent .EPCRComment,
    .EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-track {
        display: flex !important;
        flex-wrap: wrap !important;
    }
    .EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-track .slick-slide {
        width: 33.33% !important;
        break-inside: avoid;
        page-break-before: always;
    }
    .EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-track .slick-slide.slick-cloned {
        display: none;
    }
    .EPCRCBPhotos .EPCRCBPBody .slick-slider .slick-slide > div {
        display: inline !important;
    }
    .EPComponent .EPCRCBPhotos .EPCRCBPBody .slick-track {
        display: inline !important;
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 15px;
        column-gap: 15px;
    }
    .EPCRCBPhotos .EPCRCBPBody .slick-slider .slick-slide .EPCRCBPBSlide {
        margin: 7.5px !important;
    }
    .EPCRCBPhotos .EPCRCBPBody .slick-slider .slick-slide .EPCRCBPBSlide img {
        height: 200px;
        object-position: top !important;
    }
    .EPComponent .EPCRComment .EPCRCBox {
        width: calc(50% - 5px) !important;
    }
    .EPComponent .EPCRCScore .EPCRCSBox {
        width: calc(33% - 12px) !important;
        height: 150px !important;
    }
    .EPComponent .EPCRCol2 .EPCRCChart .highcharts-container {
        height: 100% !important;
    }
    .EPCRCBPBody .slick-list {
        overflow: visible !important;
    }
    .ExternalPageBody .EPBTSInner #TabID1,
    .EPBTabSections .EPComponent .EPCUserComt {
        margin-top: 20px !important;
    }
    .TOFTPWText {
        text-overflow: initial;
        white-space: normal;
    }
    .EPBTabSections .EPComponent .EPCRow,
    .EPBTabSections .EPComponent .EPCListing {
        display: flex !important;
        flex-wrap: wrap !important;
    }
    .EPComponent .EPCRow .EPCRCol25,
    .EPComponent .EPCRow .EPCRCol3 {
        width: 50%;
        flex: 2;
        grid-row: 2;
        display: inline-flex;
    }
    .EPComponent .EPCReviews {
        display: flex;
        flex-wrap: wrap;
    }
    .EPComponent .EPCReviews .EPCReviewBox {
        width: 50%;
        flex: 2;
        grid-row: 2;
    }
    .EPComponentMain .EPComponent .EPCListing .EPCLCol {
        width: 50%;
        flex: 2;
        grid-row: 2;
        display: inline-flex;
        flex-direction: column;
    }
    .EPComponentMain {
        padding-top: 20px !important;
    }
    .EPBTabSections .EPComponent .EPCHead {
        text-align: center;
    }
    .EPComponent .EPCInnerHeading {
        padding-top: 20px !important;
    }
    #TabID1 {
        break-inside: avoid;
        page-break-before: always;
    }
    #TabID2 .EPCBody .EPCUserComt {
        margin: 20px 0px 0px;
    }
    #TabID5 .EPCBody .EPCRCol5 .EPCWebType .EPCWTBFix ~ .EPCWTBFix {
        page-break-before: always;
    }
    #TabID5 .EPCBody .EPCRCol5 .EPCWebType .EPCWTBFix ~ .EPCWTBFix ~ .EPCWTBFix {
        page-break-before: avoid;
        page-break-after: always;
    }
    #TabID5 .EPCBody .EPCRCol5 ~ .EPCRCol5 .EPCWebType .EPCWTBFix ~ .EPCWTBFix {
        page-break-before: avoid;
        page-break-after: avoid;
        margin-bottom: 30px;
    }
    #TabID6 .EPCBody .EPCInnerHeading ~ .EPCInnerHeading ~ .EPCInnerHeading {
        break-inside: avoid;
        page-break-before: always;
    }
    img,
    .EPCIssueRow,
    .EPCWTBFix .EPCIssueRow .EPCIRHead .EPCIRDes,
    .EPCIssueRow .EPCIRHead .EPCIRTitleDate .EPCIRTDTitle {
        break-inside: avoid;
        page-break-inside: avoid;
    }
    #TabID6 .EPComponent .EPCRCBType .EPCRCBTBTList {
        display: flex !important;
        flex-wrap: initial !important;
    }
    .EPComponent .EPCRCBType {
        margin-top: 30px !important;
    }
    .EPCTable {
        overflow: visible;
    }
    .EPComponent .EPCHead .EPCHSTitle {
        display: none !important;
    }
    @-moz-document url-prefix() {
        #TabID5 .EPCBody .EPCRCol5 ~ .EPCRCol5 {
            margin-top: 280px;
        }
        #TabID5 .EPCBody .EPCUserComt {
            margin-top: 30px;
        }
    }
    @page {
        margin: 0.5cm;
    }
}
@media only screen and (max-width: 768.98px) {
    /* mobile portrait */

    .ExternalPageHeaderBar .EPHBOption .BlackOutlineBtn {
     margin:auto 4px;
    }
}
