.STemplateView {
    border: 1px solid #e4edff;
    border-radius: 4px;
    cursor: pointer;
    transition: all ease 0.3s;
    position: relative;
    overflow: hidden;
}

.STemplateView.activeTemplate {
    border-color: var(--dark-blue);
}

.STemplateView .STemplateViewInner {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
}

.STemplateView .STemplateViewInner .STVIImage {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
}

.STemplateView .STemplateViewInner .STVIImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.STemplateView .STemplateViewInner .STVITitle {
    padding: 7px;
    text-align: center;
}
