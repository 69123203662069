.FormPresenterDialog.FPDUserInfo {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.FormPresenterDialog.FPDUserInfo .DCFormBuilder {
    flex: 1 1;
    overflow: auto;
    position: relative;
}

.FormPresenterDialog.FPDUserInfo .DCFormBuilder .DCFBInner {
    padding: 15px;
    // align-items: center;
    display: flex;
    flex-direction: column;
    background-color: var(--user-form-page-background);
}

.DCFBInner .DCFBInnerHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2px 2px 6px 6px;
}

.FormPresenterDialog.FPDUserInfo .FPDThanks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.FormPresenterDialog.FPDUserInfo .FPDThanks .FPDTInner {
    text-align: center;
}

.FormPresenterDialog.FPDUserInfo .FPDThanks .FPDTInner .FPDTIcon {
    display: flex;
    justify-content: center;
    font-size: 200px;
}

.FormPresenterDialog.FPDUserInfo .FPDThanks .FPDTInner .FPDTTitle {
    font-size: 24px;
    font-weight: 600;
}

.FormPresenterDialog.FPDUserInfo .FPDThanks .FPDTInner .FPDTSTitle {
    font-size: 16px;
    font-weight: 500;
}

.FormPresenterDialog.FPDUserInfo .DCFormBuilder .DCFBInner .DCFBVew {
    width: var(--user-form-width);
    background-color: var(--user-form-background);
    border-style: solid;
    border-width: var(--user-form-border-width);
    border-color: var(--user-form-border-color);
    border-radius: var(--user-form-border-radius);
    min-width: 375px;
    padding: 30px;
    margin: 0px auto;
}

.DCFBInnerHeaderText {
    padding-right: 6px;
}

.FormPresenterDialog.FPDUserInfo .DCFormBuilder .DCFBInner .DCFBVew .form-group .col-form-label,
.FormPresenterDialog.FPDUserInfo
    .DCFormBuilder
    .DCFBInner
    .DCFBVew
    .form-group
    label[for='day-month'],
.FormPresenterDialog.FPDUserInfo
    .DCFormBuilder
    .DCFBInner
    .DCFBVew
    .form-group
    label[for='day-day'],
.FormPresenterDialog.FPDUserInfo
    .DCFormBuilder
    .DCFBInner
    .DCFBVew
    .form-group
    label[for='day-year'],
.FormPresenterDialog.FPDUserInfo .DCFormBuilder .DCFBInner .DCFBVew .form-group .form-check-label {
    color: var(--user-form-label-font-color);
}

.FormPresenterDialog.FPDUserInfo .DCFormBuilder .DCFBInner .DCFBVew .form-group .btn {
    color: var(--user-form-button-font-color);
    background-color: var(--user-form-button-background-color);
    border-color: var(--user-form-button-background-color);
}
