.PaymentProcess {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    background-color: #fff;

    .center {
        margin: 0 auto;
        text-align: center;
    }
    #processing {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background-color: #4e54c8;
        overflow: hidden;
        .processing-circles {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            div {
                position: absolute;
                display: block;
                list-style: none;
                width: 20px;
                height: 20px;
                background: rgba(255, 255, 255, 0.6);
                animation: animate-circles 25s linear infinite;
                bottom: -150px;
                &:nth-child(1) {
                    left: 25%;
                    width: 80px;
                    height: 80px;
                    animation-delay: 0s;
                }
                &:nth-child(2) {
                    left: 10%;
                    width: 20px;
                    height: 20px;
                    animation-delay: 2s;
                    animation-duration: 12s;
                }
                &:nth-child(3) {
                    left: 70%;
                    width: 20px;
                    height: 20px;
                    animation-delay: 4s;
                }
                &:nth-child(4) {
                    left: 40%;
                    width: 60px;
                    height: 60px;
                    animation-delay: 0s;
                    animation-duration: 18s;
                }
                &:nth-child(5) {
                    left: 65%;
                    width: 20px;
                    height: 20px;
                    animation-delay: 0s;
                }
                &:nth-child(6) {
                    left: 75%;
                    width: 110px;
                    height: 110px;
                    animation-delay: 3s;
                }
                &:nth-child(7) {
                    left: 35%;
                    width: 150px;
                    height: 150px;
                    animation-delay: 7s;
                }
                &:nth-child(8) {
                    left: 50%;
                    width: 25px;
                    height: 25px;
                    animation-delay: 15s;
                    animation-duration: 45s;
                }
                &:nth-child(9) {
                    left: 20%;
                    width: 15px;
                    height: 15px;
                    animation-delay: 2s;
                    animation-duration: 35s;
                }
                &:nth-child(10) {
                    left: 85%;
                    width: 150px;
                    height: 150px;
                    animation-delay: 0s;
                    animation-duration: 11s;
                }
            }
        }
    }

    #processing.uncomplete .gear-wrapper-1 {
        -webkit-animation: gearEnter1 0.5s ease 1s forwards;
        animation: gearEnter1 0.5s ease 1s forwards;
    }
    #processing.uncomplete .gear-wrapper-2 {
        -webkit-animation: gearEnter2 0.5s ease 1.5s forwards;
        animation: gearEnter2 0.5s ease 1.5s forwards;
    }
    #processing.uncomplete .gear-wrapper-3 {
        -webkit-animation: gearEnter3 0.5s ease 1.25s forwards;
        animation: gearEnter3 0.5s ease 1.25s forwards;
    }
    #processing.uncomplete .gear-wrapper-4 {
        -webkit-animation: gearEnter4 0.5s ease 0.75s forwards;
        animation: gearEnter4 0.5s ease 0.75s forwards;
    }
    #processing.complete .gear-wrapper-1 {
        transform: rotate(10deg) translate3d(-5px, -5px, 0);
        -webkit-animation: gearLeave1 0.5s ease 0.5s forwards;
        animation: gearLeave1 0.5s ease 0.5s forwards;
    }
    #processing.complete .gear-wrapper-2 {
        transform: rotate(25deg) translate3d(20px, -50px, 0);
        -webkit-animation: gearLeave2 0.5s ease 0.5s forwards;
        animation: gearLeave2 0.5s ease 0.5s forwards;
    }
    #processing.complete .gear-wrapper-3 {
        transform: rotate(15deg) translate3d(-25px, -15px, 0);
        -webkit-animation: gearLeave3 0.5s ease 0.5s forwards;
        animation: gearLeave3 0.5s ease 0.5s forwards;
    }
    #processing.complete .gear-wrapper-4 {
        transform: translate3d(0, 0, 0);
        -webkit-animation: gearLeave4 0.5s ease 0.5s forwards;
        animation: gearLeave4 0.5s ease 0.5s forwards;
    }
    #processing.complete .loading-bar {
        -webkit-animation: hideLoading 0.5s forwards;
        animation: hideLoading 0.5s forwards;
    }
    #processing.complete .checkmark.checkmark-success {
        -webkit-animation: fillCheckSuccess 0.4s cubic-bezier(0.65, 0, 0.45, 1) 1s forwards,
            beatCheck 0.2s ease-out 1.5s forwards, echoSuccess 1.25s ease-out 1.5s forwards;
        animation: fillCheckSuccess 0.4s cubic-bezier(0.65, 0, 0.45, 1) 1s forwards,
            beatCheck 0.2s ease-out 1.5s forwards, echoSuccess 1.25s ease-out 1.5s forwards;
        display: block;
    }
    #processing.complete .checkmark.checkmark-success .checkmark-circle {
        stroke: #3c763d;
    }
    #processing.complete .checkmark.checkmark-error {
        -webkit-animation: fillCheckError 0.4s cubic-bezier(0.65, 0, 0.45, 1) 1s forwards,
            beatCheck 0.2s ease-out 1.5s forwards, echoError 1.25s ease-out 1.5s forwards;
        animation: fillCheckError 0.4s cubic-bezier(0.65, 0, 0.45, 1) 1s forwards,
            beatCheck 0.2s ease-out 1.5s forwards, echoError 1.25s ease-out 1.5s forwards;
    }
    #processing.complete .checkmark.checkmark-error .checkmark-circle {
        stroke: #a94442;
    }
    #processing.complete .checkmark-circle {
        -webkit-animation: strokeCheck 0.5s cubic-bezier(0.65, 0, 0.45, 1) 0.75s forwards;
        animation: strokeCheck 0.5s cubic-bezier(0.65, 0, 0.45, 1) 0.75s forwards;
    }
    #processing.complete .checkmark-check {
        -webkit-animation: strokeCheck 0.3s cubic-bezier(0.65, 0, 0.45, 1) 1.25s forwards;
        animation: strokeCheck 0.3s cubic-bezier(0.65, 0, 0.45, 1) 1.25s forwards;
    }
    .headings {
        position: relative;
        z-index: 1;
        h1 {
            font-size: 68px;
            font-weight: 900;
            background: #eff1ff;
            background: radial-gradient(ellipse at center, #f6f8fc 10%, #888cda 100%);
            background-size: 54% 156%;
            background-position: center center;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 20px;
            text-transform: capitalize;

            @media screen and (max-width: 500px) {
                font-size: 58px;
            }
            @media screen and (max-width: 420px) {
                font-size: 46px;
            }
        }
        h2 {
            font-size: 38px;
            font-weight: 500;
            background: #eff1ff;
            background: radial-gradient(ellipse at center, #f6f8fc 10%, #888cda 100%);
            background-size: 54% 156%;
            background-position: center center;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .wrapper-des {
        margin-top: 30px;
        color: #f0f3fa;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    @-webkit-keyframes scale {
        to {
            transform: scale(1);
        }
    }
    @keyframes scale {
        to {
            transform: scale(1);
        }
    }
    .gear-wrapper {
        position: absolute;
    }
    .gear-wrapper.gear-wrapper-1 {
        top: -25px;
        left: -25px;
        transform: rotate(10deg) translate3d(-40px, -60px, 0);
    }
    .gear-wrapper.gear-wrapper-2 {
        top: -15px;
        right: -15px;
        transform: rotate(25deg) translate3d(70px, -130px, 0);
    }
    .gear-wrapper.gear-wrapper-3 {
        bottom: -45px;
        right: -45px;
        transform: rotate(15deg) translate3d(30px, 20px, 0);
    }
    .gear-wrapper.gear-wrapper-4 {
        bottom: -40px;
        left: -40px;
        transform: translate3d(-70px, 70px, 0);
    }
    @-webkit-keyframes gearEnter1 {
        to {
            transform: rotate(10deg) translate3d(-5px, -5px, 0);
        }
    }
    @keyframes gearEnter1 {
        to {
            transform: rotate(10deg) translate3d(-5px, -5px, 0);
        }
    }
    @-webkit-keyframes gearEnter2 {
        to {
            transform: rotate(25deg) translate3d(20px, -50px, 0);
        }
    }
    @keyframes gearEnter2 {
        to {
            transform: rotate(25deg) translate3d(20px, -50px, 0);
        }
    }
    @-webkit-keyframes gearEnter3 {
        to {
            transform: rotate(15deg) translate3d(-25px, -15px, 0);
        }
    }
    @keyframes gearEnter3 {
        to {
            transform: rotate(15deg) translate3d(-25px, -15px, 0);
        }
    }
    @-webkit-keyframes gearEnter4 {
        to {
            transform: translate3d(0, 0, 0);
        }
    }
    @keyframes gearEnter4 {
        to {
            transform: translate3d(0, 0, 0);
        }
    }
    @-webkit-keyframes gearLeave1 {
        from {
            transform: rotate(10deg) translate3d(-5px, -5px, 0);
        }
        to {
            transform: rotate(10deg) translate3d(-40px, -60px, 0);
        }
    }
    @keyframes gearLeave1 {
        from {
            transform: rotate(10deg) translate3d(-5px, -5px, 0);
        }
        to {
            transform: rotate(10deg) translate3d(-40px, -60px, 0);
        }
    }
    @-webkit-keyframes gearLeave2 {
        from {
            transform: rotate(25deg) translate3d(20px, -50px, 0);
        }
        to {
            transform: rotate(25deg) translate3d(70px, -130px, 0);
        }
    }
    @keyframes gearLeave2 {
        from {
            transform: rotate(25deg) translate3d(20px, -50px, 0);
        }
        to {
            transform: rotate(25deg) translate3d(70px, -130px, 0);
        }
    }
    @-webkit-keyframes gearLeave3 {
        from {
            transform: rotate(15deg) translate3d(-25px, -15px, 0);
        }
        to {
            transform: rotate(15deg) translate3d(30px, 20px, 0);
        }
    }
    @keyframes gearLeave3 {
        from {
            transform: rotate(15deg) translate3d(-25px, -15px, 0);
        }
        to {
            transform: rotate(15deg) translate3d(30px, 20px, 0);
        }
    }
    @-webkit-keyframes gearLeave4 {
        from {
            transform: translate3d(0, 0, 0);
        }
        to {
            transform: translate3d(-70px, 70px, 0);
        }
    }
    @keyframes gearLeave4 {
        from {
            transform: translate3d(0, 0, 0);
        }
        to {
            transform: translate3d(-70px, 70px, 0);
        }
    }
    .gear {
        fill: #fff;
        transform-origin: 50% 50%;
    }
    .gear.gear-1 {
        width: 90px;
        height: 90px;
        animation: gearRotate 1s linear 2s infinite reverse;
    }
    .gear.gear-2 {
        width: 150px;
        height: 150px;
        -webkit-animation: gearRotate 1.5s linear 2s infinite;
        animation: gearRotate 1.5s linear 2s infinite;
    }
    .gear.gear-3 {
        width: 60px;
        height: 60px;
        animation: gearRotate 0.75s linear 2s infinite reverse;
    }
    .gear.gear-4 {
        width: 120px;
        height: 110px;
        -webkit-animation: gearRotate 1.25s linear 2s infinite;
        animation: gearRotate 1.25s linear 2s infinite;
    }
    @-webkit-keyframes gearRotate {
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes gearRotate {
        to {
            transform: rotate(360deg);
        }
    }

    .loading-bar {
        position: relative;
        max-width: 450px;
        width: 100%;
        height: 25px;
        background-color: #f6f8fc;
        border-radius: 10px;
        overflow: hidden;
    }

    .loading-bar span {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #000;
        transition: width 0.5s ease;
    }

    @-webkit-keyframes hideLoading {
        to {
            height: 0;
            margin: 0;
            opacity: 0;
        }
    }
    @keyframes hideLoading {
        to {
            height: 0;
            margin: 0;
            opacity: 0;
        }
    }
    .checkmark {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        stroke-width: 1px;
        stroke: #fff;
        stroke-miterlimit: 10px;
        display: none;
    }
    .checkmark-circle {
        fill: none;
        stroke-dasharray: 200px;
        stroke-dashoffset: 200px;
        stroke-width: 1px;
        stroke-miterlimit: 10px;
    }
    @-webkit-keyframes fillCheckSuccess {
        to {
            box-shadow: inset 0 0 0 100px #3c763d;
        }
    }
    @keyframes fillCheckSuccess {
        to {
            box-shadow: inset 0 0 0 100px #3c763d;
        }
    }
    @-webkit-keyframes fillCheckError {
        to {
            box-shadow: inset 0 0 0 100px #a94442;
        }
    }
    @keyframes fillCheckError {
        to {
            box-shadow: inset 0 0 0 100px #a94442;
        }
    }
    @-webkit-keyframes beatCheck {
        0%,
        100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
    }
    @keyframes beatCheck {
        0%,
        100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
    }
    @-webkit-keyframes echoSuccess {
        from {
            box-shadow: inset 0 0 0 100px #3c763d, 0 0 0 0 #3c763d;
        }
        to {
            box-shadow: inset 0 0 0 100px #3c763d, 0 0 0 50px transparent;
        }
    }
    @keyframes echoSuccess {
        from {
            box-shadow: inset 0 0 0 100px #3c763d, 0 0 0 0 #3c763d;
        }
        to {
            box-shadow: inset 0 0 0 100px #3c763d, 0 0 0 50px transparent;
        }
    }
    @-webkit-keyframes echoError {
        from {
            box-shadow: inset 0 0 0 100px #a94442, 0 0 0 0 #a94442;
        }
        to {
            box-shadow: inset 0 0 0 100px #a94442, 0 0 0 50px transparent;
        }
    }
    @keyframes echoError {
        from {
            box-shadow: inset 0 0 0 100px #a94442, 0 0 0 0 #a94442;
        }
        to {
            box-shadow: inset 0 0 0 100px #a94442, 0 0 0 50px transparent;
        }
    }
    .checkmark-check {
        stroke: #fff;
        stroke-dasharray: 100px;
        stroke-dashoffset: 100px;
        transform-origin: 50% 50%;
    }
    @-webkit-keyframes strokeCheck {
        to {
            stroke-dashoffset: 0px;
        }
    }
    @keyframes strokeCheck {
        to {
            stroke-dashoffset: 0px;
        }
    }
}

@keyframes animate-circles {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

@media (max-width: 575.98px) {
    .PaymentProcess {
        position: relative;
        width: 100% !important;
        top:0px !important;
        height: 400px !important;
        .headings {
            position: relative;
            z-index: 1;
            h1 {
                font-size: 30px;
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 1023.98px) {
    .PaymentProcess {
        position: relative;
        width: 100% !important;
        top:0px !important;
        height: 100% !important;
        .headings {
            position: relative;
            z-index: 1;
            h1 {
                font-size: 40px;
            }
        }
    }
}