.MainDialog.centerZoom.sizeLg .MDContainer .MDCRow.PCDRow {
    max-height: 750px;
    height: 100%;
}

.PurchaseDialog {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 765px;
    flex: 1;
}

.HCDBIVideoContent .HCDBIVCVideo {
    min-width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.HCDBIVideoContent .HCDBIVCVideo.HCDBIVCVideoAvailable {
    padding-bottom: 56.25%;
}

.PurchaseDialog .topBar {
    padding: 0 0;
}

.PurchaseDialog .PDHIcon {
    display: flex;
    font-size: 30px;
    box-shadow: 0 0 7px #ffffff50;
    border-radius: 10px;
    margin-right: 15px;
}

.PurchaseDialog .PDHLeft {
    display: flex;
    align-items: center;
    width: 64%;
    padding: 0 15px;
    border-right: 1px solid #ffffff20;
    height: 100%;
}

.PurchaseDialog .PDHRight {
    display: flex;
    align-items: center;
    width: 36%;
    padding: 0 15px;
}

.PurchaseDialog .PDHRight .PDHRTitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--white);
    flex: 1;
}

.PurchaseDialog .PDBody {
    flex: 1;
    overflow: auto;
}

.PurchaseDialog .PDBody .PDBInner {
    display: flex;
    height: 100%;
    overflow: overlay;
    flex-direction: row;
}

.PurchaseDialog .PDBody .PDBInner .PDBIVideoContent {
    width: 64%;
    border-right: 1px solid #26262b20;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.PDBIVideoContent .PDBIVCVideo {
    min-width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // flex: 1;
    padding-bottom: 56%;
}

.PDBIVideoContent .PDBIVCVideo .HCDBIVCIcon {
    font-size: 150px;
    display: flex;
}

.PDBIVideoContent .PDBIVCVideo .HCDBIVCTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
}

.PDBIVideoContent .PDBIVCVideo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.PDBIVideoContent .PDBIVCFeatures {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    display: flex;
    flex-wrap: wrap;
    padding: 7.5px;
    border-top: 1px solid #e4e4e4;
}

.PDBIVCFeatures {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    display: flex;
    flex-wrap: wrap;
    padding: 7.5px;
    border-top: 1px solid #e4e4e4;
}

.PurchaseDialog .PDBody .PDBInner .PDBOrderDetail {
    width: 36%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.PurchaseDialog .PDBody .PDBInner .PDBOrderDetail.PDBOrderCongrats {
    width: 100;
    display: flex;
    flex-direction: column;
    // overflow: auto;
    height: 100%;
}

.PDBIVideoContent .PDBIVCFeatures .PDBIVCFBox {
    width: 50%;
    padding: 7.5px;
}

.PDBIVCFBox {
    padding: 7.5px;
}

.PDBIVideoContent .PDBIVCFeatures .PDBIVCFBox .PDBIVCFBTitle {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
}

.PDBIVCFeatures .PDBIVCFBox .PDBIVCFBTitle {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
}

.PDBIVCFBox .PDBIVCFBTitle .PDBIVCFBTIcon {
    font-size: 24px;
    display: flex;
    color: #4caf50;
}

.PDBIVCFBox .PDBIVCFBTitle .PDBIVCFBTText {
    font-size: 18px;
    font-weight: 600;
    padding-left: 10px;
    flex: 1 1;
}

.PDBIVideoContent .PDBIVCFeatures .PDBIVCFBox .PDBIVCFBDes {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.PDBOrderDetail .PDBODPlans .PDBODPHead {
    display: flex;
    background-color: #f6f8fc;
    border-bottom: 1px solid #e4e4e4;
}

.PDBOrderDetail .PDBODPlans .PDBODPHead .PDBODPHCol {
    font-size: 16px;
    font-weight: 600;
    padding: 7px 15px;
}

.PDBOrderDetail .PDBODPlans .PDBODPHead .PDBODPHCol:nth-child(1) {
    width: 50%;
}

.PDBOrderDetail .PDBODPlans .PDBODPHead .PDBODPHCol:nth-child(2) {
    width: 25%;
    text-align: right;
}

.PDBOrderDetail .PDBODPlans .PDBODPHead .PDBODPHCol:nth-child(3) {
    width: 25%;
    text-align: right;
}

.PDBOrderDetail .PDBODPlans .PDBODPRow {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f6f8fc;
}

.PDBOrderDetail .PDBODPlans .PDBODPRow .PDBODPRCol {
    font-size: 14px;
    font-weight: 500;
    padding: 7px 0px;
    color: #666;
}

.PDBOrderDetail .PDBODPlans .PDBODPRow .PDBODPRCol:nth-child(1) {
    width: 50%;
}

.PDBOrderDetail .PDBODPlans .PDBODPRow .PDBODPRCol:nth-child(2) {
    width: 30%;
    text-align: center;
}

.PDBOrderDetail .PDBODPlans .PDBODPRow .PDBODPRCol:nth-child(3) {
    width: 15%;
    text-align: right;
}

.PDBOrderDetail .PDBODPlans .PDBODPRow .PDBODPRPlan {
    width: 50%;
    padding: 7px 1px;
    display: flex;
    align-items: center;
}

.PDBOrderDetail .PDBODPlans .PDBODPRow .PDBODPRPlan .PDBODPRPIcon {
    font-size: 20px;
    padding: 7px;
    background-color: var(--white);
    box-shadow: 0 0 4px #5b5b5b16;
}

.PDBOrderDetail .PDBODPlans .PDBODPRow .PDBODPRPlan .PDBODPRPInfo {
    flex: 1;
    padding-left: 7px;
}

.PDBODPRPlan .PDBODPRPInfo .PDBODPRPITitle {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    line-height: normal;
}

.PDBODPRPlan .PDBODPRPInfo .PDBODPRPISub {
    display: flex;
    align-items: center;
}

.PDBODPRPlan .PDBODPRPInfo .PDBODPRPISub span {
    font-size: 12px;
    font-weight: 500;
    color: #666;
    padding-right: 5px;
}

.PDBOrderDetail .PDBODPreview {
    display: flex;
    border-bottom: 1px solid #e4e4e4;
    flex-direction: column;
    overflow: hidden;
    height: 250px;
    padding: 15px;
}

.PDBOrderDetail.PDBOrderCongrats .PDBODPreview {
    display: flex;
    border-bottom: 1px solid #e4e4e4;
    flex-direction: column;
    overflow: hidden;
    min-height: 250px;
    padding: 15px;
}

.PDBOrderDetail .PDBODPreview img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.PDBOrderDetail .PDBODDes {
    padding: 15px 15px;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    line-height: 24px;
    flex: 1;
}

.PDBOrderDetail .PDBODBtn {
    padding: 15px 15px;
    display: flex;
    border-top: 1px solid #e4e4e4;
    background-color: #f6f8fc;
}

.PDBOrderDetail .PDBODBtn .cart-button {
    position: relative;
    width: 100%;
    padding: 25px;
    border-radius: 50px;
    background-color: var(--dark-blue);
    outline: none;
    cursor: pointer;
    color: var(--blue-invert);
    transition: 0.3s ease-in-out;
    overflow: hidden;
    border: 2px solid var(--dark-blue);
}

.PDBOrderDetail .PDBODBtn .cart-button:active {
    transform: scale(0.9);
}

.PDBOrderDetail .PDBODBtn .cart-button .shopping-cart {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: -10%;
    font-size: 2em;
    transform: translate(-50%, -50%);
}
.PDBOrderDetail .PDBODBtn .cart-button .shoppingbox {
    position: absolute;
    z-index: 3;
    top: -20%;
    left: 52%;
    font-size: 1.2em;
    transform: translate(-50%, -50%);
}
.PDBOrderDetail .PDBODBtn .cart-button span {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    transform: translate(-50%, -50%);
}

.PDBOrderDetail .PDBODBtn .cart-button span.add-to-cart {
    opacity: 1;
}
.PDBOrderDetail .PDBODBtn .cart-button span.added {
    opacity: 0;
}

.PDBOrderDetail .PDBODBtn .cart-button.clicked .shopping-cart {
    animation: cart 1.5s ease-in-out forwards;
}
.PDBOrderDetail .PDBODBtn .cart-button.clicked .shoppingbox {
    animation: box 1.5s ease-in-out forwards;
}
.PDBOrderDetail .PDBODBtn .cart-button.clicked span.add-to-cart {
    animation: txt1 1.5s ease-in-out forwards;
}
.PDBOrderDetail .PDBODBtn .cart-button.clicked span.added {
    animation: txt2 1.5s ease-in-out forwards;
}
@keyframes cart {
    0% {
        left: -10%;
    }
    40%,
    60% {
        left: 50%;
    }
    100% {
        left: 110%;
    }
}
@keyframes box {
    0%,
    40% {
        top: -20%;
    }
    60% {
        top: 40%;
        left: 52%;
    }
    100% {
        top: 40%;
        left: 112%;
    }
}
@keyframes txt1 {
    0% {
        opacity: 1;
    }
    20%,
    100% {
        opacity: 0;
    }
}
@keyframes txt2 {
    0%,
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// Responsive
@media (min-width: 1559px) and (max-width: 1800px) {
}

@media (min-width: 1367px) and (max-width: 1600px) {
}

@media (min-width: 1281px) and (max-width: 1366px) {
}

@media (min-width: 1024px) and (max-width: 1280px) {
}

@media (min-width: 992px) and (max-width: 1023.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
    .PurchaseDialog .topBar {
        padding: 0px 15px;
    }
    .MainDialog.centerZoom.sizeLg .MDContainer .MDCRow.PCDRow {
        height: 100%;
        max-height: 100%;
        margin: 0;
    }
    .PDBIVideoContent .PDBIVCVideo {
        padding-bottom: 230px;
    }
    .PurchaseDialog .PDHRight .PDHRTitle {
        display: none;
    }

    .PurchaseDialog .PDHRight {
        width: auto;
        padding: 0;
    }

    .PurchaseDialog .PDHLeft {
        width: auto;
        flex: 1;
        border: 0;
        padding: 0;
    }

    .PurchaseDialog .PDBody {
        overflow-y: overlay;
    }

    .PurchaseDialog .PDBody .PDBInner {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .PurchaseDialog .PDBody .PDBInner .PDBIVideoContent {
        width: 100%;
        overflow: visible;
    }

    .PDBIVideoContent .PDBIVCVideo .HCDBIVCTitle {
        font-size: 20px;
    }

    .PDBIVideoContent .PDBIVCFeatures {
        flex: unset;
        padding: 0;
        overflow: visible;
    }

    .PDBIVideoContent .PDBIVCFeatures .PDBIVCFBox {
        width: 100%;
        border-bottom: 1px solid #e4e4e4;
        padding: 15px 15px;
    }

    .PurchaseDialog .PDBody .PDBInner .PDBOrderDetail {
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .PurchaseDialog .PDBody .PDBInner .PDBOrderDetail.PDBOrderCongrats {
        width: 36%;
        overflow: auto;
    }
    .PDBOrderDetail.PDBOrderCongrats .PDBODPreview {
        min-height: 400px;
    }
    .PDBOrderDetail.PDBOrderCongrats .PDBODPreview img {
        height: 400px;
        width: 100%;
        object-fit: cover;
        object-position: top;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .PurchaseDialog .PDBody .PDBInner .PDBOrderDetail.PDBOrderCongrats {
        overflow: auto;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .PurchaseDialog .PDBody .PDBInner .PDBOrderDetail.PDBOrderCongrats {
        overflow: auto;
    }
}
