.MobileView {
    width: 275px;
    height: 500px;
    position: relative;
}

.MobileView::before {
    content: '';
    background-image: url('../../Assets/images/mobHead.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 140px;
    height: 23px;
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 70px;
}
.MobileView .MobileViewImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.MobileView .MobileViewMob {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000;
    overflow-y: auto;
    width: 88%;
    height: calc(94% + 3px);
    border-radius: 18px;
}

.MobilePersonalise {
    color: rgb(102 75 19);
    background-color: #ffeaaa;
    display: inline-block;
    padding: 0 3px;
    border: 1px solid #ffc233;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    outline: 0px;
    padding-left: 25px;
    background-repeat: no-repeat;
    // backgroundRepeatY: 'no-repeat';
    background-size: 16px;
    background-position-x: 5px;
    background-position-y: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-image: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 32 32' ><path d='m29.764 23.453-11.188-17.933a3.027 3.027 0 0 0 -5.146-.009l-11.206 17.963a3.027 3.027 0 0 0 2.583 4.526h22.386a3.028 3.028 0 0 0 2.571-4.547zm-13.755.634a1.24 1.24 0 0 1 -.142-2.471 1.24 1.24 0 1 1 .141 2.471zm1.186-9.538-.356 4.093a3.221 3.221 0 0 1 -.249 1.12.708.708 0 0 1 -1.254.013 3.763 3.763 0 0 1 -.248-1.15l-.266-3.976c-.052-.8-.325-2.093.286-2.743.465-.5 1.566-.586 1.9.107a4.873 4.873 0 0 1 .186 2.536z' fill='rgb(102 75 19)' /></svg>");
}

.MobilePersonaliseSaved {
    color: #1f1f1f;
    background-color: #efefef;
    display: inline-block;
    padding: 0 3px;
    border: 1px solid #d3d2d2;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    outline: 0px;
    padding-left: 25px;
    background-repeat: no-repeat;
    // backgroundRepeatY: 'no-repeat',
    background-size: 16px;
    background-position-x: 5px;
    background-position-y: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-image: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 492.001 492.001' ><path d='M487.971,235.993l-85.468-85.464c-5.22-5.228-14.396-5.228-19.616,0l-7.452,7.448c-5.4,5.408-5.4,14.548,0.004,19.956    l48.456,48.792H67.911l48.696-49.02c5.408-5.412,5.408-14.384,0-19.796l-7.444-7.524c-5.232-5.232-14.404-5.272-19.624-0.044    L4.035,235.813c-2.672,2.676-4.1,6.24-4.032,9.916c-0.072,3.82,1.36,7.396,4.032,10.068l85.464,85.464    c5.228,5.232,14.396,5.228,19.62,0l7.444-7.448c5.416-5.416,5.416-13.784,0-19.192l-49.856-49.436h358.792l-50.096,49.668    c-2.612,2.604-4.052,5.884-4.052,9.592s1.436,7.088,4.052,9.7l7.444,7.396c2.616,2.612,6.1,4.02,9.812,4.02    c3.716,0,7.196-1.448,9.812-4.06l85.5-85.508c2.664-2.668,4.096-6.248,4.028-9.924    C492.075,242.245,490.639,238.665,487.971,235.993z' fill='rgb(31 31 31)'/></svg>");
}

.MobileViewMob .MobileViewMPreview {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    background-color: var(--white);
}

.MobileViewMob .MobileViewMPreview .MobileViewMPMobFeature {
    display: flex;
    align-items: center;
    padding: 5px;
    color: #26262b;
}

.MobileViewMob .MobileViewMPreview .MobileViewMPMobFeature .MobileViewMPMFTime {
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    padding-right: 10px;
}

.MobileViewMob .MobileViewMPreview .MobileViewMPMobFeature .MobileViewMPMFBT {
    display: flex;
    align-items: center;
}

.MobileViewMob .MobileViewMPreview .MobileViewMPMobFeature .MobileViewMPMFBT svg {
    margin-left: 10px;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MobileViewMEVHead .MobileViewMEVHOption {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    color: #26262b;
    font-size: 12px;
    overflow: hidden;
}

.MobileViewMEmailView .MobileViewMEVHead .MobileViewMEVHOption strong {
    min-width: 60px;
}

.MobileViewMEmailView .MobileViewMEVHead .MobileViewMEVHOption span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    padding-bottom: 10px;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MobileViewMEVMsg {
    flex: 1;
    padding: 10px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    word-break: break-word;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MobileViewMEVMsg p {
    margin-bottom: 5px;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MVEVMSMSView .MVEVMSMSVContent p {
    margin-bottom: 5px;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MobileViewMEVMsg p:empty {
    padding: 10px 0;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MVEVMSMSView .MVEVMSMSVContent p:empty {
    padding: 10px 0;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MVEVMSMSView {
    flex: 1 1;
    padding: 10px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    display: flex;
    align-items: flex-start;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MVEVMSMSView .MVEVMSMSVUser {
    display: flex;
    font-size: 25px;
    border-radius: 100%;
    overflow: hidden;
    color: #7b89a4;
}

.MobileViewMob .MobileViewMPreview .MobileViewMEmailView .MVEVMSMSView .MVEVMSMSVContent {
    flex: 1 1;
    max-height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    margin-left: 10px;
    background-color: #e8eef9;
    border: 1px solid #cad3e3;
    padding: 10px;
    border-radius: 4px;
    word-break: break-word;
}

.MobileViewMob
    .MobileViewMPreview
    .MobileViewMEmailView
    .MVEVMSMSView
    .MVEVMSMSVContent
    br:last-child {
    display: none;
}

// Desktop Responsive

@media (min-width: 1920px) {
}
@media (min-width: 1559px) and (max-width: 1800px) {
}
@media (min-width: 1367px) and (max-width: 1600px) {
    .MobileView {
        width: 270px;
    }
}
@media (min-width: 1280px) and (max-width: 1367px) {
}
