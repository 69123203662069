.DealDetailEdit .DDEInner {
    display: flex;
    align-items: flex-start;
}

.DealDetailEdit .DDEInner .DDEIInfo {
    flex: 1;
    overflow: hidden;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIISkeleton {
    margin: 5px 0px;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIStatus {
    font-size: 14px;
    font-weight: 600;
    padding: 3px 5px;
    line-height: normal;
    border-radius: 4px;
    text-transform: uppercase;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIStatus.lost {
    background-color: #f4433610;
    color: #f44336;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIStatus.won {
    background-color: #4caf5010;
    color: #4caf50;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIStatus.open {
    background-color: #4caf5010;
    color: #195592;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIName {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding-top: 10px;
    position: relative;
    display: flex;
    justify-content: center;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIName .DDEIIInnar {
    padding: 0 30px;
    position: relative;
    max-width: 100%;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIValue {
    padding-top: 4px;
    font-size: 26px;
    font-weight: 600;
    color: #000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    padding-bottom: 7px;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIContainer {
    display: flex;
    align-items: center;
    padding-top: 5px;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIContainer .DDEIILabel {
    color: #666;
    font-size: 14px;
    font-weight: 500;
    padding-right: 10px;
}

.DealDetailEdit .DDEInner .DDEIInfo .DDEIIText {
    flex: 1;
}

.DealDetailEdit .DDEInner .DDEIIBtn {
    display: flex;
    position: absolute;
    top: 6px;
    margin: 0;
    right: 0px;
}

.DealDetailEdit .DDEInner .DDEIIBtn .iconButton {
    background-color: unset;
    border: 0;
    padding: 4px;
    font-size: 16px;
    color: var(--dark-blue);
}

.DealDetailEditPopover .PopoverMainInner .PMIContent {
    padding: 20px;
}

.DealDetailEditPopover .DDEPBtns {
    display: flex;
}

.DealDetailEditPopover .DDEPBtns button {
    margin-right: 10px;
}

.DDEITag {
    display: flex;
    justify-content: center;
}

@media (min-width: 1367px) and (max-width: 1560px) {
    .DealDetailEdit {
        padding: 15px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .DealDetailEdit {
        padding: 15px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .DealDetailEdit {
        padding: 15px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .DealDetailEdit {
        padding: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .DealDetailEdit {
        padding: 15px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .DealDetailEdit {
        padding: 15px;
    }
}

@media (max-width: 575.98px) {
    .DealDetailEdit {
        padding: 15px;
    }
}
