.SkeletonMain {
    height: 20px;
    display: block;
    background-color: rgba(0, 0, 0, 0.11);
    animation: skeleton-keyframes 1.5s ease-in-out 0.5s infinite;
    border-radius: 4px;
}

.SkeletonMain.SkeletonCircleMain {
    border-radius: 100%;
    position: relative;
}

.SkeletonMain.SkeletonCircleMain::before {
    content: '';
    position: absolute;
    left: 15%;
    right: 15%;
    top: 15%;
    bottom: 15%;
    background-color: var(--white);
    border-radius: 100%;
}

@keyframes skeleton-keyframes {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
