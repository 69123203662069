.TitleIconMain {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.TitleIconMain .TIMInner {
    display: flex;
    align-items: center;
    flex: 1;
}

.TitleIconMain .TIMInner .TIMITitle {
    font-size: 16px;
    color: #000;
    padding-right: 10px;
    font-weight: 600;
}

.TitleIconMain .TIMInner .TIMIIcon {
    font-size: 16px;
    color: #bac0c7;
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .TitleIconMain .TIMInner .TIMITitle {
        font-size: 15px;
        font-weight: 600;
    }
}

@media (min-width: 1367px) and (max-width: 1560px) {
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .TitleIconMain .TIMInner .TIMITitle {
        font-size: 14px;
        font-weight: 600;
    }
    .TitleIconMain .TIMInner .TIMIIcon {
        font-size: 14px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .TitleIconMain .TIMInner .TIMITitle {
        font-size: 14px;
        font-weight: 600;
    }
    .TitleIconMain .TIMInner .TIMIIcon {
        font-size: 14px;
    }
}
