.badgeRoot {
    position: relative;
    display: flex;
}
.badgeRoot .badgeContent {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #f44336;
    color: var(--white);
    height: 20px;
    display: flex;
    font-size: 14px;
    padding: 0 6px;
    font-weight: 600;
    z-index: 1;
    flex-wrap: wrap;
    min-width: 20px;
    box-sizing: border-box;
    align-items: center;
    line-height: 1;
    align-content: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    transform: scale(1) translate(50%, -20%);
}
