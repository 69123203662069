.LoginPage {
    flex: 1;
    overflow: auto;
}

.LoginPage .LoginPageInner {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
}

.LoginPage .LoginPageInner .LPIFormSection {
    width: 30%;
    box-shadow: 0px 4px 34px rgb(0 0 0 / 10%);
    background-color: #26262b;
}

.LoginPage .LoginPageInner .LPIAppsIcons {
    width: 70%;
    background-color: #f6f8fc;
}

.LPIFormSection .LPIFSInner {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding: 25px 50px;
}

.LPIFormSection .LPIFSInner .LPIFSIHeader {
    display: flex;
    justify-content: flex-start;
}

.LPIFormSection .LPIFSInner .LPIFSIHeader .LPIFSIHLogo {
    display: flex;
}

.LPIFormSection .LPIFSInner .LPIFSIHeader .LPIFSIHLogo img {
    width: 200px;
    height: 45px;
    object-fit: contain;
    object-position: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.LPIFormSection .LPIFSInner .LPIFSIBody {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LPIFormSection .LPIFSInner .LPIFSIBody .LPIFSIFormBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.LPIFSIFormBox .LPIFSIFBTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 15px;
    height: 40%;
}

.LPIFSIFormBox .LPIFSIFBTitle .LPIFSIFBTText {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: var(--white);
}

.LPIFSIFormBox .LPIFSIFBForm {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFMail {
    position: relative;
}

.LPIFSIFormBox .LPIFSIFBForm .DCInputWrapper input.DCInput {
    border-radius: 4px;
    font-size: 16px;
    padding: 15px;
    padding-left: 40px;
    font-weight: 500;
    border: 0;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFMail .LPIFSIFBFMIcon {
    position: absolute;
    left: 10px;
    top: 15px;
    font-size: 20px;
    display: flex;
    color: #26262b;
    z-index: 1;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFMail .LPIFSIFBFMLoader {
    position: absolute;
    top: 13px;
    right: 12px;
    display: flex;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFRF {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 20px;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFRF .LPIFSIFBFRFRM {
    display: flex;
    color: #f6f8fc;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFRF .LPIFSIFBFRFRM span.CheckboxLabel {
    line-height: normal;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFRF .LPIFSIFBFRFFP {
    font-size: 14px;
    font-weight: 600;
    color: #f6f8fc;
    user-select: none;
    cursor: pointer;
    text-decoration: underline;
}

.ForgetClass {
    font-size: 14px;
    font-weight: 600;
    color: #f6f8fc;
    user-select: none;
    cursor: pointer;
    text-decoration: underline;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBPassword {
    position: relative;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBPassword .LPIFSIFBPIcon {
    position: absolute;
    left: 10px;
    top: 15px;
    font-size: 20px;
    display: flex;
    color: #26262b;
    z-index: 1;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBPassword .LPIFSIFBPRightIcon {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 20px;
    display: flex;
    color: #26262b;
    cursor: pointer;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFSBtn {
    display: flex;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFSBtn button.mainButton {
    padding: 12px;
    border-radius: 4px;
    font-size: 19px;
    font-weight: 600;
    min-height: 50px;
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBSUBtn {
    text-align: center;
    color: #666;
    font-size: 14px;
    padding-top: 25px;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBSUBtn a {
    color: var(--dark-blue);
    font-weight: 600;
}

.LPIAppsIcons .LPIAIInnner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    padding: 25px 50px;
    position: relative;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon {
    position: absolute;
    display: flex;
    font-size: 75px;
    transform-origin: 50% 50%;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    left: 40px;
    right: 0px;
    top: 40px;
    background: linear-gradient(181.93deg, #cecece -53.1%, rgba(196, 196, 196, 0) 69.78%);
    opacity: 0.8;
    filter: blur(7px);
    transform: rotate(-44.29deg);
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon svg {
    transform-origin: 50% 50%;
    position: relative;
    z-index: 1;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI1 {
    left: 0;
    top: 0;
    animation: MoveIcon1 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI2 {
    left: 25%;
    top: 25%;
    animation: MoveIcon2 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI3 {
    left: 50%;
    top: 50%;
    animation: MoveIcon2 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI4 {
    left: 75%;
    top: 25%;
    animation: MoveIcon1 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI5 {
    right: 0;
    top: 0;
    animation: MoveIcon2 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI6 {
    left: 0;
    bottom: 0;
    animation: MoveIcon3 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI7 {
    left: 30%;
    bottom: 20%;
    animation: MoveIcon2 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI8 {
    left: 75%;
    bottom: 25%;
    animation: MoveIcon1 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI9 {
    right: 0;
    bottom: 0;
    animation: MoveIcon4 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI10 {
    left: 50%;
    top: 12%;
    animation: MoveIcon1 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI11 {
    left: 12%;
    top: 45%;
    animation: MoveIcon2 15s infinite;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon.LPIAIIAI12 {
    right: 7%;
    top: 45%;
    animation: MoveIcon1 15s infinite;
}

.ResetPassword .DCPhoneNumberWrapper .react-tel-input input.form-control {
    padding-top: 15px;
    padding-bottom: 15px;
}

.ResetPassword .DCPhoneNumberWrapper--OTPField {
    margin: 20px 0;
    position: relative;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
    }
    input {
        -moz-appearance: textfield !important;
    }
}

.ResetPassword .LPIFSIFBFSBtnLoad--OTPField {
    margin: 10px 0 20px;
}

@keyframes MoveIcon1 {
    0% {
        transform: translate(15px, 0px);
    }

    25% {
        transform: translate(15px, 15px);
    }

    50% {
        transform: translate(0px, 15px);
    }

    75% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(15px, 0px);
    }
}

@keyframes MoveIcon2 {
    0% {
        transform: translate(0px, 15px);
    }

    25% {
        transform: translate(-15px, 15px);
    }

    50% {
        transform: translate(-15px, 0px);
    }

    75% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(0px, 15px);
    }
}

@keyframes MoveIcon3 {
    0% {
        transform: translate(15px, 0px);
    }

    25% {
        transform: translate(15px, -15px);
    }

    50% {
        transform: translate(0px, -15px);
    }

    75% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(15px, 0px);
    }
}

@keyframes MoveIcon4 {
    0% {
        transform: translate(0px, -15px);
    }

    25% {
        transform: translate(-15px, -15px);
    }

    50% {
        transform: translate(-15px, 0px);
    }

    75% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(0px, -15px);
    }
}

.LPIAppsIcons .LPIAIInnner .LPIAIIScreen {
    position: absolute;
    bottom: -70px;
    right: -5%;
    width: 85%;
    height: 60%;
    overflow: hidden;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIScreen img.LPIAIISVImg {
    width: 100%;
    height: 100%;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIScreen .LPIAIISWeb {
    position: absolute;
    left: 4%;
    padding-right: 6%;
    top: 7%;
    bottom: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    border-radius: 4px;
    background-color: #000;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIScreen .LPIAIISWeb img.LPIAIISWVImg {
    width: 100%;
    object-fit: cover;
    object-position: left top;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIScreen.LPIAIISFull {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.LPIAppsIcons .LPIAIInnner .LPIAIIScreen.LPIAIISFull img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.MDCRow.LoginDialogRow {
    height: 80%;
    border-radius: 4px;
}

.MDCRow.LoginDialogRow .LoginPage .LoginPageInner .LPIFormSection {
    width: 35%;
}
.MDCRow.LoginDialogRow .LoginPage .LoginPageInner .LPIAppsIcons {
    width: 65%;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBAccounts {
    max-height: 265px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 20px;
    background-color: var(--white);
    box-shadow: 0px 4px 34px rgb(0 0 0 / 10%);
}

.LPIFSIFBAccounts .LPIFSIFBEmail {
    font-size: 16px;
    font-weight: 500;
    color: #666;
    padding: 10px;
    border-bottom: 1px solid #f6f8fc;
}

.LPIFSIFBAccounts .LPIFSIFBAList {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e8f0fe;
    cursor: pointer;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem:last-child {
    border-bottom: 0;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem .DCLASIIcon {
    font-size: 20px;
    display: flex;
    color: #000;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem .DCLASITitle {
    flex: 1;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem .DCLASITitleBtn {
    display: flex;
    align-items: center;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem .DCLASITitleBtn .DCLASITBText {
    font-size: 16px;
    font-weight: 600;
    padding-right: 10px;
    opacity: 0;
    transition: all ease 0.3s;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem:hover .DCLASITitleBtn .DCLASITBText {
    padding-right: 25px;
    opacity: 1;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem .DCLASITitleBtn .DCLASITBIcon {
    display: flex;
    font-size: 18px;
    color: #666;
    transition: all ease 0.3s;
}

.LPIFSIFBAccounts .LPIFSIFBAList .DCLASItem:hover .DCLASITitleBtn .DCLASITBIcon {
    color: #000;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFSBtn button.mainButton.LPIFSIFBFSBtnLoad {
    background-color: var(--white);
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBSelected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 20px;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBSelected .LPIFSIFBSA {
    font-size: 14px;
    font-weight: 600;
    color: #f6f8fc;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBSelected .LPIFSIFBSBtn {
    font-size: 14px;
    font-weight: 600;
    color: #f6f8fc;
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
}

.LPIFormSection .LPIFSInner .LPIFSIBody .LPIMailSent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LPIMailSent .LPIMailSIcon {
    font-size: 150px;
    color: #f6f8fc;
    display: flex;
}

.LPIMailSent .LPIMailSTitle {
    font-size: 30px;
    font-weight: 600;
    color: #f6f8fc;
    margin-top: 50px;
}

.LPIMailSent .LPIMailSSubTitle {
    font-size: 18px;
    font-weight: 600;
    color: #f6f8fc;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIForgetBtn {
    display: flex;
    margin-top: 15px;
    align-items: center;
    background-color: #f44336;
    padding: 10px 15px;
    border-radius: 4px;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIForgetBtn span {
    font-size: 16px;
    font-weight: 500;
    color: #f6f8fc;
    flex: 1;
    padding-right: 10px;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIForgetBtn button.iconButton {
    padding: 0;
    background-color: unset;
    border: 0;
    color: var(--white);
    font-size: 10px;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFError {
    display: flex;
    margin-top: 15px;
    align-items: center;
    background-color: #f44336;
    padding: 10px 15px;
    border-radius: 4px;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFError span {
    font-size: 16px;
    font-weight: 500;
    color: #f6f8fc;
    flex: 1;
    padding-right: 10px;
}

.LPIFSIFormBox .LPIFSIFBForm .LPIFSIFBFError button.iconButton {
    padding: 0;
    background-color: unset;
    border: 0;
    color: var(--white);
    font-size: 10px;
}

//Redirect Page

.RedirectPage {
    flex: 1;
}

.RedirectPage .RedirectPageInner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RedirectPage .RedirectPageInner .RPIContent {
    text-align: center;
    max-width: 500px;
}

.RedirectPage .RedirectPageInner .RPIContent .RPICLoader {
    display: flex;
    justify-content: center;
}

.RedirectPage .RedirectPageInner .RPIContent .RPICLoader .CircularLoader {
    width: 100px;
    height: 100px;
}

.RedirectPage .RedirectPageInner .RPIContent .RPICLoader .CircularLoader svg {
    width: 100px;
    height: 100px;
}

.RedirectPage .RedirectPageInner .RPIContent .RPICTitle {
    font-size: 24px;
    font-weight: 600;
    padding-top: 30px;
}

// Responsive

@media (min-width: 2000px) {
    .LPIAppsIcons .LPIAIInnner .LPIAIIScreen {
        bottom: -150px;
    }
}

@media (min-width: 1600px) and (max-width: 1800px) {
    .LoginPage .LoginPageInner .LPIFormSection {
        width: 35%;
    }
    .LoginPage .LoginPageInner .LPIAppsIcons {
        width: 65%;
    }
}

@media (min-width: 1367px) and (max-width: 1559.98px) {
    .LoginPage .LoginPageInner .LPIFormSection {
        width: 35%;
    }
    .LoginPage .LoginPageInner .LPIAppsIcons {
        width: 65%;
    }
}

@media (min-width: 1024px) and (max-width: 1366.98px) {
    .LoginPage .LoginPageInner .LPIFormSection {
        width: 40%;
    }
    .LoginPage .LoginPageInner .LPIAppsIcons {
        width: 60%;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .LoginPage .LoginPageInner .LPIFormSection {
        width: 45%;
    }
    .LoginPage .LoginPageInner .LPIAppsIcons {
        width: 55%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .LoginPage .LoginPageInner .LPIFormSection {
        width: 50%;
    }
    .LoginPage .LoginPageInner .LPIAppsIcons {
        width: 50%;
    }
    .LPIAppsIcons .LPIAIInnner {
        padding: 25px 25px;
    }
    .LPIFormSection .LPIFSInner {
        padding: 25px 25px;
    }
    .LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon {
        font-size: 50px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .LoginPage .LoginPageInner .LPIFormSection {
        width: 50%;
    }
    .LoginPage .LoginPageInner .LPIAppsIcons {
        width: 50%;
    }
    .LPIAppsIcons .LPIAIInnner {
        padding: 25px 25px;
    }
    .LPIFormSection .LPIFSInner {
        padding: 25px 25px;
    }
    .LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon {
        font-size: 50px;
    }
}

@media (max-width: 575.98px) {
    .LoginPage .LoginPageInner {
        position: relative;
    }
    .LoginPage .LoginPageInner .LPIFormSection {
        width: 100%;
        position: relative;
        z-index: 1;
        background-color: #ffffffeb;
    }
    .LPIAppsIcons .LPIAIInnner {
        padding: 25px 25px;
    }
    .LPIFormSection .LPIFSInner {
        padding: 25px 25px;
    }

    .LoginPage .LoginPageInner .LPIAppsIcons {
        width: 100%;
        background-color: var(--white);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .LPIAppsIcons .LPIAIInnner .LPIAIIApps .LPIAIIAIcon {
        font-size: 50px;
    }
    .LPIMobTooltip {
        display: flex;
        align-items: center;
    }
    .LPIMobTooltip .ToolTipWrapper {
        padding-left: 8px;
    }
}

