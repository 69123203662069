.AccessDenied {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AccessDenied .AccessDeniedInner {
    text-align: center;
}

.AccessDenied .AccessDeniedInner .ADIIcon {
    display: flex;
    justify-content: center;
    font-size: 250px;
}

.AccessDenied .AccessDeniedInner .ADITitle {
    font-size: 28px;
    font-weight: 600;
}
