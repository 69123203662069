.DateTimePicker .DateTimePickerInput input {
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #7b89a4;
    padding: 10px;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
}

.DateTimePicker .DateTimePickerInput.active input,
.DateTimePicker .DateTimePickerInput input:focus {
    border-color: var(--dark-blue);
    box-shadow: 0 0 3px var(--dark-blue);
}

.DateTimePickerPopover {
    display: flex;
    padding: 5px;
}

.DateTimePickerPopover .DPMonthYearContainer {
    display: flex;
    column-gap: 5px;
}

.DateTimePickerPopover .DatePickerCalendar {
    flex: 1;
    border-right: 1px solid #f6f8fc;
}

.DateTimePickerPopover .TimePickerOptionsLists {
    box-shadow: unset;
    max-height: 268px;
}

.DateTimePickerPopover .TimePickerOptionsLists ul li {
    padding: 6px 10px;
    text-align: center;
}

.DateTimePickerPopover span.nice-dates-week-header_day {
    font-size: 12px;
}

.DateTimePickerPopover .nice-dates-navigation_previous:before {
    height: 10px;
    width: 10px;
    top: 17px;
    left: 17px;
}

.DateTimePickerPopover a.nice-dates-navigation_next:before {
    height: 10px;
    width: 10px;
    top: 17px;
    left: 14px;
}
