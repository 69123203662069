.ConfirmationDialog .MDCRow {
    border-radius: 4px;
}

.ConfirmationMain {
    display: flex;
    flex-direction: column;
}

.ConfirmationMain .CMIHead {
    text-align: center;
    padding: 25px;
    color: var(--dark-blue);
}

.ConfirmationMain.delete .CMIHead {
    color: #f44336;
}

.ConfirmationMain .CMIHead .CMIHIcon {
    font-size: 38px;
    display: flex;
    justify-content: center;
}

.ConfirmationMain .CMIHead .CMIHTitle {
    font-size: 22px;
    font-weight: 500;
    padding-top: 10px;
}

.ConfirmationMain .CMIBody {
    padding: 25px;
    background-color: #e8eef9;
    border-top: 1px solid #cad3e3;
}

.ConfirmationMain .CMIBody .CMIBInner {
    text-align: center;
}

.ConfirmationMain .CMIBody .CMIBInner .CMIBIDes {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 25px;
}

.ConfirmationMain .CMIBody .CMIBInner .CMIBIBtns {
    display: flex;
    justify-content: center;
}

.ConfirmationMain .CMIBody .CMIBInner .CMIBIBtns button {
    margin: 0 10px;
    width: 30%;
    padding: 10px;
}
