.PopoverMain {
    z-index: 9999;

    & * {
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }

    &.ToolTipPopover {
        pointer-events: none;

        .PopoverMainInner {
            pointer-events: none;
        }
    }
}

.PopoverMain .PopoverMainInner {
    position: relative;
    background-color: var(--white);
    box-shadow: 0 4px 14px 0 #00000033, 0 0 0 1px #0000000d;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
}

.PopoverMain .PopoverMainInner .PMIContent {
    padding: 10px;
    z-index: 2;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    background-color: var(--white);
    border-radius: 3px;
}

.PopoverMain .PopoverMainInner.withOutPadding .PMIContent {
    padding: 0px;
}

.popoverBtns {
    display: flex;
}

.popoverBtns button {
    margin-right: 10px;
}

// body.popoverBodyOf,
// body.popoverBodyOf .pageWrapper *,
// body.popoverBodyOf .MainDialog * {
//     overflow: hidden;
// }
