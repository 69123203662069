.RefreshBar {
    padding: 10px;
    text-align: center;
    background-image: linear-gradient(90deg, #0083ff 0%, #005eb8 94.51%);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.WarningBarYellowColor {
    background-color: #ffb74d !important;
    background-image: linear-gradient(90deg, #ffb74d 0%, #ffb74d 94.51%) !important;
}

.WarningBar {
    padding: 10px;
    text-align: center;
    background-image: linear-gradient(90deg, #e23e07cb 0%, #cc3300 94.51%);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.WBSubscription {
    background: transparent !important;
    position: absolute !important;
    right: 8px !important;
}
.WarningBar {
    padding: 10px;
    text-align: center;
    background-color: #d9534f;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
}

.RefreshBar button {
    color: #fff !important;
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    min-width: auto;
}

.WarningBar button {
    color: #fff !important;
    text-decoration: underline;
    font-weight: 600;
    font-size: 15px;
    min-width: auto;
}

.MenuBarRight {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    column-gap: 15px;

    @media screen and (min-width: 768px) and (max-width: 850px) {
        column-gap: 12px;
    }
}

.MRDScheduleDemoToolTip .PopoverMainInner.withOutPadding {
    top: 4px;

    &::before {
        left: 50%;
        top: -5px;
    }
}

.MenuBarRight .MRDemo {
    background: #0083ff;
    color: #fff;
    border-radius: 100px;
    border: none;
    font-size: 13px;
}
.MenuBarRight .MRDemo .buttonTitle {
    display: flex;
    align-items: center;
}

.MenuBarRight .MRDemo .BtnText {
    padding-top: 2px;
    width: max-content;
}
.MenuBarRight .MRDemo .MRDIcon {
    margin-top: 1px;
    margin-left: 5px;
}
.MenuBarRight .MRDemo .MRDIcon svg {
    font-size: 6px;
    display: inline;
}

button.iconButton.NotificationIconButton {
    border: 0;
    background-color: transparent !important;
    padding: 0;
    font-size: 25px;
    color: var(--blue-invert);
}

button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
    top: -3px;
    right: 1px;
}

button.iconButton.HelpIconBtn {
    border: 0;
    background-color: transparent !important;
    padding: 0;
    font-size: 25px;
    color: var(--blue-invert);
}

.MenuToggleProfile {
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-left: 1px solid var(--blue-invert);
}

.MenuToggleProfile .ProfileMenuBtn {
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    overflow: hidden;
    margin-left: 15px;
    cursor: pointer;
    background-image: linear-gradient(90deg, #2c2c2c, #383838, #464445, #545051, #625d5d);
    background-size: 200% 200%;
    padding: 0;
}

.MenuToggleProfile .ProfileMenuBtn img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 100%;
}
//

.MenuToggleProfile .MenuToggleBtn {
    width: 25px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    padding: 0;
}

.MenuToggleProfile .MenuToggleBtn:hover {
    background-color: transparent;
}

.MenuToggleProfile .MenuToggleBtn > span {
    display: block;
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: var(--blue-invert);
    opacity: 1;
    border-radius: 50%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.MenuToggleProfile .MenuToggleBtn > span:nth-child(1) {
    top: 0px;
    left: 0px;
}
.MenuToggleProfile .MenuToggleBtn > span:nth-child(2) {
    top: 0px;
    left: 10px;
}
.MenuToggleProfile .MenuToggleBtn > span:nth-child(3) {
    top: 0px;
    right: 0;
}
.MenuToggleProfile .MenuToggleBtn > span:nth-child(4) {
    top: 10px;
    left: 0px;
}
.MenuToggleProfile .MenuToggleBtn > span:nth-child(5) {
    top: 10px;
    left: 10px;
}
.MenuToggleProfile .MenuToggleBtn > span:nth-child(6) {
    top: 10px;
    right: 0;
}
.MenuToggleProfile .MenuToggleBtn > span:nth-child(7) {
    bottom: 0;
    left: 0px;
}
.MenuToggleProfile .MenuToggleBtn > span:nth-child(8) {
    bottom: 0;
    left: 10px;
}
.MenuToggleProfile .MenuToggleBtn > span:nth-child(9) {
    bottom: 0;
    right: 0;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span {
    height: 4px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(1) {
    width: 12.5px;
    transform: rotate(45deg);
    left: 0px;
    border-radius: 50px;
    top: 5px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(3) {
    width: 12.5px;
    transform: rotate(-45deg);
    border-radius: 50px;
    left: 45%;
    top: 5px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(7) {
    width: 12.5px;
    transform: rotate(-45deg);
    left: 0px;
    border-radius: 50px;
    bottom: 5px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(9) {
    width: 12.5px;
    transform: rotate(45deg);
    border-radius: 50px;
    left: 45%;
    bottom: 5px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(2) {
    top: 10px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(4) {
    left: 10px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(5) {
    left: 10px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(6) {
    right: 10px;
}

.MenuToggleProfile .MenuToggleBtn.MenuOpen > span:nth-child(8) {
    bottom: 10px;
}

.MainDialog.MenuListDialog {
    top: 60px;
}

.MainDialog.MenuListDialog .MDBackdrop {
    top: 60px;
}

.MainDialog.MenuListDialog.rightSlide .MDContainer .MDCRow {
    width: 350px;
}

.MenuList {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.MenuList .MenuListHeader {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f6f8fc;
    border-bottom: 1px solid #cad3e3;
}

.MenuList .MenuListHeader .MLHTitle {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    color: #26262b;
    line-height: normal;
    padding-right: 15px;
}

.SSOPopover {
    height: 100%;
}

.MenuList .MenuListHeader button.MLHBtn {
    line-height: normal;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 5px 10px;
    min-width: 45px;
    background-color: #26262b;
    color: var(--white);
    border-radius: 50px;

    &,
    & * {
        cursor: pointer;
    }
}

.MenuList .MenuListBody {
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    flex: 1;
    padding: 10px 0;
}

.MenuList .MenuListBody .MLBInner {
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    background-color: transparent;
    width: 100%;
}

.MenuList .MenuListBody .MLBInner .MHIMLItem {
    height: auto !important;
    display: flex;
    justify-content: center;
    background-color: transparent;
    user-select: none;
    margin: 15px 0;
    cursor: default !important;

    & * {
        cursor: pointer !important;
    }
}

.MHIMLItem a.MHIMLILink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.MenuList .MenuListBody .MLBInner .MHIMLItem.reorder {
    cursor: grab !important;
}

.MHIMLItem:nth-child(2n) a.reorder {
    animation-name: keyframes1;
    animation-delay: -0.22s;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    transform-origin: 50% 10%;
}

.MHIMLItem:nth-child(2n-1) a.reorder {
    animation-name: keyframes2;
    animation-delay: -0.22s;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transform-origin: 30% 5%;
}

@keyframes keyframes1 {
    0% {
        transform: translate(0px, 0px) rotate(-1deg);
        animation-timing-function: ease-in;
    }

    50% {
        transform: translate(0px, 0px) rotate(1.5deg);
        animation-timing-function: ease-out;
    }
}

@keyframes keyframes2 {
    0% {
        transform: translate(0px, 0px) rotate(1deg);
        animation-timing-function: ease-in;
    }

    50% {
        transform: translate(0px, 0px) rotate(-1.5deg);
        animation-timing-function: ease-out;
    }
}

.MHIMLItem a.MHIMLILink.reorder {
    user-select: all;
    -moz-user-select: all;
    -webkit-user-drag: all;
    -webkit-user-select: all;
    -ms-user-select: all;
    pointer-events: none;
}

.MHIMLItem .MHIMLIFav {
    position: absolute;
    padding: 3px;
    color: #26262b;
    top: -10px;
    right: -2px;
    z-index: 9;
    font-size: 14px;
    background-color: var(--white);
    border-radius: 3px;
    box-shadow: 0 4px 14px 0 #00000033, 0 0 0 #0000000d;
    transition: all ease 0.3s;
}

.MHIMLItem .MHIMLIFav.selected {
    color: #ffa64d;
}

.MHIMLItem a.MHIMLILink .MHIMLILIcon {
    font-size: 72px;
    display: flex;
    position: relative;
}
.MHIMLILIcon .MHIMLwrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: none;
    overflow: hidden;
}

.MHIMLILIcon .MHIMLbadge {
    height: 16px;
    background: #58c777;
    width: 65px;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    padding-top: 1px;
    font-family: sans-serif;
    color: #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    right: -21px;
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.34);
    border-top-left-radius: 10px;
}
.MHIMLItem a.MHIMLILink .MHIMLILIcon > svg {
    fill: none;
}

.MHIMLItem a.MHIMLILink .MHIMLILIcon .MHIMLILock {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #26262bad;
    z-index: 9;
    border-radius: 10px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.MHIMLItem a.MHIMLILink .MHIMLITitle {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    text-align: center;
    padding-top: 5px;
    user-select: none;
}

.PopoverMain.UserDropdown .PopoverMainInner::before {
    top: -7px;
    right: 10px;
    visibility: visible;
}

.UserDropdown .UserDropdownInfo a {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.UserDropdown .UserDropdownInfo a .UDIImg {
    display: flex;
    justify-content: center;
}

.UserDropdown .UserDropdownInfo a .UDIImg img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
}

.UserDropdown .UserDropdownInfo a .UDIName {
    text-align: center;
    padding-top: 13px;
    padding-bottom: 5px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.UserDropdown .UserDropdownInfo a .UDIMail {
    text-align: center;
    color: #666;
    font-weight: 400;
    font-size: 14px;
}

.UserDropdown .UDAcID {
    text-align: center;
    padding: 10px;
    background-color: #f6f8fc;
    font-size: 12px;
}

.UserDropdown .UDAcID strong {
    margin-left: 5px;
    display: inline-block;
}

.UserDropdown .UDMenu {
    display: flex;
    flex-direction: column;
}

.UserDropdown .UDMenu a {
    color: #586975;
    line-height: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 15px;
    display: block;
    padding: 10px 15px;
    transition: all 0.5s ease;
    text-decoration: none;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    padding-right: 45px;
}

.UserDropdown .UDMenu a::after {
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 24 24' ><path fill='rgb(88 105 117)' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path></svg>");
    position: absolute;
    right: 10px;
    top: 10px;
    transition: all ease 0.1s;
    width: 24px;
    height: 24px;
    z-index: 1;
}

.UserDropdown .UDBtns {
    display: flex;
}

.UserDropdown .UDBtns button.mainButton {
    background-color: #f44336;
    color: #fff;
    flex: 1 1;
    border: 0;
    border-radius: 0;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
}

.UserDropdown .UDBtns button.mainButton .buttonTitle {
    text-align: center;
}

.MenuList .MenuListFooter {
    display: flex;
    padding: 15px;
    background-color: #f6f8fc;
    border-top: 1px solid #cad3e3;
}

.MenuList .MenuListFooter a {
    width: 100%;
    padding: 10px;
    background-color: var(--dark-blue);
    color: var(--blue-invert);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &,
    & * {
        cursor: pointer;
    }
}

.MenuList .MenuListFooter a span {
    padding-left: 15px;
}

.MenuList .MenuListFooter a svg {
    font-size: 16px;
}

.MenuList .FavoritesMenus {
    display: none;
}

.MenuBarRight button.iconButton.CartIconButton {
    border: 0;
    padding: 0;
    font-size: 25px;
    background-color: unset;
    color: var(--blue-invert);
    transform: translateX(7px);

    @media screen and (max-width: 768px) {
        transform: translateX(10px);
    }
}

.MenuBarRight .CartIconButton .badgeRoot .badgeContent {
    top: -3px;
    left: 0;
    right: unset;
    position: relative;
    transform: scale(1) translate(-10%, -20%);
}

.PopoverMain.CartPopover {
    margin-left: 25px;
}
.PopoverMain.CartPopover.ArrowPopover .PopoverMainInner::before {
    top: -5px;
    right: 50px;
    background-color: #f6f8fc;
    visibility: visible;
}

.PopoverMain.CartPopover .CartPopoverTitle {
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    background-color: #f6f8fc;
    color: #26262b;
}

.PopoverMain.CartPopover .CartItemGroupMain {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.CartPopover .CartItemGroup {
    border-bottom: 1px solid #e9e9e9;
    padding-top: 7px;
}

.CartPopover .CartItemGroup .CIGTitle {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--dark-blue);
    padding: 10px;
    border-bottom: 1px solid #e9e9e9;
}

.CartPopover .CartItemGroup .CIGItem {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e9e9e9;
    column-gap: 10px;
    position: relative;
}

.CartPopover .CartItemGroup .CIGItem:last-child {
    border-bottom: 0;
}

.CartPopover .CartItemGroup .CIGItem .CIGIIcon {
    font-size: 20px;
    padding: 4px;
    box-shadow: 0 0 4px #5b5b5b16;
    border-radius: 4px;
    display: flex;
}

.CartPopover .CartItemGroup .CIGItem .CIGIIcon img {
    width: 20px;
    height: 20px;
}

.CartPopover .CartItemGroup .CIGItem .CIGITitle {
    flex: 1;
    font-size: 14px;
    font-weight: 500;
}

.CartPopover .CartItemGroup .CIGItem .CIGIDownUp {
    font-size: 10px;
    padding: 4px;
    border-radius: 3px;
    color: #fff;
    text-transform: capitalize;
}

.CartPopover .CartItemGroup .CIGItem .CIGIQun {
    display: flex;
    align-items: center;
}

.CartPopover .CartItemGroup .CIGItem .CIGIQun .CIGIQBtn {
    font-size: 11px;
    background-color: var(--dark-blue);
    color: var(--blue-invert);
    padding: 4px;
    border-color: var(--dark-blue);
}

.CartPopover .CartItemGroup .CIGItem .CIGIQun .CIGIQunNum {
    font-size: 14px;
    font-weight: 600;
    min-width: 25px;
    text-align: center;
    line-height: normal;
}

.CartPopover .CartItemGroup .CIGItem .CIGITitle a {
    display: block;
    color: #444444;
}

.CartPopover .CartItemGroup .CIGItem .CIGITitle a:hover {
    color: var(--dark-blue);
}

.CartPopover .CartItemGroup .CIGItem .CIGIRemove {
    padding: 4px;
    font-size: 7px;
    color: var(--white);
    background-color: #f44336;
    align-self: center;
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s;
}

.CartPopover .CartItemGroup .CIGItem:hover .CIGIRemove {
    visibility: visible;
    opacity: 1;
}

.CartPopover .CartItemGroup .CIGItem .CIGIPrice {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    width: 60px;
    text-align: right;
}

.PopoverMain.CartPopover .CartPopoverFooter {
    padding: 10px;
    border-top: 1px solid #e9e9e9;
}

.CartPopover .CartPopoverFooter button.mainButton {
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    column-gap: 10px;
}

.CartPopover .CartPopoverFooter button.mainButton span.buttonTitle {
    flex: unset;
}

.PopoverMain.HelpPopover {
    margin-left: 25px;
}

.PopoverMain.HelpPopover .PopoverMainInner::before {
    right: 30px;
    top: -5px;
    visibility: visible;
}

.HelpPopover .HelpPopoverMain {
    display: flex;
    flex-direction: column;
}

.HelpPopover .HelpPopoverMain .HPMHTitle {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}

.HelpPopover .HelpPopoverMain button.mainButton {
    width: 100%;
    border-radius: 0;
    border: 0;
    border-top: 1px solid #e8eef9;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    column-gap: 7px;
    background-color: #f6f8fc;
    color: #0c57ff;
}

.HelpPopover .HelpPopoverMain button.mainButton:hover {
    text-decoration: underline !important;
}

div#CartAnimation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    background-color: #1157f2;
    border-radius: 4px;
    color: #fff;
    transform: translate(0, 0);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    z-index: -9;
}

.ToolTipWrapper.APTWrapper {
    display: flex;
}

.APToolTip.PopoverMain.ToolTipPopover .PopoverMainInner::before {
    visibility: visible;
    top: -6px;
    left: calc(50% - 5px);
}

.SSOAccountList {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.SSOAccountList .SSOSkeletonContainer {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.SSOAccountList .DCDropdownSelect .buttonTitle .UserInfo .UIDetail .UIDetailName,
.SSOAccountList .DCDropdownSelect .buttonTitle .UserInfo .UIDetail .UIDetailEmail {
    text-align: start;
}

// Responsive
@media (min-width: 1559px) and (max-width: 1800px) {
    .MainDialog.MenuListDialog {
        top: 50px;
    }

    .MainDialog.MenuListDialog .MDBackdrop {
        top: 50px;
    }

    button.iconButton.NotificationIconButton {
        font-size: 23px;
    }

    button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
        height: 18px;
        font-size: 12px;
        min-width: 18px;
    }

    .MenuToggleProfile .ProfileMenuBtn {
        width: 30px;
        height: 30px;
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    button.iconButton.NotificationIconButton {
        font-size: 23px;
    }

    button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
        height: 18px;
        font-size: 12px;
        min-width: 18px;
    }

    .MenuToggleProfile .ProfileMenuBtn {
        width: 30px;
        height: 30px;
    }

    .MainDialog.MenuListDialog {
        top: 50px;
    }

    .MainDialog.MenuListDialog .MDBackdrop {
        top: 50px;
    }

    .MainDialog.MenuListDialog.rightSlide .MDContainer .MDCRow {
        width: 300px;
    }

    .MenuList .MenuListHeader .MLHTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .MHIMLItem a.MHIMLILink .MHIMLILIcon {
        font-size: 60px;
    }

    .MenuList .MenuListBody .MLBInner {
        padding-left: 7px;
    }

    .MHIMLItem .MHIMLIFav {
        padding: 2px;
        font-size: 12px;
        top: -10px;
        right: 4px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    button.iconButton.NotificationIconButton {
        font-size: 23px;
    }

    button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
        height: 18px;
        font-size: 12px;
        min-width: 18px;
    }

    .MenuToggleProfile .ProfileMenuBtn {
        width: 25px;
        height: 25px;
    }

    .MainDialog.MenuListDialog {
        top: 50px;
    }

    .MainDialog.MenuListDialog .MDBackdrop {
        top: 50px;
    }

    .MainDialog.MenuListDialog.rightSlide .MDContainer .MDCRow {
        width: 300px;
    }

    .MenuList .MenuListHeader .MLHTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .MHIMLItem a.MHIMLILink .MHIMLILIcon {
        font-size: 60px;
    }

    .MenuList .MenuListBody .MLBInner {
        padding-left: 7px;
    }

    .MHIMLItem .MHIMLIFav {
        padding: 2px;
        font-size: 12px;
        top: -10px;
        right: 4px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    button.iconButton.NotificationIconButton {
        font-size: 22px;
    }

    button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
        height: 16px;
        font-size: 10px;
        min-width: 16px;
    }

    .MenuToggleProfile .ProfileMenuBtn {
        width: 28px;
        height: 28px;
    }

    .MainDialog.MenuListDialog {
        top: 50px;
    }

    .MainDialog.MenuListDialog .MDBackdrop {
        top: 50px;
    }

    .MainDialog.MenuListDialog.rightSlide .MDContainer .MDCRow {
        width: 300px;
    }

    .MenuList .MenuListHeader .MLHTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .MHIMLItem a.MHIMLILink .MHIMLILIcon {
        font-size: 60px;
    }

    .MenuList .MenuListBody .MLBInner {
        padding-left: 7px;
    }

    .MHIMLItem .MHIMLIFav {
        padding: 2px;
        font-size: 12px;
        top: -10px;
        right: 4px;
    }

    .MenuToggleProfile .MenuToggleBtn {
        transform: scale(0.9);
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    button.iconButton.NotificationIconButton {
        font-size: 22px;
    }

    button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
        height: 16px;
        font-size: 10px;
        min-width: 16px;
    }

    .MenuToggleProfile .ProfileMenuBtn {
        width: 28px;
        height: 28px;
    }

    .MainDialog.MenuListDialog {
        top: 50px;
    }

    .MainDialog.MenuListDialog .MDBackdrop {
        top: 50px;
    }

    .MainDialog.MenuListDialog.rightSlide .MDContainer .MDCRow {
        width: 300px;
    }

    .MenuList .MenuListHeader .MLHTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .MHIMLItem a.MHIMLILink .MHIMLILIcon {
        font-size: 60px;
    }

    .MenuList .MenuListBody .MLBInner {
        padding-left: 7px;
    }

    .MHIMLItem .MHIMLIFav {
        padding: 2px;
        font-size: 12px;
        top: -10px;
        right: 4px;
    }

    .MenuToggleProfile .MenuToggleBtn {
        transform: scale(0.9);
    }

    .MenuList .MenuListHeader {
        padding: 10px 15px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    button.iconButton.NotificationIconButton {
        font-size: 22px;
    }

    button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
        height: 16px;
        font-size: 10px;
        min-width: 16px;
    }

    .MenuToggleProfile .ProfileMenuBtn {
        width: 26px;
        height: 26px;
    }

    .MenuToggleProfile .MenuToggleBtn {
        transform: scale(0.9);
    }

    .MainDialog.MenuListDialog {
        top: 50px;
    }

    .MainDialog.MenuListDialog .MDBackdrop {
        top: 50px;
    }

    .MainDialog.MenuListDialog.rightSlide .MDContainer .MDCRow {
        width: 300px;
    }

    .MenuList .MenuListHeader .MLHTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .MHIMLItem a.MHIMLILink .MHIMLILIcon {
        font-size: 60px;
    }

    .MenuList .MenuListBody .MLBInner {
        padding-left: 7px;
    }

    .MHIMLItem .MHIMLIFav {
        padding: 2px;
        font-size: 12px;
        top: -10px;
        right: 4px;
    }

    .MenuToggleProfile .MenuToggleBtn {
        transform: scale(0.9);
    }

    .MenuList .MenuListHeader {
        padding: 10px 15px;
    }
    .APToolTip.PopoverMain.ToolTipPopover {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    button.iconButton.NotificationIconButton {
        font-size: 22px;
    }

    button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
        height: 16px;
        font-size: 10px;
        min-width: 16px;
    }

    .MenuToggleProfile .ProfileMenuBtn {
        width: 26px;
        height: 26px;
    }

    .PopoverMain.UserDropdown .PopoverMainInner::before {
        top: -5px;
    }
    .MenuList .FavoritesMenus {
        display: flex;
        border-radius: 0;
        font-size: 27px;
        padding: 10px 15px;
        justify-content: center;
    }

    .MainDialog.MenuListDialog {
        top: 50px;
    }

    .MainDialog.MenuListDialog .MDBackdrop {
        top: 50px;
    }

    .MainDialog.MenuListDialog.rightSlide .MDContainer .MDCRow {
        width: 300px;
    }

    .MenuList .MenuListHeader {
        padding: 10px 15px;
    }

    .MenuList .MenuListHeader .MLHTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .MHIMLItem a.MHIMLILink .MHIMLILIcon {
        font-size: 60px;
    }

    .MenuList .MenuListBody .MLBInner {
        padding-left: 7px;
    }

    .MHIMLItem .MHIMLIFav {
        padding: 2px;
        font-size: 12px;
        top: -10px;
        right: 4px;
    }

    .MenuToggleProfile .MenuToggleBtn {
        transform: scale(0.9);
    }
    .APToolTip.PopoverMain.ToolTipPopover {
        display: none;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
    /* mobile portrait */
    .WBSubscription {
        background: transparent !important;
        position: absolute !important;
        right: 8px !important;
        top: 8px !important;
    }
    .WarningBar {
        padding-right: 35px !important;
    }
}

@media only screen and (min-width: 321px) and (max-device-width: 960px) and (orientation: landscape) {
    /* mobile landscape */

    .WBSubscription {
        background: transparent !important;
        position: absolute !important;
        right: 8px !important;
        top: 8px !important;
    }
    .WarningBar {
        padding-right: 35px !important;
    }
}

@media (max-width: 575.98px) {
    button.iconButton.NotificationIconButton {
        font-size: 20px;
    }

    button.iconButton.NotificationIconButton .badgeRoot.NotificationBadge .badgeContent {
        height: 16px;
        font-size: 10px;
        min-width: 16px;
    }

    .MenuToggleProfile {
        padding-left: 10px;
    }

    .MenuToggleProfile .ProfileMenuBtn {
        width: 26px;
        height: 26px;
        margin-left: 10px;
    }

    .PopoverMain.UserDropdown .PopoverMainInner::before {
        top: -5px;
    }

    .MainDialog.MenuListDialog {
        top: 50px;
    }

    .MainDialog.MenuListDialog .MDBackdrop {
        top: 50px;
    }

    .MainDialog.MenuListDialog.rightSlide .MDContainer .MDCRow {
        width: 300px;
    }

    .MenuList .MenuListHeader {
        padding: 10px 15px;
    }

    .MenuList .MenuListHeader .MLHTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .MHIMLItem a.MHIMLILink .MHIMLILIcon {
        font-size: 60px;
    }

    .MenuList .MenuListBody .MLBInner {
        padding-left: 7px;
    }

    .MHIMLItem .MHIMLIFav {
        display: none;
    }

    .MenuToggleProfile .MenuToggleBtn {
        transform: scale(0.9);
    }

    .PopoverMain.CartPopover.no-placement {
        margin-left: 0;
    }

    .PopoverMain.CartPopover.ArrowPopover.no-placement .PopoverMainInner::before {
        right: 160px;
    }

    .MenuBarRight {
        column-gap: 10px;
    }

    .MenuBarRight button.iconButton.CartIconButton {
        font-size: 20px;
        transform: translateX(7px);
    }

    .MenuBarRight .CartIconButton .badgeRoot .badgeContent {
        height: 16px;
        font-size: 10px;
        min-width: 16px;
    }

    button.iconButton.HelpIconBtn {
        font-size: 20px;
    }

    .PopoverMain.HelpPopover {
        margin-left: 0px;
    }

    .PopoverMain.HelpPopover .PopoverMainInner::before {
        right: 115px;
    }

    .APToolTip.PopoverMain.ToolTipPopover {
        display: none;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
    /* mobile portrait */
    .MBSDBHPOButton {
        display: none;
    }
}

@media only screen and (min-width: 321px) and (max-device-width: 960px) and (orientation: landscape) {
    /* mobile landscape */
    .MBSDBHPOButton {
        display: none;
    }
}

.HeaderSearch {
    padding: 0 !important;
    border-radius: 6px;
    overflow: hidden;
    background: #38383d !important;
    transition: all 0.5s ease;
    width: 12rem;
    transition: all 0.3s ease;

    & * {
        border: none !important;
        color: #fff;

        input {
            color: #fff;

            &::placeholder {
                color: rgb(226, 226, 226);
            }
        }
    }

    .SearchBox {
        background: transparent;
    }

    input {
        font-size: 14px;
        padding: 7.5px;
        border-radius: 4px;
        padding-left: 30px;
        padding-right: 25px;
    }

    &.Big {
        width: 26rem;
    }
}

.HeaderSearchPopover {
    margin-top: 2px !important;
}

.MenuBarSearchBarClass {
    padding: 0.6rem 0.6rem 0 !important;

    .SearchBox {
        border-radius: 4px;
        overflow: hidden !important;
    }
}

.HeaderSearchIcon {
    background: transparent !important;
    border: none !important;

    @media screen and (min-width: 768px) {
        margin-right: -2rem;
    }

    @media screen and (max-width: 767px) {
        transform: translateX(1rem);
    }

    @media screen and (max-width: 575px) {
        transform: translateX(0.8rem) scale(1.1);
    }

    svg {
        width: 1rem;
        height: 1rem;
        fill: var(--blue-invert);
    }
}

.HeaderSearchPopoverBox {
    margin-top: -0.6rem;

    .HeaderSearch {
        width: 100% !important;
    }

    & + .HeaderSearchPopover .PopoverMainInner {
        margin-left: -0.64rem;
    }

    .PopoverMainInner::before {
        @media screen and (min-width: 768px) and (max-width: 849px) {
            left: 7px !important;
            right: 0;
        }
    }
}
