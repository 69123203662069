.footerBar {
    padding: 0 15px 0px 40px;
    background-color: #f6f8fc;
    height: 60px;
    position: relative;
    border-top: 1px solid #eaeaea;
}

.footerBar.DialogFooterBar {
    padding: 0 15px;
}

.footerBar .footerBarInner {
    display: flex;
    height: 100%;
}
