.MainDialog.FilePreviewDialogMain .MDContainer .MDCRow {
    height: 100%;
}

.FilePreviewDialog .HeaderBar .HeaderBarClose button {
    margin-left: 15px;
}

.FilePreviewDialog {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.FilePreviewDialog .BodyBox {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.FilePreviewDialog .BodyBox .BodyBoxInner .pg-viewer-wrapper .pg-viewer .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Header .react-grid-HeaderRow div .react-grid-HeaderCell .react-grid-HeaderCell-sortable,
.FilePreviewDialogRow .BodyBox .BodyBoxInner .pg-viewer-wrapper .pg-viewer .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Header .react-grid-HeaderRow div .react-grid-HeaderCell .react-grid-HeaderCell-sortable{
pointer-events: none;
}

.FilePreviewDialog .pdf-viewer-container {
    height: 100%;
}

.FilePreviewDialog .pdf-viewer-container .pdf-viewer {
    height: 100%;
}

.FilePreviewDialog .pdf-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
}

.pg-driver-view .video-container{
    display: flex !important;
    flex-direction : column !important;
}

.pg-viewer-wrapper .document-container {
    overflow-wrap: break-word;
}
.pg-viewer-wrapper img {
    width: auto !important;
}

.pg-viewer-wrapper blockquote,
.pg-viewer-wrapper body,
.pg-viewer-wrapper bodyaddress,
.pg-viewer-wrapper center,
.pg-viewer-wrapper dd,
.pg-viewer-wrapper dir,
.pg-viewer-wrapper div,
.pg-viewer-wrapper dl,
.pg-viewer-wrapper dt,
.pg-viewer-wrapper fieldset,
.pg-viewer-wrapper form,
.pg-viewer-wrapper frame,
.pg-viewer-wrapper frameset,
.pg-viewer-wrapper h1,
.pg-viewer-wrapper h2,
.pg-viewer-wrapper h3,
.pg-viewer-wrapper h4,
.pg-viewer-wrapper h5,
.pg-viewer-wrapper h6,
.pg-viewer-wrapper hr,
.pg-viewer-wrapper html,
.pg-viewer-wrapper menu,
.pg-viewer-wrapper noframes,
.pg-viewer-wrapper ol,
.pg-viewer-wrapper p,
.pg-viewer-wrapper pre,
.pg-viewer-wrapper ul {
    text-align: center;
}

@media (min-width: 576px) and (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
    .MainDialog.FilePreviewDialogMain .MDContainer .MDCRow {
        height: 100%;
        max-height: 100%;
        margin: 0;
    }

    .FilePreviewDialog .HeaderBar h1.HeaderBarTitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .FilePreviewDialog .HeaderBar {
        overflow: hidden;
    }

    .FilePreviewDialog .pg-viewer-wrapper .pg-viewer {
        width: 100%;
    }
}
