.PCRViewMain .PCRViewContainer.DCFBContainer {
    padding: 0;
}
.DCFormBuilder {
    flex: 1;
    overflow: auto;
    position: relative;
}
.DCFormBuilder .DCFBSidebar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    background-color: #fff;
    width: 270px;
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}
.DCFormBuilder > div {
    height: 100%;
    overflow: auto;
}

.DCFormBuilder .formbuilder {
    margin: 0;
    height: 100%;
    overflow: auto;
}
.DCFormBuilder .formbuilder .formcomponents {
    padding: 0;
    max-width: 270px;
    flex: unset;
    border-right: 1px solid #eaeaea;
    overflow: auto;
    height: 100%;
    width: 100%;
}
.DCFormBuilder a {
    word-wrap: break-word !important;
}
.DCFormBuilder .builder-sidebar {
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
    background-color: #fff;
    position: relative;
    top: 0;
}

.DCFormBuilder .builder-sidebar .builder-sidebar_search {
    outline: 0;
    box-shadow: unset;
    border: 0;
    font-size: 14px;
    height: auto;
    padding: 13px 15px;
    padding-left: 40px;
    margin: 0;
    background-image: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 512 507' ><path d='M506.1,471.6L377.5,343.1c29.4-37.1,45.3-82.7,45.3-130.8c0-56.5-22-109.6-61.9-149.5 C321,22.8,267.9,0.9,211.4,0.9s-109.6,22-149.5,61.9S0,155.8,0,212.3s22,109.6,61.9,149.5c39.9,39.9,93,61.9,149.5,61.9 c51.3,0,99.8-18.1, 138.2-51.4l127.9,127.9c7.9,7.9,20.7,7.9,28.6,0v0C514,492.3,514,479.5,506.1,471.6z M211.4,391 c-98.6,0-178.7-80.2-178.7-178.7S112.8,33.5,211.4,33.5s178.7,80.2,178.7,178.7S310,391,211.4,391z' fill='rgb(146 146 146)'/></svg>");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 15px;
}

.DCFormBuilder .builder-sidebar .form-builder-group-header {
    background-color: unset;
    border: 0;
    padding: 0;
}

.DCFormBuilder .builder-sidebar .form-builder-panel {
    background-color: unset;
    border: 0;
}

.DCFormBuilder .builder-sidebar .form-builder-group-header .builder-group-button {
    padding: 15px 15px;
    text-align: left;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: normal;
    position: sticky;
    top: 0;
    z-index: 1;
    outline: 0;
    box-shadow: unset;
    border-bottom: 1px solid #eaeaea;
}

.DCFormBuilder .builder-sidebar .form-builder-group-header .builder-group-button::before {
    content: '';
    position: absolute;
    right: 16px;
    top: 16px;
    width: 12px;
    height: 12px;
    border-bottom: 3px solid #38383d;
    border-right: 3px solid #38383d;
    border-radius: 3px;
    transform: rotate(45deg);
}

.DCFormBuilder .builder-sidebar .form-builder-panel .card-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eaeaea;
}

.DCFormBuilder .builder-sidebar .form-builder-panel .card-body[data-type='file'] {
    order: 1;
}

.DCFormBuilder .builder-sidebar .form-builder-panel .card-body[data-type='image'] {
    order: 1;
}

.DCFormBuilder .builder-sidebar .form-builder-panel .card-body span.formcomponent {
    background-color: unset;
    margin: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    min-width: 50%;
    flex: 1;
    cursor: move;
    -webkit-user-select: none;
    user-select: none;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #edf2fb;
    color: #777777;
    font-size: 15px;
    font-weight: 400;
    row-gap: 10px;
    transition: all 0.3s ease;

    &:hover {
        background-color: #cad3e3;
        color: #646464;

        & > i {
            background-color: #abb5c5;
            color: #ffffff;
        }
    }
}

.DCFormBuilder .builder-sidebar .form-builder-panel .card-body span.formcomponent:nth-child(2n -1) {
    border-right: 1px solid #f6f8fc;
}

.DCFormBuilder .builder-sidebar .form-builder-panel .card-body span.formcomponent:last-child,
.DCFormBuilder
    .builder-sidebar
    .form-builder-panel
    .card-body
    span.formcomponent:nth-last-of-type(-n + 2):not(:nth-child(even)) {
    border-bottom: 0;
}

.builder-component .component-btn-group {
    background-color: #555557;
    border-radius: 4px;
    column-gap: 5px;
    border: 1px solid #000000;
}

.builder-component .component-btn-group .component-settings-button {
    margin: 0;
    padding: 0;
    box-shadow: unset;
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    display: block !important;
    border: 0;
    background-color: unset;
    color: #fff;
    background-color: #555557 !important;
}

span.formcomponent.gu-mirror {
    border: 0;
    background-color: #7b89a4;
    color: var(--blue-invert);
    padding: 10px 15px;
    user-select: none;
    cursor: move !important;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    font-size: 15px;

    & > i {
        font-size: 28px;
        padding: 0 0.6rem;
    }
}

.DCFormBuilder .builder-sidebar .form-builder-panel .card-body span.formcomponent > i {
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f8fc;
    min-width: 3.5rem !important;
    max-width: 3.5rem !important;
    color: #7b89a4;
    margin: 0;
    margin-right: 1rem !important;
    padding: 0.6rem 1rem;
    transition: all 0.3s ease;
}

.DCFormBuilder .formbuilder .formarea {
    width: calc(100% - 270px);
    max-width: unset;
    flex: unset;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 100%;
}

.formio-dialog .formio-dialog-content {
    overflow-y: overlay;
    overflow-x: overlay;
}

.formio-dialog .formio-dialog-content .component-edit-container {
    padding: 15px;
}

.DCFBContainer .DCFBBar {
    display: flex;
}
.DCFBContainer .DCFBBar .DCFBBTabs {
    width: 270px;
    background-color: #fff;
    border-right: 1px solid #eaeaea;
    display: flex;
    border-bottom: 1px solid #eaeaea;
}

.DCFBContainer .DCFBBar .DCFBBTabs button.mainButton {
    width: 50%;
    padding: 16px;
    font-size: 14px;
    border-radius: 0;
    border: 0;
}

.DCFBContainer .DCFBBar .DCFBBURTP {
    width: calc(100% - 270px);
    display: flex;
}

.DCFBContainer .DCFBBar .DCFBBURTP .DCFBBURTPUR {
    display: flex;
    align-items: center;
    min-width: 100px;
}
.DCFBContainer .DCFBBar .DCFBBURTP {
    width: calc(100% - 270px);
    display: flex;
    padding: 10px 15px;
    background-color: #cad3e3;
}

.DCFBContainer .DCFBBar .DCFBBURTP .DCFBBURTPUR button.iconButton {
    margin-right: 10px;
    padding: 5px;
    font-size: 16px;
}

.DCFBContainer .DCFBBar .DCFBBURTP .DCFBBURTPTitle {
    flex: 1 1;
    align-self: center;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #26262b;
}

.DCFBContainer .DCFBBar .DCFBBURTP .DCFBBURTPTView {
    display: flex;
}

.DCFBContainer .DCFBBar .DCFBBURTP .DCFBBURTPTView button.iconButton {
    margin-left: 10px;
    padding: 5px;
    font-size: 16px;
}

//

.BuilderStyle .BSItem .BSIIHeading {
    padding: 10px;
    text-align: center;
    background-color: #eaeaea;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: normal;
    position: sticky;
    top: 0;
    z-index: 1;
}

.BuilderStyle .BSItem .BSInfo .BSISwitch {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #f6f8fc;
}

.BuilderStyle .BSItem .BSInfo .BSISwitch .BSISTitle {
    flex: 1 1;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #666;
}
.BuilderStyle .BSItem .ColorPickerMain {
    padding: 10px 15px;
    border-bottom: 1px solid #f6f8fc;
}

.BuilderStyle .BSItem .BSIInput {
    padding: 10px 15px;
    border-bottom: 1px solid #f6f8fc;
}

.BuilderOptionPopover .BOPBtn {
    display: flex;
    justify-content: center;
}

.BuilderOptionPopover .BOPBtn button {
    margin-right: 10px;
}

.BuilderOption .BOItem {
    padding: 10px 15px;
    border-bottom: 1px solid #f6f8fc;
}
.DCFormBuilder .DCFBSRemove .formcomponents {
    visibility: hidden;
    opacity: 0;
}

.FormPreviewDialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.FormPreviewDialog .DCFormBuilder .DCFBInner {
    padding: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.dcwizard-form-page > ol.breadcrumb {
    padding: 10px;
    margin-bottom: 10px;
    grid-column-gap: 10px;
    column-gap: 10px;
    flex-wrap: unset;
    overflow-x: overlay;
}

.dcwizard-form-page > ol.breadcrumb li .wizard-page-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 10px;
    border-radius: 1px;
    letter-spacing: 0.5px;
    margin-right: 0 !important;
}

// Form Component Dialog
.formio-dialog-component-settings.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
    padding: 0;
    height: 100%;
}
.formio-dialog-component-settings.formio-dialog .formio-dialog-content .component-edit-container {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.formio-dialog-component-settings.formio-dialog .row.fdcsc-title {
    padding: 15px 15px;
    margin: 0;
    background-color: #000;
    border-bottom: 1px solid #eaeaea;
}
.formio-dialog-component-settings.formio-dialog .row.fdcsc-title p.lead {
    font-size: 22px;
    font-weight: 500;
    color: var(--white);
    opacity: 0.8;
    flex: 1 1;
    margin: 0;
    line-height: 29px;
}

.formio-dialog-component-settings.component-settings button.formio-dialog-close {
    border-radius: 100%;
    background-color: #26262b;
    border-color: transparent;
    color: var(--white);
    padding: 3px 8px;
    top: 10px;
    right: 15px;
}
.formio-dialog-component-settings.formio-dialog
    .formio-dialog-content
    .component-edit-container
    .row.fdcsc-editform {
    margin: 0;
    padding: 15px;
    overflow: auto;
    flex: 1 1;
    background-color: #f6f8fc;
    column-gap: 15px;
    flex-wrap: nowrap;
}
.formio-dialog-component-settings.formio-dialog
    .formio-dialog-content
    .component-edit-container
    .row.fdcsc-editform
    .col.fdcscef-col {
    padding: 0;
    flex: unset;
    width: calc(50% - 15px);
    overflow: auto;
}

.row.fdcsc-editform .col.fdcscef-col .formio-component-tabs .card-header {
    padding: 0 10px;
    padding-top: 10px;
}

.row.fdcsc-editform .col.fdcscef-col .formio-component-tabs .card-header ul.nav.nav-tabs {
    margin: 0;
}

.row.fdcsc-editform
    .col.fdcscef-col
    .formio-component-tabs
    .card-header
    ul.nav.nav-tabs
    li.nav-item {
    flex: 1;
}

.row.fdcsc-editform
    .col.fdcscef-col
    .formio-component-tabs
    .card-header
    ul.nav.nav-tabs
    li.nav-item
    a.nav-link {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

body.modal-open .pageWrapper {
    filter: blur(3px);
    overflow: hidden;
}

.row.fdcsc-editform
    .col.fdcscef-col
    .formio-component-tabs
    .formio-component
    input.formio-select-autocomplete-input {
    position: absolute;
}

.row.fdcsc-editform .col.fdcscef-col .formio-component-tabs .card-body.tab-pane {
    padding: 10px;
}

.ace_editor.ace_hidpi.ace-xcode {
    height:182px !important;
}

.formio-dialog-component-settings.formio-dialog
    .formio-dialog-content
    .component-edit-container
    .ace-xcode
    .ace_gutter {
    display: none;
}

.formio-dialog-component-settings.formio-dialog
    .formio-dialog-content
    .component-edit-container
    .ace_scroller {
    left: 0 !important;
    bottom: 10px !important;
}

.formio-dialog-component-settings.formio-dialog
    .formio-dialog-content
    .component-edit-container
    .ace_editor.ace-xcode {
    border: 1px solid #ced4da;
}

.formio-dialog-component-settings.formio-dialog
    .formio-dialog-content
    .component-edit-container
    .ace_print-margin {
    display: none;
}

.formio-dialog-component-settings.formio-dialog
    .formio-dialog-content
    .component-edit-container
    .ace_active-line {
    background-color: unset;
}

.formio-dialog-component-settings.formio-dialog
    .formio-dialog-content
    .component-edit-container
    .ace_content {
    top: 10px;
    bottom: 10px;
    // left: 10px;
}

//  Form Root
:root {
    --form-page-background: #fff;
    --form-background: #fff;
    --form-label-font-color: #000;
    --form-border-color: #ffffff;
    --form-button-background-color: #2780e3;
    --form-button-font-color: #ffffff;
    --form-border-width: 0px;
    --form-border-radius: 0px;
    --form-width: 100%;
}

.DCFormBuilder .formbuilder .formarea {
    background-color: var(--form-page-background);
}

.DCFormBuilder .formbuilder .formarea > .formio-component.formio-component-label-hidden {
    width: var(--form-width);
    background-color: var(--form-background);
    border-style: solid;
    border-width: var(--form-border-width);
    border-color: var(--form-border-color);
    border-radius: var(--form-border-radius);
    min-width: 375px;
    padding: 30px;
    height: 100%;
    overflow: auto;
}

.DCFormBuilder .formbuilder .formarea > .formio-component .form-group .col-form-label,
.DCFormBuilder .formbuilder .formarea > .formio-component .form-group label[for='day-month'],
.DCFormBuilder .formbuilder .formarea > .formio-component .form-group label[for='day-day'],
.DCFormBuilder .formbuilder .formarea > .formio-component .form-group label[for='day-year'],
.DCFormBuilder .formbuilder .formarea > .formio-component .form-group .form-check-label {
    color: var(--form-label-font-color);
}

.DCFormBuilder .formbuilder .formarea > .formio-component .form-group .btn {
    color: var(--form-button-font-color);
    background-color: var(--form-button-background-color);
    border-color: var(--form-button-background-color);
}

// Form Preview

.FormPreviewDialog .DCFormBuilder .DCFBInner {
    background-color: var(--form-page-background);
}

.FormPreviewDialog .DCFormBuilder .DCFBInner .DCFBVew {
    width: var(--form-width);
    background-color: var(--form-background);
    border-style: solid;
    border-width: var(--form-border-width);
    border-color: var(--form-border-color);
    border-radius: var(--form-border-radius);
    min-width: 375px;
    padding: 30px;
}

.FormPreviewDialog .DCFormBuilder .DCFBInner .DCFBVew .form-group .col-form-label,
.FormPreviewDialog .DCFormBuilder .DCFBInner .DCFBVew .form-group label[for='day-month'],
.FormPreviewDialog .DCFormBuilder .DCFBInner .DCFBVew .form-group label[for='day-day'],
.FormPreviewDialog .DCFormBuilder .DCFBInner .DCFBVew .form-group label[for='day-year'],
.FormPreviewDialog .DCFormBuilder .DCFBInner .DCFBVew .form-group .form-check-label {
    color: var(--form-label-font-color);
}

.FormPreviewDialog .DCFormBuilder .DCFBInner .DCFBVew .form-group .btn {
    color: var(--form-button-font-color);
    background-color: var(--form-button-background-color);
    border-color: var(--form-button-background-color);
}

// Wizard Form

.DCFormBuilder .formbuilder .formarea .dcwizard-form-page {
    width: var(--form-width);
    background-color: var(--form-background);
    border-style: solid;
    border-width: var(--form-border-width);
    border-color: var(--form-border-color);
    border-radius: var(--form-border-radius);
    min-width: 375px;
    padding: 30px;
    height: 100%;
    overflow: auto;
}

.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .btn-primary {
    color: var(--form-button-font-color);
    background-color: var(--form-button-background-color);
    border-color: var(--form-button-background-color);
}

.FormPresenterDialog.FPDUserInfo .DCFormBuilder .DCFBInner .DCFBVew .btn-wizard-nav-submit {
    color: var(--user-form-button-font-color);
    background-color: var(--user-form-button-background-color);
    border-color: var(--user-form-button-background-color);
}

.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .dcwizard-sort-page {
    display: flex;
    background-color: #000;
}

.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .dcwizard-sort-page ol {
    margin: 0;
    background-color: unset;
    display: flex;
    padding: 0;
    column-gap: 15px;
    flex-wrap: nowrap;
}
.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .dcwizard-sort-page ol:first-of-type {
    width: calc(100% - 100px);
    overflow: overlay;
}
.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .dcwizard-sort-page ol:last-of-type {
    width: 100px;
    overflow: overlay;
    justify-content: center;
}
.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .dcwizard-sort-page {
    display: flex;
    background-color: #cad3e3;
    padding: 10px 15px;
    margin-bottom: 15px;
    column-gap: 15px;
}

.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .dcwizard-sort-page ol li {
    display: flex;
    margin: 5px 0px;
}

.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .dcwizard-sort-page ol li span {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 0 !important;
}

.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .dcwizard-sort-page ol li span i {
    margin-right: 4px;
    font-size: 14px;
}

.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .form-group .form-check-label,
.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .form-group label[for='day-month'],
.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .form-group label[for='day-day'],
.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .form-group label[for='day-year'],
.DCFormBuilder .formbuilder .formarea .dcwizard-form-page .form-group .col-form-label {
    color: var(--form-label-font-color);
}

.builder-component .h1,
.builder-component .h2,
.builder-component .h3,
.builder-component .h4,
.builder-component .h5,
.builder-component .h6,
.builder-component h1,
.builder-component h2,
.builder-component h3,
.builder-component h4,
.builder-component h5,
.builder-component h6,
.formio-dialog-component-settings .h1,
.formio-dialog-component-settings .h2,
.formio-dialog-component-settings .h3,
.formio-dialog-component-settings .h4,
.formio-dialog-component-settings .h5,
.formio-dialog-component-settings .h6,
.formio-dialog-component-settings h1,
.formio-dialog-component-settings h2,
.formio-dialog-component-settings h3,
.formio-dialog-component-settings h4,
.formio-dialog-component-settings h5,
.formio-dialog-component-settings h6,
.DCFormBuilder .h1,
.DCFormBuilder .h2,
.DCFormBuilder .h3,
.DCFormBuilder .h4,
.DCFormBuilder .h5,
.DCFormBuilder .h6,
.DCFormBuilder h1,
.DCFormBuilder h2,
.DCFormBuilder h3,
.DCFormBuilder h4,
.DCFormBuilder h5,
.DCFormBuilder h6 {
    color: var(--form-label-font-color);
}

.DCFormBuilder .formio-form .btn.btn-wizard-nav-submit {
    color: var(--form-button-font-color);
    background-color: var(--form-button-background-color);
    border-color: var(--form-button-background-color);
}

.DCFormBuilder .formio-form ul.pagination {
    justify-content: center;
}

#FormElementDeleteConfirm {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

#FormElementDeleteConfirm .FEDCOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background-color: #00000080;
}

#FormElementDeleteConfirm .FEDCCard {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024,
        0px 9px 46px 8px #0000001f;
    position: relative;
    z-index: 99999;
    max-width: 600px;
    width: 100%;
    overflow: hidden;
}

#FormElementDeleteConfirm .FEDCCard .FEDCBody {
    padding: 30px;
}

#FormElementDeleteConfirm .FEDCCard button.FEDCClose {
    position: absolute;
    right: 7px;
    top: 7px;
    background-color: unset;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 14px;
}

#FormElementDeleteConfirm .FEDCCard .FEDCBody .FEDCBTitle {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
}

#FormElementDeleteConfirm .FEDCCard .FEDCBody .FEDCBSTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
    text-align: center;
    color: #666666;
}

#FormElementDeleteConfirm .FEDCCard .FEDCBody .FEDCBSTitle strong {
    color: #000000;
}

#FormElementDeleteConfirm .FEDCCard .FEDCBody .FEDCBBtns {
    display: flex;
    justify-content: center;
    column-gap: 15px;
    margin-top: 30px;
}

#FormElementDeleteConfirm .FEDCCard .FEDCBody .FEDCBBtns button {
    display: inline-flex;
    background-color: var(--white);
    font-size: 14px;
    text-transform: none;
    color: #84929e;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #f2f2f2;
    outline: 0;
    min-width: 70px;
    padding: 10px 20px;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    transition: all ease 0.3s;
    position: relative;
    cursor: pointer;
}

#FormElementDeleteConfirm .FEDCCard .FEDCBody .FEDCBBtns button.FEDCBBDel {
    background-color: #f44336;
    border-color: #f44336;
    color: #fff;
}

#FormElementDeleteConfirm .FEDCCard .FEDCFooter {
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: var(--dark-blue);
}

#FormElementDeleteConfirm .FEDCCard .FEDCFooter .FEDCFIcon {
    font-size: 35px;
    display: flex;
    color: var(--blue-invert);
}

#FormElementDeleteConfirm .FEDCCard .FEDCFooter .FEDCFTitle {
    flex: 1;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
    color: var(--blue-invert);
}

.DCFSkeleton {
    display: flex;
    width: 100%;
    height: 100%;
}

.DCFSkeleton .DCFSSide {
    width: 270px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 15px;
    overflow: auto;
    border-right: 1px solid #eaeaea;
}

.DCFSkeleton .DCFSRight {
    width: calc(100% - 270px);
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.form-control .formio-multiple-mask-select {
    background-color: #ced4da;
    font-weight: 100;
    word-spacing: 6px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.form-control.formio-multiple-mask-select:disabled {
    appearance: none;
    width: 5px;
}

// Responsive
@media (min-width: 1559px) and (max-width: 1800px) {
    .DCFBContainer .DCFBBar .DCFBBTabs {
        width: 250px;
    }
    .DCFormBuilder .DCFBSidebar {
        width: 250px;
    }
    .DCFBContainer .DCFBBar .DCFBBURTP {
        width: calc(100% - 250px);
    }
    .DCFormBuilder .formbuilder .formcomponents {
        max-width: 250px;
    }
    .DCFormBuilder .formbuilder .formarea {
        width: calc(100% - 250px);
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    .DCFBContainer .DCFBBar .DCFBBTabs {
        width: 250px;
    }
    .DCFormBuilder .DCFBSidebar {
        width: 250px;
    }
    .DCFBContainer .DCFBBar .DCFBBURTP {
        width: calc(100% - 250px);
    }
    .DCFormBuilder .formbuilder .formcomponents {
        max-width: 250px;
    }
    .DCFormBuilder .formbuilder .formarea {
        width: calc(100% - 250px);
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .DCFBContainer .DCFBBar .DCFBBTabs {
        width: 250px;
    }
    .DCFormBuilder .DCFBSidebar {
        width: 250px;
    }
    .DCFBContainer .DCFBBar .DCFBBURTP {
        width: calc(100% - 250px);
    }
    .DCFormBuilder .formbuilder .formcomponents {
        max-width: 250px;
    }
    .DCFormBuilder .formbuilder .formarea {
        width: calc(100% - 250px);
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .DCFBContainer .DCFBBar .DCFBBTabs {
        width: 200px;
    }
    .DCFormBuilder .DCFBSidebar {
        width: 200px;
    }
    .DCFBContainer .DCFBBar .DCFBBURTP {
        width: calc(100% - 200px);
    }
    .DCFormBuilder .formbuilder .formcomponents {
        max-width: 200px;
    }
    .DCFormBuilder .formbuilder .formarea {
        width: calc(100% - 200px);
    }

    .DCFBContainer .DCFBBar .DCFBBTabs button.mainButton {
        width: 33.33%;
        font-size: 14px;
        min-width: auto;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .DCFBContainer .DCFBBar .DCFBBTabs {
        width: 200px;
    }
    .DCFormBuilder .DCFBSidebar {
        width: 200px;
    }
    .DCFBContainer .DCFBBar .DCFBBURTP {
        width: calc(100% - 200px);
    }
    .DCFormBuilder .formbuilder .formcomponents {
        max-width: 200px;
    }
    .DCFormBuilder .formbuilder .formarea {
        width: calc(100% - 200px);
    }
    .DCFBContainer .DCFBBar .DCFBBTabs button.mainButton {
        width: 33.33%;
        font-size: 14px;
        min-width: auto;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .DCFormBuilder .DCFBSidebar {
        width: 230px;
        transition: all ease 0.3s;
        transform: translateX(-100%);
    }
    .DCFBContainer .DCFBBar .DCFBBURTP {
        width: 100%;
    }
    .DCFBContainer.DCFBCSBRV .DCFBSidebar {
        transform: translateX(0);
    }
    .DCFormBuilder .formbuilder .formcomponents {
        max-width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .DCFormBuilder .formbuilder .formarea {
        width: 100%;
    }
    .DCFBContainer .DCFBBar .DCFBBTabs {
        width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .DCFBContainer.DCFBCSBRV .DCFBBar .DCFBBTabs {
        transform: translateX(0);
    }
    .DCFBContainer.DCFBCSBRV .DCFormBuilder .formbuilder .formcomponents {
        transform: translateX(0);
    }
    .DCFBContainer .DCFBBar .DCFBBTabs button.mainButton {
        width: 33.33%;
        font-size: 14px;
        min-width: auto;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .DCFormBuilder .DCFBSidebar {
        width: 230px;
        transition: all ease 0.3s;
        transform: translateX(-100%);
    }
    .DCFBContainer .DCFBBar .DCFBBURTP {
        width: 100%;
    }
    .DCFBContainer.DCFBCSBRV .DCFBSidebar {
        transform: translateX(0);
    }
    .DCFormBuilder .formbuilder .formcomponents {
        max-width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .DCFormBuilder .formbuilder .formarea {
        width: 100%;
    }
    .DCFBContainer .DCFBBar .DCFBBTabs {
        width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .DCFBContainer.DCFBCSBRV .DCFBBar .DCFBBTabs {
        transform: translateX(0);
    }
    .DCFBContainer.DCFBCSBRV .DCFormBuilder .formbuilder .formcomponents {
        transform: translateX(0);
    }
    .DCFBContainer .DCFBBar .DCFBBTabs button.mainButton {
        width: 33.33%;
        font-size: 14px;
        min-width: auto;
    }
}

@media (max-width: 575.98px) {
    .DCFormBuilder .DCFBSidebar {
        width: 230px;
        transition: all ease 0.3s;
        transform: translateX(-100%);
    }
    .DCFBContainer .DCFBBar .DCFBBURTP {
        width: 100%;
    }
    .DCFBContainer.DCFBCSBRV .DCFBSidebar {
        transform: translateX(0);
    }
    .DCFormBuilder .formbuilder .formcomponents {
        max-width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .DCFormBuilder .formbuilder .formarea {
        width: 100%;
        padding: 15px 0;
        overflow-y: overlay;
    }
    .DCFBContainer .DCFBBar .DCFBBTabs {
        width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        transform: translateX(-100%);
        transition: all ease 0.3s;
    }
    .DCFBContainer.DCFBCSBRV .DCFBBar .DCFBBTabs {
        transform: translateX(0);
    }
    .DCFBContainer.DCFBCSBRV .DCFormBuilder .formbuilder .formcomponents {
        transform: translateX(0);
    }
    .DCFBContainer .DCFBBar .DCFBBTabs button.mainButton {
        width: 33.33%;
        font-size: 14px;
        min-width: auto;
    }
    .DCFormBuilder .formbuilder .formarea > .formio-component.formio-component-label-hidden {
        min-width: auto;
        padding: 15px;
    }

    .DCFormBuilder .formbuilder .formarea .dcwizard-form-page {
        padding: 15px;
    }

    .formio-dialog-component-settings.formio-dialog
        .formio-dialog-content
        .component-edit-container
        .row.fdcsc-editform {
        flex-direction: column;
        row-gap: 15px;
        overflow-y: overlay;
    }

    .formio-dialog-component-settings.formio-dialog
        .formio-dialog-content
        .component-edit-container
        .row.fdcsc-editform
        .col.fdcscef-col {
        width: 100%;
        overflow: unset;
    }

    .formio-dialog-component-settings.formio-dialog.formio-dialog-theme-default
        .formio-dialog-content {
        width: 100%;
        border-radius: 0px;
    }

    .MainDialog.centerZoom.sizeFull .MDContainer .MDCRow.FormPreviewDialogRow {
        max-width: 100%;
        max-height: 100%;
        margin: 0;
    }

    .FormPreviewDialog .DCFormBuilder .DCFBInner .DCFBVew {
        min-width: auto;
    }

    .formio-dialog-component-settings.formio-dialog {
        padding: 0 !important;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait),
    only screen and (min-width: 321px) and (max-device-width: 960px) and (orientation: landscape),
    only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* mobile portrait */
    .formio-form nav {
        display: block !important;
    }
    .formio-form nav ul {
        height: 50px !important;
        width: 100% !important;
        overflow-x: scroll;
        overflow-y: hidden;
        display: block !important;
        padding: 5px;
        white-space: nowrap !important;
    }
    .formio-form nav > ul > li {
        display: inline-block !important;
        width: auto;
        height: 48px !important;
        padding-left: 2px;
    }

    .formio-form > div > nav > ul.pagination .page-link {
        display: flex;
        width: 100%;
        // width: 145px;
        padding-left: 5px;
        padding-right: 3px;
        height: 36px !important;
        margin: auto 3px;
    }

    .builder-component .page-link,
    .formio-dialog-component-settings .page-link,
    .DCFormBuilder .page-link {
        height: 35px !important;
        border: 1px solid #1d74d9 !important;
        background: none;
        padding-left: 3px;
    }
}
.MainDialog.rightSlide .formio-form nav {
    display: block !important;
}
.MainDialog.rightSlide .formio-form nav ul {
    height: 50px;
    width: 100% !important;
    overflow-x: scroll;
    overflow-y: hidden;
    display: inline-flex;
    padding: 5px;
    flex-flow: nowrap row !important;
    justify-content: flex-start !important;
}
.MainDialog.rightSlide .formio-form nav > ul > li {
    display: inline-block;
    min-width: auto;
    width: auto;
    height: 48px !important;
    padding-left: 2px;
}

.MainDialog.rightSlide .formio-form > div > nav > ul.pagination .page-link {
    min-width: 145px;
    width: max-content;
    padding-left: 5px;
    padding-right: 3px;
    height: 36px !important;
    margin: auto 3px;
}

.MainDialog.rightSlide .builder-component .page-link,
.MainDialog.rightSlide .formio-dialog-component-settings .page-link,
.MainDialog.rightSlide .DCFormBuilder .page-link {
    height: 35px !important;
    border: 1px solid #1d74d9 !important;
    background: none;
    padding-left: 3px;
}
