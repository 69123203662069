.ToolTip {
    position: relative;
}

.ToolTip .ToolTipInner {
    padding: 7px 7px;
}

.ToolTip .ToolTipInner .TooltipTitle {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 3px;
}

.ToolTip .ToolTipInner .TooltipDes {
    font-size: 12px;
    opacity: 0.9;
    word-break: break-word;
}

.PopoverMain.ToolTipPopover .PopoverMainInner {
    margin-bottom: 10px;
    max-width: 200px;
}

.PopoverMain.ToolTipPopover .PopoverMainInner .PMIContent {
    z-index: 2;
}

.PopoverMain.ToolTipPopover .PopoverMainInner::before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: var(--white);
    box-shadow: 0 4px 14px 0 #00000033, 0 0 0 1px #0000000d;
    transform: rotate(45deg);
    border-radius: 4px;
}

.PopoverMain.ToolTipPopover.top-center .PopoverMainInner::before {
    bottom: -5px;
    left: calc(50% - 5px);
}

.PopoverMain.ToolTipPopover.top-start .PopoverMainInner::before {
    visibility: visible;
    bottom: -5px;
    left: calc(0% + 2px);
}

.PopoverMain.ToolTipPopover.top-end .PopoverMainInner::before {
    visibility: visible;
    bottom: -5px;
    left: calc(100% - 16px);
}

.PopoverMain.ToolTipPopover.bottom-center .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    left: calc(50% - 7px);
}

.PopoverMain.ToolTipPopover.bottom-start .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    left: calc(0% + 2px);
}

.PopoverMain.ToolTipPopover.bottom-end .PopoverMainInner::before {
    visibility: visible;
    top: -5px;
    left: calc(100% - 7px);
}

.PopoverMain.ToolTipPopover.left-start .PopoverMainInner::before {
    visibility: visible;
    top: calc(0% + 2px);
    right: -5px;
}

.PopoverMain.ToolTipPopover.left-end .PopoverMainInner::before {
    visibility: visible;
    top: calc(100% - 16px);
    right: -5px;
}

.PopoverMain.ToolTipPopover.left-center .PopoverMainInner::before {
    visibility: visible;
    top: calc(50% - 7px);
    right: -5px;
}

//
.PopoverMain.ToolTipPopover.right-start .PopoverMainInner::before {
    visibility: visible;
    top: calc(0% + 2px);
    left: -5px;
}

.PopoverMain.ToolTipPopover.right-end .PopoverMainInner::before {
    visibility: visible;
    top: calc(100% - 16px);
    left: -5px;
}

.PopoverMain.ToolTipPopover.right-center .PopoverMainInner::before {
    visibility: visible;
    top: calc(50% - 7px);
    left: -5px;
}


// .PopoverMain.ToolTipPopover.right-center .PopoverMainInner {
//     margin-bottom: 0;
//     margin-left: 10px;
// }

// .PopoverMain.ToolTipPopover.right-center .PopoverMainInner::before {
//     top: calc(50% - 5px);
//     left: -5px;
// }

.PopoverMain.ToolTipPopover.UserInfoToolTip .PopoverMainInner {
    max-width: unset;
}

.PopoverMain.ToolTipPopover.left-center .PopoverMainInner {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 10px;
}

.PopoverMain.ToolTipPopover.left-center .PopoverMainInner::before {
    right: -5px;
    top: calc(50% - 5px);
}

.ToolTipPopover.withBackgroundTooltip .PopoverMainInner .PMIContent {
    background-color: var(--primary-main);
    color: var(--blue-invert);
}

.ToolTipPopover.withBackgroundTooltip .PopoverMainInner::before {
    background-color: var(--primary-main);
}

@media (max-width: 575.98px) {
}
