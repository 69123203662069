.FavoritesMenus {
    display: flex;
    align-items: center;
    padding: 7px 7px;
    background-color: #38383d;
    border: 1px solid #38383d;
    border-radius: 5px;
    position: relative;
}

.FavoritesMenus.added {
    padding-right: 40px;
}

.FavoritesMenus a.FMItem {
    display: flex;
    font-size: 25px;
    margin-left: 7px;
    cursor: pointer;
    position: relative;
}

.FavoritesMenus a.FMItem .MHIMLILock {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: '#ffffffab';
    color: #26262b;
    border-radius: 4px;
}

.FavoritesMenus a.FMItem:first-child {
    margin-left: 0px;
}

.FavoritesMenus .FMIAdd button.iconButton {
    border-color: #6a6767;
    color: var(--white);
    background-color: transparent;
    padding: 4.5px;
}

.FavoritesMenus.added .FMIAdd {
    position: absolute;
    right: 7px;
    top: 7px;
}

.FavoritesMenus.adding {
    padding-right: 40px;
}

.FavoritesMenus.complete {
    padding-right: 7px;
}

.FavoritesMenus.adding .FMIAdd button.iconButton {
    position: absolute;
    right: 7px;
    top: 7px;
}

.PopoverMain.ToolTipPopover.FMIPopover .PopoverMainInner {
    margin-bottom: 0;
}

.PopoverMain.ToolTipPopover.FMIPopover .PopoverMainInner::before {
    top: -6px;
    left: calc(50% - 7px);
    visibility: visible;
}

// Responsive
@media (min-width: 1559px) and (max-width: 1800px) {
    .FavoritesMenus {
        padding: 5px 5px;
    }

    .FavoritesMenus .FMIAdd button.iconButton {
        font-size: 12px;
    }

    .FavoritesMenus a.FMItem {
        font-size: 23px;
    }

    .FavoritesMenus.added .FMIAdd {
        right: 5px;
        top: 5px;
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    .FavoritesMenus {
        padding: 5px 5px;
    }

    .FavoritesMenus .FMIAdd button.iconButton {
        font-size: 10px;
    }

    .FavoritesMenus a.FMItem {
        font-size: 21px;
    }

    .FavoritesMenus.added .FMIAdd {
        right: 5px;
        top: 6px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .FavoritesMenus {
        padding: 5px 5px;
    }

    .FavoritesMenus .FMIAdd button.iconButton {
        font-size: 10px;
    }

    .FavoritesMenus a.FMItem {
        font-size: 21px;
    }

    .FavoritesMenus.added .FMIAdd {
        right: 5px;
        top: 6px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .FavoritesMenus {
        transform: scale(0.8);
        margin-right: -10px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .FavoritesMenus {
        transform: scale(0.8);
        margin-right: -10px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .FavoritesMenus {
        transform: scale(0.8);
        margin-right: -10px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .FavoritesMenus {
        display: none;
    }
}

@media (max-width: 575.98px) {
    .FavoritesMenus {
        display: none;
    }
}
