.TagsContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 7px;
    column-gap: 7px;
    flex-wrap: wrap;
    row-gap: 7px;
}

.TagsContainer.hideMore {
    flex-wrap: wrap;
    line-height: 19px;
}

.TagsContainer .TCTag {
    padding: 0 8px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
}

.TagsContainer .TCTag.clickable {
    cursor: pointer;
}

.TagsContainer .TCTag.hideMore {
    margin-bottom: 5px;
}

.TagsContainer .TCTag:nth-child(n + 0) {
    border: 2px solid #e60202;
    background-color: #ff000026;
    color: #e60002;
    text-shadow: 0 0 7px #fff, 0 0 9px #fff, 0 0 30px #fff;
}

.TagsContainer .TCTag:nth-child(2n + 0) {
    border: 2px solid var(--dark-blue);
    background-color: var(--dark-blue) 26;
    color: var(--dark-blue);
    text-shadow: 0 0 7px #fff, 0 0 9px #fff, 0 0 30px #fff;
}

.TagsContainer .TCTag:nth-child(3n + 0) {
    border: 2px solid var(--dark-blue);
    background-color: var(--dark-blue) 26;
    color: var(--dark-blue);
    text-shadow: 0 0 7px #fff, 0 0 9px #fff, 0 0 30px #fff;
}

.TagsContainer button.mainButton {
    padding: 4px 8px;
    font-size: 10px;
    font-weight: 500;
    min-width: auto;
    // background-color: var(--dark-blue);
    // border-color: var(--dark-blue);
    // color: var(--blue-invert);
}
