.MainDialog.centerZoom.sizeLg .MDContainer .MDCRow.SDDRow {
    max-height: 730px;
    height: 100%;
}

.TypeOfBusiness {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}

.ScheduleDemoDialog .SDDBody {
    flex: 1 1;
    overflow: auto;
    margin-top: -1px;
}

.ScheduleDemo .PDBIVideoContent .PDBIVCVideo {
    min-width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 56%;
}
.ScheduleDemo .PDHRight .PDHRTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

.PDBOrderDetail .PDBODCloseAction {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0.7rem;

    &.close {
        justify-content: flex-end;
    }

    .iconButton {
        border-radius: 100%;
    }
}

.PDBOrderDetail .PDBODCloseAction .PDBODCAButton {
    .buttonTitle {
        display: flex;
        align-items: center;
    }

    svg {
        margin-left: 0.4rem;
    }

    &:hover svg {
        fill: #0169ff;
    }
}

.PDBIVideoContent .PDBIVCVideo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#wf_u4RpWadUbPPVP57u5ZnNcezf .wf_container .wf_layout {
    box-shadow: none !important;
    padding-top: 100px !important;
}
.PurchaseDialog.ScheduleDemo
    .PDBody
    .PDBInner
    .PDBOrderDetail
    button.wf_button.wf_reset
    .wf_reset.wf_button_text {
    @media screen and (max-width: 900px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 700px) {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 575px) {
    .PurchaseDialog.ScheduleDemo .PDBody .PDBInner {
        flex-direction: column;
        height: 100%;
    }
    .PurchaseDialog.ScheduleDemo
        .PDBody
        .PDBInner
        .PDBIVideoContent
        .PDBIVideoContent__ScheduleDemo {
        overflow: unset;
        height: 100%;
        width: 100% !important;
    }

    .PurchaseDialog.ScheduleDemo .PDBody .PDBInner .PDBOrderDetail__ScheduleDemo {
        width: 100%;
    }
    #wf_u4RpWadUbPPVP57u5ZnNcezf .wf_container .wf_layout {
        box-shadow: none !important;
        padding-top: 10px !important;
    }
}
