.UserInfo {
    display: flex;
    align-items: center;
    overflow: hidden;
}
.UserInfo .UIImg {
    width: 36px;
    height: 36px;
    display: flex;
    border-radius: 100%;
    border: 0px solid #e8eef9;
}

.UserInfo .UIImg svg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.UserInfo .UIImg img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: contain;
}

.UserInfo .UIDetail {
    flex: 1;
    overflow: hidden;
    padding-left: 10px;
}

.UserInfo .UIDetail .UIDetailName {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-blue);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.UserInfo .UIDetail .UIDetailEmail {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    text-transform: lowercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.ColorBlue {
    color: var(--dark-blue) !important;
}
//

.UserInfoWithDelete {
    display: flex;
    align-items: center;
    background-color: #f0faff;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.UserInfoWithDelete:last-child {
    margin-bottom: 0px;
}

.UserInfoWithDelete .UserInfo {
    flex: 1;
}

.UserInfoWithDelete a {
    flex: 1;
    display: flex;
}

.UserInfoWithDelete .UIWDelete {
    display: flex;
}

.ToolTip .ToolTipInner .UserInfo .UIDetail .UIDetailName {
    color: var(--blue-invert);
}

.ToolTip .ToolTipInner .UserInfo .UIDetail .UIDetailEmail {
    color: var(--blue-invert);
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .UserInfo .UIDetail .UIDetailName {
        font-size: 1rem;
    }
}

@media (min-width: 1367px) and (max-width: 1560px) {
    .UserInfo .UIDetail .UIDetailName {
        font-size: 1rem;
    }

    .UserInfo .UIImg {
        width: 30px;
        height: 30px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .UserInfo .UIDetail .UIDetailName {
        font-size: 1rem;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .UserInfo .UIDetail .UIDetailName {
        font-size: 1rem;
    }
}
