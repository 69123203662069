.MainDialog.centerZoom.sizeMd .MDContainer .MDCRow.PWDRow {
    max-height: 600px;
}

.PayWallDialog {
    display: flex;
    flex-direction: column;
    height: 765px;
    flex: 1;
}

.PayWallDialog .topBar {
    padding: 0 0;
}

.PayWallDialog .PWDHLeft {
    display: flex;
    align-items: center;
    width: 40%;
    padding: 0 15px;
    border-right: 1px solid #ffffff20;
    height: 100%;
}

.PayWallDialog .PWDHLeft .PWDHIcon {
    display: flex;
    font-size: 30px;
    box-shadow: 0 0 7px #ffffff50;
    border-radius: 10px;
    margin-right: 15px;
}

.PayWallDialog .PWDHRight {
    display: flex;
    align-items: center;
    width: 60%;
    padding: 0 15px;
    column-gap: 10px;
}

.PayWallDialog .PWDHRight .PWDHRIcon {
    font-size: 14px;
    background-color: var(--white);
    color: #4caf50;
    padding: 5px;
    box-shadow: 0 0 4px var(--white);
    border-radius: 100%;
}

.PayWallDialog .PWDHRight .PWDHRTitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--white);
    flex: 1;
}

.PayWallDialog .PWDBody {
    flex: 1;
    overflow: auto;
}

.PayWallDialog .PWDBody .PWDBInner {
    display: flex;
    height: 100%;
    overflow: auto;
}

.PayWallDialog .PWDBILeft {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    border-right: 1px solid #26262b20;
}

.PayWallDialog .PWDBILeft .PWDBILTop {
    flex: 1 1;
    padding: 15px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.PayWallDialog .PWDBILeft .PWDBILTop .PWDBILTMainTitle {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.PayWallDialog .PWDBILeft .PWDBILTop .PWDBILTIcon {
    display: flex;
    justify-content: center;
    font-size: 215px;
}

.PayWallDialog .PWDBILeft .PWDBILTop .PWDBILTDes {
    font-size: 16px;
    font-weight: 500;
    color: #666;
    line-height: 28px;
    text-align: center;
}

.PayWallDialog .PWDBILeft .PWDBILFooter {
    display: flex;
    justify-content: center;
    padding: 35px;
    padding-top: 0px;
}

.PayWallDialog .PWDBILeft .PWDBILFooter button {
    padding: 15px 20px;
    font-size: 18px;
    width: 100%;
    font-weight: 600;
}

.PayWallDialog .PWDBIRight {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 500px;
}

.PayWallDialog .PWDBIRight .PWDBIRHead {
    display: flex;
    border-bottom: 1px solid #e4e4e4;
}

.PayWallDialog .PWDBIRight .PWDBIRHead .PWDBIRCol1 {
    width: 35%;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    border-right: 1px solid #26262b20;
}

.PayWallDialog .PWDBIRight .PWDBIRHead .PWDBIRCol2 {
    display: flex;
    align-items: center;
    width: 65%;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.PayWallDialog .PWDBIRight .PWDBIRHead .PWDBIRCol2 .PWDBIRCCol1 {
    width: 40%;
}

.PayWallDialog .PWDBIRight .PWDBIRHead .PWDBIRCol2 .PWDBIRCCol2 {
    width: 20%;
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.PayWallDialog .PWDBIRight .PWDBIRBody {
    flex: 1;
    overflow: overlay;
}

.PayWallDialog .PWDBIRBody .PWDBIRBGroup {
    border-bottom: 1px solid #e4e4e4;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow {
    display: flex;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow .PWDBIRGCol1 {
    width: 35%;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 500;
    border-right: 1px solid #26262b20;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow .PWDBIRGCTitle {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow .PWDBIRGCTitle .PWDBIRGCIcon {
    font-size: 20px;
    display: flex;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow .PWDBIRGCTitle .PWDBIRGCTitle {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow .PWDBIRGCol2 {
    display: flex;
    align-items: center;
    width: 65%;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow .PWDBIRGCol2 .PWDBIRCGCol1 {
    width: 40%;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow .PWDBIRGCol2 .PWDBIRCGCol2 {
    width: 20%;
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow:first-child .PWDBIRGCol1 {
    padding-top: 15px;
    padding-bottom: 7px;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow:first-child .PWDBIRGCol2 {
    padding-top: 15px;
    padding-bottom: 7px;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow:last-child .PWDBIRGCol1 {
    padding-bottom: 10px;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow:last-child .PWDBIRGCol2 {
    padding-bottom: 10px;
}

.PayWallDialog .PWDBIRBGroup .PWDBIRBGRow .PWDBIRGCol2 .PWDBIRCGCol1.PWDBIRCGCNYIcon {
    display: flex;
    justify-content: center;
    font-size: 18px;
}

.PayWallDialog .PWDBIRight .PWDBIRPlan {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.PayWallDialog .PWDBIRight .PWDBIRPlan .PWDBIRPIcon {
    font-size: 150px;
    display: flex;
}

.PayWallDialog .PWDBIRight .PWDBIRPlan .PWDBIRPTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.PayWallDialog .PWDBIRight .PWDBIRPlan .PWDBIRPBtn {
    display: flex;
}

.PayWallDialog .PWDBIRight .PWDBIRPlan .PWDBIRPBtn button {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 25px;
}

.PayWallDialog .PWDBIRight .PWDBIRCard {
    height: 464px;
    padding: 15px;
    overflow: auto;
}

.PayWallDialog .PWDBIRight .PWDBIRCZCS {
    display: flex;
    column-gap: 15px;
    margin-bottom: 15px;
}

.PayWallDialog .PWDBIRight .PWDBIRCZCS .fieldControl {
    margin: 0;
}

.PayWallDialog .PWDBIRight .PWDBIRCBtn {
    display: flex;
}

.PayWallDialog .PWDBIRight .PWDBIRCBtn button {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 10px;
}
.PayWallDialog .PWDBody .PWDBIMessage {
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.PayWallDialog .PWDBody .PWDBIMessage .PWDBIMIcon {
    font-size: 24px;
    display: flex;
}

.PayWallDialog .PWDBody .PWDBIMessage .PWDBIMTitle {
    font-size: 20px;
    font-weight: 600;
}

.MainDialog.centerZoom .MDContainer .MDCRow.PWDSCRow {
    border-radius: 25px;
    overflow: hidden;
}

.PayWallDialog .PWDBody .PWDBISuccess {
    padding: 50px;
    border-top: 10px solid #4caf50;
    border-radius: 25px;
}

.PayWallDialog .PWDBody .PWDBISuccess .PWDBISIcon {
    font-size: 100px;
    display: flex;
    justify-content: center;
    color: #4caf50;
}

.PayWallDialog .PWDBody .PWDBISuccess .PWDBISIcon .circle {
    stroke-dasharray: 76;
    stroke-dashoffset: 76;
    -webkit-animation: draw 1s forwards;
    animation: draw 1s forwards;
}

.PayWallDialog .PWDBody .PWDBISuccess .PWDBISIcon .tick {
    stroke-dasharray: 18;
    stroke-dashoffset: 18;
    -webkit-animation: draw 1s forwards 1s;
    animation: draw 1s forwards 1s;
}

@-webkit-keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

.PayWallDialog .PWDBody .PWDBISuccess .PWDBISTitle {
    font-size: 24px;
    text-align: center;
    padding-top: 50px;
    font-weight: 600;
}

// Responsive
@media (min-width: 1560px) and (max-width: 1800px) {
}

@media (min-width: 1367px) and (max-width: 1559.98px) {
}

@media (min-width: 1280px) and (max-width: 1366.98px) {
}

@media (min-width: 1024px) and (max-width: 1279.98px) {
}

@media (min-width: 992px) and (max-width: 1023.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .MainDialog.centerZoom.sizeMd .MDContainer .MDCRow.PWDRow {
        max-height: 100%;
    }

    .PayWallDialog .PWDHRight .PWDHRIcon {
        display: none;
    }

    .PayWallDialog .PWDHRight .PWDHRTitle {
        display: none;
    }

    .PayWallDialog .PWDHRight {
        width: auto;
    }

    .PayWallDialog .PWDHLeft {
        width: auto;
        flex: 1;
    }

    .PayWallDialog .PWDBody .PWDBInner {
        flex-direction: column;
    }

    .PayWallDialog .PWDBILeft {
        width: 100%;
        border-right: 0;
    }

    .PayWallDialog .PWDBIRight {
        width: 100%;
        border-top: 1px solid #e4e4e4;
    }

    .PayWallDialog .PWDBIRight .PWDBIRHead .PWDBIRCol1 {
        padding: 10px 15px;
        font-size: 16px;
    }

    .PayWallDialog .PWDBIRight .PWDBIRHead .PWDBIRCol2 {
        padding: 10px 15px;
        font-size: 16px;
    }

    .PayWallDialog .PWDBIRight .PWDBIRBody {
        flex: unset;
        overflow: unset;
    }
}

@media (max-width: 575.98px) {
    .MainDialog.centerZoom.sizeMd .MDContainer .MDCRow.PWDRow {
        max-height: 100%;
    }

    .PayWallDialog .PWDHRight .PWDHRIcon {
        display: none;
    }

    .PayWallDialog .PWDHRight .PWDHRTitle {
        display: none;
    }

    .PayWallDialog .PWDHRight {
        width: auto;
    }

    .PayWallDialog .PWDHLeft {
        width: auto;
        flex: 1;
    }

    .PayWallDialog .PWDBody .PWDBInner {
        flex-direction: column;
    }

    .PayWallDialog .PWDBILeft {
        width: 100%;
        border-right: 0;
    }

    .PayWallDialog .PWDBIRight {
        width: 100%;
        border-top: 1px solid #e4e4e4;
    }

    .PayWallDialog .PWDBIRight .PWDBIRHead .PWDBIRCol1 {
        padding: 10px 15px;
        font-size: 16px;
    }

    .PayWallDialog .PWDBIRight .PWDBIRHead .PWDBIRCol2 {
        padding: 10px 15px;
        font-size: 16px;
    }

    .PayWallDialog .PWDBIRight .PWDBIRBody {
        flex: unset;
        overflow: unset;
    }
}
