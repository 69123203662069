.ConfettiWrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
}

[class|='confetti'] {
    position: absolute;
}

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 150 {
    $w: random(20);
    $l: random(100);
    .confetti-#{$i} {
        width: #{$w}px;
        height: #{$w * 0.4}px;
        background-color: nth($colors, random(3));
        top: -10%;
        left: unquote($l + '%');
        opacity: random() + 0.5;
        transform: rotate(#{random() * 360}deg);
        animation: drop-#{$i} unquote(4 + random() + 's') unquote(random() + 's') 1;
    }

    @keyframes drop-#{$i} {
        100% {
            top: 110%;
            left: unquote($l + random(15) + '%');
        }
    }
}
