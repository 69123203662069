.PaginationMain {
    padding: 10px;
    background-color: var(--white);
}

.PaginationMain .PMInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PaginationMain .PMInner .PMTotal {
    font-size: 14px;
    font-weight: 600;
    color: #84929e;
}

.PaginationMain .PMInner .PMTotal strong {
    color: #48484d;
}

.PaginationMain .PMInner .PMPages {
    display: flex;
    align-items: center;
}

.PaginationMain .PMInner .PMPages button.mainButton {
    margin: 0 3px;
    border-color: transparent;
    border-radius: 8px;
    font-weight: 600;
    min-width: auto;
}

.PaginationMain .PMInner .PMPages button.PaginationBtn.active {
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}

.PaginationMain .PMInner .PMPages .PaginationPNBtn {
    border-color: transparent;
    font-size: 16px;
    color: var(--dark-blue);
}

.PaginationMain .PMInner .PMRowView .PMRowViewBtn {
    border-color: transparent;
    color: var(--dark-blue);
    background-color: transparent;
}

.PaginationMain .PMInner .PMRowView .PMRowViewBtn:hover {
    text-decoration: underline;
}

.PaginationMain .PMInner .PMRowView .PMRowViewBtn span.buttonIcon {
    color: #abb7d4;
}

.PaginationSelect {
    display: flex;
    flex-direction: column;
}

.PaginationSelect button {
    border: 0;
    border-bottom: 1px solid #e8eef9;
    border-radius: 0;
    justify-content: center;
}
.PaginationSelect button:last-child {
    border-bottom: 0;
}

.PaginationSelect button.active,
.PaginationSelect button:hover {
    background-color: var(--dark-blue);
    color: var(--blue-invert);
}

.PaginationMain .PMInner .PMPages .PaginationBtnDisabled {
    pointer-events: none;
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .PaginationMain .PMInner .PMTotal {
        font-size: 12px;
        font-weight: 400;
    }
    .PaginationMain .PMInner .PMPages button.mainButton {
        padding: 4px 6px;
        font-size: 12px;
    }
    .PaginationMain .PMInner .PMRowView .PMRowViewBtn {
        font-size: 12px;
    }
}

@media (min-width: 1367px) and (max-width: 1560px) {
    .PaginationMain .PMInner .PMTotal {
        font-size: 12px;
        font-weight: 400;
    }
    .PaginationMain .PMInner .PMPages button.mainButton {
        padding: 4px 6px;
        font-size: 12px;
    }
    .PaginationMain .PMInner .PMRowView .PMRowViewBtn {
        font-size: 12px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .PaginationMain .PMInner .PMTotal {
        font-size: 12px;
        font-weight: 400;
    }
    .PaginationMain .PMInner .PMPages button.mainButton {
        padding: 4px 6px;
        font-size: 12px;
    }
    .PaginationMain .PMInner .PMRowView .PMRowViewBtn {
        font-size: 12px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .PaginationMain .PMInner .PMTotal {
        font-size: 12px;
        font-weight: 400;
    }
    .PaginationMain .PMInner .PMPages button.mainButton {
        padding: 5px 8px;
        font-size: 12px;
    }
    .PaginationMain .PMInner .PMRowView .PMRowViewBtn {
        font-size: 12px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .PaginationMain .PMInner .PMTotal {
        font-size: 12px;
        font-weight: 400;
    }
    .PaginationMain .PMInner .PMPages button.mainButton {
        padding: 5px 8px;
        font-size: 12px;
    }
    .PaginationMain .PMInner .PMRowView .PMRowViewBtn {
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .PaginationMain .PMInner .PMTotal {
        font-size: 12px;
        font-weight: 400;
    }
    .PaginationMain .PMInner .PMPages button.mainButton {
        padding: 5px 8px;
        font-size: 12px;
    }
    .PaginationMain .PMInner .PMRowView .PMRowViewBtn {
        font-size: 12px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .PaginationMain .PMInner {
        flex-wrap: wrap;
    }
    .PaginationMain .PMInner .PMTotal {
        flex: 1;
    }
    .PaginationMain .PMInner .PMRowView {
        display: flex;
        justify-content: flex-end;
    }
    .PaginationMain .PMInner .PMPages {
        width: 100%;
        order: 2;
        justify-content: center;
    }
    .PaginationMain .PMInner .PMRowView .PMRowViewBtn {
        padding: 8px 5px;
    }
}

@media (max-width: 575.98px) {
    .PaginationMain .PMInner {
        flex-wrap: wrap;
    }
    .PaginationMain .PMInner .PMTotal {
        flex: 1;
    }
    .PaginationMain .PMInner .PMRowView {
        display: flex;
        justify-content: flex-end;
    }
    .PaginationMain .PMInner .PMPages {
        width: 100%;
        order: 2;
        justify-content: center;
        margin-top: 7px;
    }
    .PaginationMain .PMInner .PMRowView .PMRowViewBtn {
        padding: 8px 5px;
    }
}
