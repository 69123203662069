@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700');
@keyframes floating {
    from {
        transform: translateY(0px);
    }
    65% {
        transform: translateY(15px);
    }
    to {
        transform: translateY(0px);
    }
}

.NotFound404 {
    background-image: url('../../../Assets/images/star.svg'),
        linear-gradient(to bottom, #05007a, #4d007d);
    flex: 1;
    background-attachment: fixed;
    overflow: hidden;
    position: relative;
}

.NotFound404 .NF404mars {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    height: 27vmin;
    background: url('../../../Assets/images/mars.svg') no-repeat bottom center;
    background-size: cover;
}

.NotFound404 .NF404logo-404 {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 25vmin;
    width: 30vmin;
    z-index: 999;
}

.NotFound404 .NotFoundshapes .NFSInner {
    height: 100%;
    width: 100%;
}
@media (max-width: 480px) and (min-width: 320px) {
    .NotFound404 .NF404logo-404 {
        top: 45vmin;
    }
}

.NotFound404 .NF404meteor {
    position: absolute;
    right: 7vmin;
    top: 16vmin;
}

.NotFound404 .NF404title {
    color: white;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 7vmin;
    margin-top: 36vmin;
    z-index: 999;
    position: relative;
}

@media (max-width: 480px) and (min-width: 320px) {
    .NotFound404 .NF404title {
        margin-top: 65vmin;
    }
}

.NotFound404 .NF404subtitle {
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 3.5vmin;
    margin-top: 3vmin;
    z-index: 999;
    position: relative;
}

.NotFound404 .NF404spaceship {
    position: absolute;
    bottom: 15vmin;
    right: 24vmin;
}

@media (max-width: 480px) and (min-width: 320px) {
    .NotFound404 .NF404spaceship {
        width: 45vmin;
        bottom: 18vmin;
    }
}

//

.NotFound404 .NotFoundshapes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    overflow: hidden;
}
.NotFoundshapes .rocket {
    transform: rotate(113deg);
    z-index: 100 !important;
}
.NotFoundshapes .rocket-holder {
    animation: up-down infinite;
    animation-direction: alternate;
    animation-duration: 150ms;
    z-index: 100 !important;
    position: absolute;
    top: 50%;
    left: 30%;
}

@keyframes up-down {
    100% {
        transform: translateY(0px);
    }
    0% {
        transform: translateY(2px);
    }
}
.NotFoundshapes .shape0 {
    position: absolute;
    background: beige;
    border-radius: 80%;
    z-index: 8;
    right: -2000px;
    animation: rocket;
    animation-iteration-count: infinite;
}

.NotFoundshapes .shape1 {
    height: 3px;
    width: 30px;
    top: 50%;

    animation-duration: 4s;
    animation-delay: 700ms;
}
.NotFoundshapes .shape2 {
    height: 3px;
    width: 60px;
    top: 60%;

    animation-duration: 4s;
    animation-delay: 500ms;
}

.NotFoundshapes .shape3 {
    height: 3px;
    width: 30px;
    top: 45%;

    animation-duration: 4s;
    animation-delay: 150ms;
}

.NotFoundshapes .shape4 {
    height: 3px;
    width: 10px;
    top: 60%;

    animation-duration: 4s;
    animation-delay: 100ms;
}

.NotFoundshapes .shape5 {
    height: 3px;
    width: 30px;
    top: 50%;

    animation-duration: 4s;
}

@keyframes rocket {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-4000px);
    }
}

.planets {
    position: absolute;
    right: -2000px;
    z-index: -1;
    animation: rocket;
    animation-iteration-count: infinite;
    animation-duration: 15s;
}

.planet1 {
    top: 20%;
    animation-delay: 50ms;
}
.planet2 {
    top: 2%;
    animation-delay: 2s;
}
.planet4 {
    bottom: 5%;
    animation-delay: 3s;
}

.planet5 {
    bottom: 30%;
    animation-delay: 5s;
}

.planet6 {
    top: 20%;
    animation-delay: 8s;
}
.planet7 {
    bottom: 30%;
    animation-delay: 9s;
}
.planet8 {
    width: 400px;
    bottom: -30%;
    left: -1000;
    animation-duration: 40s;
    animation-delay: 1s;
}
.planet9 {
    bottom: 10%;
    animation-delay: 6s;
}

.final {
    position: absolute;
    top: 35%;
    left: 70%;
    animation: final 25s normal;
    z-index: 100;
    animation-delay: 15s;
    width: 399px;
}

@keyframes final {
    0% {
        transform: translateX(5000px);
    }
    100% {
        transform: translateX(0px);
    }
}

.final img {
    position: absolute;
}

.final .human {
    width: 100px;
    top: 0px;
    animation: up-down-final infinite;
    animation-direction: alternate;
    animation-duration: 1s;
}
.final .planet10 {
    animation: up-down-final infinite;
    animation-direction: alternate;
    animation-duration: 2s;
    bottom: 20%;
}
.final .planet11 {
    animation: up-down-final infinite;
    animation-direction: alternate;
    animation-duration: 2s;
    animation-delay: 2s;
    top: 10%;
}

@keyframes up-down-final {
    100% {
        transform: translateY(0px);
    }
    0% {
        transform: translateY(10px);
    }
}
