.MDCRow.errorSWDialogMain {
    height: 80%;
}

.errorSWDialogMain .errorSWDialogInner {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.errorSWDialogMain .errorSWDialogInner button.eSWIBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    border-radius: 100%;
    box-shadow: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024,
        0px 9px 46px 8px #0000001f;
}

.errorSWDialogMain .errorSWDialogInner .sSWDIIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.errorSWDialogMain .errorSWDialogInner .sSWDIIcon .sSWDIIPeashooter {
    display: flex;
    z-index: 1;
    position: relative;
}

.errorSWDialogMain .errorSWDialogInner .sSWDIIcon .sSWDIIPeashooter svg {
    width: 450px;
    height: 450px;
}

.errorSWDialogMain .errorSWDialogInner .sSWDIInfo {
    text-align: center;
    padding: 50px 0;
}

.errorSWDialogMain .errorSWDialogInner .sSWDIInfo .sSWDIITitle {
    font-size: 40px;
    font-weight: 600;
    color: #000;
}

.errorSWDialogMain .errorSWDialogInner .sSWDIInfo .sSWDIISTitle {
    font-size: 18px;
    font-weight: 500;
    color: #666;
}

.errorSWDialogMain .errorSWDialogInner .sSWDIInfo .sSWDIIBtn {
    padding-top: 10px;
}

.errorSWDialogMain .errorSWDialogInner .sSWDIAnimation {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    padding: 50px 0;
}

/* KEYFRAMES */

@-webkit-keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }
    100% {
        margin-left: 100%;
    }
}

@-moz-keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }
    100% {
        margin-left: 100%;
    }
}

@keyframes animateCloud {
    0% {
        margin-left: -1000px;
    }
    100% {
        margin-left: 100%;
    }
}

/* ANIMATIONS */

.sSWDIACloudWrap1 {
    -webkit-animation: animateCloud 35s linear infinite;
    -moz-animation: animateCloud 35s linear infinite;
    animation: animateCloud 35s linear infinite;

    -webkit-transform: scale(0.65);
    -moz-transform: scale(0.65);
    transform: scale(0.65);
}

.sSWDIACloudWrap2 {
    -webkit-animation: animateCloud 20s linear infinite;
    -moz-animation: animateCloud 20s linear infinite;
    animation: animateCloud 20s linear infinite;

    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
}

.sSWDIACloudWrap3 {
    -webkit-animation: animateCloud 30s linear infinite;
    -moz-animation: animateCloud 30s linear infinite;
    animation: animateCloud 30s linear infinite;

    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    transform: scale(0.5);
}

.sSWDIACloudWrap4 {
    -webkit-animation: animateCloud 18s linear infinite;
    -moz-animation: animateCloud 18s linear infinite;
    animation: animateCloud 18s linear infinite;

    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    transform: scale(0.4);
}

.sSWDIACloudWrap5 {
    -webkit-animation: animateCloud 25s linear infinite;
    -moz-animation: animateCloud 25s linear infinite;
    animation: animateCloud 25s linear infinite;

    -webkit-transform: scale(0.55);
    -moz-transform: scale(0.55);
    transform: scale(0.55);
}

/* OBJECTS */

.sSWDIACWCloud {
    background: #edf2fd;
    background: -moz-linear-gradient(to bottom, #edf2fd 5%, #f1f1f1 100%);
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(5%, #edf2fd),
        color-stop(100%, #f1f1f1)
    );
    background: -webkit-linear-gradient(to bottom, #edf2fd 5%, #f1f1f1 100%);
    background: -o-linear-gradient(to bottom, #edf2fd 5%, #f1f1f1 100%);
    background: -ms-linear-gradient(to bottom, #edf2fd 5%, #f1f1f1 100%);
    background: linear-gradient(to bottom, #edf2fd 5%, #f1f1f1 100%);
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    height: 120px;
    position: relative;
    width: 350px;
}

.sSWDIACWCloud:after,
.sSWDIACWCloud:before {
    background: #edf2fd;
    content: '';
    position: absolute;
    z-index: -1;
}

.sSWDIACWCloud:after {
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    height: 100px;
    left: 50px;
    top: -50px;
    width: 100px;
}

.sSWDIACWCloud:before {
    -webkit-border-radius: 200px;
    -moz-border-radius: 300px;
    border-radius: 200px;
    width: 180px;
    height: 180px;
    right: 50px;
    top: -90px;
}
