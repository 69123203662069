.AutoCompleteRoot .ACRLabel {
    position: relative;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #7b89a4;
    padding: 4px 8px;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
    cursor: pointer;
    background-color: var(--white);
    padding-right: 35px;
    line-height: normal;
    user-select: none;
}
.AutoCompleteRoot .ACRLabelReadonly {
    position: relative;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #7b89a4;
    padding: 4px 8px;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
    cursor: pointer;
    background-color: var(--white);
    padding-right: 35px;
    line-height: normal;
    user-select: none;
}

.AutoCompleteRoot .ACRLabelDisabled {
    cursor: not-allowed;
}

.AutoCompleteRoot .ACRLabel::after {
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 512 512' ><path d='M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0    s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667    C514.096,145.416,514.096,131.933,505.755,123.592z' fill='rgb(132 146 158)'></path></svg>");
    position: absolute;
    right: 10px;
    top: 12px;
    transition: all ease 0.1s;
    width: 15px;
    height: 15px;
    z-index: 1;
    line-height: normal;
}

.AutoCompleteRoot .ACRLabel.SingleSelection {
    padding: 10px;
    padding-right: 40px;
}

.ACRSearch {
    padding: 10px;
    background-color: #f6f8fc;
}

.AutoCompleteRootPopover .ACRResults {
    overflow-y: overlay !important;
}

.AutoCompleteRootPopover .ACRResults button.ACSingleOption {
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    padding: 10px;
    border: 0;
    position: relative;
}

.AutoCompleteRootPopover .ACRResults button.ACSingleOption .buttonTitle {
    text-align: left;
}

.AutoCompleteRootPopover .ACRResults button.ACSingleOption.active {
    background-color: #e8eef9;
}

.ACMultiOption label.Checkbox.ACMOICheckbox {
    padding: 10px;
    border-bottom: 1px solid #f6f8fc;
}
.ACMultiOption label.Checkbox.ACMOICheckbox.ACMOICTO span.CheckboxLabel {
    text-transform: initial;
}
.ACMultiOption.active {
    background-color: #e8eef9;
}

.AutoCompleteRoot .ACRLabel.ACRMultiSelection {
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.AutoCompleteRootPopover .ACRNotResults {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 150px;
}

.AutoCompleteRootPopover .GACMainList {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    position: relative;
}

.AutoCompleteRootPopover .GACMainList .GACMLTitle {
    font-size: 13px;
    font-weight: 600;
    padding: 10px 10px;
    background-color: #26262b;
    color: var(--white);
    position: sticky;
    top: 0;
    z-index: 1;
}

.AutoCompleteRootPopover .GACMainList .GACMLInner {
    display: flex;
    flex-direction: column;
}

.AutoCompleteRootPopover .GACMainList .GACMLInner button.mainButton {
    padding: 10px;
    justify-content: flex-start;
    border: 0;
    border-bottom: 1px solid #eaeaea;
    border-radius: 0;
    padding-left: 15px;
    opacity: 1;
}

.AutoCompleteRootPopover .GACMainList .GACMLInner button.mainButton span.buttonTitle {
    text-align: left;
}

.AutoCompleteRootPopover .ACSkeletonContainer {
    padding: 5px;
}

.AutoCompleteRootPopover .ACSkeletonContainer .ACSkeleton {
    margin: auto;
    margin-bottom: 5px;
}
