.topBar {
    padding: 0px 15px;
    background-color: #26262b;
    height: 60px;
    position: relative;
    border-bottom: 1px solid #eaeaea;
}

.topBar.DialogTopBar {
    padding: 0 15px;
}

.topBar .topBarInner {
    display: flex;
    min-height: 60px;
    height: 100%;
}

.MainDialog.rightSlide .topBar.DialogTopBar {
    padding: 0px 15px;
}

// Responsive
@media (min-width: 1561px) and (max-width: 1800px) {
    .topBar {
        height: 50px;
    }
    .topBar .topBarInner {
        min-height: 50px;
    }
}

@media (min-width: 1367px) and (max-width: 1560px) {
    .topBar {
        height: 50px;
    }
    .topBar .topBarInner {
        min-height: 50px;
    }
}
@media (min-width: 1281px) and (max-width: 1366px) {
    .topBar {
        height: 50px;
    }
    .topBar .topBarInner {
        min-height: 50px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .topBar {
        height: 50px;
    }
    .topBar .topBarInner {
        min-height: 50px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .topBar {
        height: 50px;
    }
    .topBar .topBarInner {
        min-height: 50px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .topBar {
        height: 50px;
    }
    .topBar .topBarInner {
        min-height: 50px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .topBar {
        height: 50px;
    }
    .topBar .topBarInner {
        min-height: 50px;
    }
}

@media (max-width: 575.98px) {
    .topBar {
        height: 50px;
    }
    .topBar .topBarInner {
        min-height: 50px;
    }
}
