.newsHeadline {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    text-align: center;
    background-color: #fe6666;
    color: #fff;
    width: 100%;
    height: 25px;
    overflow: hidden;
    padding: 3px;
    -webkit-user-select: none;
    user-select: none;
    justify-content: center;
    align-items: center;
    display: flex;
}

.newsHeadline .newsHeadlineContent {
    overflow: hidden;
    position: relative;
    width: 100%;

    &:hover {
        animation-play-state: paused;
    }
}

.newsHeadline .newsHeadlineContent .newsHeadlineItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
}

.newsHeadline .newsHeadlineContent .newsHeadlineItem .newsHeadlineItemCloseIcon {
    margin-left: 1rem;
}

.newsHeadline .newsHeadlineContent .newsHeadlineItem .newsHeadlineItemCloseIcon svg {
    width: 0.6rem;
    height: 0.6rem;
}

// 0 10 20 30 40 50 60 70 80 90 100

