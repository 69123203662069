.SignUpPage {
    flex: 1;
    overflow: auto;
}

.SignUpPage .SUPInner {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.SignUpPage .SUPInner .SUPICol {
    width: 50%;
}

.SignUpPage .SUPInner .SUPICol:first-child {
    @media screen and (min-width: 650px) {
        box-shadow: 0px 4px 34px rgb(0 0 0 / 10%);
    }
}

.SignUpPage .SUPInner .SUPICols {
    width: 50%;
    overflow: auto;
    height: 100%;
    background-color: #f6f8fc;
}

.SignUpPage .SUPInner .SUPICols:first-child {
    @media screen and (min-width: 650px) {
        box-shadow: 0px 4px 34px rgb(0 0 0 / 10%);
    }
}

.SignUpPage .SUPInner .SUPIFormSection {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 25px 50px;

    @media screen and (max-width: 850px) {
        padding: 25px;
    }
}

.SUPIFormSection .SUPIFSInner {
    width: 100%;
    transform: translateY(-70px);
}

.SUPIFormSection .SUPIFSInner .SUPIFSIIcon {
    display: flex;
    justify-content: center;
}

.SUPIFormSection .SUPIFSInner .SUPIFSIIcon img {
    max-height: 90px;
    object-fit: contain;
    border-radius: 10px;
    object-position: left;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.SUPIFormSection .SUPIFSInner .SUPIFSITitle {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: #000;
    padding-top: 10px;
    padding-bottom: 5px;
}

.SUPIFormSection .SUPIFSInner .SUPIFSISTitle {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    padding-bottom: 35px;
}

.SUPIFormSection .SUPIFSInner .SUPIFSISForm {
    display: flex;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
}

.SUPIFormSection .SUPIFSInner .SUPIFSISForm .DCInputWrapper {
    flex: 1;
}

.SUPIFormSection .SUPIFSInner .SUPIFSISForm .DCInputWrapper input.DCInput {
    border-radius: 50px 0 0 50px;
    font-size: 16px;
    padding: 15px;
    font-weight: 500;
    border: 0;
    box-shadow: none;
}

.SUPIFormSection .SUPIFSInner .SUPIFSISForm button.iconButton {
    padding: 0 20px;
    font-size: 22px;
    border-radius: 0 50px 50px 0;
}

.SUPIFormSection .SUPIFSInner .SUPIFSISIn {
    text-align: center;
    color: #666;
    font-size: 14px;
    padding-top: 25px;
    position: relative;

    &::after {
        @media screen and (max-width: 650px) {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 50%;
            height: 75%;
            backdrop-filter: blur(4px) brightness(0.92);
            border-radius: 8px;
            z-index: -1;
        }

        @media screen and (max-width: 480px) {
            width: 60%;
        }
        @media screen and (max-width: 400px) {
            width: 70%;
        }
        @media screen and (max-width: 300px) {
            width: 80%;
        }
    }
}

.SUPIFormSection .SUPIFSInner .SUPIFSISIn a {
    color: var(--dark-blue);
    font-weight: 600;
}

.SUPIFormSection .SUPIFSScreen {
    position: absolute;
    bottom: 0;
    width: 75%;
    overflow: hidden;
    margin: 0 auto;
    transform: translateY(30%);
}

.SUPIFormSection .SUPIFSScreen img.SUPIFSSVImg {
    width: 100%;
    height: 100%;
}

.SUPIFormSection .SUPIFSScreen .SUPIFSSWeb {
    position: absolute;
    left: 4%;
    padding-right: 6%;
    top: 7%;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    background-color: #000;
}
.slider-dot25 {
    color: #fff;
    position: absolute;
    top: 0;
    transform: translateY(-30px);

    @media screen and (max-width: 1400px) {
        transform: translateY(-27px);
    }
    @media screen and (max-width: 1100px) {
        transform: translateY(-26px);
    }
    @media screen and (max-width: 1200px) {
        transform: translateY(-25px);
    }
    @media screen and (max-width: 960px) {
        transform: translateY(-23px);
    }
    @media screen and (max-width: 750px) {
        transform: translateY(-20px);
    }
    @media screen and (max-width: 650px) {
        transform: translateY(-24px);
    }
    @media screen and (max-width: 500px) {
        transform: translateY(-23px);
    }
}
.slick-dots.slider-dot25 li button:before {
    color: #666;
    opacity: 1;
}
.slick-dots.slider-dot25 li.slick-active button:before {
    color: #fff;
    opacity: 1;
}
.SUPIFormSection .SUPIFSScreen img.SUPIFSSWVImg {
    width: 100%;
    object-fit: cover;
    object-position: left top;
}
.SUPIFSSWVImg {
    width: 100%;
}
.SignUpPage .SUPInner .SUPIAppSection {
    background-color: #f6f8fc;
    height: 100%;
    padding: 25px;
    display: flex;
    justify-content: center;
    // align-items: center;

    @media screen and (max-width: 850px) {
        padding: 20px;
    }
}
.SUPIAppSection .SUPIASInner .SUPIASIIcon .SUPIASIInner > div {
    font-size: 15px;
    padding-top: 2px;
    font-weight: 600;
    color: #00000090;
}

@media screen and (max-width: 650px) {
    .SignUpPage {
        overflow: initial;
    }
    .SignUpPage .SUPInner .SUPIFormSection {
        justify-content: flex-start;
    }
    .SignUpPage .SUPInner {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding-top: 220px !important;
    }
    .SignUpPage .SUPInner .SUPICol {
        width: 100%;
    }
    .SignUpPage .SUPInner .SUPIAppSection {
        padding: 15px;
        display: block;
        background: none !important;
    }

    .SUPIAppSection {
        display: block !important;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: unset;
        overflow: unset;
        height: 26% !important;
        padding: 0 !important;
        left: 0;
    }
    .SUPIFormSection .SUPIFSScreen {
        width: 70%;
    }
    .SignUpPage .SUPInner .SUPICols {
        width: 0px !important;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon {
        position: absolute;
        width: 35px !important;
        height: 35px !important;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon {
        width: auto !important;
        padding: 0 !important;
        display: block !important;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon .SUPIASIInner {
        font-size: 35px !important;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon .SUPIASIInner div {
        display: none;
    }
    .SignUpPage .SUPInner .SUPIFormSection {
        padding: 15px;
    }
    .SUPIFormSection .SUPIFSInner {
        transform: translateY(0);
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII1 {
        left: 5%;
        top: 10%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII2 {
        left: 32%;
        top: 10%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII3 {
        right: 5%;
        top: 10%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII4 {
        left: 16%;
        top: 44%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII5 {
        left: 46%;
        top: 50%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII6 {
        right: 16%;
        top: 44%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII7 {
        left: 25%;
        top: 78%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII8 {
        right: 25%;
        top: 78%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII9 {
        right: 8%;
        top: 111%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII10 {
        left: 8%;
        top: 111%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII11 {
        left: 58%;
        top: 10%;
    }
    .SUPIAppSection .SUPIASInner .SUPIASIIcon.SUPIASII12 {
        left: 46%;
        top: 88%;
    }

    .SUPIFSBack {
        position: fixed !important;
        z-index: 1;
    }
}

@media screen and (max-width: 525px) {
    .SUPIFormSection .SUPIFSScreen {
        width: 80%;
    }
}
@media screen and (max-width: 450px) {
    .SUPIFormSection .SUPIFSScreen {
        width: 90%;
    }
}
@media screen and (max-width: 350px) {
    .SUPIFormSection .SUPIFSScreen {
        width: 100%;
    }
}

.SUPIAppSection .SUPIASInner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &.animate > * {
        opacity: 0;
        animation: slideDownAppIconsSignup 1.2s ease forwards;

        @media screen and (max-width: 850px) {
            transform: scale(0.8) !important;
        }

        &:nth-child(1) {
            animation-delay: 0.1s;
        }
        &:nth-child(2) {
            animation-delay: 0.2s;
        }
        &:nth-child(3) {
            animation-delay: 0.3s;
        }
        &:nth-child(3) {
            animation-delay: 0.3s;
        }
        &:nth-child(4) {
            animation-delay: 0.4s;
        }
        &:nth-child(5) {
            animation-delay: 0.5s;
        }
        &:nth-child(6) {
            animation-delay: 0.6s;
        }
        &:nth-child(7) {
            animation-delay: 0.7s;
        }
        &:nth-child(8) {
            animation-delay: 0.8s;
        }
        &:nth-child(9) {
            animation-delay: 0.9s;
        }
        &:nth-child(10) {
            animation-delay: 1s;
        }
        &:nth-child(11) {
            animation-delay: 1.1s;
        }
        &:nth-child(12) {
            animation-delay: 1.2s;
        }
        &:nth-child(13) {
            animation-delay: 1.3s;
        }
    }
}

.SUPIAppSection .SUPIASInner .SUPIASIIcon {
    width: 33.33%;
    padding: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.SUPIAppSection .SUPIASInner .SUPIASIIcon .SUPIASIInner {
    display: flex;
    font-size: 75px;
    transform-origin: 50% 50%;
    flex-direction: column;
    align-items: center;
}

.SUPIFSBack {
    position: absolute;
    top: 50px;
    left: 50px;
}

.SUPIFSISIcon {
    display: flex;
    position: relative;
    width: 350px;
    margin: 25px 0;
    z-index: 2;
    margin: 0 auto;
}
.SUPIFSISIcon img {
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 0ms;
}
.SUPIFSISIcon span {
    position: absolute;
    top: 100px;
    left: 118px;
    background-color: #0083ff;
    display: block;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    user-select: none;
    border-radius: 50px;
}

.SUPIAppSection .SUPIASInner .SUPIASIIcon .SUPIASIInner svg {
    transform-origin: 50% 50%;
}

.LPIFSIFBFError {
    display: flex;
    margin-top: 15px;
    align-items: center;
    background-color: #f44336;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 4px;
    margin: 15px auto;
    max-width: 400px;
}

.LPIFSIFBFError span {
    padding: 0;
    background-color: unset;
    border: 0;
    color: var(--white);
    font-size: 12px;
}

.LPIFSIFBFError button.iconButton {
    padding: 0;
    background-color: unset;
    border: 0;
    color: var(--white);
    font-size: 10px;
}
////////////////////////////////////////
.SignupNextPage {
    height: 100%;
}
.SignupNextPage .SNPBody {
    height: 100%;
    display: flex;
}
.SignupNextPage .SNPBody .SNPBVerificationDetails {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    z-index: 2;

    & > *:not(:last-child) {
        margin-bottom: 20px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        width: 50%;
    }

    @media (max-width: 767px) {
        width: 100%;
    }
}

.SignupNextPage .SNPBody .SNPBVerificationDetails .SNPBVDHeading {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
}

.SignupNextPage .SNPBody .SNPBVerificationDetails .SNPBVDSubHeading {
    font-size: 16px;
    font-weight: 500;
    color: #00000060;
    text-align: center;

    &--important {
        color: red;
        font-size: 14px;
        text-align: center;
    }
}

.SignupNextPage .SNPBody .SNPBVerificationDetails .SUPIFSISEmailVerifyImage {
    display: flex;
    position: relative;
    width: 350px;
    margin: 25px 0 !important;
    z-index: 2;
    margin: 0 auto;

    @media (max-width: 575px) {
        width: 250px;

        span {
            top: 68px !important;
            left: 68px !important;
        }
    }
}
.SignupNextPage .SNPBody .SNPBVerificationDetails .SUPIFSISEmailVerifyImage img {
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 0ms;
}
.SignupNextPage .SNPBody .SNPBVerificationDetails .SUPIFSISEmailVerifyImage span {
    position: absolute;
    top: 100px;
    left: 118px;
    background-color: #0083ff;
    display: block;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    user-select: none;
    border-radius: 50px;
}

.SignupNextPage .SNPBody .SNPBVerificationDetails .SUPIFSISEmailResend {
    font-size: 14px;
    font-weight: 600;
    color: #00000060;

    a {
        color: #0083ff;
    }
}

.SignupNextPage .SNPBody .SNPBVerificationImage {
    width: 60%;
    background: #000;
    position: relative;

    @media (min-width: 768px) and (max-width: 991px) {
        width: 50%;
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.SignupNextPage .SNPBody .SNPBVerificationImage .SNPBVIEmailPreview {
    position: absolute;
    right: 0;
    top: 24%;
    width: 74%;
    display: flex;

    @media screen and (min-width: 1800px) {
        width: 78%;
    }

    @media screen and (max-width: 1600px) {
        width: 70%;
    }

    &--img {
        border-radius: 10px 0 0 10px;
    }
}

.SignupNextPage .SNPBody .SNPBVerificationImage .SNPBVIEmailPreview .SNPBVIEPArrowPointer {
    position: absolute;
    top: -165px;
    left: -470px;
    z-index: 1;
    width: 550px;
    display: flex;

    @media screen and (max-width: 1200px) {
        left: -360px;
        width: 550px;
    }
}

//////////////////////////////////////////////////////////////////
@keyframes MoveIcon1 {
    0% {
        transform: translate(15px, 0px);
    }

    25% {
        transform: translate(15px, 15px);
    }

    50% {
        transform: translate(0px, 15px);
    }

    75% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(15px, 0px);
    }
}

@keyframes MoveIcon2 {
    0% {
        transform: translate(0px, 15px);
    }

    25% {
        transform: translate(-15px, 15px);
    }

    50% {
        transform: translate(-15px, 0px);
    }

    75% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(0px, 15px);
    }
}

@keyframes MoveIcon3 {
    0% {
        transform: translate(15px, 0px);
    }

    25% {
        transform: translate(15px, -15px);
    }

    50% {
        transform: translate(0px, -15px);
    }

    75% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(15px, 0px);
    }
}

@keyframes slideDownAppIconsSignup {
    from {
        opacity: 0;
        transform: translate3d(0, -100px, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
}

