.FilterBarMain {
    padding-bottom: 15px;
}

.FilterBarMain .FBMInner {
    padding: 10px;
    padding-bottom: 5px;
    background-color: var(--white);
    border-radius: 2px;
    box-shadow: 0 0 2px #e8eef9;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.FBFilterItem {
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
}

.FBFilterItem .FBFIBtnOuter {
    display: block;
    position: relative;
}

.FBFilterItem .FBFIBtnOuter button.FBFIBtn {
    font-size: 12px;
    text-transform: initial;
    padding: 6px 7px;
    background-color: #e8eef9;
    color: #000;
    line-height: normal;
    border-radius: 4px 0px 0px 4px;
    border: 0;
}

.FBFilterItem .FBFIBtnOuter button.FBFIBtn span.buttonIcon {
    color: #000;
    font-size: 14px;
}

.FBFilterItem .FBFIBtnOuter:hover button.FBFIBtn {
    background-color: #cdddff;
    color: var(--dark-blue);
}

.FBFilterItem .FBFIBtnOuter:hover button.FBFIBtn span.buttonIcon {
    color: var(--dark-blue);
}

.FBFilterItem .FBFIBtnOuter button.FBFIBtn .buttonTitle {
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: normal;
}

.FBFilterItem .FBFIBtnOuter button.FBFIBtn .buttonTitle .FBFIBEnterText {
    font-weight: 400;
}

.FBFilterItem .FBFIBtnOuter .FBFICTooltip {
    display: inline-flex;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.FBFilterItem .FBFIBtnOuter button.FBFICloseBtn {
    font-size: 10px;
    padding: 8px;
    background-color: #e8eef9;
    color: var(--dark-blue);
    border: 0;
    border-radius: 0;
    box-shadow: -17px 0px 3px #e8eef9f0;
    transition: all ease 0.3s;
    opacity: 0;
    visibility: hidden;
}

.FBFilterItem .FBFIBtnOuter:hover button.FBFICloseBtn {
    background-color: #cdddff;
    box-shadow: -17px 0px 3px #cdddffa3;
    color: var(--dark-blue);
    opacity: 1;
    visibility: visible;
}

.FilterItemCondition {
    display: inline-block;
    margin-bottom: 5px;
}

.FilterItemCondition button.FICBtn {
    font-size: 12px;
    font-weight: 600;
    text-transform: initial;
    padding: 6px 7px;
    background-color: #e8eef9;
    line-height: normal;
    border-radius: 0;
    min-width: 40px;
    border: 0;
}

.FilterItemCondition button.FICBtn.FICBActive,
.FilterItemCondition button.FICBtn:hover {
    color: var(--dark-blue);
    background-color: #cdddff;
}

.FilterItemCondition.GroupCondition .FICBtn {
    background-color: unset;
}

.FilterItemCondition.GroupCondition button.FICBtn.FICBActive,
.FilterItemCondition.GroupCondition button.FICBtn:hover {
    color: var(--dark-blue);
    background-color: unset;
}

.FilterItemConditionDropdown .DCDSOptionList .DropdownOptionsBtn.mainButton {
    font-size: 12px;
    padding: 7px 7px;
    line-height: normal;
    font-weight: 500;
    color: #000;
    background-color: var(--white);
    transition: all ease 0.3s;
    position: relative;
}

.FilterItemConditionDropdown .DCDSOptionList .DropdownOptionsBtn.mainButton:hover,
.FilterItemConditionDropdown .DCDSOptionList .DropdownOptionsBtn.mainButton.active {
    color: var(--dark-blue);
}

.FBFIFieldWrapper .FBFIFieldList {
    padding: 15px;
}

.FBFIFieldWrapper .FBFIFSave button {
    display: flex;
    width: 100%;
    border: 0;
    border-radius: 0;
    color: var(--dark-blue);
    border-top: 1px solid #e7f0f9;
}

.FBFIFieldWrapper .FBFIFieldList label.radioButton.fullWidth {
    margin-bottom: 5px;
}

.FBFIFieldWrapper .FBFIFieldList label.radioButton span.radioButtonIcon {
    font-size: 16px;
}

.FBFIFieldWrapper .FBFIFieldList label.radioButton span.radioButtonLabel {
    font-size: 14px;
    line-height: normal;
}

.FBFIFieldWrapper .FBFIFieldList .FBFIFLItem {
    margin-bottom: 10px;
}

.FBFIFieldWrapper .FBFIFieldList .FBFIFLItem .FIDORORGOInput {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.FBFIFieldWrapper .FBFIFieldList .FBFIFLItem:last-child {
    margin-bottom: 0px;
}

.FBFIFieldWrapper .FBFIFieldList .FBFIFLItem .DCInputWrapper {
    padding-left: 25px;
    width: auto;
}

.FBFIFieldWrapper .FBFIFieldList .FBFIFLItem .DatePickerCalendar {
    padding-left: 25px;
}

.CreateFilterDropdown {
    display: inline-block;
    margin-bottom: 5px;
}

.CreateFilterDropdown .FBFIATooltip {
    display: inline-block;
}

.CreateFilterDropdown .FBFIAddBtn {
    font-size: 13px;
    text-transform: initial;
    padding: 6px 7px;
    background-color: #e8eef9;
    line-height: normal;
    border-radius: 0 4px 4px 0px;
    color: #000;
    border-left: 1px solid #fff;
}

.CreateFilterDropdown.AutoCompleteRoot .ACRLabel.SingleSelection {
    padding: 0;
    border: 0;
    background-color: unset;
}

.CreateFilterDropdown.AutoCompleteRoot .ACRLabel::after {
    display: none;
}

.CreateFilterDropdown .FBFIAddFilterBtn {
    font-size: 12px;
    text-transform: initial;
    padding: 6px 7px;
    background-color: unset;
    line-height: normal;
    border-radius: 4px 0px 0px 4px;
    color: var(--dark-blue);
    margin-left: 10px;
    border: 0;
}

.FilterBarMain .FBMInner .FBMISaveSegBtn {
    font-size: 12px;
    text-transform: initial;
    padding: 6px 7px;
    background-color: unset;
    line-height: normal;
    border-radius: 4px 0px 0px 4px;
    color: var(--dark-blue);
    margin-left: 10px;
    border: 0;
    margin-bottom: 5px;
    min-width: auto;
}

.FilterBarMain .FBMInner .FBMIClearBtn {
    font-size: 12px;
    text-transform: initial;
    padding: 6px 7px;
    background-color: unset;
    line-height: normal;
    border-radius: 4px 0px 0px 4px;
    color: #f44336;
    margin-left: 10px;
    margin-bottom: 5px;
    min-width: auto;
    border: 0;
}

.FBMISavePopover {
    padding: 10px;
}

.FBMISavePopover .FBFIFLShare {
    display: flex;
}

.FBMISavePopover .FBFIFLShare label {
    width: 50%;
}

.FBFilterItem .FBFIBtnOuter.filterError button.FBFIBtn {
    background-color: #fed9db;
    color: #fd3a57;
}

.FBFilterItem .FBFIBtnOuter.filterError button.FBFIBtn span.buttonIcon {
    color: #fd3a57;
}

.FBFilterItem .FBFIBtnOuter.filterError:hover button.FBFICloseBtn {
    background-color: #fdd9db;
    box-shadow: -17px 0px 3px #fdd9dbe0;
    color: #fd3a57;
}

.FBFIFieldWrapper
    .FBFIFieldList
    .FBFIFLItem
    .DCPhoneNumberWrapper
    .react-tel-input
    .flag-dropdown
    ul.country-list {
    margin-top: -175px;
    width: 270px;
}
