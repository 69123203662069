.AlertDialog {
    border-radius: 4px;
}

.AlertMain {
    display: flex;
    flex-direction: column;
}

.AlertMain .AMIHead {
    text-align: center;
    padding: 25px;
    color: var(--dark-blue);
}

.AlertMain .AMIHead .AMIHTitle {
    font-size: 22px;
    font-weight: 500;
    padding-top: 10px;
}

.AlertMain .AMIBody {
    padding: 25px;
    background-color: #e8eef9;
    border-top: 1px solid #cad3e3;
}

.AlertMain .AMIBody .AMIBInner {
    text-align: center;
}

.AlertMain .AMIBody .AMIBInner .AMIBIDes {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 25px;
}

.AlertMain .AMIBody .AMIBInner .AMIBIBtns {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}
