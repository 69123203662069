.DatePicker .DatePickerInput {
    position: relative;
}

.DatePicker .DatePickerInput .DPIIcon {
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 18px;
    color: #7b89a4;
    display: flex;
}

.DatePicker .DatePickerInput input {
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #7b89a4;
    padding: 10px;
    padding-right: 36px;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
    position: relative;
    z-index: 1;
}

.DatePicker .DatePickerInput input:focus {
    border-color: var(--dark-blue);
    box-shadow: 0 0 3px var(--dark-blue);
}

.DPPopover .PopoverMainInner .PMIContent .DPMonthYearContainer {
    display: flex;
    column-gap: 5px;
}

.DatePickerCalendar .nice-dates-day::before {
    background-color: var(--dark-blue);
}

.DatePickerCalendar .nice-dates-day::after {
    border-color: var(--dark-blue);
}

.DatePickerCalendar .nice-dates-day span.nice-dates-day_month {
    display: none;
}
