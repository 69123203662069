.AFRContainer {
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    margin-bottom: 15px;
}

.AFRContainer .AFRCCol1 {
    width: 35%;
    background-color: var(--white);
    border-radius: 4px;
}

.AFRUserCol .AFRUCInfo {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f6f8fc;
}

.AFRUserCol .AFRUCInfo .AFRUCIImg {
    display: flex;
    width: 50px;
    height: 50px;
}

.AFRUserCol .AFRUCInfo .AFRUCIImg img {
    border-radius: 50%;
}

.AFRUserCol .AFRUCInfo .AFRUCIImg svg {
    height: 100%;
    width: 100%;
}

.AFRUserCol .AFRUCInfo .AFRUCIDet {
    padding-left: 15px;
    flex: 1;
}

.AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDLevel {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    padding-bottom: 2px;
}

.AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDSDate {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.AFRUserCol .AFRReferralLink {
    padding: 15px;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo {
    background-color: #f6f8fc;
    padding: 15px;
    border-radius: 4px;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLITitle {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLLink {
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLBtn {
    font-size: 20px;
    color: #666;
    display: flex;
    cursor: pointer;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLIProgress {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-top: 15px;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLIProgress .AFRRLIPNum {
    font-size: 15px;
    font-weight: 600;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLIProgress .AFRRLIPView {
    flex: 1;
    height: 25px;
    // background-color: var(--dark-blue);
    border-radius: 4px;
}

.AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLIProgress .FilledProgress {
    background-color: var(--dark-blue);
    height: 25px;
}

.AFRUserCol .AFRReferralLink .AFRRLIMore {
    margin-top: 15px;
    display: flex;
}

.AFRUserCol .AFRReferralLink .AFRRLIMore a {
    color: var(--dark-blue);
    font-size: 15px;
    font-weight: 500;
}

.AFRContainer .AFRCCol2 {
    width: 65%;
    background-color: var(--white);
    border-radius: 4px;
}

.AFRContainer .AFRCCol2b {
    justify-content: center;
    display: flex;
    align-items: center;
}

.AFRLeaderBoard .AFRLBHead {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f6f8fc;
    column-gap: 15px;
}

.AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox {
    display: flex;
    align-items: center;
    flex: 1;
}

.AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo {
    padding-right: 15px;
    border-right: 1px solid #f6f8fc;
    display: flex;
}

.AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
    height: 50px;
    width: 215px;
    object-fit: contain;
}

.AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
    padding-left: 15px;
    font-size: 22px;
    font-weight: 600;
    color: #666;
    flex: 1;
}

.AFRLeaderBoard .AFRLBody {
    padding: 15px;
    display: flex;
    column-gap: 15px;

    &--NoData {
        justify-content: center;
    }
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard {
    width: calc(50% - 7.5px);
    padding: 15px;
    border: 1px solid #ddd;
    background-color: #f6f8fc;
    border-radius: 4px;

    @media screen and (max-width: 991px) {
        width: 100%;
    }
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 15px;
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow:last-child {
    margin-bottom: 0px;
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow .AFRLBBNum {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow .UserInfo.AFRLBBTitle {
    flex: 1;
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow .UserInfo.AFRLBBTitle .UIDetailName {
    color: #000;
    font-size: 16px;
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow .UserInfo.AFRLBBTitle .UIImg {
    width: 30px;
    height: 30px;
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow .AFRLBBIcon {
    font-size: 18px;
    display: flex;
}

.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow .AFRLBBIcon.AFRLBBIUp {
    color: #49e194;
}
.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow .AFRLBBIcon.AFRLBBIDown {
    color: #fe4f4c;
}
.AFRLeaderBoard .AFRLBody .AFRLBBoard .AFRLBBRow .AFRLBBIcon.AFRLBBINeutral {
    color: #b6b6b6;
}

.AFRNAL .AFRNALHead {
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.AFRNAL .AFRNALBody {
    padding: 15px;
    display: flex;
    align-items: center;
}

.AFRNAL .AFRNALBody .AFRNALBInfo {
    flex: 1;
    padding-right: 15px;
}

.AFRNAL .AFRNALBody .AFRNALBInfo .AFRNALBITitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-blue);
}

.AFRNAL .AFRNALBody .AFRNALBInfo .AFRNALBISTitle {
    color: #666;
    font-size: 14px;
    padding-top: 3px;
}

.AFRNAL .AFRNALBody .AFRNALBIcon {
    font-size: 50px;
    display: flex;
    border: 5px solid #f1f8ff;
    border-radius: 50%;
}

.AFRDPEF {
    display: flex;
    padding: 15px;
    column-gap: 15px;
    flex: 1;
}

.AFRDPEF .AFRDPEFCol {
    width: 50%;
    display: flex;
    column-gap: 15px;
}

.AFRDPEF .AFRDPEFCol .AFRDPEFCIcon {
    font-size: 40px;
    display: flex;
}

.AFRDPEF .AFRDPEFCol .AFRDPEFCInfo {
    flex: 1;
}

.AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCITitle {
    font-size: 22px;
    font-weight: 600;
    color: #000;
}

.AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIPrice {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 0;
}

.AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIEarn {
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.AFRThreeCol {
    display: flex;
    column-gap: 15px;
}

.AFRThreeCol .AFRTCol1 {
    width: calc(35% - 5px);
    height: 100%;
    display: flex;
    column-gap: 15px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.AFRThreeCol .AFRTCol1 .AFRTCCol {
    width: 50%;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.AFRThreeCol .AFRTCol2 {
    width: 65%;
    background-color: var(--white);
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.AFRTCCol {
    background-color: var(--white);
}

.AFRTCCol .AFRTCCHead {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
}

.AFRTCCol .AFRTCCInfo {
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #f6f8fc;
}

.AFRTCCol .AFRTCCInfo .AFRTCCITitle {
    font-size: 16px;
    font-weight: 600;
    color: #666;
    padding-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
}
.AFRTCCITitle > * {
    margin-left: 5px;
}

.AFRTCCITitleToolTip > .PopoverMainInner {
    margin: 0 !important;
    margin-bottom: 0 !important;
}

.AFRTCCol .AFRTCCInfo .AFRTCCISTitle {
    color: var(--dark-blue);
    font-size: 25px;
    font-weight: 600;
}

.AFRTTable {
    display: flex;
    flex-direction: column;
    height: 100%;
}

// Responsive
@media (min-width: 1560px) and (max-width: 1800px) {
}

@media (min-width: 1367px) and (max-width: 1559.98px) {
    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDLevel {
        font-size: 16px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDSDate {
        font-size: 12px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIImg {
        width: 40px;
        height: 40px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLITitle {
        font-size: 16px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLLink {
        font-size: 14px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLBtn {
        font-size: 18px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLIMore a {
        font-size: 13px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
        width: 150px;
        height: 36px;
        object-fit: contain;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
        font-size: 18px;
    }

    .AFRNAL .AFRNALHead {
        font-size: 16px;
    }

    .AFRNAL .AFRNALBody .AFRNALBIcon {
        font-size: 40px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCIcon {
        font-size: 30px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCITitle {
        font-size: 20px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIPrice {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCHead {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCInfo .AFRTCCITitle {
        font-size: 14px;
    }
}

@media (min-width: 1280px) and (max-width: 1366.98px) {
    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDLevel {
        font-size: 16px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDSDate {
        font-size: 12px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIImg {
        width: 40px;
        height: 40px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLITitle {
        font-size: 16px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLLink {
        font-size: 14px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLBtn {
        font-size: 18px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLIMore a {
        font-size: 13px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
        width: 150px;
        height: 36px;
        object-fit: contain;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
        font-size: 18px;
    }

    .AFRNAL .AFRNALHead {
        font-size: 16px;
    }

    .AFRNAL .AFRNALBody .AFRNALBIcon {
        font-size: 40px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCIcon {
        font-size: 30px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCITitle {
        font-size: 20px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIPrice {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCHead {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCInfo .AFRTCCITitle {
        font-size: 14px;
    }
}

@media (min-width: 1024px) and (max-width: 1279.98px) {
    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDLevel {
        font-size: 16px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDSDate {
        font-size: 12px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIImg {
        width: 40px;
        height: 40px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLITitle {
        font-size: 16px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLLink {
        font-size: 14px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLBtn {
        font-size: 18px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLIMore a {
        font-size: 13px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
        width: 150px;
        height: 36px;
        object-fit: contain;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
        font-size: 18px;
    }

    .AFRNAL .AFRNALHead {
        font-size: 16px;
    }

    .AFRNAL .AFRNALBody .AFRNALBIcon {
        font-size: 40px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCIcon {
        font-size: 30px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCITitle {
        font-size: 20px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIPrice {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCHead {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCInfo .AFRTCCITitle {
        font-size: 14px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDLevel {
        font-size: 16px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDSDate {
        font-size: 12px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIImg {
        width: 40px;
        height: 40px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLITitle {
        font-size: 16px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLLink {
        font-size: 14px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLBtn {
        font-size: 18px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLIMore a {
        font-size: 13px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
        width: 150px;
        height: 36px;
        object-fit: contain;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
        font-size: 18px;
    }

    .AFRNAL .AFRNALHead {
        font-size: 16px;
    }

    .AFRNAL .AFRNALBody .AFRNALBIcon {
        font-size: 40px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCIcon {
        font-size: 30px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCITitle {
        font-size: 20px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIPrice {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCHead {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCInfo .AFRTCCITitle {
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDLevel {
        font-size: 16px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDSDate {
        font-size: 12px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIImg {
        width: 40px;
        height: 40px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLITitle {
        font-size: 16px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLLink {
        font-size: 14px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLBtn {
        font-size: 18px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLIMore a {
        font-size: 13px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
        width: 150px;
        height: 36px;
        object-fit: contain;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
        font-size: 18px;
    }

    .AFRNAL .AFRNALHead {
        font-size: 16px;
    }

    .AFRNAL .AFRNALBody .AFRNALBIcon {
        font-size: 40px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCIcon {
        font-size: 30px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCITitle {
        font-size: 20px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIPrice {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCHead {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCInfo .AFRTCCITitle {
        font-size: 14px;
    }
    .AFRContainer {
        flex-wrap: wrap;
    }

    .AFRContainer .AFRCCol1 {
        width: 100%;
    }

    .AFRContainer .AFRCCol2 {
        width: 100%;
    }

    .PCRViewMain .PCRViewContainer .PCRVBInner.AFRPageView {
        height: auto;
    }

    .AFRThreeCol {
        flex-wrap: wrap;
    }

    .AFRThreeCol .AFRTCol1 {
        width: 100%;
    }

    .AFRThreeCol {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .AFRThreeCol .AFRTCol1 .AFRTCCol {
        height: auto;
    }

    .AFRThreeCol .AFRTCol2 {
        width: 100%;
        min-height: 400px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDLevel {
        font-size: 16px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDSDate {
        font-size: 12px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIImg {
        width: 40px;
        height: 40px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLITitle {
        font-size: 16px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLLink {
        font-size: 14px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLBtn {
        font-size: 18px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLIMore a {
        font-size: 13px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
        width: 150px;
        height: 36px;
        object-fit: contain;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
        font-size: 18px;
    }

    .AFRNAL .AFRNALHead {
        font-size: 16px;
    }

    .AFRNAL .AFRNALBody .AFRNALBIcon {
        font-size: 40px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCIcon {
        font-size: 30px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCITitle {
        font-size: 20px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIPrice {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCHead {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCInfo .AFRTCCITitle {
        font-size: 14px;
    }

    .AFRContainer {
        flex-wrap: wrap;
    }

    .AFRContainer .AFRCCol1 {
        width: 100%;
    }

    .AFRContainer .AFRCCol2 {
        width: 100%;
    }

    .PCRViewMain .PCRViewContainer .PCRVBInner.AFRPageView {
        height: auto;
    }

    .AFRThreeCol {
        flex-wrap: wrap;
    }

    .AFRThreeCol .AFRTCol1 {
        width: 100%;
    }

    .AFRThreeCol {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .AFRThreeCol .AFRTCol1 .AFRTCCol {
        height: auto;
    }

    .AFRThreeCol .AFRTCol2 {
        width: 100%;
        min-height: 400px;
    }
}

@media (max-width: 575.98px) {
    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDLevel {
        font-size: 16px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIDet .AFRUCIDSDate {
        font-size: 12px;
    }

    .AFRUserCol .AFRUCInfo .AFRUCIImg {
        width: 40px;
        height: 40px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLITitle {
        font-size: 16px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLLink {
        font-size: 14px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLInfo .AFRRLICopyLink .AFRRLICLBtn {
        font-size: 18px;
    }

    .AFRUserCol .AFRReferralLink .AFRRLIMore a {
        font-size: 13px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
        width: 150px;
        height: 36px;
        object-fit: contain;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
        font-size: 18px;
    }

    .AFRNAL .AFRNALHead {
        font-size: 16px;
        padding: 10px;
    }

    .AFRNAL .AFRNALBody {
        padding: 10px;
    }

    .AFRDPEF {
        padding: 10px;
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .AFRDPEF .AFRDPEFCol {
        width: 100%;
    }

    .AFRNAL .AFRNALBody .AFRNALBIcon {
        font-size: 40px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCIcon {
        font-size: 30px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCITitle {
        font-size: 20px;
    }

    .AFRDPEF .AFRDPEFCol .AFRDPEFCInfo .AFRDPEFCIPrice {
        font-size: 16px;
    }

    .AFRTCCol .AFRTCCHead {
        font-size: 16px;
        padding: 10px;
    }

    .AFRTCCol .AFRTCCInfo {
        padding: 10px;
    }

    .AFRTCCol .AFRTCCInfo .AFRTCCITitle {
        font-size: 14px;
    }
    .AFRContainer {
        flex-wrap: wrap;
    }

    .AFRContainer .AFRCCol1 {
        width: 100%;
    }

    .AFRContainer .AFRCCol2 {
        width: 100%;
    }

    .PCRViewMain .PCRViewContainer .PCRVBInner.AFRPageView {
        height: auto;
    }

    .AFRThreeCol {
        flex-wrap: wrap;
    }

    .AFRThreeCol .AFRTCol1 {
        width: 100%;
    }

    .AFRThreeCol {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .AFRThreeCol .AFRTCol1 .AFRTCCol {
        height: auto;
    }

    .AFRThreeCol .AFRTCol2 {
        width: 100%;
        min-height: 400px;
    }

    .AFRUserCol .AFRUCInfo {
        padding: 10px;
    }

    .AFRLeaderBoard .AFRLBHead {
        padding: 10px;
    }

    .AFRUserCol .AFRReferralLink {
        padding: 10px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo {
        padding-right: 5px;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBLogo img {
        width: 100px;
        height: auto;
    }

    .AFRLeaderBoard .AFRLBHead .AFRLBHTitleBox .AFRLBHTBTitle {
        padding-left: 5px;
        font-size: 14px;
    }

    .AFRLeaderBoard .AFRLBody {
        padding: 10px;
        row-gap: 15px;
        flex-wrap: wrap;
    }

    .AFRLeaderBoard .AFRLBody .AFRLBBoard {
        width: 100%;
    }
}
