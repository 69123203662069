.ManageProperties {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.ManageProperties .HeaderBar .HeaderBarLeft {
    display: flex;
    align-items: center;
    width: 70%;
}

.ManageProperties .HeaderBar .HeaderBarClose {
    flex: 1;
    justify-content: flex-end;
}

.ManageProperties .BodyBox {
    flex: 1;
}

.ManageProperties .BodyBox .BodyBoxInner {
    display: flex;
    align-items: center;
}

.ManageProperties .ManagePropertiesInner {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 70%;
}

.ManageProperties .ManagePropertiesInner .MPISidebar {
    width: 400px;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.MPISidebar .MPISInner {
    // height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.MPISidebar .MPISInner .MPISIUserInfo {
    padding: 5px;
    background-color: #f6f8fc;
    border-radius: 4px;
    border: 1px solid #e8edf8;
}

.MPISIAbout {
    background-color: #f6f8fc;
    border-radius: 4px;
    border: 1px solid #e8edf8;
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.MPISIAbout .MPISIAInner {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
}

.MPISIAbout .MPISIAInner .MPISIAINfo {
    padding: 20px;
    padding-bottom: 10px;
}

.MPISIAbout .MPISIAInner .MPISIAITitle {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
}

.MPISIAbout .MPISIAInner .MPISIAIDes {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    letter-spacing: 0.5px;
}

.MPISIAbout .MPISIAInner .MPISIAIAdded {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    padding: 15px 20px;
}

.MPISIAIAdded .MPISIAIAPNoData .NoDataInner {
    grid-row-gap: 0;
    row-gap: 0;
}

.MPISIAIAdded .MPISIAIAPInner .MPISIAIAPIITem {
    margin-bottom: 15px;
}

.MPISIAIAdded .MPISIAIAPInnerNonDraggable .MPISIAIAPIITem {
    margin-bottom: 15px;
}

.MPISIAIAdded .MPISIAIAPInner .MPISIAIAPIITem:last-child {
    margin-bottom: 0px;
}

.ManageProperties .ManagePropertiesInner .MPIRightSection {
    width: calc(100% - 400px);
    padding-left: 25px;
    height: 100%;
}

.MPIRightSection .MPIRSInner {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
    background-color: #f6f8fc;
    border-radius: 4px;
    border: 1px solid #e8edf8;
}

.MPIRightSection .MPIRSInner .MPIRSHeader {
    border-bottom: 1px solid #e8edf8;
    padding: 20px;
}

.MPIRSHeader .MPIRSHInfo {
    flex: 1;
    overflow: hidden;
    padding-right: 25px;
}

.MPIRSHeader .MPIRSHInfo .MPIRSHTitle {
    font-size: 24px;
    line-height: normal;
    padding-bottom: 7px;
}

.MPIRightSection .MPIRSInner .MPIRSBody {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.MPIRightSection .MPIRSInner .MPIRSBody .MPIRSBInner {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.MPIRSBody .MPIRSBInner .MPIRSBIHead {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e8edf8;
    background-color: var(--white);
}

.MPIRSBody .MPIRSBInner .MPIRSBIHead .SearchBox {
    flex: 1;
    margin-right: 20px;
}

.MPIRSBody .MPIRSBInner .MPIRSBIHead .SearchBox input {
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 25px;
}

.MPIRSBody .MPIRSBInner .MPIRSBIHead .MPIRSHBtn {
    display: flex;
}

.MPIRSBody .MPIRSBInner .MPIRSBIHead .MPIRSHBtn button {
    padding: 11px;
}

.MPIRSBody .MPIRSBInner .MPIRSBIBody {
    flex: 1;
    padding: 20px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns {
    display: flex;
    align-items: center;
    padding: 7px;
    background-color: var(--white);
    border: 1px solid #e8edf8;
    border-radius: 4px;
    margin-bottom: 10px;
}
.MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns:last-child {
    margin-bottom: 0px;
}

.MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITTitle {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITTitle .ToolTipWrapper svg {
    margin-left: 5px;
}

.MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITOptions {
    display: flex;
}

.MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITOptions button {
    border-radius: 0;
    margin-left: 5px;
}

.ManageProperties .BodyBox .BodyBoxInner .MPIIcon {
    padding-left: 50px;
    display: flex;
    width: 30%;
    align-self: flex-end;
}

.ManageProperties .BodyBox .BodyBoxInner .MPIIcon svg {
    width: 80%;
    height: 100%;
}
.ManageProperties .ButtonWrapper {
    display: flex;
    justify-content: flex-start;
}

.ManageProperties .ButtonWrapper button {
    margin: 0px 5px 0px 5px;
}

.MPSectionTitle {
    font-size: 22px;
    line-height: normal;
    padding: 10px 0px;
    padding-bottom: 15px;
}

@media (min-width: 1024px) and (max-width: 1367px) {
    .ManageProperties .ManagePropertiesInner {
        width: 100%;
    }
    .ManageProperties .BodyBox .BodyBoxInner .MPIIcon {
        display: none;
    }

    .ManageProperties .ManagePropertiesInner .MPISidebar {
        width: 350px;
    }

    .ManageProperties .ManagePropertiesInner .MPIRightSection {
        width: calc(100% - 350px);
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .ManageProperties .ManagePropertiesInner {
        width: 100%;
    }
    .ManageProperties .BodyBox .BodyBoxInner .MPIIcon {
        display: none;
    }

    .ManageProperties .ManagePropertiesInner .MPISidebar {
        width: 350px;
    }

    .ManageProperties .ManagePropertiesInner .MPIRightSection {
        width: calc(100% - 350px);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .ManageProperties .ManagePropertiesInner {
        width: 100%;
    }
    .ManageProperties .BodyBox .BodyBoxInner .MPIIcon {
        display: none;
    }

    .ManageProperties .ManagePropertiesInner .MPISidebar {
        width: 300px;
    }

    .ManageProperties .ManagePropertiesInner .MPIRightSection {
        width: calc(100% - 300px);
    }

    .MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITTitle {
        font-size: 16px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .ManageProperties .ManagePropertiesInner {
        width: 100%;
    }
    .ManageProperties .BodyBox .BodyBoxInner .MPIIcon {
        display: none;
    }

    .ManageProperties .ManagePropertiesInner .MPISidebar {
        width: 100%;
    }

    .ManageProperties .ManagePropertiesInner .MPIRightSection {
        width: 100%;
        padding-top: 25px;
        padding-left: 0;
    }

    .MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITTitle {
        font-size: 16px;
    }
}

@media (max-width: 575.98px) {
    .ManageProperties .ManagePropertiesInner {
        width: 100%;
    }
    .ManageProperties .BodyBox .BodyBoxInner .MPIIcon {
        display: none;
    }

    .ManageProperties .ManagePropertiesInner .MPISidebar {
        width: 100%;
    }

    .ManageProperties .ManagePropertiesInner .MPIRightSection {
        width: 100%;
        padding-top: 25px;
        padding-left: 0;
    }

    .MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns {
        flex-direction: column;
    }

    .MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITTitle {
        font-size: 16px;
        width: 100%;
        padding-right: 0;
        padding-bottom: 7px;
        border-bottom: 1px solid #e8edf8;
    }

    .MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITOptions {
        width: 100%;
        padding-top: 0px;
        flex-wrap: wrap;
    }

    .MPIRSBIBody .MPIRSBIBInner .MPIRSBIBITitleBtns .MPIRSBIBITOptions button {
        margin-left: 0px;
        margin-right: 5px;
        margin-top: 5px;
    }

    .ManageProperties .HeaderBar .HeaderBarLeft {
        width: 85%;
    }
}
