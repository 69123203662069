.DragBoxMain {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px;
    padding-left: 27px;
    position: relative;
    background-color: #e8eef9;
    border: 1px solid #cad3e3;
    border-radius: 4px;
    margin-bottom: 12px;
}

.DragBoxMain:last-child {
    margin-bottom: 0px;
}

.DragBoxMain::before {
    content: '';
    background-image: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 5 24' ><path d='M0 0h2v2H0V0zm0 8h2v2H0V8zm0 8h2v2H0v-2zM0 4h2v2H0V4zm0 8h2v2H0v-2zm0 8h2v2H0v-2zM3 0h2v2H3V0zm0 8h2v2H3V8zm0 8h2v2H3v-2zM3 4h2v2H3V4zm0 8h2v2H3v-2zm0 8h2v2H3v-2z' fill='rgb(0 0 0)' /></svg>");
    position: absolute;
    left: 5px;
    top: 12px;
    transition: all ease 0.3s;
    background-repeat: no-repeat;
    width: 6px;
    height: 24px;
    z-index: 1;
}

.DragBoxMain.dragDisabled::before {
    display: none;
    content: none;
}

.DragBoxMain .DBMTitle {
    flex: 1 1;
    font-size: 16px;
    color: #000;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DragBoxMain .DBMBtns {
    display: flex;
    align-items: center;
}

.DragBoxMain .DBMBtns .iconButton.DBMBBtn:first-child {
    margin-left: 0px;
}

.DragBoxMain .DBMBtns .iconButton.DBMBBtn {
    border: 0;
    margin-left: 10px;
    background-color: unset;
    font-size: 10px;
    padding: 5px;
}
