.UserDetailEdit {
    padding: 15px;
}

.UserDetailEdit .UDEInner {
    display: flex;
    align-items: flex-start;
}

.UserDetailEdit .UDEInner .UDEIImg {
    width: 80px;
    height: 80px;
    // display: flex;
    position: relative;
}

.UserDetailEdit .UDEInner .UDEIImg input[type='file'] {
    position: absolute;
    z-index: -1;
}

.UserDetailEdit .UDEInner .UDEIImg .UDEIImgInner {
    display: flex;
    font-size: 80px;
    overflow: hidden;
    position: relative;
    border-radius: 100%;
}

.UserDetailEdit .UDEInner .UDEIImg .UDEIImgInner img {
    cursor: pointer;
    object-fit: contain;
}

.UserDetailEdit .UDEInner .UDEIInfoEdit {
    flex: 1 1;
    padding-left: 15px;
    overflow: hidden;
    display: flex;
    align-items: baseline;
}

.UserDetailEdit .UDEInner .UDEIInfo {
    padding-right: 40px;
    overflow: hidden;
}

.UserDetailEdit .UDEInner .UDEIInfo .UDEIIName {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.UserDetailEdit .UDEInner .UDEIInfo .UDEIIJob {
    padding-top: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.UserDetailEdit .UDEInner .UDEIIBtn {
    display: flex;
}

.UserDetailEdit .UDEInner .UDEIIBtn .iconButton {
    background-color: unset;
    border: 0;
    padding: 4px;
    font-size: 16px;
    color: var(--dark-blue);
}

.ContactPreview .UserDetailEdit.UDECenter .UDEInner .UDEIInfoEdit {
    margin: 10px 0;
}

.UserDetailEdit .UDEInner .UDEIIStatus {
    font-size: 14px;
    font-weight: 600;
    padding: 3px 5px;
    line-height: normal;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--blue-invert);
}

.UserDetailEditPopover .PopoverMainInner .PMIContent {
    padding: 20px;
}

.UserDetailEditPopover .UDEPBtns {
    display: flex;
}

.UserDetailEditPopover .UDEPBtns button {
    margin-right: 10px;
}

.CropImageDialog .UICPBtns {
    display: flex;
    justify-content: flex-end;
}

.CropImageDialog .UICPBtns button {
    margin-left: 10px;
}

.CropImageDialog {
    padding: 15px;
}

.imageDropdownPopover .PopoverMainInner::before {
    top: -6px;
    left: 62px;
    visibility: visible;
}

.UserDetailEditPopover.ArrowPopover .PopoverMainInner::before {
    top: -5px;
    left: 3px;
    visibility: visible;
}

.UserDetailEdit.UDECenter .UDEInner {
    flex-direction: column;
    align-items: center;
}

.UserDetailEdit.UDECenter .UDEInner .UDEIInfoEdit {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    text-align: center;
}

.UserDetailEdit.UDECenter .UDEInner .UDEIInfoEdit .UDEIInfo {
    padding-right: 0px;
}

.UserDetailEdit.UDECenter .UDEInner .UDEIInfoEdit .UDEIIBtn {
    position: absolute;
    right: 0px;
    top: 5px;
}

.UserDetailEdit .UDEInner .UDEIImg.UDEIImgSquare {
    width: 180px;
}

.UserDetailEdit .UDEInner .UDEIImg .UDEIImgInner img {
    cursor: pointer;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.UDEIImg.UDEIImgSquare .UserImageEdit {
    border-radius: 4px;
}

.UserDetailEdit .UDEInner .UDEIImg.UDEIImgSquare .UDEIImgInner {
    border-radius: 4px;
    width: 100%;
    height:80px;
}

.UserDetailEdit .UDEInner .UDEIImg.UDEIImgSquare .UDEIImgInner.UDEIIISvg {
    justify-content: center;
}

.UserDetailEdit .UDEInner .UDEIInfo .UDEIWebsiteLink {
    display: inline-block;
    color: var(--dark-blue);
}
