.IRTLPCFSChart {
    background-color: var(--white);
    position: relative;
    border-radius: 4px;
    box-shadow: 0 0 3px #fff;
    display: flex;
    flex-direction: column;
}

.IRTLPCFSChart .IRTLPCFSCHead {
    padding: 0 15px;
    padding-top: 15px;
}

.IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHTitle {
    font-size: 20px;
    font-weight: 600;
    color: #666666;
}

.IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
    font-size: 26px;
    color: #666666;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle strong {
    color: #000;
}

.IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle span {
    display: flex;
    font-size: 20px;
}

.IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle span.IRTLPCFSCHSdown {
    color: #f44336;
}

.IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle span.IRTLPCFSCHSup {
    color: #4caf50;
}

.ColumnChart {
    background-color: var(--white);
    border-radius: 4px;
}

.ColumnChart .ColumnChartHead {
    padding: 15px;
    display: flex;
    align-items: center;
}

.ColumnChart .ColumnChartHead .CCHTitle {
    flex: 1;
    font-size: 20px;
    font-weight: 600;
    color: #666666;
}

.ColumnChart .ColumnChartHead .CCHOptions {
    display: flex;
}

.ColumnChart .ColumnChartHead .CCHOptions button.mainButton {
    min-width: 120px;
    padding: 10px 10px;
}

.ColumnChart .ColumnChartHead .CCHOptions button.mainButton span.buttonTitle {
    text-align: left;
}

.ColumnChart .ColumnChartGraph .highcharts-xaxis-labels > span {
    font-size: 16px !important;
    font-weight: 600;
    color: #666666 !important;
}

.ColumnChart .ColumnChartGraph .highcharts-yaxis-labels > span {
    font-size: 16px !important;
    font-weight: 500;
    color: #666666 !important;
}

.ChartSeries {
    padding: 7.5px 15px;
    display: flex;
    align-items: center;
    border-top: 1px solid #f6f8fc;
    flex-wrap: wrap;
}

.ChartSeries .CSItem {
    display: flex;
    align-items: center;
    margin-right: 25px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
}

.ChartSeries .CSItem .CSIDot {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.ChartSeries .CSItem .CSITitle {
    flex: 1;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: normal;
}

.AreaSplineChart {
    width: 100%;
    overflow: auto;
}

//

.IRTLPCFSChart.IRTLPCFSCLoader {
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.IRTLPCFSChart.IRTLPCFSCLoader .IRTLPCFSCHead {
    position: relative;
    padding: 5px;
}

.IRTLPCFSChart.IRTLPCFSCLoader .IRTLPCFSCHSTitle {
    display: flex;
    margin-top: 5px;
}

.IRTLPCFSChart.IRTLPCFSCLoader .IRTLPCFSCGraph {
    flex: 1;
    padding: 5px;
}

.ColumnChart .ColumnChartGraph {
    width: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.ColumnChart .ColumnChartGraph > div {
    overflow: auto !important;
}

.IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHOther {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}
.CircleChartGraph .highcharts-container {
    width: 100% !important;
}

.CircleChartGraph text.highcharts-title {
    font-size: 44px !important;
    font-weight: 600;
    fill: #000 !important;
}

.CircleChartGraph text.highcharts-subtitle {
    font-size: 22px !important;
    font-weight: 600;
    fill: #666 !important;
}

.AreaSplineChart .highcharts-yaxis-labels > span {
    font-size: 20px !important;
    font-weight: 500;
    color: #666666 !important;
}

.AreaSplineChart .highcharts-xaxis-labels > span {
    font-size: 16px !important;
    font-weight: 600;
    color: #666666 !important;
}

.AreaChart .highcharts-yaxis-labels > span {
    font-size: 20px !important;
    font-weight: 500;
    color: #666666 !important;
}

.AreaChart .highcharts-xaxis-labels > span {
    font-size: 16px !important;
    font-weight: 600;
    color: #666666 !important;
}

.APGPGraph .AreaChart {
    padding: 15px;
    width: 100%;
    overflow: auto;
}

// Responsive
@media (min-width: 1560px) and (max-width: 1800px) {
    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
        font-size: 18px;
    }

    .ColumnChart .ColumnChartHead .CCHTitle {
        font-size: 16px;
        font-weight: 500;
    }
}

@media (min-width: 1367px) and (max-width: 1559px) {
    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
        font-size: 18px;
    }
}

@media (min-width: 1281px) and (max-width: 1366px) {
    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHTitle {
        font-size: 14px;
        font-weight: 500;
    }

    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
        font-size: 12px;
    }

    .ColumnChart .ColumnChartHead .CCHTitle {
        font-size: 16px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHTitle {
        font-size: 16px;
    }

    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
        font-size: 14px;
    }

    .ColumnChart .ColumnChartHead .CCHTitle {
        font-size: 16px;
    }

    .ColumnChart .ColumnChartGraph .highcharts-yaxis-labels > span {
        font-size: 13px !important;
    }

    .ColumnChart .ColumnChartGraph .highcharts-xaxis-labels > span {
        font-size: 13px !important;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHTitle {
        font-size: 16px;
    }

    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
        font-size: 14px;
    }

    .ColumnChart .ColumnChartHead .CCHTitle {
        font-size: 16px;
    }

    .ColumnChart .ColumnChartGraph .highcharts-yaxis-labels > span {
        font-size: 13px !important;
    }

    .ColumnChart .ColumnChartGraph .highcharts-xaxis-labels > span {
        font-size: 13px !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHTitle {
        font-size: 16px;
    }

    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
        font-size: 14px;
    }

    .ColumnChart .ColumnChartHead .CCHTitle {
        font-size: 16px;
    }

    .ColumnChart .ColumnChartGraph .highcharts-yaxis-labels > span {
        font-size: 13px !important;
    }

    .ColumnChart .ColumnChartGraph .highcharts-xaxis-labels > span {
        font-size: 13px !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHTitle {
        font-size: 16px;
    }

    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
        font-size: 14px;
    }

    .ColumnChart .ColumnChartHead .CCHTitle {
        font-size: 16px;
    }

    .ColumnChart .ColumnChartGraph .highcharts-yaxis-labels > span {
        font-size: 13px !important;
    }

    .ColumnChart .ColumnChartGraph .highcharts-xaxis-labels > span {
        font-size: 13px !important;
    }
}

@media (max-width: 575.98px) {
    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHTitle {
        font-size: 16px;
    }

    .IRTLPCFSChart .IRTLPCFSCHead .IRTLPCFSCHSTitle {
        font-size: 14px;
    }

    .ColumnChart .ColumnChartHead .CCHTitle {
        font-size: 16px;
    }

    .ColumnChart .ColumnChartGraph .highcharts-yaxis-labels > span {
        font-size: 13px !important;
    }

    .ColumnChart .ColumnChartGraph .highcharts-xaxis-labels > span {
        font-size: 13px !important;
    }
}
