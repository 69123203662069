.accordionRoot .accordionRootInner {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
}

.accordionRoot.collapsed .accordionRootInner {
    border-bottom: 0;
}

.accordionRoot .accordionRootInner .arIHeader {
    position: relative;
}

.accordionRoot .accordionRootInner .arIHeader .arIHInner {
    padding: 15px 15px;
    border-bottom: 1px solid #eaeaea;
    padding-left: 40px;
    cursor: pointer;
    user-select: none;
    position: relative;
}

.accordionRoot .accordionRootInner .arIHeader .arIHInner.right {
    padding: 15px;
    padding-right: 40px;
}

.accordionRoot .accordionRootInner .arIHeader .arIHInner::before {
    content: '';
    position: absolute;
    left: 15px;
    top: 21px;
    transition: all ease 0.3s;
    width: 11px;
    height: 11px;
    z-index: 1;
    border-right: 3px solid #000;
    border-top: 3px solid #000;
    border-radius: 2px;
    transform: rotate(45deg);
}

.accordionRoot .accordionRootInner .arIHeader .arIHInner.right::before {
    content: '';
    position: absolute;
    right: 15px;
    left: unset;
    top: 21px;
    transition: all ease 0.3s;
    width: 11px;
    height: 11px;
    z-index: 1;
    border-right: 3px solid #000;
    border-top: 3px solid #000;
    border-radius: 2px;
    transform: rotate(45deg);
}

.accordionRoot .accordionRootInner .arIHeader.active .arIHInner::before {
    transform: rotate(135deg);
    top: 18px;
    border-color: var(--dark-blue);
}

.accordionRoot .accordionRootInner .arIHeader .arIHInner .arIHITitle {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

.accordionRoot .accordionRootInner .arIBody {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.accordionRoot .accordionRootInner .arIBody.active {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordionRoot .accordionRootInner .arIBody .arIBInner {
    padding: 15px;
}
