.DealInfo {
    background-color: #f0faff;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.DealInfo:last-child {
    margin-bottom: 0px;
}

.DealInfo .DealInfoInner .DIIHead {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.DealInfo .DealInfoInner .DIIHead .DIIHTitle {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-blue);
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    flex: 1;
}

.DealInfo .DealInfoInner .DIIHead .DIIHTitle a {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-blue);
}

.DealInfo .DealInfoInner .DIIHead .DIIHPrice {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    padding-left: 10px;
}

.DealInfo .DealInfoInner .DIIBody {
    display: flex;
    justify-content: flex-end;
}

.DealInfo .DealInfoInner .DIIBody button.mainButton.DIIBBtn {
    background-color: transparent;
    color: #000;
    font-weight: 600;
    border: 0;
    padding: 0;
    padding-right: 5px;
}

.DealInfoMain .DIMStages {
    display: flex;
    justify-content: space-between;
}

.DealInfoMain .DIMStages .DIMStem {
    height: 5px;
    background-color: #f6f8fc;
    border-radius: 15px;
}

.DealInfoMain .DIMStages .DIMStem.active {
    background-color: var(--dark-blue);
}
