.CreateProperty {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
}

.CreateProperty .BodyBox {
    flex: 1;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.CreatePropertyForm .CPFOptions {
    margin-bottom: 15px;
    max-height: 400px;
    overflow: scroll;
}

.CreatePropertyForm .CPFOptions .CPFOHead {
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #7b7b7b;
}

.CreatePropertyForm .CPFOptions .CPFOBody .CPFOBOption {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.CreatePropertyForm .CPFOptions .CPFOBody .CPFOBOption:last-child {
    padding-bottom: 0px;
}

.CreatePropertyForm .CPFOptions .CPFOBody .CPFOBOption .DCInputWrapper {
    flex: 1;
}

.CreatePropertyForm .CPFOptions .CPFOBody .CPFOBOption .CPFOBOBtns .iconButton {
    margin-left: 15px;
}

.fieldPreview {
    padding: 15px;
    background-color: #f6f8fc;
    border: 1px solid #eaeaea;
    border-radius: 4px;
}
