.CreateProfilePage {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.CreateProfilePage .CPCHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    padding: 15px;
}

.CreateProfilePage .CPCHeader .CPCHeaderContent .CPCHCHeading {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 5px;
    display: inline;

    @media screen and (max-width: 1200px) {
        font-size: 18px;
    }
}

.CreateProfilePage .CPCHeader .CPCHeaderContent .CPCHCHeading + .CPCHCBCIAccountStatus {
    font-size: 14px;
    padding: 3px 4px;
    color: white;
    border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #fff;
    text-decoration: none;
    margin-left: 0.8rem;
}

.CreateProfilePage .CPCHeader .CPCHeaderContent .CPCHCBreadCrum {
    display: flex;
    align-items: center;
    margin-top: 0.26rem;

    & > *:not(:first-child) {
        margin-left: 0.6rem;
    }

    & > *:last-child {
        text-decoration: underline;
    }
}

.CreateProfilePage .CPCHeader .CPCHeaderContent .CPCHCBreadCrum .CPCHCBCItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #fff;
}

.CreateProfilePage .CPCHeader .CPCHeaderContent .CPCHCBreadCrum .CPCHCBCIIcon svg {
    fill: #fff;
}

.CreateProfilePage .CPCHeader .CPCHeaderLogo {
    display: flex;
    justify-content: center;
}

.CreateProfilePage .CPCHeader .CPCHeaderLogo img {
    width: 100%;
    max-height: 42px;
    object-fit: contain;
    object-position: left;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    @media screen and (max-width: 1200px) {
        max-height: 29.3px;
    }
}

.CreateProfilePage .CPCBody {
    display: flex;
    flex: 1;
    overflow: hidden;
}
.CreateProfilePage .CPCBody .CPCBForm {
    width: 30%;
    background: #000;
    overflow-y: auto;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 40%;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
.CreateProfilePage .CPCBody .CPCBForm .CPCBFHeader {
    padding: 35px;
    padding-bottom: 0;
    color: #fff;
    text-align: center;

    @media screen and (max-width: 1199px) {
        padding: 15px;
    }

    @media screen and (max-width: 575px) {
        padding: 20px;
        padding-bottom: 0;
    }
}
.CreateProfilePage .CPCBody .CPCBForm .CPCBFHeader .CPCBFHHeading {
    font-weight: 600;
    margin-bottom: 5px;
}
.CreateProfilePage .CPCBody .CPCBForm .CPCBFHeader .CPCBFHSubHeading {
    color: #ffffff60;
    font-size: 1rem;
}
.CreateProfilePage .CPCBody .CPCBForm .CPCBFMainContent {
    padding: 35px;
    position: relative;

    .fieldControl:not(:last-of-type) {
        margin-bottom: 28px;
    }

    .fieldControl:last-of-type {
        margin-bottom: 0;
    }

    .fieldControl .DCPhoneNumberWrapper {
        .PhoneInput {
            border: none;

            .AutoCompleteRoot .SingleSelection {
                padding-top: 17px;
                padding-bottom: 17px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &::after {
                    top: 18px;
                }
            }

            .PhoneInputInput {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .PhoneInputInput {
            padding: 18px 10px;
        }
    }
}

.CPCBFMainContent--border::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: calc(100% - 70px);
    height: 1px;
    background: #333;
    margin: 0 auto;
}

//////////////////////////////////////
.CreateProfilePage .CPCBody .CPCBBrandingImage {
    width: 70%;
    padding: 35px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.CreateProfilePage .CPCBody .CPCBForm .CPCBFMainContent .CPCBFMCFieldLabel {
    color: #bab9b9;
    font-size: 14px;
}

.CreateProfilePage .CPCBody .CPCBForm .CPCBFMainContent .CPCBFMCFieldInput {
    border: none !important;
    input {
        padding: 1rem 15px;
        font-size: 1rem;
        color: #333;
        font-weight: 600;

        &:disabled {
            cursor: no-drop;
            background: #b8b8b8;
            color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
            border: none !important;
        }
    }
}

.CPCBFMCFieldInput.create_profile_email {
    background: #fff !important;
    color: #000 !important;
    border: 1px solid #e7f0f9;
    border-radius: 4px;
    transition: all ease 0.1s;
    outline: 0;
    width: 100%;
}

.check-warning {
    border: 1px solid red !important;
}

.CreateProfilePage .CPCBody .CPCBForm .CPCBFCTABtn {
    padding: 0 35px !important;
    padding-bottom: 20px !important;
    margin-bottom: 0;

    .CPCBFCTABButton {
        padding: 15px;
        border-radius: 100px;
        background-image: linear-gradient(90deg, #0083ff 0, #0161bd 100%);
        color: #fff;

        &:hover {
            background-image: linear-gradient(90deg, #0083ff 0, #0161bd 100%);
        }

        .buttonTitle {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
        }

        .CPCBFCTABButtonIcon {
            transform: translateX(-0.55rem);
        }
    }
    .CPCBFCTABButtonRed {
        padding: 15px;
        border-radius: 100px;
        background-image: linear-gradient(90deg, #ff0000 0, #ff0000 100%);
        color: #fff;
        transition: all 0.3s ease;

        &:disabled {
            opacity: 1;
            background: #990b07;
            border: #990b07;
            color: #d9a3a1;
        }

        &:hover {
            opacity: 0.85;
        }

        .buttonTitle {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
        }

        .CPCBFCTABButtonIcon {
            transform: translateX(-0.55rem);
        }
    }
}

.SACPPhoneView {
    border: 1px solid #cbced4;
    border-radius: 5px;
    padding: 1rem 15px;
    font-size: 1rem;
    color: #333;
    font-weight: 600;
    background-color: #b8b8b8;
}
.SAPHCMessage{
    color: green;
    padding-top: 5px;
}

