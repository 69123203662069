.ManageColumnsHeader {
    flex: 1;
    display: flex;
    align-items: center;
}

.MCGridContainer.GridContainer {
    position: relative;
    flex: 1;
    height: 500px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.ManageColumns .GridContainer .GridContainerRow {
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.MCGridContainer.GridContainer .GridContainerRow .GCRCol {
    padding: 0;
    height: 100%;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    box-shadow: 0 4px 34px 0 #00000010;
}

.ManageColumns .MCColumnsCol {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    padding: 30px 40px;
    background-color: #fcfcfc;
    height: 100%;
}

.ManageColumns .MCColumnsCol .MCColSearch {
    padding-bottom: 20px;
}

.ManageColumns .MCColumnsCol .MCColHead {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.ManageColumns .MCColumnsCol .MCColHead .MCColHTitle {
    flex: 1;
}

.ManageColumns .MCColumnsCol .MCColHead .MCColHCheck {
    display: flex;
}

.ManageColumns .MCColumnsCol .MCColList {
    padding: 20px;
    background-color: var(--white);
    border-radius: 4px;
    flex: 1 1;
    max-height: 300px;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
}

.ManageColumns .MCColumnsCol .MCColList .MCColLInner .MCColLItem {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #e8eef9;
}

.ManageColumns .MCColumnsCol .MCColList .MCColLInner .MCColLItem .MCCILabel {
    display: flex;
    align-items: center;
}

.ManageColumns .MCColumnsCol .MCColList .MCColLInner .MCColLItem .MCCILabel .ToolTipWrapper svg {
    margin-left: 5px;
}

.ManageColumns .MCColumnsCol .MCColList .MCColLInner .MCColLItem:last-child {
    padding-bottom: 0px;
    border-bottom: 0;
}

.ManageColumns .MCColumnsCol .MCColFooter .MCColFText {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-top: 15px;
}

.ManageColumns .MCColumnsCol .MCColFooter .MCColFText span {
    color: var(--dark-blue);
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}

.ManageColumns .MCColumnsCol .MCColFooter .MCColFText span:hover {
    text-decoration: underline;
}

.ManageColumns .MCColumnsCol .MCColFooter .MCColFBtns {
    padding-top: 20px;
}

.ManageColumns .MCColumnsCol .MCColFooter .MCColFBtns button {
    margin-right: 15px;
}

.ManageColumns .MCSelectedColumns {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    padding: 30px 40px;
    height: 100%;
}

.ManageColumns .MCSelectedColumns .MCSCHeader {
    padding-bottom: 20px;
}

.ManageColumns .MCSelectedColumns .MCSCHeader h5 {
    line-height: 36px;
}

.ManageColumns .MCSelectedColumns .MCSCBody {
    flex: 1;
    overflow: auto;
    // overflow-y: overlay;
    overflow-x: overlay;
    max-height: 400px;
}

.ManageColumns .MCSelectedColumns .MCSCBody .MCSCBDrag {
    margin-bottom: 15px;
}

.ManageColumns .MCColumnsCol .MCColList .MCColLInner .MCColLItem .MCColHCCBtn {
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s;
}

.ManageColumns .MCColumnsCol .MCColList .MCColLInner .MCColLItem:hover .MCColHCCBtn {
    visibility: visible;
    opacity: 1;
}
.ManageColumns .MCColumnsCol .MCColList .MCColLInner .MCColLItem .MCColHCCBtn button {
    margin-left: 10px;
    background-color: unset;
    border: 0;
    padding: 0;
    font-size: 16px;
}

.ManageColumns .MCColumnsCol .MCColSearch .SearchBox input {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ManageColumns {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    overflow-x: overlay;
    height: 100%;
}

@media (min-width: 680px) and (max-width: 767.98px) {
    .ManageColumns .topBar.DialogTopBar {
        padding: 0 25px;
    }
    .ManageColumns .MCColumnsCol {
        padding: 25px 25px;
    }
    .ManageColumns .MCSelectedColumns {
        padding: 25px 25px;
    }
}

@media (max-width: 679.98px) {
    .ManageColumns .topBar.DialogTopBar {
        padding: 0 25px;
    }
    .ManageColumns .MCColumnsCol {
        padding: 25px 25px;
    }
    .ManageColumns .MCSelectedColumns {
        padding: 25px 25px;
        border-top: 1px solid #e8eef9;
    }
    .MCGridContainer.GridContainer .GridContainerRow .GCRCol {
        flex: 0 0 100%;
        max-width: 100%;
        height: auto;
        box-shadow: unset;
    }
}
